import {
  InvitesAppCreateApiRes,
  InvitesAppCreateApiArg,
  InvitesAppGetApiRes,
  InvitesAppGetApiArg,
  InvitesProjectCreateApiRes,
  InvitesProjectCreateApiArg,
  InvitesProjectGetApiRes,
  InvitesProjectGetApiArg,
  InviteAddMemberApiRes,
  InviteAddMemberApiArg,
  InviteDeleteApiRes,
  InviteDeleteApiArg,
  InvitesUserGetApiRes,
  InvitesUserGetApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    invitesAppCreate: build.mutation<
      InvitesAppCreateApiRes,
      InvitesAppCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/invite/app/${queryArg.appHandle}`,
        method: "POST",
        body: queryArg.invitesAppReq,
      }),
    }),
    invitesAppGet: build.query<InvitesAppGetApiRes, InvitesAppGetApiArg>({
      query: (queryArg) => ({ url: `/invite/app/${queryArg.appHandle}` }),
    }),
    invitesProjectCreate: build.mutation<
      InvitesProjectCreateApiRes,
      InvitesProjectCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/invite/project/${queryArg.projectHandle}`,
        method: "POST",
        body: queryArg.invitesProjectReq,
      }),
    }),
    invitesProjectGet: build.query<
      InvitesProjectGetApiRes,
      InvitesProjectGetApiArg
    >({
      query: (queryArg) => ({
        url: `/invite/project/${queryArg.projectHandle}`,
      }),
    }),
    inviteAddMember: build.mutation<
      InviteAddMemberApiRes,
      InviteAddMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/invite/${queryArg.kind}/${queryArg.inviteId}`,
        method: "PATCH",
      }),
    }),
    inviteDelete: build.mutation<InviteDeleteApiRes, InviteDeleteApiArg>({
      query: (queryArg) => ({
        url: `/invite/${queryArg.kind}/${queryArg.inviteId}`,
        method: "DELETE",
      }),
    }),
    invitesUserGet: build.query<InvitesUserGetApiRes, InvitesUserGetApiArg>({
      query: () => ({ url: `/invite/user` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as inviteApi };
