import { DateRange, Dropdown, OptionButton, T2, T3 } from "library/components";
import { BasePaths } from "library/consts";
import { ProjectStateOptions } from "./StateOptions";
import { TeamTypeOptionsSmall } from "./TeamOptions";
import { ProjectState, TeamType } from "library/types";

type CardOptions = {
  projectHandle: string;
  number: number;
  teamType: TeamType;
  state: ProjectState;
  end?: string;
  start?: string;
};

export const ProjectCardOptions = ({
  projectHandle,
  number,
  teamType,
  state,
  end,
  start,
}: CardOptions) => {
  const link = `launchspace.app/${BasePaths.p}/${projectHandle}`;

  return (
    <>
      <OptionButton
        justifyContent="space-between"
        borderRadius="8px"
        marginBottom="8px"
        showBorder
        textActive
        onClick={() => {
          navigator.clipboard.writeText(number.toString());
        }}
      >
        <T3 normal>Project #</T3>
        <T2 medium>{number}</T2>
      </OptionButton>

      <OptionButton
        justifyContent="space-between"
        borderRadius="8px"
        marginBottom="20px"
        showBorder
        textActive
        onClick={() => {
          navigator.clipboard.writeText(`https://${link}`);
        }}
      >
        <T3 normal marginRight="12px">
          Link:
        </T3>
        <T2 ellipsis medium>
          {link}
        </T2>
      </OptionButton>

      <Dropdown
        heading="Team"
        handleSelect={() => {}}
        selectValue={teamType}
        options={TeamTypeOptionsSmall}
        buttonProps={{ showBorder: true }}
        wrapperProps={{ width: "100%", marginBottom: "8px" }}
        hideChevron
        disabled
      />

      <Dropdown
        heading="State"
        handleSelect={() => {}}
        selectValue={state}
        options={ProjectStateOptions}
        buttonProps={{ showBorder: true }}
        wrapperProps={{ width: "100%", marginBottom: "8px" }}
        hideChevron
      />

      {/* {projectName && (
        <OptionButton
          display="block"
          borderRadius="8px"
          marginBottom="8px"
          showBorder
          textActive
        >
          <T3 marginBottom="4px" normal>
            Project
          </T3>
          <T2 ellipsis medium>
            {projectName}
          </T2>
        </OptionButton>
      )} */}

      <DateRange
        end={end}
        handleSelectEnd={() => {}}
        handleSelectStart={() => {}}
        start={start}
        // disabled
      />
    </>
  );
};
