import { ExternalLinkIcon } from "library/assets";
import {
  A,
  CardCheckmarks,
  Div,
  Flex,
  TxTypeDescriptions,
  UserIconNameHandle,
} from "library/components";
import { TokenSymbol } from "library/types";
import { formatDateLong, formatNumber, formatPercent } from "library/utils";
import { ProjectMembersRes } from "redux/apiTypes";
import { MemberFinancialType } from "./FinancialsUtil";
import { getExplorerUrl } from "utils";

export const ProjectPaymentsRowRender = (
  d: ProjectMembersRes[number],
  _: number,
  rowData?: {
    amount: number;
    tokenSymbol: string;
    financialValues: MemberFinancialType;
  }
) => {
  const { amount, tokenSymbol, financialValues: fv } = rowData || {};
  const fvid = fv?.[d.member.componentAddress];

  return [
    <Div display="flex" alignItems="center">
      <UserIconNameHandle
        icon={d.member.icon}
        memberHandle={d.member.memberHandle}
        name={d.member.name}
        small
      />
    </Div>,
    fvid ? (
      <CardCheckmarks
        count={fv[d.member.componentAddress].completed || 0}
        total={fv[d.member.componentAddress].objectives || 0}
        justifyCenter
      />
    ) : (
      "--"
    ),
    amount && fvid ? formatPercent(fvid.amount / amount) : "--",
    fvid
      ? `${formatNumber(fvid.amount)} ${tokenSymbol || TokenSymbol.XRD}`
      : "--",
    fvid
      ? `${formatNumber(fvid.paid)} ${tokenSymbol || TokenSymbol.XRD}`
      : "--",
  ];
};

export const ProjectTransactionsRowRender = (
  d: Record<string, string | undefined>,
  _: number,
  rowData?: { members: ProjectMembersRes }
) => {
  const from = d.from_handle
    ? rowData?.members.find((m) => m.member.memberHandle === d.from_handle)
    : null;
  const fromLabel =
    d.from_handle === d.contract_handle ? "📜 \xa0 Contract" : "--";
  const to = d.to_handle
    ? rowData?.members.find((m) => m.member.memberHandle === d.to_handle)
    : null;
  const toLabel = d.to_handle === d.contract_handle ? "📜 \xa0 Contract" : "--";

  return [
    d.id,
    from ? (
      <Div display="flex" alignItems="center">
        <UserIconNameHandle
          icon={from.member.icon}
          memberHandle={from.member.memberHandle}
          name={from.member.name}
          small
        />
      </Div>
    ) : (
      fromLabel
    ),
    to ? (
      <Div display="flex" alignItems="center">
        <UserIconNameHandle
          icon={to.member.icon}
          memberHandle={to.member.memberHandle}
          name={to.member.name}
          small
        />
      </Div>
    ) : (
      toLabel
    ),
    d.tx_type ? TxTypeDescriptions[d.tx_type] || "" : "--",
    d.amount
      ? `${formatNumber(d.amount)} ${d.symbol ? d.symbol : TokenSymbol.XRD}`
      : "--",
    d.epoch ? formatDateLong(Number(d.epoch) * 1000) : "--",
    <Flex center>
      <A
        padding="4px"
        href={
          d.contract_address
            ? `${getExplorerUrl(d.contract_address)}/recent-transactions`
            : "/"
        }
        target="_blank"
        $useOpacity
      >
        <ExternalLinkIcon size={16} />
      </A>
    </Flex>,
  ];
};
