import { Div, Flex, H1, Img, Link, PX, T1, T20 } from "library/components";
import { AppIconName } from "./CardHeading";
import { Card } from "./Card";
import { GoldScryptoIcon, ScryptoIcon } from "library/assets";
import { Colors, TeamTypeDescriptions } from "library/consts";
import { Category, TeamType } from "library/types";
import { BasePaths } from "consts";

type ProjectPageCardProps = {
  category: Category;
  name: string;
  teamType: TeamType;
  image: string | null;
  appName: string;
  appHandle: string;
  icon: string;
};

export const ProjectPageCard = ({
  category,
  name,
  teamType,
  image,
  appName,
  appHandle,
  icon,
}: ProjectPageCardProps) => (
  <Card zIndex="1" margin="32px 0" borderRadius="12px">
    <Img
      height="100%"
      width="100%"
      position="absolute"
      top="0"
      left="0"
      zIndex="2"
      src={
        image ||
        "https://c4.wallpaperflare.com/wallpaper/39/346/426/digital-art-men-city-futuristic-night-hd-wallpaper-preview.jpg"
      }
      alt={name}
    />

    <Div
      position="relative"
      zIndex="3"
      backgroundColor="rgba(28, 28, 40, 0.55)"
      color={Colors.white}
      padding="32px"
      fullHeight
      fullWidth
    >
      <Flex alignCenter>
        {teamType === TeamType.BLUEPRINT && (
          <Flex marginRight="8px" alignCenter>
            <ScryptoIcon />
          </Flex>
        )}
        {teamType === TeamType.AUDIT && (
          <Flex marginRight="8px" alignCenter>
            <GoldScryptoIcon />
          </Flex>
        )}
        <T20 medium>{TeamTypeDescriptions[teamType]}</T20>
      </Flex>

      <H1 fontSize={PX(42)} margin="20px 0" medium>
        {name}
      </H1>

      <Div
        display="block"
        sm={{ display: "flex", justifyContent: "space-between" }}
      >
        <Link to={`/${BasePaths.a}/${appHandle}`} $useOpacity>
          <Flex alignCenter>
            <AppIconName icon={icon} name={appName} medium />
          </Flex>
        </Link>
        <Flex marginTop="24px" sm={{ marginTop: "0" }} alignCenter>
          <T1 medium>{category.toLowerCase()}</T1>
        </Flex>
      </Div>
    </Div>
  </Card>
);
