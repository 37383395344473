import { MemberInviteType } from "library/types";
import { VestingModal } from "./VestingModal";
import { vestingApi } from "redux/apis/vesting-api";
import { awaitTo, parseRtkError } from "library/utils";
import { VestingReqType } from "schema";
import { AppContract, adminCreateVestingCall, useRadixCall } from "radix";
import { AuthUserRes } from "redux/apiTypes";

export const VestingCreateModal = ({
  hideModal,
  show,
  auth,
  contract,
  members,
  appHandle,
  refetchVestings,
}: {
  hideModal: () => void;
  show: boolean;
  auth: AuthUserRes;
  contract: AppContract | undefined;
  members: MemberInviteType[];
  appHandle: string;
  refetchVestings: () => Promise<void>;
}) => {
  const [handleCreate, { error, isLoading }] =
    vestingApi.useVestingCreateMutation();

  const [contractCall, { error: errorC, isLoading: isLoadingC }] = useRadixCall(
    adminCreateVestingCall
  );

  const onSubmit = async (data: VestingReqType) => {
    if (!contract) {
      return;
    }
    // Get admin component from componentAddress
    // Record<adminAddress, componentAddress>
    const [adminComponent] =
      Object.entries(contract.admin_components).find(
        ([_, v]) => v === auth.componentAddress
      ) || [];
    if (!adminComponent) {
      return;
    }
    const contractRes = await contractCall(
      auth,
      adminComponent,
      data.memberAddress,
      data
    );
    if (!contractRes) {
      return;
    }
    const [, res] = await awaitTo(
      handleCreate({
        vestingBodyReq: {
          memberAddress: data.memberAddress,
          componentAddress: contractRes.address,
          name: data.name,
          description: data.description,
        },
        appHandle,
      }).unwrap()
    );
    if (res?.id) {
      setTimeout(() => {
        refetchVestings();
      }, 1500);
      return res;
    }
    return undefined;
  };

  return (
    <VestingModal
      show={show}
      hideModal={hideModal}
      members={members}
      handleCreate={onSubmit}
      error={parseRtkError(error) || errorC}
      isLoading={isLoading || isLoadingC}
    />
  );
};
