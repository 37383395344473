import { MemberType, TeamType } from "library/types";
import { ObjectiveReq as ObjectiveReqType } from "redux/apiTypes";
import { objectiveApi, projectApi } from "redux/apis";
import { awaitTo, parseRtkError } from "library/utils";
import { ObjectiveModal } from "./ObjectiveModal";

type ObjectiveCreateModalProps = {
  hideModal: () => void;
  show: boolean;
  members: MemberType[];
  objectiveNumber?: string | number;
  teamType: TeamType;
  projectHandle: string;
};

export const ObjectiveCreateModal = ({
  hideModal,
  show,
  members,
  objectiveNumber,
  teamType,
  projectHandle,
}: ObjectiveCreateModalProps) => {
  const [handleCreate, { error, isLoading }] =
    objectiveApi.useObjectiveCreateMutation();

  const {
    refetch,
    error: errorP,
    isLoading: isLoadingP,
    isFetching: isFetchingObjectives,
  } = projectApi.useProjectGetObjectivesQuery({ projectHandle });

  const onSubmit = async (data: ObjectiveReqType) => {
    const [, obj] = await awaitTo(
      handleCreate({
        objectiveReq: data,
        projectHandle,
      }).unwrap()
    );
    if (obj?.id) {
      await refetch();
      return obj;
    }
    return undefined;
  };

  return (
    <ObjectiveModal
      show={show}
      hideModal={hideModal}
      members={members}
      objectiveNumber={objectiveNumber}
      teamType={teamType}
      error={parseRtkError(error || errorP)}
      isLoading={isLoading || isLoadingP || isFetchingObjectives}
      handleCreate={(data) => onSubmit(data)}
    />
  );
};
