import { Helmet } from "react-helmet-async";
import { css } from "styled-components";
import { useProject } from "hooks";
import {
  Card,
  CreatedUpdatedCard,
  Div,
  Dropdown,
  Flex,
  Gallery,
  LoadingPage,
  MdRender,
  MemberCardOptions,
  P,
  Page,
  ProjectCardOptions,
  ProjectPageCard,
  ReviewStatusOptions,
  Tags,
} from "library/components";
import { getYoutubeUrls } from "utils";

export const Project = () => {
  const { profile: data, members, error, isLoading } = useProject();

  if (!data || !members || data.isLocked || error || isLoading) {
    return (
      <LoadingPage
        error={error}
        isLoading={isLoading}
        isLocked={data?.isLocked}
      />
    );
  }

  const profile = data.projectProfile;

  return (
    <Page>
      <Helmet>
        <title>{data.name} | Launchspace</title>
        <meta name="description" content={data.name} />
      </Helmet>

      <Div display="flex" flexDirection="column" md={{ flexDirection: "row" }}>
        <Div flex="1">
          <ProjectPageCard
            {...data}
            appName={data.app.name}
            appHandle={data.app.appHandle}
            icon={data.app.icon}
            category={data.category}
          />

          <Gallery
            data={[
              ...(data.projectProfile?.videoIds || []).map((v) =>
                getYoutubeUrls(v)
              ),
              ...(data.projectProfile?.images || []).map((s) => ({
                imageUrl: s,
              })),
            ]}
          />

          <Div marginTop="32px">
            {profile?.description ? (
              <MdRender text={profile.description} />
            ) : (
              <P lineHeight="2.5" fontStyle="italic" secondary>
                No description
              </P>
            )}
          </Div>
        </Div>

        <Div
          width="100%"
          marginTop="32px"
          md={{ width: "320px", marginLeft: "32px" }}
        >
          <Dropdown
            heading="Proposals"
            handleSelect={() => {}}
            selectValue={data.reviewStatus}
            options={ReviewStatusOptions.map((o) => ({
              value: o.value,
              label: (
                <Flex width="100%" center>
                  {o.label}
                </Flex>
              ),
            }))}
            buttonProps={{
              showBorder: true,
              css: css`
                background-color: ${({ theme }) => theme.cardBackgroundColor};
              `,
            }}
            wrapperProps={{ width: "100%" }}
            hideChevron
            disabled
          />

          <Card
            borderRadius="12px"
            marginTop="24px"
            padding="20px"
            overflow="visible"
          >
            <ProjectCardOptions
              number={data.number}
              projectHandle={data.projectHandle}
              teamType={data.teamType}
              state={data.state}
              start={data.startDate}
              end={data.endDate}
            />
          </Card>

          <Card
            borderRadius="12px"
            marginTop="24px"
            padding="20px"
            overflow="visible"
          >
            <MemberCardOptions
              members={members.map((m) => m.member)}
              amount={data.amount}
              tokenSymbol={data.tokenSymbol}
              showMembers
            />
          </Card>

          <Card
            borderRadius="12px"
            marginTop="24px"
            padding="20px"
            overflow="visible"
          >
            <CreatedUpdatedCard
              created={data.createdAt}
              updated={data.updatedAt}
            />
          </Card>

          <Div marginTop="24px">
            <Tags tags={data.tags.map((t) => t.name)} />
          </Div>
        </Div>
      </Div>
    </Page>
  );
};
