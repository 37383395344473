import { CheckmarkThin, XIcon } from "library/assets";
import { BaseButton, Flex, T3 } from "library/components";

type SaveButtonProps = {
  error?: string;
  isLoading?: boolean;
  isSuccess?: boolean;
  text?: string;
};

export const SaveButton = ({
  error,
  isLoading,
  isSuccess,
  text,
}: SaveButtonProps) => (
  <Flex marginTop="32px" gap="32px" alignCenter>
    <BaseButton width="100px" isLoading={isLoading}>
      {text || "Save"}
    </BaseButton>
    <Flex gap="4px" alignCenter>
      {isSuccess && <CheckmarkThin size={14} strokeWidth={3} />}
      {error && <XIcon size={14} strokeWidth={3} />}
      {(isSuccess || error) && <T3>{error || "Saved"}</T3>}
    </Flex>
  </Flex>
);
