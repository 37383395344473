import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  AppsTableCols,
  AppsTableHeadings,
  AppUrl,
  BasePaths,
  CategoryDescriptions,
  PageDescriptions,
  PageTitles,
  SortAppOptions,
  SortAppTypes,
} from "consts";
import {
  AppCard,
  BookmarkRowButton,
  Card,
  CardGrid,
  DataTable,
  Div,
  Divider,
  Dropdown,
  Flex,
  IconNameSubtitle,
  InfiniteList,
  InfiniteLoader,
  LargeCard,
  NoneCard,
  Page,
  SearchInput,
  Tab,
  TableButtons,
  TeamsPageOptions,
} from "library/components";
import {
  Category,
  StateFilter,
  TableStateEnum,
  TeamsPage,
} from "library/types";
import { Bookmark, ChevronDown } from "library/assets";
import { CategoryTabs } from "views/components";
import { formatNumber, isCategory } from "library/utils";
import { SearchAppsRes, SortApp } from "redux/apiTypes";
import { useMember, useSaved } from "hooks";
import { searchApi } from "redux/apis";
import { PageSize } from "library/consts";

export const Apps = () => {
  const navigate = useNavigate();
  const [tableState, setTableState] = useState<TableStateEnum>(
    TableStateEnum.GRID
  );

  const [search, setSearch] = useState("");
  const [category, setCategory] = useState<Category | string>(StateFilter.ALL);
  const [sort, setSort] = useState(SortAppTypes.MOST_REWARDS_ALL);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<SearchAppsRes>();
  const [isMounted, setIsMounted] = useState(false);
  const { saved } = useSaved();

  const { isSignedIn } = useMember();

  const { data, isLoading, isFetching } = searchApi.useSearchAppsQuery({
    category: isCategory(category) ? category : undefined,
    sort,
    search: search || undefined,
    skip: skip || undefined,
    isSaved: isSignedIn ? isSaved : false,
  });
  const isLoadingAll = isLoading || isFetching;

  // Infinite Loader
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    } else {
      setList(undefined);
      setSkip(0);
    }
  }, [search, category, sort, isSaved, isMounted]);

  const title = isCategory(category) ? CategoryDescriptions[category] : "";

  return (
    <Page>
      <Helmet>
        <title>{`${title || "All"} - ${PageTitles.apps}`}</title>
        <meta name="description" content={PageDescriptions.apps} />
        <link rel="canonical" href={`${AppUrl}/${BasePaths.apps}`} />
      </Helmet>

      <InfiniteList
        data={data}
        isLoading={isFetching}
        list={list}
        setList={(d) => {
          setList(d);
        }}
      />

      <Flex gap="8px" margin="32px 0" alignCenter>
        <SearchInput
          flex="1"
          handleSearch={(v) => setSearch(v)}
          isLoading={isLoadingAll}
        />
      </Flex>

      <CategoryTabs
        category={category}
        handleCategory={(v) => setCategory(v)}
      />

      <Div
        display="block"
        alignItems="center"
        margin="8px 0"
        sm={{ display: "flex", margin: "24px 0" }}
      >
        <Div flex="1" margin="32px 0 16px" sm={{ margin: "0 24px 0 12px" }}>
          <Dropdown
            handleSelect={(v) => {
              if (v === TeamsPage.MEMBERS) {
                navigate(`/${BasePaths.members}`);
              }
            }}
            selectValue={TeamsPage.APPS}
            options={TeamsPageOptions}
            icon={
              <Div display="flex" marginLeft="20px">
                <ChevronDown size={32} />
              </Div>
            }
          />
        </Div>

        <Div
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          margin="16px 0"
          sm={{ justifyContent: "flex-end", margin: "0" }}
        >
          <Dropdown
            handleSelect={(v) => setSort(v as SortApp)}
            selectValue={sort}
            options={SortAppOptions}
          />
          {isSignedIn && (
            <Tab
              height="40px"
              sm={{ padding: "12px" }}
              onClick={() => {
                setList(undefined);
                setIsSaved(!isSaved);
              }}
            >
              <Bookmark fill={isSaved ? "currentColor" : "none"} />
            </Tab>
          )}
          <Divider margin="0 16px" />
          <TableButtons
            tableState={tableState}
            setTableState={setTableState}
            showBlockButton
          />
        </Div>
      </Div>

      {(tableState === TableStateEnum.BLOCK ||
        tableState === TableStateEnum.GRID) &&
        list?.length === 0 && <NoneCard large />}

      {tableState === TableStateEnum.BLOCK &&
        list?.map((d) => <LargeCard key={d.id} {...d} />)}

      {tableState === TableStateEnum.GRID && (
        <CardGrid>
          {list?.map((d) => (
            <AppCard
              key={d.id}
              {...d}
              saved={saved?.savedApps.some((s) => s.appHandle === d.appHandle)}
            />
          ))}
        </CardGrid>
      )}

      {tableState === TableStateEnum.ROW && (
        <Card marginTop="32px">
          <DataTable
            cols={AppsTableCols}
            headings={AppsTableHeadings}
            data={list}
            rowRender={(d) => [
              <Flex alignCenter>
                <IconNameSubtitle
                  icon={d.icon}
                  name={d.name}
                  subtitle={d.subtitle}
                />
              </Flex>,
              d.category.toLowerCase(),
              formatNumber(d._count.members),
              formatNumber(d._count.projects),
              formatNumber(d.statsProject),
              formatNumber(d.statsVesting),
              formatNumber(d.statsTotal),
              <BookmarkRowButton
                saved={saved?.savedApps.some(
                  (s) => s.appHandle === d.appHandle
                )}
              />,
            ]}
            handleRowClick={(d) => {
              navigate(`/${BasePaths.a}/${d.appHandle}`);
            }}
          />
        </Card>
      )}

      <InfiniteLoader
        show={isFetching || data?.length === PageSize}
        callback={() => {
          if (list && isLoadingAll && data?.length === PageSize) {
            const newSkip = list.length;
            if (skip !== newSkip) {
              setSkip(newSkip);
            }
          }
        }}
      />
    </Page>
  );
};
