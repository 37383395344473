import React from "react";
import { Helmet } from "react-helmet-async";
import { PageDescriptions, PageTitles } from "consts";
import { Flex, H1, PX, Page } from "library/components";

export const NotFound = () => (
  <Page>
    <Helmet>
      <title>{PageTitles.notFound}</title>
      <meta name="description" content={PageDescriptions.notFound} />
    </Helmet>

    <Flex center column fullHeight>
      <H1 fontSize={PX(100)} bold>
        404
      </H1>
      <H1>Not Found</H1>
    </Flex>
  </Page>
);
