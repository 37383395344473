import { createGlobalStyle } from "styled-components";
import { TransitionConst, Width } from "library/consts";

export const GlobalStyle = createGlobalStyle`
  html {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    margin: 0;
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.backgroundColor};

    font-family: 'Red Hat Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    
    overflow-y: scroll;
    overscroll-behavior: none;
    @media only screen and (min-width: ${Width.SM}px) {
      overscroll-behavior: contain;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    --radix-connect-button-border-radius: 12px;
  }

  @media (hover: none) and (pointer: coarse) {
    html, body {
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  // --- Default Overrides ---
  h1, h2, h3, h4, h5, h6 { margin: 0; font-weight: 500; }
  p { margin: 0; }

  input {
    color: inherit;
    border: none;
    padding: 0;
    background-color: transparent;
    font-family: inherit;
    transition: ${TransitionConst};
  }
  /* Hide HTML5 Up and Down arrows. */
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] { -moz-appearance: textfield; }
  // input:focus { outline: none; }
  input:focus::placeholder { color: transparent; }
  input:disabled { border: none; background-color: transparent; }

  select {
    font-family: inherit;
  }
  option {
    font-family: inherit;
  }

  textarea {
    color: inherit;
    resize: none;
    border: none;
    padding: 0;
    background-color: transparent;
    white-space: pre-wrap;
    line-height: 1.5;
    font-family: inherit;
  }
  textarea:focus { resize: vertical; }
  textarea:focus::placeholder { color: transparent; }
  textarea:disabled { border: none; background-color: transparent; }

  a {
    color: inherit;
    text-decoration: none;
    transition: ${TransitionConst};
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
  a:active { color: inherit; }

  button {
    color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background-color: transparent;
    font-family: inherit;
    cursor: pointer;
    text-transform: none;
    user-select: none;
    transition: ${TransitionConst};
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  button::-moz-focus-inner { border: 0; }

  label {
    cursor: pointer;
    transition: ${TransitionConst};
  }

  img {
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  ul { margin: 0 0 0 15px; padding: 0; }
  ol { margin: 0 0 0 15px; padding: 0; }
  iframe { border: none; margin: 0; }

  // --- Fade Transition ---
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity ${TransitionConst};
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity ${TransitionConst};
  }
`;
