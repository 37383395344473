import { createAction } from "@reduxjs/toolkit";
import { ThemeOptionType } from "library/types";
import { AppDispatch } from "redux/store";

export const themeActionConsts = {
  SET_THEME: "SET_THEME",
};

const setTheme = (theme: ThemeOptionType) => async (dispatch: AppDispatch) =>
  dispatch(createAction<ThemeOptionType>(themeActionConsts.SET_THEME)(theme));

export const themeActions = {
  setTheme,
};
