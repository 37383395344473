import { TokenSymbol } from "../../types";
import { RadixIcon, USDCIcon } from "../../assets";
import { Flex } from "../Common";
import { XRD_ADDRESS } from "consts";

export const CoinOptions = [
  {
    label: (
      <Flex gap="8px" alignCenter>
        <RadixIcon />
        XRD
      </Flex>
    ),
    value: TokenSymbol.XRD,
  },
  {
    label: (
      <Flex gap="8px" alignCenter>
        <USDCIcon />
        USDC
      </Flex>
    ),
    value: TokenSymbol.USDC,
    disabled: true,
  },
];

export const CoinResourceOptions = [
  {
    label: (
      <Flex gap="8px" alignCenter>
        <RadixIcon />
        XRD
      </Flex>
    ),
    value: XRD_ADDRESS,
  },
  {
    label: (
      <Flex gap="8px" alignCenter>
        <USDCIcon />
        USDC
      </Flex>
    ),
    value: "--",
    disabled: true,
  },
];
