import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { css } from "styled-components";
import {
  DatePicker,
  Div,
  Dropdown,
  Flex,
  H3,
  ImageInput,
  Input,
  MdEditor,
  ReviewStatusOptions,
  ProjectStateOptions,
  T1,
  T2,
  TagInput,
  TeamTypeOptionsSmall,
  TwoColGrid,
  Error,
  BaseButton,
  CheckboxForm,
  Select,
  RadixButton,
  CoinOptions,
} from "library/components";
import { Colors } from "library/consts";
import { removeEmpty } from "library/utils";
import {
  ProjectProfileRes,
  ProjectReq as ProjectReqType,
  SearchTagsRes,
} from "redux/apiTypes";
import { ProjectReq } from "schema";
import { CheckmarkThin } from "library/assets";
import {
  Category,
  ProjectState,
  ReviewStatus,
  TeamType,
  TokenSymbol,
} from "library/types";
import { CategoryFormOptions } from "consts";

type ProjectSettingsFormProps = {
  appCategory?: Category;
  data?: ProjectProfileRes;
  error?: string;
  handleCreate?: (d: ProjectReqType) => void;
  handleUpdate?: (d: ProjectReqType) => void;
  handleSearch: (v: string) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  isLoadingTags?: boolean;
  suggestions?: SearchTagsRes;
};

export const ProjectSettingsForm = ({
  appCategory,
  data,
  error,
  handleCreate,
  handleUpdate,
  handleSearch,
  isLoading,
  isSuccess,
  isLoadingTags,
  suggestions,
}: ProjectSettingsFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectReqType>({
    resolver: zodResolver(ProjectReq),
    defaultValues: {
      ...(data ? removeEmpty(data) : {}),
      teamType: data?.teamType || TeamType.BLUEPRINT,
      state: data?.state || ProjectState.DRAFT,
      reviewStatus: data?.reviewStatus || ReviewStatus.OPEN,
      category: data?.category || appCategory,
      tags: data?.tags.map((t) => t.name) || [],
      tokenSymbol: data?.tokenSymbol || TokenSymbol.XRD,
      componentAddress: data?.componentAddress || "",
      amount: data ? Number(data.amount) : 0,
    },
  });
  const onSubmit = (d: ProjectReqType) =>
    data && handleUpdate
      ? handleUpdate(d)
      : handleCreate
      ? handleCreate(d)
      : console.log(d);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap="40px" flexDirection="column" sm={{ flexDirection: "row" }}>
        <Flex flex="1" flexDirection="column" gap="20px">
          {!data && <H3 marginBottom="4px">Create Project</H3>}
          <Input
            label="Project Name*"
            error={errors.name?.message}
            rProps={register("name")}
            iProps={{ showBorder: true }}
          />
          <Input
            label="Image Url*"
            error={errors.image?.message}
            rProps={register("image")}
            iProps={{ showBorder: true }}
          />

          <TwoColGrid>
            <Select
              label="Category*"
              error={errors.category?.message}
              rProps={register("category")}
              options={CategoryFormOptions}
              iProps={{ showBorder: true }}
            />
            <CheckboxForm
              label="Hide from Search"
              description="Hide project from being searched"
              error={errors.isHidden?.message}
              rProps={register("isHidden")}
            />
            <Controller
              control={control}
              name="projectProfile.images"
              render={({ field: { value, onChange } }) => (
                <ImageInput
                  formError={errors.projectProfile?.images?.message}
                  handleChange={onChange}
                  label="Image Urls*"
                  values={value}
                  iProps={{ showBorder: true }}
                />
              )}
            />
            <Controller
              control={control}
              name="projectProfile.videoIds"
              render={({ field: { value, onChange } }) => (
                <ImageInput
                  formError={errors.projectProfile?.videoIds?.message}
                  handleChange={onChange}
                  label="YouTube Video Ids"
                  values={value}
                  iProps={{ showBorder: true }}
                  isVideoId
                />
              )}
            />
          </TwoColGrid>

          <T1 medium>Description*</T1>
          <Controller
            control={control}
            name="projectProfile.description"
            render={({ field: { value, onChange } }) => (
              <MdEditor
                formError={errors.projectProfile?.description?.message}
                value={value}
                setValue={onChange}
                style={{ height: "420px" }}
              />
            )}
          />
        </Flex>

        <Flex
          gap="20px"
          flexDirection="column"
          width="100%"
          sm={{ width: "300px" }}
        >
          {/* <Flex
              borderRadius="8px"
              padding="8px 12px"
              css={css`
                border: 2px solid ${({ theme }) => theme.borderColor};
              `}
              justifyBetween
            >
              <T3 normal>Project #</T3>
              <T2 medium>{projectNumber}</T2>
            </Flex> */}

          <Controller
            control={control}
            name="teamType"
            render={({ field: { value, onChange } }) => (
              <Div>
                <Dropdown
                  heading="Team"
                  handleSelect={onChange}
                  selectValue={value}
                  options={TeamTypeOptionsSmall}
                  wrapperProps={{ width: "100%" }}
                  buttonProps={{ minHeight: "56px", showBorder: true }}
                  hideChevron
                />
                {errors.teamType?.message && (
                  <T2 color={Colors.red} marginTop="8px" medium>
                    {errors.teamType.message}
                  </T2>
                )}
              </Div>
            )}
          />
          <Controller
            control={control}
            name="state"
            render={({ field: { value, onChange } }) => (
              <Div>
                <Dropdown
                  heading="State"
                  handleSelect={onChange}
                  selectValue={value}
                  options={ProjectStateOptions}
                  wrapperProps={{ width: "100%" }}
                  buttonProps={{ minHeight: "56px", showBorder: true }}
                  hideChevron
                />
                {errors.state?.message && (
                  <T2 color={Colors.red} marginTop="8px" medium>
                    {errors.state.message}
                  </T2>
                )}
              </Div>
            )}
          />

          <Div>
            <Flex
              position="relative"
              flexWrap="nowrap"
              borderRadius="8px"
              css={css`
                border: ${({ theme }) => `2px solid ${theme.borderColor}`};
              `}
            >
              <Controller
                control={control}
                name="startDate"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    handleSelectDate={(v) => onChange(v || "")}
                    label="Start"
                    date={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="endDate"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    handleSelectDate={(v) => onChange(v || "")}
                    label="End"
                    date={value}
                  />
                )}
              />
            </Flex>
            {(errors.startDate?.message || errors.endDate?.message) && (
              <T2 color={Colors.red} marginTop="8px" medium>
                {errors.startDate?.message || errors.endDate?.message}
              </T2>
            )}
          </Div>

          <Controller
            control={control}
            name="reviewStatus"
            render={({ field: { value, onChange } }) => (
              <Div>
                <Dropdown
                  heading="Proposals"
                  handleSelect={onChange}
                  selectValue={value}
                  options={ReviewStatusOptions.map((o) => ({
                    value: o.value,
                    label: (
                      <Flex width="100%" center>
                        {o.label}
                      </Flex>
                    ),
                  }))}
                  wrapperProps={{ width: "100%" }}
                  buttonProps={{ showBorder: true }}
                  hideChevron
                />
                {errors.reviewStatus?.message && (
                  <T2 color={Colors.red} marginTop="8px" medium>
                    {errors.reviewStatus.message}
                  </T2>
                )}
              </Div>
            )}
          />

          <Controller
            control={control}
            name="tokenSymbol"
            render={({ field: { value, onChange } }) => (
              <Dropdown
                heading="Token"
                handleSelect={(v) => onChange(v)}
                selectValue={value}
                options={CoinOptions}
                buttonProps={{ showBorder: true }}
                disabled={!!data}
              />
            )}
          />

          <Controller
            control={control}
            name="tags"
            render={({ field: { value, onChange } }) => (
              <TagInput
                formError={errors.tags?.message}
                handleChange={onChange}
                handleSearch={(v) => handleSearch(v)}
                isLoading={isLoadingTags}
                label="Tags"
                values={value}
                iProps={{ showBorder: true }}
                suggestions={suggestions?.map((t) => t.name)}
              />
            )}
          />

          <Flex marginTop="32px" marginBottom="8px" gap="12px">
            <BaseButton isLoading={isLoading} flex="1">
              {!data ? "Create Project" : "Save Project"}
            </BaseButton>
            {!data && <RadixButton small />}
          </Flex>
          <Error error={error} />
          {isSuccess && (
            <Flex gap="4px" alignCenter>
              <CheckmarkThin size={14} strokeWidth={3} />
              <T2>Success</T2>
            </Flex>
          )}
        </Flex>
      </Flex>
    </form>
  );
};
