import { awaitTo } from "library/utils";
import { MemberManifest, VestingManifest } from "radix/manifests";
import { VestingContract, parseVestingContract } from "radix/parsers";
import {
  getEntityDetails,
  getWalletAccount,
  pollTxStatus,
} from "radix/radix-util";
import { AuthUserRes } from "redux/apiTypes";

export const vestingJoinCall = async (
  auth: AuthUserRes,
  vestingComponent: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle, componentAddress } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      MemberManifest.joinVesting(
        componentAddress,
        vestingComponent,
        accountAddress
      )
  );
  return txDetails;
};

export const vestingWithdrawCall = async (
  auth: AuthUserRes,
  vestingComponent: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      VestingManifest.withdraw(vestingComponent, accountAddress)
  );
  return txDetails;
};

export const vestingLeaveCall = async (
  auth: AuthUserRes,
  vestingComponent: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      VestingManifest.leave(vestingComponent)
  );
  return txDetails;
};

export const vestingCancelCall = async (
  auth: AuthUserRes,
  vestingComponent: string
) => {
  const depositAccount = await getWalletAccount(true);
  const accountAddress = await getWalletAccount(true);
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress || !depositAccount) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      VestingManifest.cancellation(vestingComponent, depositAccount)
  );
  return txDetails;
};

export const vestingGetContracts = async (addresses: string[]) => {
  const [, res] = await awaitTo(getEntityDetails(addresses));
  if (res) {
    return res.reduce(
      (acc: Record<string, VestingContract | undefined>, curr) => {
        const newContract = curr.details
          ? parseVestingContract(curr.details, curr.address)
          : undefined;
        const funds = newContract
          ? curr.fungibles.find(
              (v) => typeof v.vaults[newContract.funds_vault] === "number"
            )
          : undefined;
        const reserved = newContract
          ? curr.fungibles.find(
              (v) => typeof v.vaults[newContract.reserved_vault] === "number"
            )
          : undefined;
        if (newContract && funds && reserved) {
          const funds_amt = funds.vaults[newContract.funds_vault] || 0;
          const r_amt = reserved.vaults[newContract.reserved_vault] || 0;
          newContract.funds = funds_amt;
          newContract.reserved = r_amt;
          newContract.resource_address = funds.resourceAddress;
          newContract.token = funds.metadata;
          acc[newContract.address] = newContract;
        }
        return acc;
      },
      {}
    );
  }
};
