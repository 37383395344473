import {
  DiscordIcon,
  FigmaIcon,
  GithubIcon,
  GlobeIcon,
  LinkedInIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "library/assets";
import { Button } from "../Buttons";
import { Div } from "../Common";

type SocialsProps = {
  appUrl?: string | null;
  companyUrl?: string | null;
  websiteUrl: string | null;
  gitHub: string | null;
  figma: string | null;
  linkedIn: string | null;
  discord: string | null;
  reddit: string | null;
  telegram: string | null;
  twitter: string | null;
  youtube: string | null;
};

export const Socials = ({
  socials,
  isMember,
}: {
  socials: SocialsProps | null;
  isMember?: boolean;
}) => {
  const {
    appUrl,
    companyUrl,
    websiteUrl,

    discord,
    figma,
    gitHub,
    linkedIn,
    reddit,
    telegram,
    twitter,
    youtube,
  } = socials || {};

  return (
    <Div
      display="flex"
      flexWrap="wrap"
      margin="24px 0"
      padding="0 16px"
      sm={{ padding: "0" }}
    >
      {gitHub && (
        <Button as="a" href={`https://github.com/${gitHub}`} target="_blank">
          <GithubIcon size={32} />
        </Button>
      )}
      {twitter && (
        <Button as="a" href={`https://twitter.com/${twitter}`} target="_blank">
          <TwitterIcon size={32} />
        </Button>
      )}
      {reddit && (
        <Button
          as="a"
          href={`https://reddit.com/${isMember ? "u" : "r"}/${reddit}`}
          target="_blank"
        >
          <RedditIcon size={32} />
        </Button>
      )}
      {telegram && (
        <Button as="a" href={`https://t.me/${telegram}`} target="_blank">
          <TelegramIcon size={32} />
        </Button>
      )}
      {discord && (
        <Button
          as="a"
          href={
            isMember
              ? `https://discordapp.com/users/${discord}`
              : `https://discord.gg/${discord}`
          }
          target="_blank"
        >
          <DiscordIcon size={32} />
        </Button>
      )}
      {youtube && (
        <Button as="a" href={`https://youtube.com/@${youtube}`} target="_blank">
          <YoutubeIcon size={32} />
        </Button>
      )}

      {figma && (
        <Button as="a" href={`https://figma.com/@${figma}`} target="_blank">
          <FigmaIcon size={30} />
        </Button>
      )}
      {linkedIn && (
        <Button as="a" href={linkedIn} target="_blank">
          <LinkedInIcon />
        </Button>
      )}
      {appUrl && (
        <Button as="a" href={appUrl} target="_blank">
          <GlobeIcon size={32} />
        </Button>
      )}
      {companyUrl && (
        <Button as="a" href={companyUrl} target="_blank">
          <GlobeIcon size={32} />
        </Button>
      )}
      {websiteUrl && (
        <Button as="a" href={websiteUrl} target="_blank">
          <GlobeIcon size={32} />
        </Button>
      )}
    </Div>
  );
};
