const pe = process.env;

export const isDev = pe.REACT_APP_NODE_ENV === "development";
export const isBeta = pe.REACT_APP_NODE_ENV === "beta";
export const isProd = pe.REACT_APP_NODE_ENV === "production";

export const AppUrl = isProd
  ? "https://launchspace.app"
  : isBeta
  ? "https://beta.launchspace.app"
  : "http://localhost:3000";

export const ApiUrl = isProd
  ? "https://api.launchspace.app"
  : isBeta
  ? "https://api-launchspace.koyeb.app/"
  : "http://localhost:5000";

export const GithubClientID = pe.REACT_APP_GITHUB_CLIENT_ID;

export const APP_NAME = "launchspace";
export const DAPP_ADDRESS =
  "account_tdx_2_12893a32aeygqc4667dws2xfa30rr80lmd9z7lmu9x0fcxv2ckh460z";
export const PACKAGE_ADDRESS =
  "package_tdx_2_1phfyndd42mzcgujmts90spz2v47n2dvac2ycgjphz0rax7ukdzzmlm";
export const XRD_ADDRESS =
  "resource_tdx_2_1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxtfd2jc";
export const FEE_VAL = 0.03;
