import { ChangeEventHandler } from "react";
import { css } from "styled-components";
import { Flex } from "..";
import { Colors } from "../../consts";
import { CheckmarkThin } from "../../assets";

type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
  handleCheck: ChangeEventHandler<HTMLInputElement>;
  padding?: string;
};

export const Checkbox = ({
  checked,
  disabled,
  handleCheck,
  padding,
}: CheckboxProps) => (
  <Flex as="label" userSelect="none" padding={padding} center>
    <input
      type="checkbox"
      checked={checked}
      onChange={disabled ? undefined : handleCheck}
      style={{ display: "none" }}
      disabled={disabled}
    />
    <Flex
      height="20px"
      width="20px"
      backgroundColor={checked ? Colors.greenDark : undefined}
      css={css`
        border: 2px solid
          ${({ theme }) => (checked ? Colors.greenDark : theme.textColorActive)};
      `}
      borderRadius="6px"
      center
    >
      {checked && (
        <CheckmarkThin size={12} strokeWidth={5} color={Colors.white} />
      )}
    </Flex>
  </Flex>
);
