import { z } from "zod";
import {
  BooleanSchema,
  DefaultProfileSchema,
  SocialsSchema,
  StringSchema,
  TagSchema,
  TeamTypeEnumZod,
  UrlSchema,
  NameSchema,
} from "./common-schema";

const MemberProfileSchema = z
  .object({
    company: StringSchema,
    location: StringSchema,
    isInvitesDisabled: BooleanSchema,

    companyUrl: UrlSchema.or(z.string().length(0)),
    websiteUrl: UrlSchema.or(z.string().length(0)),
  })
  .merge(DefaultProfileSchema)
  .merge(SocialsSchema)
  .partial();

export const MemberReq = z.object({
  name: NameSchema,
  icon: UrlSchema.or(z.string().length(0)),
  bio: NameSchema.or(z.string().length(0)),
  teamTypes: z.array(TeamTypeEnumZod),
  tags: z.array(TagSchema).max(5, "Maximum of 5 tags").optional(),
  isHidden: BooleanSchema.optional(),
  memberProfile: MemberProfileSchema.optional(),
});
