import { vestingApi } from "redux/apis/vesting-api";
import { AppVestingsRes, AuthUserRes } from "redux/apiTypes";
import { awaitTo, parseRtkError } from "library/utils";
import { useEffect } from "react";
import { useModal } from "library/components";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { VestingEditReqType } from "schema";
import { VestingEditModal } from "./VestingEditModal";
import {
  VestingContract,
  useRadixCall,
  vestingCancelCall,
  vestingGetContracts,
  vestingJoinCall,
  vestingLeaveCall,
  vestingWithdrawCall,
} from "radix";
import { useParams } from "react-router-dom";

/**
 * Navigation Two Cases
 * 1. Vesting List Page: contract exists in contracts obj
 * 2. Tx List with contractHandle: contract not in contracts obj
 */

export const VestingUpdateModal = ({
  auth,
  handleClose,
  vesting: vest,
  contracts,
  updateContracts,
  refetchVestings,
}: {
  auth?: AuthUserRes;
  handleClose: () => void;
  vesting: AppVestingsRes[number] | undefined;
  contracts: Record<string, VestingContract | undefined>;
  updateContracts: (a: Record<string, VestingContract | undefined>) => void;
  refetchVestings: () => Promise<void>;
}) => {
  const { appHandle, vestingHandle } = useParams();
  const { hideModal, show, showModal } = useModal();

  const [joinCall, { error: joinError, isLoading: isLoadingJoin }] =
    useRadixCall(vestingJoinCall);
  const [withdrawCall, { error: withdrawError, isLoading: isLoadingW }] =
    useRadixCall(vestingWithdrawCall);
  const [leaveCall, { error: leaveError, isLoading: isLoadingL }] =
    useRadixCall(vestingLeaveCall);
  const [cancelCall, { error: cancelError, isLoading: isLoadingC }] =
    useRadixCall(vestingCancelCall);

  const [handleUpdate, { error, isLoading }] =
    vestingApi.useVestingUpdateMutation();
  const [handleRefresh, { error: errorR, isLoading: isLoadingR }] =
    vestingApi.useVestingRefreshMutation();

  const {
    data,
    error: errorV,
    isLoading: isLoadingV,
  } = vestingApi.useVestingGetQuery(
    appHandle && vestingHandle ? { vestingHandle } : skipToken
  );

  const vesting = vest || data;
  const contract = vesting ? contracts[vesting.componentAddress] : undefined;

  useEffect(() => {
    if (vest || vestingHandle) {
      showModal();
    } else {
      hideModal();
    }
  }, [hideModal, showModal, vest, vestingHandle]);

  // get vesting contract when contract is missing
  useEffect(() => {
    const contractsUpdate = async () => {
      if (!contract && vesting) {
        const res = await vestingGetContracts([vesting.componentAddress]);
        if (res) {
          updateContracts({ ...contracts, ...res });
        }
      }
    };
    contractsUpdate();
  }, [contract, contracts, updateContracts, vesting]);

  const handleUpdateDetails = async (data: VestingEditReqType) => {
    if (!vesting) return;
    const [, res] = await awaitTo(
      handleUpdate({
        vestingUpdateReq: {
          name: data.name,
          description: data.description,
        },
        vestingHandle: vesting.contractHandle,
      }).unwrap()
    );
    if (res?.id) {
      await refetchVestings();
      return res;
    }
    return undefined;
  };

  // used for when contract is updated
  const contractsUpdate = async (address: string) => {
    const res = await vestingGetContracts([address]);
    if (res) {
      updateContracts({ ...contracts, ...res });
    }
  };

  const handleJoin = async () => {
    if (!auth || !vesting || !contract || contract.signature.length) {
      return;
    }
    const res = await joinCall(auth, vesting.componentAddress);
    if (res) {
      await contractsUpdate(vesting.componentAddress);
    }
  };

  const handleWithdraw = async () => {
    if (!auth || !vesting || !contract || !contract.signature.length) {
      return;
    }
    const res = await withdrawCall(auth, vesting.componentAddress);
    if (res) {
      await contractsUpdate(vesting.componentAddress);
      handleRefresh({ vestingHandle: vesting.contractHandle });
    }
  };

  const handleLeave = async () => {
    if (!auth || !vesting || !contract || !contract.signature.length) {
      return;
    }
    const res = await leaveCall(auth, vesting.componentAddress);
    if (res) {
      await contractsUpdate(vesting.componentAddress);
    }
  };

  const handleCancel = async () => {
    if (!auth || !vesting || !contract) {
      return;
    }
    const res = await cancelCall(auth, vesting.componentAddress);
    if (res) {
      await contractsUpdate(vesting.componentAddress);
    }
  };

  return (
    <VestingEditModal
      show={show}
      hideModal={handleClose}
      handleUpdate={handleUpdateDetails}
      handleJoin={handleJoin}
      handleWithdraw={handleWithdraw}
      handleLeave={handleLeave}
      handleCancel={handleCancel}
      error={
        parseRtkError(error || errorV || errorR) ||
        joinError ||
        withdrawError ||
        leaveError ||
        cancelError
      }
      isLoading={isLoading || isLoadingV}
      isLoadingMember={isLoadingJoin || isLoadingW || isLoadingR}
      isLoadingCancel={isLoadingL || isLoadingC}
      data={vesting}
      contract={contract}
      authHandle={auth?.memberHandle}
    />
  );
};
