import {
  VestingCreateApiRes,
  VestingCreateApiArg,
  VestingUpdateApiRes,
  VestingUpdateApiArg,
  VestingRefreshApiRes,
  VestingRefreshApiArg,
  VestingGetApiRes,
  VestingGetApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    vestingCreate: build.mutation<VestingCreateApiRes, VestingCreateApiArg>({
      query: (queryArg) => ({
        url: `/vesting/create/${queryArg.appHandle}`,
        method: "POST",
        body: queryArg.vestingBodyReq,
      }),
    }),
    vestingUpdate: build.mutation<VestingUpdateApiRes, VestingUpdateApiArg>({
      query: (queryArg) => ({
        url: `/vesting/update/${queryArg.vestingHandle}`,
        method: "PATCH",
        body: queryArg.vestingUpdateReq,
      }),
    }),
    vestingRefresh: build.mutation<VestingRefreshApiRes, VestingRefreshApiArg>({
      query: (queryArg) => ({
        url: `/vesting/refresh/${queryArg.vestingHandle}`,
        method: "PATCH",
      }),
    }),
    vestingGet: build.query<VestingGetApiRes, VestingGetApiArg>({
      query: (queryArg) => ({ url: `/vesting/${queryArg.vestingHandle}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as vestingApi };
