import { useRef, useState } from "react";
import styled from "styled-components";
import { CalendarIcon } from "../../assets";
import { useMultiClickOutside } from "../../hooks";
import { formatDate } from "../../utils";
import { OptionButton } from "../Buttons/Button";
import { Div, Flex } from "../Common";
import { PopupStyle } from "../../styles";
import { T2, T3 } from "../Text";
import { DatePickerCalendar } from "./DatePickerCalendar";
import { CssPropsType } from "library/types";

const CalendarWrapper = styled(Div)<
  CssPropsType & {
    $active?: boolean;
    alignTop?: boolean;
    isOpen?: boolean;
    minWidth?: string;
  }
>`
  min-width: 100%;
  padding: 12px;
  ${PopupStyle}
  top: 56px;
`;

type DatePickerProps = {
  handleSelectDate: (val?: string) => void;
  label: string;
  date?: string;
  disabled?: boolean;
};

export const DatePicker = ({
  handleSelectDate,
  label,
  date,
  disabled,
}: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [calendarState, setCalendarState] = useState(
    date ? new Date(date) : new Date()
  );

  const buttonRef = useRef<HTMLButtonElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useMultiClickOutside([buttonRef, wrapperRef], () => setIsOpen(false));

  return (
    <>
      <OptionButton
        ref={buttonRef}
        type="button"
        onClick={() => {
          if (disabled) return;
          setCalendarState(date ? new Date(date) : new Date());
          setIsOpen(!isOpen);
        }}
        display="block"
        borderRadius="6px"
        flex="1"
        textActive
        disabled={disabled}
      >
        <Flex justifyBetween>
          <T3 textAlign="left" marginBottom="4px" normal>
            {label}
          </T3>
          <CalendarIcon fill="currentColor" size={14} />
        </Flex>
        <T2>{date ? formatDate(date) : "--"}</T2>
      </OptionButton>

      <CalendarWrapper ref={wrapperRef} isOpen={isOpen} tabIndex={-1}>
        <DatePickerCalendar
          calendarState={calendarState}
          date={date}
          handleSetDate={(d) => {
            handleSelectDate(d);
            setIsOpen(false);
          }}
          handleSetCalendarState={setCalendarState}
        />
      </CalendarWrapper>
    </>
  );
};
