import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  Div,
  Divider,
  Flex,
  H4,
  H6,
  Img,
  T2,
  T3,
  TeamTypeOptionsMedium,
} from "library/components";
import { AppIconName, CardHeading } from "./CardHeading";
import { BasePaths, Colors, Width } from "library/consts";
import { FrostedBackgroundStyle, FrostedBoxShadowStyle } from "library/styles";
import { TeamType } from "library/types";
import { CoinIcons } from "library/assets";
import { formatNumber } from "library/utils";

export const LargeEmptyCard = styled(Div)`
  min-height: 500px;
  max-height: 1200px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 24px;
  overflow: hidden;

  background-color: ${({ theme }) =>
    theme?._isDark ? Colors.grayBlack : Colors.border};
  box-shadow: ${({ theme }) =>
    theme?._isDark
      ? "0 4px 24px 0 rgba(0, 0, 20, 0.5)"
      : "0 4px 24px 0 rgba(0, 0, 20, 0.25)"};

  margin: 16px 0 32px;
  @media only screen and (min-width: ${Width.SM}px) {
    min-height: 600px;
    margin: 32px 0 64px;
    aspect-ratio: 16/9;
  }

  @media only screen and (max-width: ${Width.SM}px) {
    aspect-ratio: 3/4;
  }
`;

const FrostedHeadingStyle = css`
  ${FrostedBackgroundStyle}
  ${FrostedBoxShadowStyle}
  
  flex: 1;
  @media only screen and (min-width: ${Width.SM}px) {
    border-radius: 20px;
    flex: 0;
  }
`;

type LargeCardProps = {
  appHandle: string;
  icon?: string;
  image?: string;
  name: string;
  subtitle: string;
};

export const LargeCard = ({
  appHandle,
  icon,
  image,
  name,
  subtitle,
}: LargeCardProps) => (
  <Link to={`/${BasePaths.a}/${appHandle}`}>
    <LargeEmptyCard>
      <Img
        // as={!image && 'div'}
        height="100%"
        width="100%"
        src={
          image ||
          // 'https://cdn.setapp.com/blog/images/cleanshot-x-screen-recording.gif'
          // 'https://forum.keyboardmaestro.com/uploads/default/6cdc72d4d8465839340ee97808e39a0a90790675'
          "https://formcarry.com/blog/content/images/2021/09/CleanShot-2021-07-10-at-12.23.47.gif"
        }
        alt={name}
      />

      <Div
        width="100%"
        display="flex"
        justifyContent="center"
        position="absolute"
        bottom="0"
        padding="0"
        sm={{ padding: "0 32px 32px 32px" }}
      >
        <CardHeading
          css={FrostedHeadingStyle}
          icon={icon}
          name={name}
          subtitle={subtitle}
        />
      </Div>
    </LargeEmptyCard>
  </Link>
);

// Large Project Card

const FrostedCard = styled(Flex)`
  ${FrostedHeadingStyle}
`;

const FrostedCardSmall = styled(Flex)`
  ${FrostedBackgroundStyle}
  ${FrostedBoxShadowStyle}
`;

type LargeProjectCardProps = {
  amount: string | number;
  appName: string;
  icon?: string | null;
  image?: string | null;
  name: string;
  projectHandle: string;
  teamType: TeamType;
  tokenSymbol: string;
};

export const LargeProjectCard = ({
  amount,
  appName,
  icon,
  image,
  name,
  projectHandle,
  teamType,
  tokenSymbol,
}: LargeProjectCardProps) => (
  <Link to={`/${BasePaths.p}/${projectHandle}`}>
    <LargeEmptyCard>
      <Img
        // as={!image && 'div'}
        height="100%"
        width="100%"
        src={
          image ||
          // "https://cdn.setapp.com/blog/images/cleanshot-x-screen-recording.gif"
          // "https://forum.keyboardmaestro.com/uploads/default/6cdc72d4d8465839340ee97808e39a0a90790675"
          "https://formcarry.com/blog/content/images/2021/09/CleanShot-2021-07-10-at-12.23.47.gif"
        }
        alt={name}
      />

      <Flex
        width="100%"
        position="absolute"
        top="0"
        padding="8px 8px 0 8px"
        sm={{ padding: "32px 32px 0 32px" }}
        justifyBetween
      >
        <FrostedCardSmall
          whiteSpace="nowrap"
          padding="12px"
          borderRadius="16px"
          gap="4px"
          alignCenter
        >
          {TeamTypeOptionsMedium.find((v) => v.value === teamType)?.label}
        </FrostedCardSmall>

        <FrostedCardSmall
          whiteSpace="nowrap"
          padding="12px"
          borderRadius="16px"
          gap="4px"
          alignCenter
        >
          {CoinIcons[tokenSymbol]}
          <H6>{formatNumber(amount)}</H6>
          <T3 medium>{tokenSymbol}</T3>
        </FrostedCardSmall>
      </Flex>

      <Div
        width="100%"
        display="flex"
        justifyContent="center"
        position="absolute"
        bottom="0"
        padding="0"
        sm={{ padding: "0 32px 32px 32px", display: "grid" }}
      >
        <FrostedCard
          padding="16px"
          sm={{ padding: "18px" }}
          gap="24px"
          alignCenter
        >
          <Div width="100%">
            <T2>PROJECT</T2>
            <H4>{name}</H4>
          </Div>

          <Divider
            height="36px"
            opacity="0.5"
            display="none"
            sm={{ display: "block" }}
          />

          <Flex display="none" sm={{ display: "flex" }} alignCenter>
            <AppIconName icon={icon} name={appName} noRightMargin />
          </Flex>
        </FrostedCard>
      </Div>
    </LargeEmptyCard>
  </Link>
);
