import { Card, Div, Flex, H1, H2, H4, H6, Link, T2 } from "library/components";
import { ChevronRight } from "library/assets";
import { ReactNode } from "react";

type SectionHeadingProps = {
  title: string;
  to: string;
};

export const SectionHeading = ({ title, to }: SectionHeadingProps) => (
  <Flex margin="16px 0" sm={{ margin: "64px 0 24px" }} alignCenter>
    <H1 flex="1" margin="0 16px" sm={{ margin: "0 24px" }} bold>
      {title}
    </H1>

    <Link to={to}>
      <Div display="flex" alignItems="center" padding="16px">
        <T2 marginRight="8px" medium>
          View All
        </T2>
        <ChevronRight />
      </Div>
    </Link>
  </Flex>
);

type SectionCardProps = {
  description: string;
  title: string;
  to: string;
  icon?: ReactNode;
};

export const SectionCard = ({ description, title, to }: SectionCardProps) => (
  <Div margin="32px 0" sm={{ margin: "64px 0 24px" }}>
    <Link to={to} padding="0">
      <Card display="flex" width="100%" alignItems="center" padding="32px">
        <Div flex="1">
          <H6 fontWeight="normal" marginBottom="8px">
            {title}
          </H6>
          <H2>{description}</H2>
        </Div>
        <ChevronRight size={32} />
      </Card>
    </Link>
  </Div>
);

export const SectionSmallCard = ({
  description,
  icon,
  title,
  node,
}: {
  description?: string;
  icon?: ReactNode;
  title: string;
  node?: ReactNode;
}) => (
  <Card padding="32px" width="100%">
    <H6 fontWeight="medium" marginBottom="16px" textAlign="center">
      {title}
    </H6>
    <Flex gap="12px" alignCenter>
      {icon}
      {node ? (
        node
      ) : (
        <>
          <H4 flex="1">{description}</H4>
          <ChevronRight size={32} />
        </>
      )}
    </Flex>
  </Card>
);
