import { Helmet } from "react-helmet-async";
import {
  // AppsTableData,
  AppUrl,
  BasePaths,
  // MembersTableData,
  PageDescriptions,
  PageNames,
  PageTitles,
  // ProjectData,
  // ProjectsTableData,
} from "consts";
import {
  DiscordIcon,
  GithubIcon,
  InfoIcon,
  LoadingDots,
  TelegramIcon,
  TwitterIcon,
} from "library/assets";
import {
  A,
  AppCard,
  Button,
  Card,
  CardGrid,
  FileCard,
  Flex,
  LargeEmptyCard,
  LargeProjectCard,
  Link,
  MemberCard,
  Page,
  ProjectCard,
} from "library/components";
import {
  SectionCard,
  SectionHeading,
  SectionSmallCard,
} from "./HomeComponents";
import { homeApi } from "redux/apis";
import { useSaved } from "hooks";

const EmptyCards = ({
  n,
  minHeight,
  isLoading,
}: {
  n: number;
  minHeight: string;
  isLoading: boolean;
}) => (
  <>
    {[...Array(n)].map((_, i) => (
      <Card key={`${i}`}>
        <Flex minHeight={minHeight} center>
          {isLoading && <LoadingDots size={100} r={2} />}
        </Flex>
      </Card>
    ))}
  </>
);

export const Home = () => {
  const { saved } = useSaved();
  const { data, isLoading } = homeApi.useHomeGetQuery();
  const { projects, apps, members, files } = data || {};
  const project = projects?.find((v) => v);

  return (
    <Page>
      <Helmet>
        <title>{PageTitles.home}</title>
        <meta name="description" content={PageDescriptions.home} />
        <link rel="canonical" href={AppUrl} />
      </Helmet>

      {project ? (
        <LargeProjectCard
          {...project}
          appName={project.app.name}
          icon={project.app.icon}
        />
      ) : (
        <LargeEmptyCard>
          <Flex height="100%" center>
            {isLoading && <LoadingDots size={100} r={2} />}
          </Flex>
        </LargeEmptyCard>
      )}

      <SectionHeading
        title={PageNames.projects}
        to={`/${BasePaths.projects}`}
      />
      <CardGrid>
        {projects?.map((d) => (
          <ProjectCard
            key={d.id}
            {...d}
            icon={d.app.icon}
            appName={d.app.name}
            subtitle={d.app.subtitle}
            tags={d.tags.map((t) => t.name)}
            saved={saved?.savedProjects.some(
              (s) => s.projectHandle === d.projectHandle
            )}
          />
        ))}
        <EmptyCards
          n={6 - (projects?.length || 0)}
          minHeight="345px"
          isLoading={isLoading}
        />
      </CardGrid>
      <SectionCard
        title="For Builders"
        description="Join projects & get rewards"
        to={`/${BasePaths.projects}`}
      />

      <SectionHeading title={PageNames.library} to={`/${BasePaths.library}`} />
      <CardGrid>
        {files?.map((d) => (
          <FileCard
            key={d.id}
            {...d}
            icon={d.app?.icon || d.member?.icon || undefined}
            appHandle={d.app?.appHandle}
            memberHandle={d.member?.memberHandle}
            tags={d.tags.map((v) => v.name)}
            saved={saved?.savedFiles.some((s) => s.fileHandle === d.fileHandle)}
          />
        ))}
        <EmptyCards
          n={6 - (files?.length || 0)}
          minHeight="245px"
          isLoading={isLoading}
        />
      </CardGrid>
      <SectionCard
        title="For Developers"
        description="Share & discover code files"
        to={`/${BasePaths.library}`}
      />

      <SectionHeading title={PageNames.apps} to={`/${BasePaths.apps}`} />
      <CardGrid>
        {apps?.map((d) => (
          <AppCard
            key={d.id}
            {...d}
            saved={saved?.savedApps.some((s) => s.appHandle === d.appHandle)}
          />
        ))}
        <EmptyCards
          n={6 - (apps?.length || 0)}
          minHeight="345px"
          isLoading={isLoading}
        />
      </CardGrid>
      <SectionCard
        title="For Founders"
        description="Launch projects & build teams"
        to={`/${BasePaths.apps}`}
      />

      <SectionHeading title={PageNames.members} to={`/${BasePaths.members}`} />
      <CardGrid>
        {members?.map((d) => (
          <MemberCard
            key={d.id}
            {...d}
            rewards={d.statsTotal}
            apps={d._count.apps}
            projects={d._count.memberProjectRoles}
            saved={saved?.savedMembers.some(
              (s) => s.memberHandle === d.memberHandle
            )}
          />
        ))}
        <EmptyCards
          n={6 - (members?.length || 0)}
          minHeight="180px"
          isLoading={isLoading}
        />
      </CardGrid>
      <SectionCard
        title="For Members"
        description="Connect & collaborate with others"
        to={`/${BasePaths.members}`}
      />

      <CardGrid marginTop="32px" sm={{ marginTop: "64px" }}>
        <Link to={`/${BasePaths.about}`} padding="0">
          <SectionSmallCard
            title="About"
            description="About & Features"
            icon={<InfoIcon />}
          />
        </Link>
        <A
          href="https://github.com/launchspace/issues/issues"
          target="_blank"
          padding="0"
        >
          <SectionSmallCard
            title="Development"
            description="Issues & Requests"
            icon={<GithubIcon />}
          />
        </A>
        <SectionSmallCard
          title="Socials"
          node={
            <Flex justifyContent="space-evenly" width="100%" alignCenter>
              <Button
                as="a"
                href="https://twitter.com/launchspaceapp"
                target="_blank"
                padding="0"
              >
                <TwitterIcon size={32} />
              </Button>
              <Button
                as="a"
                href="https://t.me/launchspace_app"
                target="_blank"
                padding="0"
              >
                <TelegramIcon size={32} />
              </Button>
              <Button
                as="a"
                href="https://discord.gg/apKWAYgKDJ"
                target="_blank"
                padding="0"
              >
                <DiscordIcon size={32} />
              </Button>
            </Flex>
          }
        />
      </CardGrid>
    </Page>
  );
};
