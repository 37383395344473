const IconDefaults = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
};

export const DiscordIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028c.462-.63.874-1.295 1.226-1.994a.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418Z" />
  </svg>
);

export const FigmaIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M12 0.96V7.68H8.64C6.7896 7.68 5.28 6.1704 5.28 4.32C5.28 2.4696 6.7896 0.96 8.64 0.96H12Z" />
    <path d="M12 8.64V15.36H8.64C6.7896 15.36 5.28 13.8504 5.28 12C5.28 10.1496 6.7896 8.64 8.64 8.64H12Z" />
    <path d="M12 16.32V19.68C12 21.5304 10.4904 23.04 8.64 23.04C6.7896 23.04 5.28 21.5304 5.28 19.68C5.28 17.8296 6.7896 16.32 8.64 16.32H12Z" />
    <path d="M19.68 4.32C19.68 6.1704 18.1704 7.68 16.32 7.68H12.96V0.96H16.32C18.1704 0.96 19.68 2.4696 19.68 4.32Z" />
    <path d="M16.32 15.36C18.1757 15.36 19.68 13.8557 19.68 12C19.68 10.1443 18.1757 8.64 16.32 8.64C14.4643 8.64 12.96 10.1443 12.96 12C12.96 13.8557 14.4643 15.36 16.32 15.36Z" />
  </svg>
);

export const GithubIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M11.999 1C5.926 1 1 5.925 1 12c0 4.86 3.152 8.983 7.523 10.437.55.102.75-.238.75-.53 0-.26-.009-.952-.014-1.87-3.06.664-3.706-1.475-3.706-1.475-.5-1.27-1.221-1.61-1.221-1.61-.999-.681.075-.668.075-.668 1.105.078 1.685 1.134 1.685 1.134.981 1.68 2.575 1.195 3.202.914.1-.71.384-1.195.698-1.47-2.442-.278-5.01-1.222-5.01-5.437 0-1.2.428-2.183 1.132-2.952-.114-.278-.491-1.397.108-2.91 0 0 .923-.297 3.025 1.127A10.536 10.536 0 0 1 12 6.32c.935.004 1.876.125 2.754.37 2.1-1.424 3.022-1.128 3.022-1.128.6 1.514.223 2.633.11 2.911.705.769 1.13 1.751 1.13 2.952 0 4.226-2.572 5.156-5.022 5.428.395.34.747 1.01.747 2.037 0 1.47-.014 2.657-.014 3.017 0 .295.199.637.756.53C19.851 20.979 23 16.859 23 12c0-6.075-4.926-11-11.001-11" />
  </svg>
);

export const LinkedInIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6S.02 4.881.02 3.5C.02 2.12 1.13 1 2.5 1s2.48 1.12 2.48 2.5zM5 8H0v16h5V8zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V24H24V13.869c0-7.88-8.922-7.593-11.018-3.714V8z" />
  </svg>
);

export const RedditIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M20.64 9.54c-.704 0-1.323.29-1.777.744-1.674-1.157-3.929-1.903-6.427-1.982l1.298-5.85 4.135.928a1.84 1.84 0 0 0 1.837 1.837c1.032 0 1.861-.848 1.861-1.86 0-1.013-.825-1.862-1.86-1.862-.723 0-1.346.436-1.655 1.032l-4.566-1.013c-.23-.06-.455.103-.516.333l-1.425 6.45c-2.48.103-4.71.848-6.389 2.006a2.491 2.491 0 0 0-1.8-.764c-2.606 0-3.46 3.497-1.073 4.692-.085.37-.122.764-.122 1.158 0 3.928 4.425 7.111 9.858 7.111 5.456 0 9.881-3.183 9.881-7.11 0-.395-.042-.807-.145-1.178 2.339-1.2 1.476-4.673-1.116-4.673ZM6.065 14.48c0-1.032.825-1.861 1.86-1.861 1.013 0 1.838.825 1.838 1.86a1.84 1.84 0 0 1-1.837 1.838 1.846 1.846 0 0 1-1.861-1.837Zm10.045 4.383c-1.706 1.706-6.52 1.706-8.227 0-.187-.165-.187-.455 0-.643a.45.45 0 0 1 .62 0c1.302 1.336 5.624 1.36 6.984 0a.45.45 0 0 1 .618 0c.192.188.192.478.005.643Zm-.038-2.541a1.84 1.84 0 0 1-1.837-1.838 1.849 1.849 0 1 1 1.837 1.838Z" />
  </svg>
);

export const TelegramIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M2.915 11.808c6.284-2.764 14.416-6.135 15.538-6.601 2.945-1.221 3.849-.987 3.398 1.716-.323 1.944-1.257 8.378-2.001 12.383-.442 2.375-1.432 2.657-2.99 1.63-.748-.495-4.529-2.995-5.35-3.582-.748-.535-1.782-1.178-.486-2.446.461-.451 3.483-3.337 5.838-5.583.308-.295-.08-.78-.435-.543a2749.248 2749.248 0 0 0-8.134 5.406c-.846.574-1.659.838-3.117.419-1.102-.316-2.179-.694-2.598-.838-1.613-.554-1.23-1.271.337-1.961Z" />
  </svg>
);

export const TwitterIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
  </svg>
);

export const YoutubeIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
  </svg>
);
