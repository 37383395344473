import {
  SavedAddAppApiRes,
  SavedAddAppApiArg,
  SavedAddProjectApiRes,
  SavedAddProjectApiArg,
  SavedAddMemberApiRes,
  SavedAddMemberApiArg,
  SavedAddFileApiRes,
  SavedAddFileApiArg,
  SavedHandlesApiRes,
  SavedHandlesApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    savedAddApp: build.mutation<SavedAddAppApiRes, SavedAddAppApiArg>({
      query: (queryArg) => ({
        url: `/saved/app/${queryArg.handle}/${queryArg.isSaved}`,
        method: "PATCH",
      }),
    }),
    savedAddProject: build.mutation<
      SavedAddProjectApiRes,
      SavedAddProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/saved/project/${queryArg.handle}/${queryArg.isSaved}`,
        method: "PATCH",
      }),
    }),
    savedAddMember: build.mutation<SavedAddMemberApiRes, SavedAddMemberApiArg>({
      query: (queryArg) => ({
        url: `/saved/member/${queryArg.handle}/${queryArg.isSaved}`,
        method: "PATCH",
      }),
    }),
    savedAddFile: build.mutation<SavedAddFileApiRes, SavedAddFileApiArg>({
      query: (queryArg) => ({
        url: `/saved/file/${queryArg.handle}/${queryArg.isSaved}`,
        method: "PATCH",
      }),
    }),
    savedHandles: build.query<SavedHandlesApiRes, SavedHandlesApiArg>({
      query: () => ({ url: `/saved/handles` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as savedApi };
