import { Div, T3 } from "library/components";
import { formatDateLong } from "library/utils";

type CreatedUpdatedCardProps = {
  created?: string;
  updated?: string;
};

export const CreatedUpdatedCard = ({
  created,
  updated,
}: CreatedUpdatedCardProps) => (
  <Div paddingLeft="8px">
    <T3>Created</T3>
    <T3 marginBottom="8px" medium>
      {created ? formatDateLong(created) : "--"}
    </T3>
    <T3>Updated</T3>
    <T3 medium>{updated ? formatDateLong(updated) : "--"}</T3>
  </Div>
);
