import { css } from "styled-components";
import { Flex } from "../Common";
import { T2 } from "../Text";

export const NoneCard = ({
  label,
  large,
}: {
  label?: string;
  large?: boolean;
}) => (
  <Flex
    minHeight={large ? "300px" : "80px"}
    padding="8px"
    marginTop="16px"
    borderRadius="8px"
    css={css`
      border: 2px dashed ${({ theme }) => theme.borderColor};
    `}
    center
  >
    <T2>{label || "None"}</T2>
  </Flex>
);
