import {
  ProposalCreateApiRes,
  ProposalCreateApiArg,
  ProposalUpdateApiRes,
  ProposalUpdateApiArg,
  ProposalViewApiRes,
  ProposalViewApiArg,
  ProposalReviewApiRes,
  ProposalReviewApiArg,
  ProposalHideApiRes,
  ProposalHideApiArg,
  ProposalsProjectGetApiRes,
  ProposalsProjectGetApiArg,
  ProposalsMemberGetApiRes,
  ProposalsMemberGetApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    proposalCreate: build.mutation<ProposalCreateApiRes, ProposalCreateApiArg>({
      query: (queryArg) => ({
        url: `/proposal/${queryArg.projectHandle}`,
        method: "POST",
        body: queryArg.proposalReq,
      }),
    }),
    proposalUpdate: build.mutation<ProposalUpdateApiRes, ProposalUpdateApiArg>({
      query: (queryArg) => ({
        url: `/proposal/${queryArg.proposalId}`,
        method: "PATCH",
        body: queryArg.proposalReq,
      }),
    }),
    proposalView: build.mutation<ProposalViewApiRes, ProposalViewApiArg>({
      query: (queryArg) => ({
        url: `/proposal/${queryArg.proposalId}/view`,
        method: "PATCH",
      }),
    }),
    proposalReview: build.mutation<ProposalReviewApiRes, ProposalReviewApiArg>({
      query: (queryArg) => ({
        url: `/proposal/${queryArg.proposalId}/review`,
        method: "PATCH",
        body: queryArg.proposalReviewReq,
      }),
    }),
    proposalHide: build.mutation<ProposalHideApiRes, ProposalHideApiArg>({
      query: (queryArg) => ({
        url: `/proposal/${queryArg.proposalId}/hide/${queryArg.isHidden}`,
        method: "PATCH",
      }),
    }),
    proposalsProjectGet: build.query<
      ProposalsProjectGetApiRes,
      ProposalsProjectGetApiArg
    >({
      query: (queryArg) => ({
        url: `/proposal/project/${queryArg.projectHandle}`,
      }),
    }),
    proposalsMemberGet: build.query<
      ProposalsMemberGetApiRes,
      ProposalsMemberGetApiArg
    >({
      query: (queryArg) => ({
        url: `/proposal/member/${queryArg.memberHandle}`,
        params: {
          appHandle: queryArg.appHandle,
          search: queryArg.search,
          isUpdated: queryArg.isUpdated,
          take: queryArg.take,
          skip: queryArg.skip,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as proposalApi };
