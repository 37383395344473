import { Link as BaseLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { baseMixin } from "library/components";
import { Width } from "library/consts";
import { BoxShadowSmallStyle } from "library/styles";
import { CssPropsType } from "library/types";
import { ReactNode } from "react";
import { LoadingDots } from "library/assets";

// Parent selector required for nested hover
// https://styled-components.com/docs/basics#pseudoelements-pseudoselectors-and-nesting
// https://sass-lang.com/documentation/style-rules/parent-selector/
// https://stackoverflow.com/questions/15983639/sass-nesting-for-hover-does-not-work
// https://styled-components.com/docs/faqs#can-i-nest-rules

// ABOUT: Use `as` prop for "Link" and "a"
const buttonDisabledStyle = css`
  &:disabled {
    cursor: not-allowed;

    opacity: 0.5;
    &:active {
      background-color: transparent;
      color: ${({ theme }) => theme.buttonTextColorActive};
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.buttonTextColorActive};
      }
    }
  }
`;

const buttonDefaultStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8125rem; // 13px
  font-weight: 500;

  &:disabled {
    cursor: not-allowed;
  }
`;

const buttonOpacityStyle = css`
  &:active {
    opacity: 0.7;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.7;
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const buttonColorStyle = css<CssPropsType & { $active?: boolean }>`
  color: ${({ $active, theme }) =>
    $active ? theme.buttonTextColorActive : theme.buttonTextColor};
  &:active {
    color: ${({ theme }) => theme.buttonTextColorActive};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.buttonTextColorActive};
    }
  }
  ${buttonDisabledStyle}
`;

const defaultStyle = css<CssPropsType & { $useOpacity?: boolean }>`
  padding: 8px;
  ${buttonDefaultStyle}
  ${({ $useOpacity }) => ($useOpacity ? buttonOpacityStyle : buttonColorStyle)}
`;

export const Button = styled.button<CssPropsType & { $useOpacity?: boolean }>`
  ${defaultStyle}
  ${baseMixin}
`;
export const Link = styled(BaseLink)<CssPropsType & { $useOpacity?: boolean }>`
  ${defaultStyle}
  ${baseMixin}
`;
export const A = styled.a<CssPropsType & { $useOpacity?: boolean }>`
  ${defaultStyle}
  ${baseMixin}
`;

// Tab - Inverted button colors, only active tab has regular button styles
const tabTextColorStyle = css<
  CssPropsType & { $active?: boolean; textActive?: boolean }
>`
  color: ${({ $active, textActive, theme }) =>
    $active || textActive
      ? theme.buttonTextColor
      : theme.buttonTextColorActive};
  &:active {
    color: ${({ theme }) => theme.buttonTextColor};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.buttonTextColor};
    }
  }
`;

export const Tab = styled.button<
  CssPropsType & { $active?: boolean; textActive?: boolean }
>`
  ${buttonDefaultStyle}
  ${tabTextColorStyle}
  ${baseMixin}
`;

export const TabLink = styled(BaseLink)<
  CssPropsType & { $active?: boolean; textActive?: boolean }
>`
  ${buttonDefaultStyle}
  ${tabTextColorStyle}
  ${baseMixin}
`;

// TabFilled - Active button has background that is regular text color
const tabFilledStyle = css<CssPropsType & { $active?: boolean }>`
  color: ${({ $active, theme }) =>
    $active ? theme.buttonTextColorInverted : theme.buttonTextColorActive};
  background-color: ${({ $active, theme }) =>
    $active ? theme.buttonTextColor : theme.cardBackgroundColor};
  &:active {
    color: ${({ theme }) => theme.buttonTextColorInverted};
    background-color: ${({ theme }) => theme.buttonTextColor};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.buttonTextColorInverted};
      background-color: ${({ theme }) => theme.buttonTextColor};
    }
  }

  ${BoxShadowSmallStyle}
`;

export const TabFilled = styled.button<CssPropsType & { $active?: boolean }>`
  ${buttonDefaultStyle}
  ${tabFilledStyle}
  ${baseMixin}
`;

//
//
// Main Buttons

const mainButtonStyle = css`
  height: 40px;
  font-weight: 500;
  border-radius: 12px;

  padding: 0px 12px;
  @media only screen and (min-width: ${Width.SM}px) {
    padding: 0px 20px;
  }
`;

export const PrimaryButton = styled.button<
  CssPropsType & { $useOpacity?: boolean }
>`
  color: ${({ theme }) => theme.buttonTextColorInverted};
  background-color: ${({ theme }) => theme.buttonTextColor};

  ${buttonDefaultStyle}
  ${mainButtonStyle}
  ${buttonOpacityStyle}
  ${baseMixin}
`;

export const SecondaryButton = styled.button<
  CssPropsType & { $useOpacity?: boolean }
>`
  color: ${({ theme }) => theme.secondaryButtonTextColorInverted};
  background-color: ${({ theme }) => theme.secondaryButtonTextColor};

  ${buttonDefaultStyle}
  ${mainButtonStyle}
  ${buttonOpacityStyle}
  ${baseMixin}
`;

// button component to extend base button
// https://stackoverflow.com/a/62568833
interface BaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  isSecondary?: boolean;
  $useOpacity?: boolean;
}
export const BaseButton = ({
  children,
  disabled,
  isLoading,
  isSecondary,
  ...rest
}: CssPropsType & BaseButtonProps) => {
  const Btn = isSecondary ? SecondaryButton : PrimaryButton;
  return (
    <Btn {...rest} disabled={disabled || isLoading}>
      {isLoading ? <LoadingDots /> : children}
    </Btn>
  );
};

// BackgroundButton - Uses current theme background with active hover
export const BackgroundButton = styled.button<CssPropsType>`
  background-color: ${({ theme }) => theme.backgroundButton};

  &:active {
    background-color: ${({ theme }) => theme.backgroundButtonActive};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme }) => theme.backgroundButtonActive};
    }
  }

  ${buttonDefaultStyle}
  ${baseMixin}
`;

//
//
// Option Button - Used in dropdowns

export const cardBackgroundStyle = css<CssPropsType & { $active?: boolean }>`
  background-color: ${({ $active, theme }) =>
    $active && theme.cardBackgroundColorActive};
  &:active {
    background-color: ${({ theme }) => theme.cardBackgroundColorActive};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme }) => theme.cardBackgroundColorActive};
    }
  }
`;

// 13px
export const OptionButton = styled.button<
  CssPropsType & {
    $active?: boolean;
    showBorder?: boolean;
    textActive?: boolean;
  }
>`
  min-height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  text-align: left;
  font-size: 0.8125rem; // 13px
  font-weight: 500;

  border: ${({ showBorder, theme }) =>
    showBorder && `2px solid ${theme.borderColor}`};

  ${tabTextColorStyle}
  ${cardBackgroundStyle}
  ${buttonDisabledStyle}
  ${baseMixin}
`;
