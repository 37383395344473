import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { BasePaths, Colors } from "library/consts";
import {
  Div,
  Flex,
  H1,
  Img,
  T3,
  TeamTypeOptionsMedium,
  UserImage,
} from "library/components";
import { BoxShadowSmallStyle, FrostedLightStyle } from "library/styles";
import { TeamType } from "library/types";
import { BookmarkButton } from "./CardComponents";
import { Card } from "./Card";

const SubHeading = styled(Flex)`
  ${FrostedLightStyle}
`;

type FileCardProps = {
  appHandle?: string;
  category: string;
  fileHandle: string;
  icon?: string;
  image?: string | null;
  memberHandle?: string;
  name: string;
  number: number | string;
  tags: string[];
  teamType: TeamType;
  saved?: boolean;
  showNumber?: boolean;
};

export const FileCard = ({
  appHandle,
  category,
  fileHandle,
  icon,
  image,
  memberHandle,
  name,
  number,
  tags,
  teamType,
  saved,
  showNumber,
}: FileCardProps) => (
  <Div position="relative">
    <BookmarkButton color={Colors.white} saved={saved} />

    <Link to={`/${BasePaths.f}/${fileHandle}`}>
      <Card zIndex="1">
        <Img
          height="100%"
          width="100%"
          position="absolute"
          top="0"
          left="0"
          zIndex="2"
          src={image || ""}
          alt={name}
        />

        <Flex
          position="relative"
          zIndex="3"
          backgroundColor="rgba(28, 28, 40, 0.55)"
          color={Colors.white}
          fullHeight
          fullWidth
          column
        >
          <Flex flex="1" column>
            <Flex margin="24px 24px 0 24px" alignCenter>
              {TeamTypeOptionsMedium.find((v) => v.value === teamType)?.label}
            </Flex>

            <H1 flex="1" minHeight="90px" margin="32px 24px">
              {name}
            </H1>
          </Flex>

          <SubHeading padding="12px 16px" alignCenter>
            {showNumber && (
              <Div
                borderRadius="4px"
                padding="4px 8px"
                fontSize="12px"
                fontWeight="500"
                color={Colors.blueBlack}
                css={css`
                  background-color: ${({ theme }) =>
                    theme._isDark ? Colors.background : Colors.white};
                  ${BoxShadowSmallStyle}
                `}
              >
                #{number}
              </Div>
            )}
            {appHandle && !showNumber && (
              <UserImage icon={icon} handle={appHandle} />
            )}
            {memberHandle && !showNumber && (
              <UserImage icon={icon} handle={memberHandle} isRound />
            )}
            <Div flex="1" />
            <T3 medium>
              {[category.toLowerCase(), ...tags].slice(0, 3).join(", ")}
            </T3>
          </SubHeading>
        </Flex>
      </Card>
    </Link>
  </Div>
);
