import {
  T1,
  CardCheckmarks,
  UserIcon,
  Flex,
  ObjectiveStateDescriptions,
} from "library/components";
import { MemberType } from "library/types";
import { formatDateShort } from "library/utils";
import { ProjectObjectivesRes } from "redux/apiTypes";

export const ProjectObjectivesRowRender = (
  d: ProjectObjectivesRes[number],
  _: number,
  rowData?: { members: MemberType[]; tokenSymbol: string | null }
) => [
  d.number,
  <T1 ellipsis medium>
    {d.name}
  </T1>,
  ObjectiveStateDescriptions[d.state],
  formatDateShort(d.startDate),
  formatDateShort(d.endDate),
  <CardCheckmarks
    count={d.tasks.reduce((acc, curr) => acc + (curr.isCompleted ? 1 : 0), 0)}
    total={d.tasks.length}
    justifyCenter
  />,
  <Flex width="124px" overflow="auto" alignCenter>
    {d.owners?.map(({ memberId }, i) => {
      const member = rowData?.members.find((m) => m.id === memberId);
      return (
        <UserIcon
          key={i}
          icon={member?.icon}
          name={member?.name || "Unknown"}
          marginLeft={i === 0 ? "auto" : "4px"}
          marginRight={i === d.owners.length - 1 ? "auto" : "0"}
        />
      );
    })}
  </Flex>,
];
