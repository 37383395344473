import {
  HomeGetApiRes,
  HomeGetApiArg,
  HomeGetPricesApiRes,
  HomeGetPricesApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    homeGet: build.query<HomeGetApiRes, HomeGetApiArg>({
      query: () => ({ url: `/home/all` }),
    }),
    homeGetPrices: build.query<HomeGetPricesApiRes, HomeGetPricesApiArg>({
      query: () => ({ url: `/home/prices` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as homeApi };
