import { css } from "styled-components";
import { Flex } from "../Common";
import { DatePicker } from "./DatePicker";

type DateRangeProps = {
  disabled?: boolean;
  end?: string;
  handleSelectEnd: (val?: string) => void;
  handleSelectStart: (val?: string) => void;
  start?: string;
};

export const DateRange = ({
  disabled,
  end,
  handleSelectEnd,
  handleSelectStart,
  start,
}: DateRangeProps) => (
  <Flex
    width="100%"
    position="relative"
    flexWrap="nowrap"
    borderRadius="8px"
    css={css`
      border: ${({ theme }) => `2px solid ${theme.borderColor}`};
    `}
  >
    <DatePicker
      handleSelectDate={handleSelectStart}
      label="Start"
      date={start}
      disabled={disabled}
    />
    <DatePicker
      handleSelectDate={handleSelectEnd}
      label="End"
      date={end}
      disabled={disabled}
    />
  </Flex>
);
