import { useEffect, useRef, useState } from "react";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button,
  DataTable,
  Div,
  Error,
  Flex,
  H2,
  IconNameSubtitle,
  Modal,
  RadixButton,
  SearchInput,
  T20,
  TeamSymbol,
  useModal,
} from "library/components";
import {
  AppParams,
  BasePaths,
  InvitesATableCols,
  InvitesATableHeadings,
  InvitesPTableCols,
  InvitesPTableHeadings,
  MemberPaths,
} from "consts";
import { LoadingDots, PlusIcon, XIcon } from "library/assets";
import { TeamRoleDescriptions } from "library/consts";
import { authApi, inviteApi, memberApi } from "redux/apis";
import { awaitTo, parseRtkError } from "library/utils";
import { AuthUserRes, InvitesUserRes } from "redux/apiTypes";
import { skipToken } from "@reduxjs/toolkit/query";
import { appInviteJoinCall, projectInviteJoinCall } from "radix";

type InvitesRowProps =
  | {
      handleAdd: (inviteId: string) => Promise<void>;
      handleDelete: (inviteId: string) => Promise<void>;
      addId: string;
      deleteId: string;
      isProjects: boolean;
    }
  | undefined;

export const InvitesRowRender = (
  d:
    | InvitesUserRes["appInvites"][number]
    | InvitesUserRes["projectInvites"][number],
  _: number,
  rowData: InvitesRowProps
) => {
  const { handleAdd, handleDelete, addId, deleteId, isProjects } =
    rowData || {};
  const app = "app" in d ? d.app : d.project.app;
  const teamType = "teamType" in d ? d.teamType : d.project.teamType;
  const project = "project" in d ? d.project : undefined;

  const cols = [
    app ? (
      <Link to={`/${BasePaths.a}/${app.appHandle}`}>
        <Div display="flex" alignItems="center">
          <IconNameSubtitle
            icon={app.icon}
            name={app.name}
            subtitle={app.subtitle}
          />
        </Div>
      </Link>
    ) : (
      "--"
    ),
    <Link to={`/${BasePaths.p}/${project?.projectHandle}`}>
      <T20 ellipsis medium>
        {project?.name || "--"}
      </T20>
    </Link>,
    <Div display="flex" alignItems="center" justifyContent="center">
      {teamType ? <TeamSymbol type={teamType} /> : "--"}
    </Div>,
    TeamRoleDescriptions[d.teamRole],
    <Flex center>
      <Button
        onClick={() => handleAdd?.(d.id)}
        disabled={Boolean(addId || deleteId)}
        $useOpacity
      >
        {addId === d.id ? <LoadingDots /> : <PlusIcon />}
      </Button>
      <Button
        onClick={() => handleDelete?.(d.id)}
        disabled={Boolean(addId || deleteId)}
        $useOpacity
      >
        {deleteId === d.id ? <LoadingDots /> : <XIcon />}
      </Button>
    </Flex>,
  ];

  return isProjects ? cols : [cols[0], cols[2], cols[3], cols[4]];
};

export const InvitesModal = ({
  auth,
  isProjects,
  refetchProjects,
}: {
  auth: AuthUserRes;
  isProjects: boolean;
  refetchProjects?: () => Promise<void>;
}) => {
  const { hideModal, show, showModal } = useModal();
  const { projects, teams, invites } = MemberPaths;
  const aInvitesPath = useMatch(
    `/${BasePaths.m}/:${AppParams.memberHandle}/${projects}/${invites}`
  );
  const pInvitesPath = useMatch(
    `/${BasePaths.m}/:${AppParams.memberHandle}/${teams}/${invites}`
  );
  const { memberHandle } = useParams();
  const navigate = useNavigate();

  // Show if match path
  useEffect(() => {
    if (aInvitesPath || pInvitesPath) {
      showModal();
    } else {
      hideModal();
    }
  }, [hideModal, aInvitesPath, pInvitesPath, showModal]);

  const overflowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (overflowRef.current && show) {
      overflowRef.current.scrollTop = 0;
    }
  }, [show]);

  // API Calls

  const { refetch: refetchMember } = memberApi.useMemberGetProfileQuery(
    memberHandle ? { memberHandle } : skipToken
  );

  const { refetch: refetchCounts } = authApi.useAuthGetCountsQuery();

  const [err, setErr] = useState("");
  const [search, setSearch] = useState("");
  const {
    refetch,
    data,
    isLoading,
    error: errorInvites,
  } = inviteApi.useInvitesUserGetQuery();
  const appInvites = !data
    ? []
    : search
    ? data.appInvites.filter((d) => d.app.name.includes(search))
    : data.appInvites;
  const projectInvites = !data
    ? []
    : search
    ? data.projectInvites.filter((d) => d.project.name.includes(search))
    : data.projectInvites;
  const currInvites = isProjects ? projectInvites : appInvites;

  const [addId, setAddId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [handleAddMut, { error: errorAdd }] =
    inviteApi.useInviteAddMemberMutation();
  const [handleDeleteMut, { error: errorDelete }] =
    inviteApi.useInviteDeleteMutation();

  const handleAdd = async (inviteId: string) => {
    const inv = currInvites.find((i) => i.id === inviteId);
    if (!inv) {
      setErr("Invite not found");
      return;
    }
    setAddId(inviteId);
    const contractUpdate =
      isProjects && "project" in inv
        ? await projectInviteJoinCall(auth, inv.project.componentAddress)
        : "app" in inv
        ? await appInviteJoinCall(auth, inv.app.componentAddress)
        : undefined;

    if (!contractUpdate) {
      setErr("Unable to update contract");
      setAddId("");
      return;
    }

    const [, res] = await awaitTo(
      handleAddMut({ inviteId, kind: isProjects ? "project" : "app" }).unwrap()
    );
    if (res?.id) {
      await Promise.all([refetch(), refetchCounts()]);
      if (!isProjects) {
        refetchMember();
      } else if (refetchProjects) {
        refetchProjects();
      }
    }
    setAddId("");
  };

  const handleDelete = async (inviteId: string) => {
    setDeleteId(inviteId);
    const [, res] = await awaitTo(
      handleDeleteMut({
        inviteId,
        kind: isProjects ? "project" : "app",
      }).unwrap()
    );
    if (res?.id) {
      await refetch();
      await refetchCounts();
    }
    setDeleteId("");
  };

  const error = parseRtkError(errorInvites || errorAdd || errorDelete);

  useEffect(() => {
    if (show) {
      setErr("");
    }
  }, [show]);

  return (
    <>
      {show && (
        <Helmet>
          <title>Invites</title>
          <meta name="description" content="View invites" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}

      <Modal
        show={show}
        closeModal={() =>
          isProjects
            ? navigate(`/${BasePaths.m}/${memberHandle}/${projects}`)
            : navigate(`/${BasePaths.m}/${memberHandle}/${teams}`)
        }
        large
      >
        <Flex column fullHeight>
          <Flex marginBottom="16px" gap="12px" justifyBetween>
            <H2>Invites</H2>
            {show && <RadixButton small />}
          </Flex>

          <SearchInput
            inputProps={{ secondary: true }}
            handleSearch={(v) => setSearch(v)}
          />

          <Error error={err} />

          <Div ref={overflowRef} flex="1" margin="16px 0 16px" overflow="auto">
            {!isLoading && <Error error={error} margin="16px 0" />}
            {isLoading && (
              <Flex height="300px" center>
                <LoadingDots />
              </Flex>
            )}

            {!isLoading && (
              <DataTable
                cols={isProjects ? InvitesPTableCols : InvitesATableCols}
                headings={
                  isProjects ? InvitesPTableHeadings : InvitesATableHeadings
                }
                data={currInvites}
                rowData={{
                  handleAdd,
                  handleDelete,
                  addId,
                  deleteId,
                  isProjects,
                }}
                rowRender={InvitesRowRender}
              />
            )}
          </Div>
        </Flex>
      </Modal>
    </>
  );
};
