import { StateEntityDetailsResponseItemDetails } from "@radixdlt/radix-dapp-toolkit";
import { PACKAGE_ADDRESS } from "consts";
import { Blueprints, StateValue, ValueKind } from "../radix-types";
import {
  ObjectiveData,
  parseArray,
  parseHashMap,
  parseObjectives,
  parseTuple,
} from "./parser-utils";

// -------------------------------

export type ProjectContract = {
  address: string;
  members: Record<string, string>;
  signatures: string[];
  funds_vault: string;
  fees_vault: string;

  // undefined values
  funds: number;
  fees: number;
  resource_address: string;

  objectives: ObjectiveData;
  completed: ObjectiveData;
  reserved: Record<string, string>;
  is_cancelled: boolean;

  IS_RESET: boolean; // used for redux store reset
  paid: number; // not in contract
  tx_nft_address: string;
  tx_nft_count: number;
};

export const parseProjectContract = (
  details: StateEntityDetailsResponseItemDetails,
  address: string
) => {
  if (
    details.type !== "Component" ||
    details.package_address !== PACKAGE_ADDRESS ||
    details.blueprint_name !== Blueprints.ProjectContract ||
    !details.state
  ) {
    return undefined;
  }
  const s = parseTuple(details.state as StateValue);
  if (!s) {
    return undefined;
  }

  const completed = s.completed ? parseObjectives(s.completed) : {};
  const paid = Object.values(completed).reduce(
    (acc, curr) => Object.values(curr).reduce((a, c) => a + c, 0) + acc,
    0
  );

  const projectState: ProjectContract = {
    address,
    members: s.members ? parseHashMap(s.members) : {},
    signatures: s.signatures ? parseArray(s.signatures) : [],
    funds_vault: s.funds?.value?.toString() || "",
    fees_vault: s.fees?.value?.toString() || "",
    funds: 0,
    fees: 0,
    resource_address: "",

    objectives: s.objectives ? parseObjectives(s.objectives) : {},
    completed,
    reserved: s.reserved ? parseHashMap(s.reserved) : {},
    is_cancelled:
      s.is_cancelled?.kind === ValueKind.Bool ? s.is_cancelled.value : false,

    IS_RESET: false,
    paid,
    tx_nft_address: "",
    tx_nft_count: 0,
  };

  return projectState;
};
