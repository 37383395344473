import {
  BaseButton,
  BorderTag,
  Card,
  Flex,
  H5,
  Hr,
  Input,
  T2,
  T3,
} from "library/components";
import { Colors } from "library/consts";
import { formatPercent } from "library/utils";
import {
  Control,
  FieldErrorsImpl,
  useFieldArray,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { Distributions } from "./Distributions";
import { ContractReqType } from "schema";
import { CheckmarkThin, XIcon } from "library/assets";

// Nested Field Array
// https://codesandbox.io/embed/react-hook-form-usefieldarray-nested-arrays-x7btr

type ObjectivesProps = {
  control: Control<ContractReqType>;
  register: UseFormRegister<ContractReqType>;
  watch: UseFormWatch<ContractReqType>;
  errors: Partial<FieldErrorsImpl<ContractReqType>>;
  readOnly?: boolean;
  handleReward: (objNum: number) => Promise<boolean>;
  rewardNum?: number;
};

export const Objectives = ({
  control,
  register,
  watch,
  errors,
  readOnly,
  handleReward,
  rewardNum,
}: ObjectivesProps) => {
  const { fields } = useFieldArray({
    control,
    name: "objectives",
  });

  const values = watch();

  return (
    <>
      {fields.map((objective, index) => {
        const val = values.objectives[index];

        return (
          <Card key={objective.id} padding="24px" borderRadius="12px">
            <T3 marginBottom="16px" medium secondary>
              Objective {objective.number}
            </T3>

            <Flex gap="16px" alignCenter>
              <H5 flex="1">{objective.name}</H5>
              {values.amount > 0 && val.amount > 0 && (
                <BorderTag>
                  {formatPercent(val.amount / values.amount)}
                </BorderTag>
              )}
              <Input
                rProps={register(`objectives.${index}.amount`, {
                  valueAsNumber: true,
                })}
                iProps={{
                  type: "number",
                  placeholder: "0",
                  width: "88px",
                  secondary: true,
                  fontWeight: "500",
                  readOnly: readOnly || val.isCompleted,
                }}
              />
            </Flex>

            <Hr margin="24px 0" />

            <Distributions
              nestIndex={index}
              control={control}
              register={register}
              values={values}
              readOnly={readOnly}
            />

            <Hr margin="24px 0" isDashed />

            {errors.objectives?.[index]?.amount?.message && (
              <T2 color={Colors.red} margin="16px 0" medium>
                {errors.objectives?.[index]?.amount?.message}
              </T2>
            )}

            <Flex justifyEnd>
              {val.isCompleted ? (
                <Flex
                  backgroundColor={Colors.greenDark}
                  marginLeft="20px"
                  padding="6px 12px"
                  borderRadius="16px"
                  alignCenter
                >
                  <CheckmarkThin size={14} strokeWidth={3} />
                  <T3 marginLeft="4px" color={Colors.white} medium>
                    Completed
                  </T3>
                </Flex>
              ) : val.isAdded ? (
                <BaseButton
                  type="button"
                  backgroundColor={Colors.blue}
                  color={Colors.white}
                  disabled={values.isCancelled || !readOnly}
                  onClick={() => handleReward(val.number)}
                  isLoading={val.number === rewardNum}
                >
                  Reward
                </BaseButton>
              ) : (
                <Flex
                  backgroundColor={Colors.darkRed}
                  marginLeft="20px"
                  padding="6px 12px"
                  borderRadius="16px"
                  alignCenter
                >
                  <XIcon size={14} strokeWidth={3} />
                  <T3 marginLeft="4px" color={Colors.white} medium>
                    Not Added
                  </T3>
                </Flex>
              )}
            </Flex>
          </Card>
        );
      })}
    </>
  );
};
