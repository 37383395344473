import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import styled, { css } from "styled-components";
import {
  BaseButton,
  CoinResourceOptions,
  DatePicker,
  Div,
  Dropdown,
  Error,
  Flex,
  H6,
  Input,
  MdEditor,
  Modal,
  PX,
  RadixButton,
  T2,
  T3,
  UserIcon,
} from "library/components";
import { Colors, Width } from "library/consts";
import { MemberInviteType } from "library/types";
import { awaitTo } from "library/utils";
import { IdRes } from "redux/apiTypes";
import { VestingReq, VestingReqType } from "schema";
import { XRD_ADDRESS } from "consts";

// TwoColGrid with gap of 20px
export const TwoCol = styled(Div)`
  // Mobile (XS)
  display: grid;
  grid-template-columns: repeat(1, minmax(150px, 1fr));
  grid-gap: 20px;

  // Tablet (SM)
  @media only screen and (min-width: ${Width.SM}px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
`;

type VestingModalProps = {
  hideModal: () => void;
  show: boolean;
  members: MemberInviteType[];
  error?: string;
  isLoading?: boolean;
  handleCreate: (data: VestingReqType) => Promise<IdRes | undefined>;
};

export const VestingModal = ({
  hideModal,
  show,
  members,
  error,
  isLoading,
  handleCreate,
}: VestingModalProps) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<VestingReqType>({
    resolver: zodResolver(VestingReq),
    defaultValues: {
      resourceAddress: XRD_ADDRESS,
    },
  });

  const onSubmit = async (d: VestingReqType) => {
    const [err, res] = await awaitTo(handleCreate(d));
    if (!err && res?.id) {
      hideModal();
      reset({});
    }
  };

  return (
    <Modal show={show} closeModal={hideModal} large>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap="40px" flexDirection="column" sm={{ flexDirection: "row" }}>
          <Flex flex="1" flexDirection="column" gap="20px">
            <Input
              error={errors.name?.message}
              rProps={register("name")}
              iProps={{
                placeholder: "Vesting Name*",
                fontSize: PX(20),
                fontWeight: "500",
                secondary: true,
              }}
            />

            <Div>
              <H6 marginBottom="8px">Vesting Member</H6>
              <Controller
                control={control}
                name="memberAddress"
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    handleSelect={(v) => onChange(v)}
                    selectValue={value}
                    options={[
                      { label: "--", value: "" },
                      ...members.map((m) => ({
                        label: (
                          <Flex key={m.id} width="100%" alignCenter>
                            <UserIcon icon={m.icon} name={m.name} />
                            <T2 marginLeft="8px" ellipsis>
                              {m.name} ({m.memberHandle})
                            </T2>
                          </Flex>
                        ),
                        value: m.componentAddress,
                      })),
                    ]}
                    buttonProps={{ minHeight: "64px", showBorder: true }}
                    wrapperProps={{ width: "100%" }}
                    optionsProps={{ width: "100%" }}
                  />
                )}
              />
            </Div>

            <Div>
              <TwoCol marginBottom="16px">
                <Input
                  label="Amount"
                  error={errors.amount?.message}
                  rProps={register("amount", { valueAsNumber: true })}
                  iProps={{
                    type: "number",
                    secondary: true,
                  }}
                />
                <Controller
                  control={control}
                  name="resourceAddress"
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      heading="Token"
                      handleSelect={(v) => onChange(v)}
                      selectValue={value}
                      options={CoinResourceOptions}
                      buttonProps={{ showBorder: true, minHeight: "68px" }}
                    />
                  )}
                />
              </TwoCol>
            </Div>

            <Controller
              control={control}
              name="description"
              render={({ field: { value, onChange } }) => (
                <MdEditor
                  formError={errors.description?.message}
                  value={value}
                  setValue={onChange}
                  style={{ height: "320px" }}
                />
              )}
            />
          </Flex>

          <Flex
            gap="8px"
            flexDirection="column"
            width="100%"
            sm={{ width: "300px" }}
          >
            <Div position="relative" flexWrap="nowrap">
              <Controller
                control={control}
                name="startDate"
                render={({ field: { value, onChange } }) => (
                  <Div
                    borderRadius="8px"
                    css={css`
                      border: ${({ theme }) =>
                        `2px solid ${theme.borderColor}`};
                    `}
                  >
                    <DatePicker
                      handleSelectDate={(v) => onChange(v || "")}
                      label="Start Date"
                      date={value}
                    />
                  </Div>
                )}
              />
              {errors.startDate?.message && (
                <T2 color={Colors.red} marginTop="8px" medium>
                  {errors.startDate?.message}
                </T2>
              )}
            </Div>

            <Div position="relative" flexWrap="nowrap">
              <Controller
                control={control}
                name="cliffDate"
                render={({ field: { value, onChange } }) => (
                  <Div
                    borderRadius="8px"
                    css={css`
                      border: ${({ theme }) =>
                        `2px solid ${theme.borderColor}`};
                    `}
                  >
                    <DatePicker
                      handleSelectDate={onChange}
                      label="Cliff Date"
                      date={value}
                    />
                  </Div>
                )}
              />
              {errors.cliffDate?.message && (
                <T2 color={Colors.red} marginTop="8px" medium>
                  {errors.cliffDate?.message}
                </T2>
              )}
            </Div>

            <Div position="relative" flexWrap="nowrap">
              <Controller
                control={control}
                name="endDate"
                render={({ field: { value, onChange } }) => (
                  <Div
                    borderRadius="8px"
                    css={css`
                      border: ${({ theme }) =>
                        `2px solid ${theme.borderColor}`};
                    `}
                  >
                    <DatePicker
                      handleSelectDate={(v) => onChange(v || "")}
                      label="End Date"
                      date={value}
                    />
                  </Div>
                )}
              />
              {errors.endDate?.message && (
                <T2 color={Colors.red} marginTop="8px" medium>
                  {errors.endDate?.message}
                </T2>
              )}
            </Div>

            <Div marginTop="8px">
              <T3 marginBottom="8px">Vest Interval (Days)</T3>
              <Input
                error={errors.vestInterval?.message}
                rProps={register("vestInterval", { valueAsNumber: true })}
                iProps={{
                  type: "number",
                  secondary: true,
                }}
              />
            </Div>

            <Flex marginTop="32px" gap="12px">
              <BaseButton flex="1" isLoading={isLoading}>
                Create Vesting
              </BaseButton>
              <RadixButton small />
            </Flex>
            {isLoading && (
              <T3>
                * Wallet will first request the withdraw account, then the badge
                account
              </T3>
            )}
            <Error error={error} />
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
