import { Div, Flex, T2 } from "library/components";

type StatusProps = {
  color: string;
  label: string;
  center?: boolean;
};

export const Status = ({ center, color, label }: StatusProps) => (
  <Flex justifyCenter={center} alignCenter>
    <Div
      height="6px"
      width="6px"
      backgroundColor={color}
      borderRadius="50%"
      marginRight="8px"
    />
    <T2 medium>{label}</T2>
  </Flex>
);
