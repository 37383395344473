import { Link } from "react-router-dom";
import { BasePaths, Colors } from "library/consts";
import { Div, Img } from "library/components";
import { FrostedBackgroundStyle } from "library/styles";
import { Card } from "./Card";
import { BookmarkButton } from "./CardComponents";
import { CardHeading } from "./CardHeading";

// TODO: determine whether to use Frosted or Gradient

type AppCardProps = {
  appHandle: string;
  icon?: string;
  image?: string;
  name: string;
  subtitle: string;
  saved?: boolean;
};

export const AppCard = ({
  appHandle,
  icon,
  image,
  name,
  subtitle,
  saved,
}: AppCardProps) => (
  <Div position="relative">
    <BookmarkButton color={Colors.white} saved={saved} />

    <Link to={`/${BasePaths.a}/${appHandle}`}>
      <Card aspectRatio="1" zIndex="1" sm={{ aspectRatio: "5/4" }}>
        <Img
          as={!image ? "div" : "img"}
          height="100%"
          width="100%"
          zIndex="2"
          src={image}
          alt={name}
        />
        <Div
          height="45%"
          width="100%"
          position="absolute"
          bottom="0"
          zIndex="3"
          // css={css`
          //   background: ${({ theme }) =>
          //     theme._isDark ? EaseGradient(28, 28, 40) : EaseGradient(100, 100, 120)};
          // `}
        />

        <Div position="absolute" bottom="0" width="100%" zIndex="4">
          <CardHeading
            css={FrostedBackgroundStyle}
            icon={icon}
            name={name}
            subtitle={subtitle}
          />
        </Div>
      </Card>
    </Link>
  </Div>
);
