import {
  FeedbackCreateApiRes,
  FeedbackCreateApiArg,
  FeedbackGetApiRes,
  FeedbackGetApiArg,
  FeedbackUpdateApiRes,
  FeedbackUpdateApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    feedbackCreate: build.mutation<FeedbackCreateApiRes, FeedbackCreateApiArg>({
      query: (queryArg) => ({
        url: `/feedback/${queryArg.projectHandle}`,
        method: "POST",
        body: queryArg.feedbackReq,
      }),
    }),
    feedbackGet: build.query<FeedbackGetApiRes, FeedbackGetApiArg>({
      query: (queryArg) => ({ url: `/feedback/${queryArg.projectHandle}` }),
    }),
    feedbackUpdate: build.mutation<FeedbackUpdateApiRes, FeedbackUpdateApiArg>({
      query: (queryArg) => ({
        url: `/feedback/${queryArg.feedbackId}`,
        method: "PATCH",
        body: queryArg.feedbackReq,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as feedbackApi };
