/* eslint-disable @typescript-eslint/no-redeclare */

export const Category = {
  DEFI: "DEFI",
  EXCHANGE: "EXCHANGE",
  FUND: "FUND",
  GAMEFI: "GAMEFI",
  GOVERNANCE: "GOVERNANCE",
  IDENTITY: "IDENTITY",
  INSURANCE: "INSURANCE",
  LENDING: "LENDING",
  MARKETPLACE: "MARKETPLACE",
  NFT: "NFT",
  ORACLE: "ORACLE",
  SOCIAL: "SOCIAL",
  TOOL: "TOOL",
  OTHER: "OTHER",
} as const;
export type Category = keyof typeof Category;

export const ObjectiveState = {
  DRAFT: "DRAFT",
  READY_FOR_DEVELOPMENT: "READY_FOR_DEVELOPMENT",
  IN_PROGRESS: "IN_PROGRESS",
  REVIEW: "REVIEW",
  DEPLOYMENT: "DEPLOYMENT",
  QA: "QA",
  COMPLETED: "COMPLETED",
} as const;
export type ObjectiveState = keyof typeof ObjectiveState;

export const ProjectState = {
  DRAFT: "DRAFT",
  READY_FOR_DEVELOPMENT: "READY_FOR_DEVELOPMENT",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
} as const;
export type ProjectState = keyof typeof ProjectState;

export const ProposalStatus = {
  ACCEPTED: "ACCEPTED",
  CLOSED: "CLOSED",
  PENDING: "PENDING",
} as const;
export type ProposalStatus = keyof typeof ProposalStatus;

export const ReportType = {
  ADVERTISEMENT_SPAM: "ADVERTISEMENT_SPAM",
  COPYRIGHT_TRADEMARK_PRIVATE: "COPYRIGHT_TRADEMARK_PRIVATE",
  IDENTITY_IMPERSONATION: "IDENTITY_IMPERSONATION",
  THREAT_FROM_CONTENT: "THREAT_FROM_CONTENT",
  ABUSIVE_INAPPROPRIATE_CONTENT: "ABUSIVE_INAPPROPRIATE_CONTENT",
  HARMFUL_CODE: "HARMFUL_CODE",
  OTHER: "OTHER",
} as const;
export type ReportType = keyof typeof ReportType;

export const ReviewStatus = {
  OPEN: "OPEN",
  UNDER_REVIEW: "UNDER_REVIEW",
  CLOSED: "CLOSED",
} as const;
export type ReviewStatus = keyof typeof ReviewStatus;

export const Stage = {
  IDEA: "IDEA",
  DESIGN: "DESIGN",
  BUILD: "BUILD",
  VALIDATE: "VALIDATE",
  GROWTH: "GROWTH",
} as const;
export type Stage = keyof typeof Stage;

export const TeamRole = {
  TEAM_LEAD: "TEAM_LEAD",
  MEMBER: "MEMBER",
  CONTRIBUTOR: "CONTRIBUTOR",
} as const;
export type TeamRole = keyof typeof TeamRole;

export const TeamType = {
  BLUEPRINT: "BLUEPRINT",
  AUDIT: "AUDIT",
  FRONTEND: "FRONTEND",
  BACKEND: "BACKEND",
  DEVOPS: "DEVOPS",
  DESIGN: "DESIGN",
  PRODUCT: "PRODUCT",
  COMMUNITY: "COMMUNITY",
  GROWTH: "GROWTH",
  LEGAL: "LEGAL",
  ADMIN: "ADMIN",
} as const;
export type TeamType = keyof typeof TeamType;
