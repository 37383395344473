import { FileExtensions } from "consts";
import { LoadingDots } from "library/assets";
import { Flex } from "library/components";
import { Highlight, Prism, themes } from "prism-react-renderer";
import { useTheme } from "redux/hooks";

(typeof global !== "undefined" ? global : window).Prism = Prism;
// @ts-ignore
await import("prismjs/components/prism-python");
// @ts-ignore
await import("prismjs/components/prism-css");
// @ts-ignore
await import("prismjs/components/prism-java");

export const CodeBlock = ({
  url,
  code,
}: {
  url: string;
  code: string | undefined;
}) => {
  const { state } = useTheme();
  const { currentTheme } = state;
  const theme = currentTheme === "dark" ? themes.vsDark : themes.vsLight;

  const [ext] = url.split(".").splice(-1);
  const lang = FileExtensions[ext];

  if (!code) {
    return (
      <Flex minHeight="100%" flex="1" center column>
        <LoadingDots size={100} r={2} />
      </Flex>
    );
  }

  if (!lang) {
    return (
      <pre
        style={{
          margin: "0",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        <code>{code}</code>
      </pre>
    );
  }

  return (
    <Highlight theme={theme} code={code} language={lang}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          style={{
            ...style,
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            margin: "0",
            padding: "16px",
            overflow: "hidden",
          }}
        >
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line })}>
              <span style={{ marginRight: "24px" }}>{i + 1}</span>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
