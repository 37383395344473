import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Div,
  Dropdown,
  Flex,
  H3,
  ImageInput,
  Input,
  T2,
  TagInput,
  TeamTypeOptionsSmall,
  TwoColGrid,
  Error,
  BaseButton,
  CheckboxForm,
  Select,
  RadixButton,
  T1,
  MdEditor,
  OneColGrid,
} from "library/components";
import { Colors } from "library/consts";
import { removeEmpty } from "library/utils";
import { FileGetRes, SearchTagsRes } from "redux/apiTypes";
import { FileReq, FileReqType } from "schema";
import { CheckmarkThin } from "library/assets";
import { Category, TeamType } from "library/types";
import { CategoryFormOptions } from "consts";
import { FileNftType } from "radix";

type FileSettingsFormProps = {
  creatorHandle?: string;
  appCategory?: Category;
  data?: FileGetRes;
  nft?: FileNftType;
  error?: string;
  handleCreate?: (d: FileReqType) => void;
  handleUpdate?: (d: FileReqType) => void;
  handleSearch: (v: string) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  isLoadingTags?: boolean;
  suggestions?: SearchTagsRes;
};

export const FileSettingsForm = ({
  creatorHandle,
  appCategory,
  data,
  nft,
  error,
  handleCreate,
  handleUpdate,
  handleSearch,
  isLoading,
  isSuccess,
  isLoadingTags,
  suggestions,
}: FileSettingsFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FileReqType>({
    resolver: zodResolver(FileReq),
    defaultValues: {
      ...(data ? removeEmpty(data) : {}),
      creatorHandle: creatorHandle || "",
      teamType: data?.teamType || TeamType.BLUEPRINT,
      category: data?.category || appCategory,
      urls: nft?.urls || [],
      images: nft?.images || [],
      videoIds: nft?.videoIds || [],
      tags: data?.tags.map((t) => t.name) || [],
    },
  });
  const onSubmit = (d: FileReqType) =>
    data && handleUpdate
      ? handleUpdate(d)
      : handleCreate
      ? handleCreate(d)
      : console.log(d);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap="40px" flexDirection="column" sm={{ flexDirection: "row" }}>
        <Flex flex="1" flexDirection="column" gap="20px">
          {!data && <H3 marginBottom="4px">Create Library File</H3>}
          <Input
            label="Name*"
            error={errors.name?.message}
            rProps={register("name")}
            iProps={{ showBorder: true }}
          />
          <Input
            label="Card Image Url*"
            error={errors.image?.message}
            rProps={register("image")}
            iProps={{ showBorder: true }}
          />
          <OneColGrid>
            <Controller
              control={control}
              name="urls"
              render={({ field: { value, onChange } }) => (
                <ImageInput
                  formError={
                    errors.urls?.message ||
                    (errors.urls?.find && errors.urls.find((v) => v)?.message)
                  }
                  handleChange={onChange}
                  label="File Urls*"
                  values={value}
                  iProps={{ showBorder: true }}
                  hidePreview
                />
              )}
            />
          </OneColGrid>

          <TwoColGrid>
            <Controller
              control={control}
              name="images"
              render={({ field: { value, onChange } }) => (
                <ImageInput
                  formError={
                    errors.images?.find && errors.images.find((v) => v)?.message
                  }
                  handleChange={onChange}
                  label="Image Urls*"
                  values={value}
                  iProps={{ showBorder: true }}
                />
              )}
            />
            <Controller
              control={control}
              name="videoIds"
              render={({ field: { value, onChange } }) => (
                <ImageInput
                  formError={
                    errors.videoIds?.find &&
                    errors.videoIds.find((v) => v)?.message
                  }
                  handleChange={onChange}
                  label="YouTube Video Ids"
                  values={value}
                  iProps={{ showBorder: true }}
                  isVideoId
                />
              )}
            />
          </TwoColGrid>

          <T1 medium>Description*</T1>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <MdEditor
                formError={errors.description?.message}
                value={value}
                setValue={onChange}
                style={{ height: "420px" }}
              />
            )}
          />
        </Flex>

        <Flex
          gap="20px"
          flexDirection="column"
          width="100%"
          sm={{ width: "300px" }}
        >
          <Select
            label="Category*"
            error={errors.category?.message}
            rProps={register("category")}
            options={CategoryFormOptions}
            iProps={{ showBorder: true }}
          />
          <Controller
            control={control}
            name="teamType"
            render={({ field: { value, onChange } }) => (
              <Div>
                <Dropdown
                  heading="Team"
                  handleSelect={onChange}
                  selectValue={value}
                  options={TeamTypeOptionsSmall}
                  wrapperProps={{ width: "100%" }}
                  buttonProps={{ minHeight: "56px", showBorder: true }}
                  hideChevron
                />
                {errors.teamType?.message && (
                  <T2 color={Colors.red} marginTop="8px" medium>
                    {errors.teamType.message}
                  </T2>
                )}
              </Div>
            )}
          />
          <Controller
            control={control}
            name="tags"
            render={({ field: { value, onChange } }) => (
              <TagInput
                formError={errors.tags?.message}
                handleChange={onChange}
                handleSearch={(v) => handleSearch(v)}
                isLoading={isLoadingTags}
                label="Tags"
                values={value}
                iProps={{ showBorder: true }}
                suggestions={suggestions?.map((t) => t.name)}
              />
            )}
          />
          {data && (
            <CheckboxForm
              label="File is Active"
              description="This file is in active use by the creator"
              error={errors.isActive?.message}
              rProps={register("isActive")}
            />
          )}

          <Flex marginTop="32px" marginBottom="8px" gap="12px">
            <BaseButton isLoading={isLoading} flex="1">
              {!data ? "Create File" : "Update File"}
            </BaseButton>
            <RadixButton small />
          </Flex>
          <Error error={error} />
          {isSuccess && (
            <Flex gap="4px" alignCenter>
              <CheckmarkThin size={14} strokeWidth={3} />
              <T2>Success</T2>
            </Flex>
          )}
        </Flex>
      </Flex>
    </form>
  );
};
