import { ChangeEvent, useState } from "react";
import styledComponents, { css } from "styled-components";
import {
  Button,
  Checkbox,
  Div,
  Flex,
  PX,
  SecondaryButton,
  Span,
  T1,
  Tab,
  Textarea as TextareaBase,
} from "library/components";
import { MemberDropdown } from "../Dropdown";
import { XIcon } from "library/assets";
import { MemberType, TaskType } from "../../types";

const Textarea = styledComponents(TextareaBase)`
  flex: 1;
  padding: 7px 8px;
  marginRight: 8px;
`;

type TaskProps = {
  task: TaskType;
  handleDelete: (val: string) => void;
  handleUpdateList: (val: Partial<TaskType>) => void;
  membersList: MemberType[];
};

const Task = ({
  task,
  handleDelete,
  handleUpdateList,
  membersList,
}: TaskProps) => {
  const [isEdit, setIsEdit] = useState(!Boolean(task.name));
  const [name, setName] = useState(task.name);

  return (
    <Flex alignStart>
      <Checkbox
        padding="8px"
        checked={task.isCompleted}
        handleCheck={() => {
          handleUpdateList({
            id: task.id,
            isCompleted: !task.isCompleted,
          });
        }}
      />

      {isEdit ? (
        <Textarea
          value={name}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setName(e.target.value)
          }
          onBlur={() => {
            setIsEdit(false);

            if (!name) {
              handleDelete(task.id);
            } else {
              handleUpdateList({ id: task.id, name });
            }
          }}
          maxLength={160}
          autoFocus
        />
      ) : (
        <Button
          type="button"
          fontSize={PX(15)}
          fontWeight="400"
          padding="8px"
          textAlign="left"
          justifyContent="flex-start"
          flex="1"
          onClick={() => setIsEdit(true)}
        >
          {name}
        </Button>
      )}

      <MemberDropdown
        handleSelectMember={(id) => {
          const newOwners = task.owners.includes(id)
            ? task.owners.filter((m) => m !== id)
            : [...task.owners, id];
          handleUpdateList({ id: task.id, owners: newOwners });
        }}
        selected={task.owners}
        members={membersList}
        wrapperProps={{ width: "initial" }}
        small
      />

      <Tab
        type="button"
        padding="8px 4px"
        sm={{ padding: "8px 4px" }}
        onClick={() => handleDelete(task.id)}
      >
        <XIcon size={20} />
      </Tab>
    </Flex>
  );
};

type TasksListProps = {
  membersList: MemberType[];
  setTasksList: (tasks: TaskType[]) => void;
  tasksList?: TaskType[];
  disabled?: boolean;
};

export const TasksList = ({
  membersList,
  setTasksList,
  tasksList = [],
  disabled,
}: TasksListProps) => {
  const checkedCount =
    tasksList.reduce((acc, curr) => (curr.isCompleted ? acc + 1 : acc), 0) || 0;

  return (
    <Div>
      <T1
        paddingBottom="8px"
        marginBottom="8px"
        css={css`
          border-bottom: 2px solid ${({ theme }) => theme.borderColor};
        `}
        medium
      >
        Tasks{" "}
        <Span secondary>
          {tasksList.length > 0 && `(${checkedCount}/${tasksList.length})`}
        </Span>
      </T1>

      {tasksList.map((task, i) => (
        <Task
          key={task.id || i}
          task={task}
          handleDelete={(id) =>
            setTasksList(tasksList.filter((t) => t.id !== id))
          }
          handleUpdateList={(task) => {
            const newList = [...tasksList];
            const updateIdx = newList.findIndex((t) => t.id === task.id);
            newList[updateIdx] = { ...newList[updateIdx], ...task };
            setTasksList(newList);
          }}
          membersList={membersList}
        />
      ))}

      <SecondaryButton
        type="button"
        height="32px"
        marginTop="16px"
        padding="8px 12px"
        sm={{ padding: "8px 12px" }}
        borderRadius="8px"
        fontSize={PX(12)}
        fontWeight="500"
        onClick={() =>
          setTasksList([
            ...tasksList,
            {
              id: tasksList.length.toString(),
              isCompleted: false,
              name: "",
              owners: [],
            },
          ])
        }
        disabled={tasksList.length >= 10 || disabled}
      >
        Add Task
      </SecondaryButton>
    </Div>
  );
};
