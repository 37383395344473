import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { BasePaths, MemberPaths } from "consts";
import { useModal } from "library/components";
import { ObjectiveModal } from "./ObjectiveModal";
import { objectiveApi } from "redux/apis";
import { awaitTo, parseRtkError } from "library/utils";
import {
  MemberDashboardRes,
  ObjectiveReq as ObjectiveReqType,
} from "redux/apiTypes";

type ObjectiveDashboardModalProps = {
  list: MemberDashboardRes;
  updateList: (
    val: MemberDashboardRes[number]["owner"][number],
    projectRoleIdx: number,
    objIdx: number
  ) => void;
};

export const ObjectiveDashboardModal = ({
  list,
  updateList,
}: ObjectiveDashboardModalProps) => {
  const { hideModal, show, showModal } = useModal();
  const { memberHandle, objectiveHandle, projectHandle } = useParams();
  const navigate = useNavigate();

  const [handleUpdate, { error, isLoading }] =
    objectiveApi.useObjectiveUpdateMutation();

  const projectRoleIdx = list.findIndex(
    (r) => r.project.projectHandle === projectHandle
  );
  const projectRole = projectRoleIdx !== -1 ? list[projectRoleIdx] : undefined;
  const { owner, project } = projectRole || {};
  const objectiveIdx = (owner || []).findIndex(
    (o) => o.number === Number(objectiveHandle)
  );
  const objective =
    objectiveIdx !== -1 && owner ? owner[objectiveIdx] : undefined;
  const members = project?.memberProjectRoles.map((m) => m.member) || [];

  useEffect(() => {
    if (objective) {
      showModal();
    } else {
      hideModal();
    }
  }, [hideModal, objective, showModal]);

  const handleClose = () => {
    navigate(`/${BasePaths.m}/${memberHandle}/${MemberPaths.dashboard}`);
  };

  const onSubmit = async (data: ObjectiveReqType) => {
    if (!objective) return;
    const [, res] = await awaitTo(
      handleUpdate({
        objectiveReq: data,
        objectiveId: objective.id,
      }).unwrap()
    );
    if (res?.id) {
      updateList(res, projectRoleIdx, objectiveIdx);
      return res;
    }
    return undefined;
  };

  return (
    <>
      {show && objective && (
        <Helmet>
          <title>{objective.name}</title>
          <meta name="description" content={objective.name} />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}

      <ObjectiveModal
        show={show}
        hideModal={handleClose}
        members={members}
        objectiveNumber={objectiveHandle}
        teamType={project?.teamType}
        error={parseRtkError(error)}
        isLoading={isLoading}
        handleUpdate={(data) => onSubmit(data)}
        projectHandle={projectHandle}
        projectName={project?.name}
        data={objective}
      />
    </>
  );
};
