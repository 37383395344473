export const VestingTableCols = [
  { textAlign: "center" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const VestingTableHeadings = [
  "Id",
  "Member",
  "Amount",
  "Vested",
  "Withdrawn",
  "Reserved",
  "Start Date",
  "Cliff Date",
  "End Date",
  "Link",
];

export const MemberVestingTableHeadings = [
  "Id",
  "App",
  "Amount",
  "Vested",
  "Withdrawn",
  "Reserved",
  "Start Date",
  "Cliff Date",
  "End Date",
  "Link",
];
