export const LoadingDots = ({ fill = "currentColor", size = 24, r = 3 }) => (
  <svg
    width={size}
    height={size}
    fill={fill}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="12" r={r}>
      <animate
        attributeName="opacity"
        values="0;1;0"
        dur="1s"
        begin="-0.4s;op.end-0.4s"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="12" cy="12" r={r}>
      <animate
        attributeName="opacity"
        values="0;1;0"
        dur="1s"
        begin="-0.2s;op.end-0.2s"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="21" cy="12" r={r}>
      <animate
        attributeName="opacity"
        values="0;1;0"
        dur="1s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);
