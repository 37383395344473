import {
  ObjectiveCreateApiRes,
  ObjectiveCreateApiArg,
  ObjectiveUpdateApiRes,
  ObjectiveUpdateApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    objectiveCreate: build.mutation<
      ObjectiveCreateApiRes,
      ObjectiveCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/objective/${queryArg.projectHandle}/create`,
        method: "POST",
        body: queryArg.objectiveReq,
      }),
    }),
    objectiveUpdate: build.mutation<
      ObjectiveUpdateApiRes,
      ObjectiveUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/objective/${queryArg.objectiveId}`,
        method: "PATCH",
        body: queryArg.objectiveReq,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as objectiveApi };
