import { createAction } from "@reduxjs/toolkit";
import { FileNftType } from "radix";
import { AppDispatch } from "redux/store";

export const fileActionConsts = {
  SET_FILE: "SET_FILE",
  RESET_FILE: "RESET_FILE",
};

const setFile =
  (file: FileNftType | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      createAction<FileNftType | undefined>(fileActionConsts.SET_FILE)(file)
    );

const resetFile = () => async (dispatch: AppDispatch) =>
  dispatch(createAction(fileActionConsts.RESET_FILE)());

export const fileActions = {
  setFile,
  resetFile,
};
