import { TransitionConst } from "library/consts";
import { CssPropsType } from "library/types";
import { css } from "styled-components";

export const FrostedBackgroundStyle = css`
  // using rgb values of theme.backgroundColor
  background-color: ${({ theme }) =>
    theme._isDark ? "rgba(28, 28, 40, 0.9)" : "rgba(250, 250, 253, 0.9)"};

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: saturate(200%) blur(24px);
    background-color: ${({ theme }) =>
      theme._isDark ? "rgba(28, 28, 40, 0.7)" : "rgba(250, 250, 253, 0.7)"};
  }
`;

// custom due to additional background overlay for ProjectCard Reward component
export const FrostedLightStyle = css`
  background-color: rgba(28, 28, 40, 0.9);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: saturate(150%) blur(24px);
    background-color: transparent;
  }
`;

export const BoxShadowStyle = css`
  box-shadow: ${({ theme }) =>
    theme._isDark
      ? "0 2px 6px 0 rgba(0, 0, 20, 0.5)"
      : "0 2px 6px 0 rgba(0, 0, 20, 0.1)"};
`;

export const BoxShadowSmallStyle = css`
  box-shadow: ${({ theme }) =>
    theme._isDark
      ? "0 1px 2px 0 rgba(0, 0, 0, 0.5)"
      : "0 1px 2px 0 rgba(0, 0, 0, 0.1)"};
`;

export const FrostedBoxShadowStyle = css`
  box-shadow: ${({ theme }) =>
    theme._isDark
      ? "0 2px 32px 12px rgba(255, 255, 255, 0.1)"
      : "0 2px 32px 12px rgba(0, 0, 20, 0.2)"};
`;

export const EaseGradient = (r = 255, g = 255, b = 255) => {
  const rgb = `${r}, ${g}, ${b}`;
  return `
    linear-gradient(
      to top,
      rgba(${rgb}, 1.000) 0.000%,
      rgba(${rgb}, 0.987) 8.100%,
      rgba(${rgb}, 0.951) 15.50%,
      rgba(${rgb}, 0.896) 22.50%,
      rgba(${rgb}, 0.825) 29.00%,
      rgba(${rgb}, 0.741) 35.30%,
      rgba(${rgb}, 0.648) 41.20%,
      rgba(${rgb}, 0.550) 47.10%,
      rgba(${rgb}, 0.450) 52.90%,
      rgba(${rgb}, 0.352) 58.80%,
      rgba(${rgb}, 0.259) 64.70%,
      rgba(${rgb}, 0.175) 71.00%,
      rgba(${rgb}, 0.104) 77.50%,
      rgba(${rgb}, 0.049) 84.50%,
      rgba(${rgb}, 0.013) 91.90%,
      rgba(${rgb}, 0.000) 100.0%
    );
  `;
};

export const PopupStyle = css<
  CssPropsType & {
    $active?: boolean;
    alignTop?: boolean;
    isOpen?: boolean;
    minWidth?: string;
  }
>`
  min-width: ${({ minWidth }) => minWidth};
  position: absolute;
  bottom: ${({ alignTop }) => alignTop && "calc(100% + 8px)"};
  margin-top: 8px;

  z-index: 10;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: ${TransitionConst};

  border-radius: 8px;
  overflow: hidden;

  background-color: ${({ $active, theme }) =>
    $active ? theme.buttonTextColor : theme.cardBackgroundColor};
  ${BoxShadowStyle}
`;
