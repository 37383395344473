import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  CategoryDescriptions,
  PageDescriptions,
  PageTitles,
  ProjectsTableCols,
  ProjectsTableHeadings,
  AppUrl,
  BasePaths,
  SortProjectOptions,
  SortProjectTypes,
} from "consts";
import {
  Card,
  CardGrid,
  DataTable,
  Div,
  Divider,
  Dropdown,
  Flex,
  InfiniteList,
  InfiniteLoader,
  LargeProjectCard,
  NoneCard,
  Page,
  ProjectCard,
  ReviewStatusOptions,
  SearchInput,
  Tab,
  TableButtons,
  TeamTypeOptions,
} from "library/components";
import {
  Category,
  ReviewStatus,
  StateFilter,
  TableStateEnum,
  TeamType,
} from "library/types";
import { CategoryTabs } from "views/components";
import { Bookmark, ChevronDown } from "library/assets";
import { ProjectsRowRender } from "./ProjectsRow";
import { useMember, useSaved } from "hooks";
import { SearchProjectsRes } from "redux/apiTypes";
import { searchApi } from "redux/apis";
import { PageSize } from "library/consts";
import { isCategory } from "library/utils";

export const Projects = () => {
  const navigate = useNavigate();
  const [tableState, setTableState] = useState<TableStateEnum>(
    TableStateEnum.GRID
  );

  const [search, setSearch] = useState("");
  const [category, setCategory] = useState<Category | string>(StateFilter.ALL);
  const [teamType, setTeamType] = useState<TeamType>(TeamType.BLUEPRINT);
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>(
    ReviewStatus.OPEN
  );
  const [sort, setSort] = useState(SortProjectTypes.NEWEST_FIRST);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<SearchProjectsRes>();
  const [isMounted, setIsMounted] = useState(false);
  const { saved } = useSaved();

  const { isSignedIn } = useMember();

  const { data, isLoading, isFetching } = searchApi.useSearchProjectsQuery({
    category: isCategory(category) ? category : undefined,
    teamType,
    reviewStatus,
    sort,
    search: search || undefined,
    skip: skip || undefined,
    isSaved: isSignedIn ? isSaved : false,
  });
  const isLoadingAll = isLoading || isFetching;

  // Infinite Loader
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    } else {
      setList(undefined);
      setSkip(0);
    }
  }, [search, category, teamType, reviewStatus, sort, isSaved, isMounted]);

  const title = isCategory(category) ? CategoryDescriptions[category] : "";

  return (
    <Page>
      <Helmet>
        <title>{`${title || "All"} - ${PageTitles.projects}`}</title>
        <meta name="description" content={PageDescriptions.projects} />
        <link rel="canonical" href={`${AppUrl}/${BasePaths.projects}`} />
      </Helmet>

      <InfiniteList
        data={data}
        isLoading={isFetching}
        list={list}
        setList={(d) => {
          setList(d);
        }}
      />

      <Flex gap="8px" margin="32px 0" alignCenter>
        <SearchInput
          flex="1"
          handleSearch={(v) => setSearch(v)}
          isLoading={isLoadingAll}
        />
      </Flex>

      <CategoryTabs
        category={category}
        handleCategory={(v) => setCategory(v)}
      />

      <Div
        display="block"
        alignItems="center"
        margin="8px 0"
        sm={{ display: "flex", margin: "24px 0" }}
      >
        <Div flex="1" margin="32px 0 16px" sm={{ margin: "0 24px 0 12px" }}>
          <Dropdown
            handleSelect={(v) => setTeamType(v)}
            selectValue={teamType}
            options={TeamTypeOptions}
            icon={
              <Div display="flex" marginLeft="20px">
                <ChevronDown size={32} />
              </Div>
            }
            optionsProps={{ minWidth: "280px" }}
            scrollOptionTop
          />
        </Div>

        <Div
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          margin="16px 0"
          sm={{ justifyContent: "flex-end", margin: "0" }}
        >
          <Dropdown
            handleSelect={(v) => setReviewStatus(v)}
            selectValue={reviewStatus}
            options={ReviewStatusOptions}
          />
          <Dropdown
            handleSelect={(v) => setSort(v)}
            selectValue={sort}
            options={SortProjectOptions}
          />
          {isSignedIn && (
            <Tab
              height="40px"
              sm={{ padding: "12px" }}
              onClick={() => {
                setList(undefined);
                setIsSaved(!isSaved);
              }}
            >
              <Bookmark fill={isSaved ? "currentColor" : "none"} />
            </Tab>
          )}
          <Divider margin="0 16px" />
          <TableButtons
            tableState={tableState}
            setTableState={setTableState}
            showBlockButton
          />
        </Div>
      </Div>

      {(tableState === TableStateEnum.BLOCK ||
        tableState === TableStateEnum.GRID) &&
        list?.length === 0 && <NoneCard large />}

      {tableState === TableStateEnum.BLOCK &&
        list?.map((d) => (
          <LargeProjectCard
            key={d.id}
            amount={d.amount || 0}
            appName={d.app.name}
            icon={d.app.icon}
            image={d.image || undefined}
            name={d.name}
            projectHandle={d.projectHandle}
            teamType={d.teamType}
            tokenSymbol={d.tokenSymbol || ""}
          />
        ))}

      {tableState === TableStateEnum.GRID && (
        <CardGrid>
          {list?.map((p) => (
            <ProjectCard
              key={p.id}
              {...p}
              icon={p.app.icon}
              appName={p.app.name}
              category={p.app.category}
              subtitle={p.app.subtitle}
              tags={p.tags.map((t) => t.name)}
              saved={saved?.savedProjects.some(
                (s) => s.projectHandle === p.projectHandle
              )}
            />
          ))}
        </CardGrid>
      )}

      {tableState === TableStateEnum.ROW && (
        <Card marginTop="32px">
          <DataTable
            cols={ProjectsTableCols}
            headings={ProjectsTableHeadings}
            data={list}
            rowData={saved?.savedProjects}
            rowRender={ProjectsRowRender}
            handleRowClick={(d) => {
              navigate(`/${BasePaths.p}/${d.projectHandle}`);
            }}
          />
        </Card>
      )}

      <InfiniteLoader
        show={isFetching || data?.length === PageSize}
        callback={() => {
          if (list && isLoadingAll && data?.length === PageSize) {
            const newSkip = list.length;
            if (skip !== newSkip) {
              setSkip(newSkip);
            }
          }
        }}
      />
    </Page>
  );
};
