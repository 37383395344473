import { SortFile } from "redux/apiTypes";

export const FileMetadata = {
  app: "AppFile",
  member: "MemberFile",
};

export type FileKind = "APP" | "MEMBER";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileKind: { [key in FileKind]: FileKind } = {
  APP: "APP",
  MEMBER: "MEMBER",
};
export const FileKindOptions = [
  { label: "All Files", value: "" },
  { label: "App Files", value: FileKind.APP },
  { label: "Member Files", value: FileKind.MEMBER },
];

export const SortFileTypes: { [key in SortFile]: SortFile } = {
  NEWEST_FIRST: "NEWEST_FIRST",
  OLDEST_FIRST: "OLDEST_FIRST",
  MOST_SAVED: "MOST_SAVED",
  LEAST_SAVED: "LEAST_SAVED",
};

export const SortFileOptions = [
  { label: "Newest First", value: SortFileTypes.NEWEST_FIRST },
  { label: "Oldest First", value: SortFileTypes.OLDEST_FIRST },
  { label: "Most Saved", value: SortFileTypes.MOST_SAVED },
  { label: "Least Saved", value: SortFileTypes.LEAST_SAVED },
];

export const FilesTableCols = [
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center", width: "120px" },
];

export const FilesTableHeadings = [
  "Creator",
  "File",
  "Team",
  "Category",
  "Tags",
  "Follow",
];

export const FileExtensions: Record<string, string | undefined> = {
  xml: "markup",
  html: "markup",
  js: "jsx",
  jsx: "jsx",
  ts: "tsx",
  tsx: "tsx",
  swift: "swift",
  kt: "kotlin",
  rs: "rust",
  graphql: "graphql",
  yaml: "yaml",
  go: "go",
  cpp: "cpp",
  md: "markdown",

  // imported
  py: "python",
  css: "css",
  java: "java",
};
