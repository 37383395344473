import { PACKAGE_ADDRESS } from "consts";

const instantiate = (accountAddress: string, memberHandle: string) => `
CALL_FUNCTION
  Address("${PACKAGE_ADDRESS}")
  "MemberContract"
  "instantiate_member"
  "${memberHandle}"
;
CALL_METHOD
  Address("${accountAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

const proof = (
  accountAddress: string,
  resourceAddress: string,
  memberHandle: string,
  isPop?: boolean
) => `
CALL_METHOD
  Address("${accountAddress}")
  "create_proof_of_non_fungibles"
  Address("${resourceAddress}")
  Array<NonFungibleLocalId>(
    NonFungibleLocalId("<${memberHandle}>"),
  )
;
${
  !isPop
    ? ""
    : `
POP_FROM_AUTH_ZONE
  Proof("proof1")
;
`
}
`;

const joinProject = (
  memberComponent: string,
  projectComponent: string,
  accountAddress: string
) => `
CALL_METHOD
  Address("${memberComponent}")
  "join_project"
  Address("${projectComponent}")
;
CALL_METHOD
  Address("${accountAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

const joinVesting = (
  memberComponent: string,
  vestingContract: string,
  accountAddress: string
) => `
CALL_METHOD
  Address("${memberComponent}")
  "join_vesting"
  Address("${vestingContract}")
;
CALL_METHOD
  Address("${accountAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

const joinAdmin = (memberComponent: string, appAddress: string) => `
CALL_METHOD
  Address("${memberComponent}")
  "join_admin"
  Address("${appAddress}")
;
`;

const createApp = (
  memberComponent: string,
  appName: string,
  appHandle: string
) => `
CALL_METHOD
  Address("${memberComponent}")
  "create_app"
  "${appName}"
  "${appHandle}"
;
`;

export const MemberManifest = {
  instantiate,
  proof,
  joinProject,
  joinVesting,
  joinAdmin,
  createApp,
};
