import { Colors, Gradients } from "library/consts";
import { TeamRole, TeamType } from "library/types";

export const TeamTypeDescriptions: { [key in TeamType]: string } = {
  BLUEPRINT: "Blueprint",
  AUDIT: "Audit",
  FRONTEND: "Frontend",
  BACKEND: "Backend",
  DEVOPS: "DevOps",
  DESIGN: "Design",
  PRODUCT: "Product",
  COMMUNITY: "Community",
  GROWTH: "Growth",
  LEGAL: "Legal",
  ADMIN: "Admin",
};

export const TeamTypeBackgrounds: { [key in TeamType]: string } = {
  BLUEPRINT: Colors.blueDark,
  AUDIT: Gradients.goldGradient,
  FRONTEND: Colors.navy,
  BACKEND: Colors.aqua,
  DEVOPS: Colors.teal,
  DESIGN: Colors.darkRed,
  PRODUCT: Colors.maroon,
  COMMUNITY: Colors.orange,
  GROWTH: Colors.purple,
  LEGAL: Colors.purpleDark,
  ADMIN: Colors.black,
};

export const TeamTypeSymbols: { [key in TeamType]: string } = {
  BLUEPRINT: "BP",
  AUDIT: "AU",
  FRONTEND: "FE",
  BACKEND: "BE",
  DEVOPS: "DO",
  DESIGN: "DS",
  PRODUCT: "PD",
  COMMUNITY: "CM",
  GROWTH: "GO",
  LEGAL: "LE",
  ADMIN: "AM",
};

export const TeamRoleDescriptions: { [key in TeamRole]: string } = {
  TEAM_LEAD: "Team Lead",
  MEMBER: "Member",
  CONTRIBUTOR: "Contributor",
};

export const TeamRoleOptions = Object.values<TeamRole>(TeamRole).map(
  (value) => ({ label: TeamRoleDescriptions[value], value })
);

export const ScryptoTypesArray: TeamType[] = [
  TeamType.BLUEPRINT,
  TeamType.AUDIT,
];

export const TeamHiddenOptions: TeamType[] = [
  TeamType.COMMUNITY,
  TeamType.PRODUCT,
  TeamType.LEGAL,
];
