import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Div } from "library/components";
import { LoadingDots, SearchIcon, XIcon } from "library/assets";
import { InputBase } from "./Input";
import { TransitionConst } from "library/consts";
import { useDebounce } from "library/hooks";

const SearchTextInput = styled(InputBase)`
  flex: 1;
  height: 100%;
  padding: 0 48px;
  border-radius: 20px;
`;

type SearchInputProps = {
  handleSearch?: (val: string) => void;
  inputProps?: object;
  isLoading?: boolean;
  isReset?: boolean;
  [prop: string]: any;
};

export const SearchInput = ({
  handleSearch,
  inputProps = {},
  isLoading,
  isReset,
  ...rest
}: SearchInputProps) => {
  const [search, setSearch] = useState("");
  const [debounced, setDebounced] = useDebounce(search);
  useEffect(() => {
    if (handleSearch && (debounced.length === 0 || debounced.length >= 3)) {
      handleSearch(debounced);
    }
  }, [debounced, handleSearch]);

  useEffect(() => {
    if (isReset) {
      setSearch("");
      setDebounced("");
    }
  }, [isReset, setDebounced]);

  return (
    <Div display="flex" height="40px" position="relative" {...rest}>
      <form
        style={{ flex: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <SearchTextInput
          placeholder="Search..."
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          {...inputProps}
        />

        <Div
          height="100%"
          width="48px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
        >
          <SearchIcon />
        </Div>

        <Div
          height="100%"
          width="48px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          right="0"
          visibility={!search ? "hidden" : undefined}
          opacity={!search ? "0" : "1"}
          transition={TransitionConst}
        >
          {isLoading ? (
            <LoadingDots />
          ) : (
            <Button
              display="flex"
              alignItems="center"
              onClick={() => {
                setSearch("");
                setDebounced("");
                if (handleSearch) {
                  handleSearch("");
                }
              }}
            >
              <XIcon />
            </Button>
          )}
        </Div>
      </form>
    </Div>
  );
};
