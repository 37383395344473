import { MutableRefObject, RefObject, useCallback, useEffect } from "react";

const useMouseTouch = (listener: (e: MouseEvent | TouchEvent) => void) => {
  useEffect(() => {
    const opts: AddEventListenerOptions & EventListenerOptions = {
      passive: true,
    };
    document.addEventListener("mousedown", listener, opts);
    document.addEventListener("touchstart", listener, opts);

    return () => {
      document.removeEventListener("mousedown", listener, opts);
      document.removeEventListener("touchstart", listener, opts);
    };
  }, [listener]);
};

export const useClickOutside = (
  ref: MutableRefObject<HTMLElement> | RefObject<HTMLElement>,
  callback: () => void
) => {
  const listener = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const target = e.target as HTMLElement;
      if (ref && ref.current && !ref.current.contains(target)) callback();
    },
    [callback, ref]
  );

  useMouseTouch(listener);
};

export const useMultiClickOutside = (
  refs: MutableRefObject<HTMLElement>[] | RefObject<HTMLElement>[],
  callback: () => void
) => {
  const listener = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const target = e.target as HTMLElement;
      const isInside = refs.some((r) => r.current?.contains(target));
      if (!isInside) callback();
    },
    [callback, refs]
  );

  useMouseTouch(listener);
};
