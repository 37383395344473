import styled from "styled-components";
import { CategoryOptions } from "consts";
import { Div, TabFilled } from "library/components";
import { StateFilter } from "library/types";

const CategoryTab = styled(TabFilled)`
  border-radius: 24px;
  padding: 0 16px;
`;

type CategoryTabsProps = {
  category: string;
  handleCategory: (c: string) => void;
};

export const CategoryTabs = ({
  category,
  handleCategory,
}: CategoryTabsProps) => (
  <Div overflowX="auto" padding="2px 2px 10px 2px">
    <Div height="40px" display="flex" gap="16px">
      {[{ label: "All", value: StateFilter.ALL }, ...CategoryOptions].map(
        ({ label, value }) => (
          <CategoryTab
            key={value}
            $active={value === category}
            onClick={() => value !== category && handleCategory(value)}
          >
            {label}
          </CategoryTab>
        )
      )}
    </Div>
  </Div>
);
