export const BasePaths = {
  a: "a",
  p: "p",
  o: "o",
  m: "m",
  d: "d",
  f: "f",
};

export const ProjectPaths = {
  objectives: "objectives",
};
