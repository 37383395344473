export const Blueprints = {
  AdminContract: "AdminContract",
  AppContract: "AppContract",
  MemberContract: "MemberContract",
  ProjectContract: "ProjectContract",
  TxManager: "TxManager",
  VestingContract: "VestingContract",
};

type IterateValue =
  | {
      kind: ValueKind.Map;
      field_name?: string;
      key_kind: ValueKind;
      key_type_name: string;
      value_kind: ValueKind;
      entries: {
        key: ConstValue;
        value: StateValue;
      }[];
      value?: undefined;
    }
  | {
      kind: ValueKind.Array;
      field_name?: string;
      element_kind: ValueKind;
      element_name: string;
      elements: StateValue[];
      value?: undefined;
    }
  | {
      kind: ValueKind.Tuple;
      field_name?: string;
      type_name: string;
      fields: StateValue[];
      value?: undefined;
    }
  | {
      kind: ValueKind.Enum;
      field_name?: string;
      type_name: string;
      variant_id: string;
      variant_name: string;
      fields: StateValue[];
      value?: undefined;
    };

type ConstValue =
  | {
      kind: ValueKind.Own;
      field_name?: string;
      type_name: "FungibleVault" | "NonFungibleVault";
      value: string;
    }
  | {
      kind: ValueKind.Reference;
      field_name?: string;
      type_name: "ComponentAddress" | "ResourceAddress";
      value: string;
    }
  | {
      kind: ValueKind.Bool;
      field_name?: string;
      value: boolean;
    }
  | {
      kind: ValueKind.I64;
      field_name?: string;
      value: string;
    }
  | {
      kind: ValueKind.String;
      field_name?: string;
      value: string;
    }
  | {
      kind: ValueKind.Decimal;
      field_name?: string;
      value: string;
    };

export type StateValue = ConstValue | IterateValue;

export enum ValueKind {
  Map = "Map",
  Array = "Array",
  Tuple = "Tuple",
  Enum = "Enum",

  Own = "Own",
  Reference = "Reference",
  Bool = "Bool",
  I64 = "I64",
  String = "String",
  Decimal = "Decimal",
}

export type FungibleData = {
  resourceAddress: string;
  amount: number;
  vaults: Record<string, number | undefined>;
  metadata: Record<string, string>;
};

export type NonFungibleData = {
  resourceAddress: string;
  total: number;
  metadata: Record<string, string>;
};
