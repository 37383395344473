import "react-markdown-editor-lite/lib/index.css";
import { createGlobalStyle, css } from "styled-components";

const DarkEditorStyle = css`
  .rc-md-editor {
    background: ${({ theme }) => theme.backgroundColorActive};
    border: 1px solid ${({ theme }) => theme.borderColor};
  }
  .rc-md-editor .rc-md-navigation {
    background-color: ${({ theme }) => theme.borderColor};
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
  .rc-md-editor .editor-container {
    height: 100%;
  }
  .rc-md-editor .editor-container .sec-md .input {
    background: ${({ theme }) => theme.backgroundColorActive};
    color: inherit;
  }
  .rc-md-editor .rc-md-navigation .button-wrap .button {
    color: ${({ theme }) => theme.textColor};
  }
  .rc-md-editor .rc-md-navigation .button-wrap .button:hover {
    color: ${({ theme }) => theme.textColorActive};
  }
  .rc-md-editor .rc-md-navigation .button-wrap .button.disabled {
    color: ${({ theme }) => theme.textColor};
    opacity: 0.3;
  }
  .rc-md-editor .drop-wrap {
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    border: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

export const MdEditorStyle = createGlobalStyle`
  ${({ theme }) => theme._isDark && DarkEditorStyle}
  .rc-md-editor {
    height: 600px;
    border-radius: 4px;
  }
  .rc-md-editor .editor-container .sec-md .input {
    font-size: 1rem; // 16px
  }
  .rc-md-editor .rc-md-navigation {
    flex-wrap: wrap;
  }
  .rc-md-editor .drop-wrap {
    padding: 0;
  }
  .rc-md-editor .table-list.wrap {
    margin: 10px;
  }
  .rc-md-editor .header-list .list-item {
    width: 72px;
  }
  .rc-md-editor .editor-container>.section {
    border-right: none;
  }
  .rc-md-editor .editor-container>.section.visible+.section {
    border-left: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

// --- MdRender Styles

const DarkRenderStyle = css`
  .custom-html-style {
    color: inherit;
  }
  .custom-html-style blockquote {
    background: none repeat scroll 0 0 #252535;
    border-left: 10px solid #d6dbdf;
    color: inherit;
  }
  .custom-html-style code,
  .custom-html-style pre {
    background-color: #252535;
  }
  .custom-html-style table th {
    color: #001320;
  }
`;

export const MdRenderStyle = createGlobalStyle`
  ${({ theme }) => theme._isDark && DarkRenderStyle}
  .custom-html-style p {
    font-size: 1.125rem; // 18px
  }
  .custom-html-style h1 {
    font-size: 2.125rem; // 34px
  }
  .custom-html-style h2 {
    font-size: 1.75rem; // 28px
  }
  .custom-html-style h3 {
    font-size: 1.375rem; // 22px
  }
  .custom-html-style ol,
  .custom-html-style ul {
    padding-left: 32px;
  }
  .custom-html-style .contains-task-list {
    list-style: none;
    margin: 0;
  }
  .custom-html-style code {
    padding: 3px 6px;
  }
  .custom-html-style pre code {
    padding: 3px 0;
  }
  .custom-html-style table {
    width: 100%;
  }
  .custom-html-style table th {
    padding: 10px 12px;
  }
  .custom-html-style img {
    width: 100%;
    overflow: hidden;
    border-radius: 24px;
  }
`;
