import { createAction } from "@reduxjs/toolkit";
import { TabStateEnum } from "library/types";
import { AppDispatch } from "redux/store";

export const tabActionConsts = {
  SET_TAB_STATE: "SET_TAB_STATE",
};

const setTabState = (tabState: TabStateEnum) => async (dispatch: AppDispatch) =>
  dispatch(createAction<TabStateEnum>(tabActionConsts.SET_TAB_STATE)(tabState));

export const tabActions = {
  setTabState,
};
