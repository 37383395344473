import { Div } from "../Common";
import { Tab } from "./Button";
import { BlockIcon, GridIcon, RowIcon } from "library/assets";
import { TableStateEnum } from "library/types";

type TableButtonProps = {
  setTableState: (val: TableStateEnum) => void;
  showBlockButton?: boolean;
  tableState: TableStateEnum;
};

export const TableButtons = ({
  setTableState,
  showBlockButton = false,
  tableState,
}: TableButtonProps) => (
  <Div
    height="40px"
    display="flex"
    alignItems="center"
    justifyContent="flex-end"
    sm={{ paddingRight: "8px" }}
  >
    {showBlockButton && (
      <Tab
        onClick={() => setTableState(TableStateEnum.BLOCK)}
        $active={tableState === TableStateEnum.BLOCK}
        padding="0 8px"
        sm={{ padding: "0 12px" }}
      >
        <BlockIcon />
      </Tab>
    )}
    <Tab
      onClick={() => setTableState(TableStateEnum.GRID)}
      $active={tableState === TableStateEnum.GRID}
      padding="0 8px"
      sm={{ padding: "0 12px" }}
    >
      <GridIcon />
    </Tab>
    <Tab
      onClick={() => setTableState(TableStateEnum.ROW)}
      $active={tableState === TableStateEnum.ROW}
      padding="0 8px"
      sm={{ padding: "0 12px" }}
    >
      <RowIcon />
    </Tab>
  </Div>
);
