import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  BasePaths,
  ObjectivesTableCols,
  ObjectivesTableHeadings,
  PageDescriptions,
  PageTitles,
} from "consts";
import {
  AppIconName,
  Card,
  CardGrid,
  DataTable,
  Div,
  Divider,
  Dropdown,
  ObjectiveCard,
  ObjectiveStateFilterOptions,
  Page,
  TeamTypeOptionsMedium,
  SearchInput,
  TableButtons,
  TX,
  T2,
  useModal,
  Flex,
  PrimaryButton,
  LoadingPage,
  NoneCard,
} from "library/components";
import { ObjectiveState, StateFilter, TableStateEnum } from "library/types";
import { ObjectiveCreateModal, ObjectiveEditModal } from "views/components";
import { ProjectObjectivesRowRender } from "./ProjectObjectivesRow";
import { PlusIcon } from "library/assets";
import { useProject } from "hooks";
import { projectApi } from "redux/apis";
import { ProjectObjectivesRes } from "redux/apiTypes";
import { isObjectiveState } from "library/utils";

export const ProjectObjectives = () => {
  const { projectHandle } = useParams();
  const navigate = useNavigate();
  const { show, showModal, hideModal } = useModal();

  const [tableState, setTableState] = useState<TableStateEnum>(
    TableStateEnum.GRID
  );
  const [objectiveState, setObjectiveState] = useState<ObjectiveState | string>(
    StateFilter.ALL
  );
  const [search, setSearch] = useState("");

  const {
    profile: p,
    members,
    teamMembers,
    error: errorP,
    isLoading: isLoadingP,
    isAMember,
  } = useProject();

  const { data, error, isLoading } = projectApi.useProjectGetObjectivesQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  if (
    !p ||
    p.isLocked ||
    !members ||
    !teamMembers ||
    errorP ||
    isLoadingP ||
    error ||
    isLoading ||
    !data
  ) {
    return (
      <LoadingPage
        error={error || errorP}
        isLoading={isLoading || isLoadingP}
        isLocked={p?.isLocked}
      />
    );
  }

  const memberTypes = members.map((m) => m.member);

  const objs = search
    ? data.filter((d) => d.name.toLowerCase().includes(search.toLowerCase()))
    : data;
  const ongoingInit = {} as Record<ObjectiveState, ProjectObjectivesRes>;
  const ongoing = objs.reduce((acc: typeof ongoingInit, curr) => {
    if (curr.isCancelled) {
      return acc;
    }
    if (!acc[curr.state]) {
      acc[curr.state] = [curr];
    } else {
      acc[curr.state].push(curr);
    }
    return acc;
  }, ongoingInit);
  const cancelled = objs.filter((o) => o.isCancelled);

  const currObjs: Record<ObjectiveState | string, ProjectObjectivesRes> =
    objectiveState === StateFilter.CANCELLED
      ? { [StateFilter.CANCELLED]: cancelled }
      : isObjectiveState(objectiveState)
      ? { [objectiveState]: ongoing[objectiveState] }
      : { ...ongoing, [StateFilter.CANCELLED]: cancelled };

  return (
    <Page>
      <Helmet>
        <title>
          {p.name} - {PageTitles.projectObjectives}
        </title>
        <meta
          name="description"
          content={`${p.name} - ${PageDescriptions.projectObjectives}`}
        />
      </Helmet>

      <Flex height="40px" margin="32px 0" gap="24px" alignCenter>
        <SearchInput handleSearch={(v) => setSearch(v)} flex="1" />
        {isAMember && (
          <PrimaryButton
            height="100%"
            sm={{ padding: "0 12px" }}
            onClick={showModal}
          >
            <PlusIcon />
          </PrimaryButton>
        )}
      </Flex>

      <Div
        display="block"
        alignItems="center"
        margin="8px 0"
        sm={{ display: "flex", margin: "24px 0" }}
      >
        <Div flex="1" margin="32px 0 16px" sm={{ margin: "0 24px 0 12px" }}>
          <Div display="flex" alignItems="center">
            <AppIconName icon={p.app.icon} name={p.app.name} />
            <Div marginRight="24px">
              {TeamTypeOptionsMedium.find((o) => o.value === p.teamType)?.label}
            </Div>
            <TX ellipsis medium>
              {p.name}
            </TX>
          </Div>
        </Div>

        <Div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          margin="16px 0"
          sm={{ justifyContent: "flex-end", margin: "0" }}
        >
          <Dropdown
            handleSelect={(v) => setObjectiveState(v)}
            selectValue={objectiveState}
            options={ObjectiveStateFilterOptions}
          />
          <Divider margin="0 16px" />
          <TableButtons tableState={tableState} setTableState={setTableState} />
        </Div>
      </Div>

      {tableState === TableStateEnum.GRID && (!objs || objs.length === 0) && (
        <NoneCard large />
      )}

      {tableState === TableStateEnum.GRID &&
        ObjectiveStateFilterOptions.filter((o) => o.value).map(
          (option) =>
            currObjs[option.value]?.length > 0 && (
              <Fragment key={option.value}>
                <T2
                  marginBottom="16px"
                  marginTop="64px"
                  marginLeft="12px"
                  medium
                >
                  {option.label}
                </T2>
                <CardGrid>
                  {currObjs[option.value].map((o) => (
                    <ObjectiveCard
                      key={o.id}
                      objectiveHandle={o.number}
                      projectHandle={p.projectHandle}
                      members={o.owners.map(
                        (m) =>
                          members.find((mem) => m.memberId === mem.memberId)
                            ?.member || {
                            id: "unknown",
                            name: "Unknown",
                            memberHandle: "unknown",
                          }
                      )}
                      name={o.name}
                      number={o.number}
                      project={p.name}
                      tasksCount={o.tasks.reduce(
                        (acc, curr) => (curr.isCompleted ? acc + 1 : acc),
                        0
                      )}
                      tasksTotal={o.tasks.length}
                    />
                  ))}
                </CardGrid>
              </Fragment>
            )
        )}

      {tableState === TableStateEnum.ROW && (
        <Card marginTop="32px">
          <DataTable
            cols={ObjectivesTableCols}
            headings={ObjectivesTableHeadings}
            data={objectiveState ? currObjs[objectiveState] : objs}
            rowData={{ members: memberTypes, tokenSymbol: p.tokenSymbol }}
            rowRender={ProjectObjectivesRowRender}
            handleRowClick={(d: ProjectObjectivesRes[number]) => {
              navigate(
                `/${BasePaths.p}/${projectHandle}/${BasePaths.o}/${d.number}`
              );
            }}
          />
        </Card>
      )}

      <ObjectiveEditModal
        members={memberTypes}
        teamType={p.teamType}
        projectName={p.name}
        readOnly={!isAMember}
      />

      <ObjectiveCreateModal
        show={show}
        hideModal={hideModal}
        members={memberTypes}
        objectiveNumber={data.length + 1}
        teamType={p.teamType}
        projectHandle={p.projectHandle}
      />
    </Page>
  );
};
