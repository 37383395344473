import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TeamType } from "library/types";
import { getEntityDetails, parseAppContract } from "radix";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { appApi, authApi } from "redux/apis";
import { useContract, useJwt } from "redux/hooks";
import { getTeamMembers } from "utils";

export const useApp = (isGetContract?: boolean) => {
  const { appHandle } = useParams();
  const { state: contractState, actions: contractActions } = useContract();
  const { appContract } = contractState;
  const { resetContract, setAppContract } = contractActions;

  const { state } = useJwt();
  const { isSignedIn } = state;
  const {
    data: auth,
    error: authError,
    isLoading: authLoading,
  } = authApi.useAuthGetUserQuery(undefined, {
    skip: !isSignedIn,
  });

  const {
    data: profile,
    error: profileError,
    isLoading: profileLoading,
  } = appApi.useAppGetProfileQuery(appHandle ? { appHandle } : skipToken);

  const {
    data: teams,
    error: teamsError,
    isLoading: teamsLoading,
  } = appApi.useAppGetTeamsQuery(appHandle ? { appHandle } : skipToken);

  const isAdmin =
    auth && teams
      ? teams?.some(
          (r) => r.memberId === auth.id && r.teamType === TeamType.ADMIN
        )
      : false;

  const isAppMember =
    auth && teams ? teams?.some((r) => r.memberId === auth.id) : false;

  // Get unique app members from team roles
  const teamMembers = getTeamMembers(teams);

  const contract =
    appContract && profile && appContract.address === profile.componentAddress
      ? appContract
      : undefined;

  useEffect(() => {
    const getContract = async () => {
      if (
        profile?.componentAddress &&
        (!contract || contract.IS_RESET) &&
        isGetContract
      ) {
        const res = await getEntityDetails([profile.componentAddress]);
        const com = res?.find((v) => v);
        if (!com?.details) return;
        const contract = parseAppContract(com.details, com.address);
        if (contract) setAppContract({ ...contract, nfts: com.nonfungibles });
      }
    };
    getContract();
  }, [contract, isGetContract, profile?.componentAddress, setAppContract]);

  return {
    auth,
    contract,
    resetContract: async () => {
      resetContract("app");
    },
    profile,
    teams,
    teamMembers,
    error: authError || profileError || teamsError,
    isAdmin,
    isAppMember,
    isLoading: authLoading || teamsLoading || profileLoading,
    isSignedIn,
  };
};
