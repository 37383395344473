export const ContractStageTypes = {
  DEPOSIT_FUNDS: "DEPOSIT_FUNDS",
  INVITE_MEMBERS: "INVITE_MEMBERS",
  ADD_REWARDS: "ADD_REWARDS",
  APPROVE_OBJECTIVES: "APPROVE_OBJECTIVES",
  COMPLETED: "COMPLETED",
};

export const ContractStageDescriptions = {
  DEPOSIT_FUNDS: "🏛️ \xa0 Deposit Funds",
  INVITE_MEMBERS: "📥 \xa0 Invite Members",
  ADD_REWARDS: "💎 \xa0 Add Rewards",
  APPROVE_OBJECTIVES: "🎯 \xa0 Approve Objectives",
  COMPLETED: "✅ \xa0 Completed",
};

export const ContractStageOptions = Object.entries(
  ContractStageDescriptions
).map(([value, label]) => ({
  value,
  label,
}));

export const Nfts = {
  ProjectTx: "ProjectTx",
  VestingTx: "VestingTx",
};
