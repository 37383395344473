import { ExternalLinkIcon } from "library/assets";
import { A, Flex, TxTypeDescriptions } from "library/components";
import { TokenSymbol } from "library/types";
import { formatDateLong, formatNumber } from "library/utils";
import { getExplorerUrl } from "utils";

export const ProjectVestingTxRowRender = (
  d: Record<string, string | undefined>
) => [
  d.id,
  d.app_handle,
  d.contract_handle?.split("-").find((v) => Number(v)) || "--",
  d.from_handle === d.contract_handle ? "📜 \xa0 Contract" : d.from_handle,
  d.to_handle === d.contract_handle ? "📜 \xa0 Contract" : d.to_handle,
  d.tx_type ? TxTypeDescriptions[d.tx_type] : "--",
  `${d.amount ? formatNumber(d.amount) : "--"} ${
    d.symbol ? d.symbol : TokenSymbol.XRD
  }`,
  d.epoch ? formatDateLong(Number(d.epoch) * 1000) : "--",
  <Flex center>
    <A
      padding="4px"
      href={
        d.contract_address
          ? `${getExplorerUrl(d.contract_address)}/recent-transactions`
          : "/"
      }
      target="_blank"
      $useOpacity
    >
      <ExternalLinkIcon size={16} />
    </A>
  </Flex>,
];
