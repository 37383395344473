import { Colors, TeamTypeBackgrounds, TeamTypeSymbols } from "library/consts";
import { TeamType } from "library/types";
import { T2 } from "../Text";

type TeamSymbolProps = {
  type: TeamType;
  fontSize?: string;
};

export const TeamSymbol = ({ type, ...rest }: TeamSymbolProps) => (
  <T2
    color={Colors.white}
    background={TeamTypeBackgrounds[type]}
    padding="1px 4px"
    borderRadius="4px"
    textShadow={`0 1px 2px ${Colors.grayBlack}`}
    bold
    {...rest}
  >
    {TeamTypeSymbols[type]}
  </T2>
);
