// https://stackoverflow.com/a/67243723
// https://stackoverflow.com/a/70226943

// export const toKebabCase = (str: string) =>
//   str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

// https://stackoverflow.com/a/51564734/15224708
// export const hex2rgba = (hex: string, alpha = 1) => {
//   const rgb = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16));
//   const [r, g, b] = rgb || [];
//   return `rgba(${r},${g},${b},${alpha})`;
// };

// https://reacthustle.com/blog/javascript-remove-null-or-undefined-from-an-object
export const removeEmpty = (data: object): object => {
  //transform properties into key-values pairs and filter all the empty-values
  const entries = Object.entries(data).filter(([, value]) => value);

  //map through all the remaining properties and check if the value is an object.
  //if value is object, use recursion to remove empty properties
  const clean = entries.map(([key, v]) => {
    const value = Array.isArray(v)
      ? v
      : typeof v === "object"
      ? removeEmpty(v)
      : v;
    return [key, value];
  });

  //transform the key-value pairs back to an object.
  return Object.fromEntries(clean);
};
