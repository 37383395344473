import { MemberInviteType, TeamRole, TeamType } from "library/types";
import { appApi, inviteApi } from "redux/apis";
import { awaitTo, parseRtkError } from "library/utils";
import { InviteModal } from "./InviteModal";
import { AppContract, appInviteCall, useRadixCall } from "radix";
import { AuthUserRes } from "redux/apiTypes";

type InviteAppModalProps = {
  appAddress: string;
  appHandle: string;
  auth: AuthUserRes;
  contract: AppContract | undefined;
  members: MemberInviteType[];
  resetContract: () => Promise<void>;
  teamType: TeamType;
  hideModal: () => void;
  show: boolean;
};

export const InviteAppModal = ({
  appAddress,
  appHandle,
  auth,
  contract,
  members,
  resetContract,
  teamType,
  hideModal,
  show,
}: InviteAppModalProps) => {
  const [
    handleCreate,
    { data: inviteErrors, isLoading: isLoadingInvites, error },
  ] = inviteApi.useInvitesAppCreateMutation();
  const { refetch: refetchInvites } = inviteApi.useInvitesAppGetQuery({
    appHandle,
  });
  const {
    refetch,
    isLoading: isLoadingTeams,
    isFetching: isFetchingTeams,
  } = appApi.useAppGetTeamsQuery({
    appHandle,
  });
  const [contractCall, { error: contractError, isLoading: isLoadingContract }] =
    useRadixCall(appInviteCall);

  return (
    <InviteModal
      handleSubmit={async (selected, teamType) => {
        if (!contract) {
          return;
        }
        // find new admins not already added or not invited
        const newAdmins = selected.filter((v) => {
          const componentAddress = v.componentAddress || "";
          const isLead = v.teamRole === TeamRole.TEAM_LEAD;
          const isNotAdded =
            !contract.admin_badges[componentAddress] &&
            !contract.admin_invites[componentAddress];
          if (teamType === TeamType.ADMIN) {
            if (isLead) {
              return !contract.superusers.includes(componentAddress);
            } else {
              return !contract.app_admins.includes(componentAddress);
            }
          } else {
            return isLead && isNotAdded;
          }
        });
        if (newAdmins.length) {
          const txDetails = await contractCall(
            auth,
            appAddress,
            newAdmins,
            teamType
          );
          if (!txDetails) {
            return;
          } else {
            resetContract();
          }
        }

        const [err, data] = await awaitTo(
          handleCreate({
            appHandle,
            invitesAppReq: {
              teamType,
              invites: selected.map((s) => ({
                memberId: s.id,
                teamRole: s.teamRole,
              })),
            },
          }).unwrap()
        );
        if (!err && data) {
          setTimeout(() => {
            refetch();
          }, 1000);
          if (data.errors.length === 0) {
            hideModal();
            // Unknown cause of delay between invite creation and invite in response array
            // 500ms: [], 600ms: either or, 1500ms: [{...}] guaranteed
            setTimeout(() => {
              refetchInvites();
            }, 1500);
          }
          return data;
        }
        return undefined;
      }}
      hideModal={hideModal}
      inviteErrors={inviteErrors}
      isLoading={
        isLoadingInvites ||
        isLoadingTeams ||
        isFetchingTeams ||
        isLoadingContract
      }
      members={members}
      serverError={parseRtkError(error) || contractError}
      show={show}
      teamType={teamType}
    />
  );
};
