import { useCallback, useEffect, useState } from "react";
import {
  FinancialPageTypes,
  Nfts,
  ProjectTransactionsTableCols,
  ProjectTransactionsTableHeadings as PHeadings,
  VestingTransactionsTableHeadings as VHeadings,
} from "consts";
import { BaseButton, Card, DataTable, Flex } from "library/components";
import { ProjectVestingTxRowRender } from "./ProjectVestingTxRow";
import { getNftData, parseTxNftData } from "radix";
import { NonFungibleData } from "radix/radix-types";

type TxItemType = Record<string, string | undefined>;
export type TxDataType = Record<string, TxItemType[] | undefined>;

type UserContract = {
  address: string;
  nfts: NonFungibleData[];
};

export const ProjectVestingTxs = ({
  contract,
  page,
  year,
  ptxs,
  vtxs,
  setPtxs,
  setVtxs,
}: {
  contract?: UserContract;
  page: string;
  year: string;
  ptxs: TxDataType;
  vtxs: TxDataType;
  setPtxs: (v: TxDataType) => Promise<void>;
  setVtxs: (v: TxDataType) => Promise<void>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isProjects = page === FinancialPageTypes.PROJECTS;
  const idx = contract ? `${contract.address}-${year}` : "";
  const txs = isProjects ? ptxs[idx] : vtxs[idx];
  const kind = isProjects
    ? `${Nfts.ProjectTx}-${year}`
    : `${Nfts.VestingTx}-${year}`;
  const nft = contract?.nfts.find((v) => v.metadata?.["name"] === kind);

  const handleTxs = useCallback(
    async (s?: number) => {
      setIsLoading(true);
      const setTxs = isProjects ? setPtxs : setVtxs;
      const allTxs = isProjects ? ptxs : vtxs;
      if (!nft || nft.total <= 0) {
        return setTxs({ ...allTxs, [idx]: [] });
      }
      const start = s || nft.total;
      const end = Math.max(start - 30, 0);
      const data = await getNftData(nft.resourceAddress, start, end);
      const parsed = parseTxNftData(data);
      if (parsed) {
        if (s && txs) {
          setTxs({ ...allTxs, [idx]: [...txs, ...parsed] });
        } else {
          setTxs({ ...allTxs, [idx]: parsed });
        }
      }
      setIsLoading(false);
    },
    [idx, isProjects, nft, ptxs, setPtxs, setVtxs, txs, vtxs]
  );

  useEffect(() => {
    if (contract && !txs) {
      handleTxs();
    }
  }, [contract, handleTxs, txs]);

  return (
    <>
      <Card borderRadius="12px">
        <DataTable
          cols={ProjectTransactionsTableCols}
          headings={isProjects ? PHeadings : VHeadings}
          data={txs}
          rowRender={ProjectVestingTxRowRender}
        />
      </Card>

      {txs && nft && txs.length < nft.total && (
        <Flex margin="32px 0" center>
          <BaseButton
            type="button"
            isLoading={isLoading}
            onClick={() => {
              const lastTx = txs ? txs[txs.length - 1] : undefined;
              const start = lastTx ? Number(lastTx.id) - 1 : undefined;
              handleTxs(start);
            }}
          >
            Load More
          </BaseButton>
        </Flex>
      )}
    </>
  );
};
