import { ReactNode, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Div, Flex } from "../Common";
import { useClickOutside } from "../../hooks";
// import { Button } from "../Buttons/Button";
// import { XIcon } from "../../assets";
import { TransitionConst } from "../../consts";
import { BoxShadowStyle } from "../../styles";

type ShowProps = {
  show?: boolean;
};

const showStyles = css<ShowProps>`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  transition: ${TransitionConst};
`;

const ModalBackground = styled(Div)<ShowProps>`
  opacity: ${({ show }) => (show ? "0.8" : "0")};
  background-color: ${({ theme }) => theme?.backgroundColor};
  ${showStyles}
`;

const ModalContainer = styled(Flex)<ShowProps>`
  opacity: ${({ show }) => (show ? "1" : "0")};
  ${showStyles}
`;

const ModalCard = styled(Div)`
  background-color: ${({ theme }) => theme?.cardBackgroundColor};
  ${BoxShadowStyle}
`;

export const useModal = () => {
  const [show, setShow] = useState(false);

  const setModal = (val: boolean) => {
    if (val) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
    setShow(val);
  };

  useEffect(() => {
    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  return {
    show,
    showModal: () => setModal(true),
    hideModal: () => setModal(false),
  };
};

type ModalPropsType = {
  children: ReactNode;
  closeModal: () => void;
  noHeight?: boolean;
  large?: boolean;
  show: boolean;
  showOverflow?: boolean;
  // useX?: boolean;
  isLocked?: boolean;
};

export const Modal = ({
  children,
  closeModal,
  noHeight,
  large,
  // useX = false,
  show,
  showOverflow,
  isLocked = false,
}: ModalPropsType) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useClickOutside(modalRef, () => {
    if (isLocked) {
      return;
    } else if (show) {
      closeModal();
    }
  });

  const overflowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (overflowRef.current && show) {
      overflowRef.current.scrollTop = 0;
    }
  }, [show]);

  return (
    <>
      <ModalBackground
        show={show}
        position="fixed"
        top="0"
        left="0"
        zIndex="99"
        fullHeight
        fullWidth
      />

      <ModalContainer
        show={show}
        position="fixed"
        top="0"
        left="0"
        zIndex="100"
        center
        fullHeight
        fullWidth
      >
        <ModalCard
          ref={modalRef}
          height={noHeight ? undefined : large ? "80%" : "70%"}
          width="calc(100% - 32px)"
          borderRadius="24px"
          overflow={showOverflow ? undefined : "hidden"}
          sm={{ width: large ? "min(calc(100% - 32px), 1000px)" : "500px" }}
        >
          <Div
            ref={overflowRef}
            overflow={showOverflow ? undefined : "auto"}
            position="relative"
            padding="16px"
            sm={{ padding: large ? "40px" : "24px" }}
            fullHeight
            fullWidth
          >
            {/* {useX && (
              <Button
                position="absolute"
                top="0"
                right="0"
                padding="20px"
                onClick={closeModal}
              >
                <XIcon size={20} />
              </Button>
            )} */}
            {show && children}
          </Div>
        </ModalCard>
      </ModalContainer>
    </>
  );
};
