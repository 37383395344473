import {
  ContractRefreshApiRes,
  ContractRefreshApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    contractRefresh: build.mutation<
      ContractRefreshApiRes,
      ContractRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/contract/refresh/${queryArg.projectHandle}/${queryArg.rewards}`,
        method: "PATCH",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as contractApi };
