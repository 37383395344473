import { FileManifest, MemberManifest } from "radix/manifests";
import { parseMemberContract } from "radix/parsers";
import {
  getWalletAccount,
  pollEntityDetails,
  pollTxStatus,
} from "radix/radix-util";
import { AuthUserRes } from "redux/apiTypes";
import { FileReqType } from "schema";

export const createMemberContract = async (memberHandle: string) => {
  const accountAddress = await getWalletAccount();
  if (!accountAddress) {
    return;
  }
  const components = await pollEntityDetails(
    MemberManifest.instantiate(accountAddress, memberHandle)
  );
  if (components) {
    const c = components.find((v) => v);
    if (c && c.details) {
      const contract = parseMemberContract(c.details, c.address);
      if (contract) {
        return {
          componentAddress: contract.address,
        };
      }
    }
  }
};

export const createMemberFile = async (auth: AuthUserRes, f: FileReqType) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle, componentAddress } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      FileManifest.createFile(componentAddress, f)
  );
  return txDetails;
};

export const updateMemberFile = async (
  auth: AuthUserRes,
  f: FileReqType,
  n: number
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle, componentAddress } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      FileManifest.updateFile(componentAddress, f, n)
  );
  return txDetails;
};
