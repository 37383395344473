import { FEE_VAL } from "consts";
import { VestingReqType } from "schema";

const createProject = (
  adminComponent: string,
  fundsResource: string,
  accountAddress: string
) => `
CALL_METHOD
  Address("${adminComponent}")
  "create_project"
  Address("${fundsResource}")
;
CALL_METHOD
  Address("${accountAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

const getSecs = (s: string) => new Date(s).getTime() / 1000;

const createVesting = (
  adminComponent: string,
  memberComponent: string,
  vesting: VestingReqType,
  withdrawAddress: string,
  depositAddress: string
) => {
  const {
    resourceAddress,
    amount,
    startDate,
    cliffDate,
    endDate,
    vestInterval,
  } = vesting;
  const fee = amount * FEE_VAL;

  return `
CALL_METHOD
  Address("${withdrawAddress}")
  "withdraw"
  Address("${resourceAddress}")
  Decimal("${amount + fee}")
;
TAKE_FROM_WORKTOP
  Address("${resourceAddress}")
  Decimal("${amount}")
  Bucket("bucket1")
;
TAKE_FROM_WORKTOP
  Address("${resourceAddress}")
  Decimal("${fee}")
  Bucket("bucket2")
;
CALL_METHOD
  Address("${adminComponent}")
  "create_vesting"
  Address("${memberComponent}")
  Bucket("bucket1")
  Bucket("bucket2")
  ${getSecs(startDate)}i64
  ${cliffDate ? `Some(${getSecs(cliffDate)}i64)` : `None`}
  ${getSecs(endDate)}i64
  ${vestInterval}i64
;
CALL_METHOD
  Address("${depositAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;
};

export const AdminManifest = {
  createProject,
  createVesting,
};
