import { useCallback, useEffect } from "react";
import { TabStateEnum } from "library/types";
import { useTab } from "redux/hooks";

export const useHideTab = () => {
  const { state, actions } = useTab();
  const { tabState } = state;
  const { setTabState } = actions;

  const hideBar = useCallback(
    (e: FocusEvent) => {
      const target = e.target as HTMLInputElement;

      if (
        target?.matches("input") ||
        target.matches("textarea") ||
        target.matches("select")
      ) {
        if (tabState === TabStateEnum.VISIBLE) setTabState(TabStateEnum.HIDDEN);
      }
    },
    [tabState, setTabState]
  );

  const showBar = useCallback(
    (e: FocusEvent) => {
      const target = e.target as HTMLInputElement;

      if (
        target.matches("input") ||
        target.matches("textarea") ||
        target.matches("select")
      ) {
        if (tabState === TabStateEnum.HIDDEN) setTabState(TabStateEnum.VISIBLE);
      }
    },
    [tabState, setTabState]
  );

  useEffect(() => {
    // https://github.com/microsoft/TypeScript/issues/32912#issuecomment-522142969
    const opts: AddEventListenerOptions & EventListenerOptions = {
      passive: true,
    };
    document.addEventListener("focusin", hideBar, opts);
    document.addEventListener("focusout", showBar, opts);

    return () => {
      document.removeEventListener("focusin", hideBar, opts);
      document.removeEventListener("focusout", showBar, opts);
    };
  }, [hideBar, showBar]);
};
