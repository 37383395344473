import Editor, { Plugins } from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import { Colors } from "library/consts";
import { MdEditorStyle, MdRenderStyle } from "./MdEditorStyle";
import { Div } from "../Common";
import { T2 } from "../Text";

// https://github.com/HarryChen0506/react-markdown-editor-lite

Editor.unuse(Plugins.BlockQuote);
Editor.unuse(Plugins.Clear);
Editor.unuse(Plugins.FontUnderline);

type MdEditorProps = {
  formError?: string;
  value?: string;
  setValue: (val: string) => void;
  handleBlur?: () => void;
  style?: object;
  wrapperStyle?: object;
};

export const MdEditor = ({
  formError,
  value,
  setValue,
  handleBlur,
  style,
  wrapperStyle,
}: MdEditorProps) => (
  <Div {...wrapperStyle}>
    <MdEditorStyle />
    <MdRenderStyle />

    <Editor
      value={value}
      onChange={({ text }) => setValue(text)}
      onBlur={handleBlur ? handleBlur : undefined}
      view={{ menu: true, md: true, html: false }}
      renderHTML={(text) => (
        <ReactMarkdown
          children={text}
          remarkPlugins={[remarkBreaks, remarkGfm]}
        />
      )}
      style={style}
    />

    {formError && (
      <T2 color={Colors.red} margin="8px 0">
        {formError}
      </T2>
    )}
  </Div>
);

export const MdRender = ({ text }: { text: string }) => (
  <>
    <MdRenderStyle />
    <Div className="custom-html-style">
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkBreaks, remarkGfm]}
      />
    </Div>
  </>
);
