import { z } from "zod";

export const BooleanSchema = z.boolean({
  required_error: "Is required",
  invalid_type_error: "Must be a boolean",
});

export const DescriptionSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .max(3000, { message: "Maximum is 3000 characters" });

export const NameSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .min(1, "Minimum is 1 character")
  .max(30, { message: "Maximum is 30 characters" });

export const LongNameSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .min(1, "Minimum is 1 character")
  .max(60, { message: "Maximum is 60 characters" });

export const NumberSchema = z
  .number({
    required_error: "Number is required",
    invalid_type_error: "Must be a number",
  })
  .finite()
  .gte(0)
  .lte(100000000000);

export const StringSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .max(160, { message: "Maximum is 160 characters" });

export const UuidSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a uuid",
  })
  .uuid();

export const UrlSchema = z
  .string({
    required_error: "Url is required",
    invalid_type_error: "Url must be a string",
  })
  .trim()
  .url({ message: "Invalid url" })
  .max(160, { message: "Url maximum is 160 characters" });

export const HandleSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .min(3, "Minimum is 3 characters")
  .max(39, "Maximum is 39 characters")
  .regex(
    new RegExp(/^[a-zA-Z\d](?:[a-zA-Z\d]|-(?=[a-zA-Z\d])){0,38}$/i),
    "May only contain alphanumeric characters or hyphens"
  );

export const SymbolSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .min(1, { message: "Minimum length of 1 characters" })
  .max(12, { message: "Maximum length of 12 characters" })
  .regex(new RegExp(/^[A-Z]*$/));

export const TagSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .min(3, { message: "Minimum length of 3 characters" })
  .max(24, { message: "Maximum is 24 characters" })
  .regex(
    new RegExp(/^[a-z]+(?: [a-z]+)*$/),
    "May only contain lowercase alphanumeric characters or spaces"
  );

export const AlphaNumericSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .max(24, { message: "Maximum is 24 characters" })
  .regex(
    new RegExp(/^[a-zA-Z0-9]*$/),
    "May only contain alphanumeric characters"
  );

export const DateTimeSchema = z.string().datetime();

//
//
// Common Schemas
export const VideoIdSchema = z
  .string({
    required_error: "Is required",
    invalid_type_error: "Must be a string",
  })
  .trim()
  .length(11, { message: "Ids have 11 characters" });

export const DefaultProfileSchema = z.object({
  videoIds: z.array(VideoIdSchema).max(3),
  images: z.array(UrlSchema).max(7),
  description: DescriptionSchema,
});

export const DefaultProfileReqSchema = z.object({
  videoIds: z.array(VideoIdSchema).max(3).optional(),
  images: z.array(UrlSchema).min(1).max(7),
  description: DescriptionSchema.min(30),
});

export const SocialsSchema = z.object({
  gitHub: StringSchema,
  linkedIn: StringSchema,
  figma: StringSchema,
  twitter: StringSchema,
  reddit: StringSchema,
  discord: StringSchema,
  telegram: StringSchema,
  youtube: StringSchema,
});

//
//
// Enums

export const ReportTypeEnumZod = z.enum([
  "ADVERTISEMENT_SPAM",
  "COPYRIGHT_TRADEMARK_PRIVATE",
  "IDENTITY_IMPERSONATION",
  "THREAT_FROM_CONTENT",
  "ABUSIVE_INAPPROPRIATE_CONTENT",
  "HARMFUL_CODE",
  "OTHER",
]);

export const TeamTypeEnumZod = z.enum(
  [
    "BLUEPRINT",
    "AUDIT",
    "FRONTEND",
    "BACKEND",
    "DEVOPS",
    "DESIGN",
    "PRODUCT",
    "COMMUNITY",
    "GROWTH",
    "LEGAL",
    "ADMIN",
  ],
  { errorMap: () => ({ message: "Required" }) }
);

export const TeamRoleEnumZod = z.enum(["TEAM_LEAD", "MEMBER", "CONTRIBUTOR"]);

export const ProjectStateEnumZod = z.enum([
  "DRAFT",
  "READY_FOR_DEVELOPMENT",
  "IN_PROGRESS",
  "COMPLETED",
]);

export const ReviewStatusEnumZod = z.enum(["OPEN", "UNDER_REVIEW", "CLOSED"]);

export const ObjectiveStateEnumZod = z.enum([
  "DRAFT",
  "READY_FOR_DEVELOPMENT",
  "IN_PROGRESS",
  "REVIEW",
  "DEPLOYMENT",
  "QA",
  "COMPLETED",
]);

export const ProposalStatusEnumZod = z.enum(["ACCEPTED", "CLOSED", "PENDING"]);

export const TokenSymbolEnumZod = z.enum(["XRD", "USDC"]);

export const CategoryEnumZod = z.enum(
  [
    "DEFI",
    "EXCHANGE",
    "FUND",
    "GAMEFI",
    "GOVERNANCE",
    "IDENTITY",
    "INSURANCE",
    "LENDING",
    "MARKETPLACE",
    "NFT",
    "ORACLE",
    "SOCIAL",
    "TOOL",
    "OTHER",
  ],
  { errorMap: () => ({ message: "Required" }) }
);

export const StageEnumZod = z.enum([
  "IDEA",
  "DESIGN",
  "BUILD",
  "VALIDATE",
  "GROWTH",
]);
