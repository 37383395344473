import { Helmet } from "react-helmet-async";
import { BasePaths, PageDescriptions } from "consts";
import { useFile } from "hooks";
import {
  A,
  Div,
  Dropdown,
  Flex,
  Link,
  LoadingPage,
  OptionButton,
  Page,
  UserImage,
} from "library/components";
import { Fragment, useEffect, useState } from "react";
import { FileIcon, GithubIcon } from "library/assets";
import { css } from "styled-components";
import { awaitTo } from "library/utils";
import { CodeBlock } from "./CodeBlock";

const BorderCss = css`
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: 12px;
`;

const BottomBorderCss = css`
  border-bottom: 2px solid ${({ theme }) => theme.borderColor};
`;

export const File = () => {
  const { file, nft, error, isLoading } = useFile();
  const [url, setUrl] = useState<string>("");
  const [files, setFiles] = useState<Record<string, string | undefined>>({});

  // Initialize first entry
  useEffect(() => {
    if (nft?.urls.length && !url) {
      setUrl(nft.urls.find((v) => v) || "");
    }
  }, [nft?.urls, url]);

  // Fetch raw files from GitHub
  useEffect(() => {
    if (url && !files[url] && url.startsWith("https://github.com/")) {
      // get file text
      const [user, repo, ...rest] = url
        .replace("https://github.com/", "")
        .replace("/blob/", "/")
        .split("/");
      const rawUrl = `https://cdn.jsdelivr.net/gh/${user}/${repo}@${rest.join(
        "/"
      )}`;
      const fetchFile = async () => {
        const [, res] = await awaitTo(fetch(rawUrl));
        const text = res?.ok ? await res.text() : undefined;
        if (text) {
          setFiles({ ...files, [url]: text });
        }
      };
      fetchFile();
    }
  }, [files, url]);

  if (!file || !nft || file.isLocked || error || isLoading) {
    return (
      <LoadingPage
        error={error}
        isLoading={isLoading}
        isLocked={file?.isLocked}
      />
    );
  }

  const [user, repo, branch, ...restUrls] = url
    .replace("https://github.com/", "")
    .replace("/blob/", "/")
    .split("/");
  const gitHubUrl = `https://github.com/${user}/${repo}/tree/${branch}`;

  return (
    <Page>
      <Helmet>
        <title>{`${file.name} | Files`}</title>
        <meta name="description" content={PageDescriptions.files} />
      </Helmet>

      <Flex
        minHeight="100%"
        paddingTop="32px"
        flexDirection="column"
        sm={{ flexDirection: "row" }}
        gap="16px"
      >
        {/* Side Panel */}
        <Div
          display="none"
          sm={{ display: "block", width: "240px" }}
          css={BorderCss}
          overflow="hidden"
        >
          {nft.urls.map((v) => {
            const p = v.split("/").splice(-3).join("/");
            return (
              <OptionButton
                key={p}
                $active={v === url}
                onClick={() => setUrl(v)}
              >
                <Flex marginRight="8px" center>
                  <FileIcon size={16} />
                </Flex>
                {`../${p}`}
              </OptionButton>
            );
          })}
        </Div>

        {/* Main Content */}
        <Flex flex="1" column>
          <Div display="block" sm={{ display: "none" }} marginBottom="16px">
            <Dropdown
              handleSelect={(v) => setUrl(v)}
              selectValue={url}
              options={nft.urls.map((v) => {
                const p = v.split("/").splice(-3).join("/");
                return {
                  label: (
                    <Flex gap="8px" alignCenter>
                      <FileIcon size={16} />
                      {`../${p}`}
                    </Flex>
                  ),
                  value: v,
                };
              })}
              buttonProps={{ showBorder: true }}
              wrapperProps={{ width: "100%" }}
            />
          </Div>

          <Flex
            marginBottom="16px"
            padding="4px"
            css={BorderCss}
            alignCenter
            justifyBetween
          >
            {file.app && (
              <Link to={`/${BasePaths.a}/${file.app.appHandle}`} $useOpacity>
                <UserImage icon={file.app.icon} handle={file.app.appHandle} />
              </Link>
            )}
            {file.member && (
              <Link
                to={`/${BasePaths.m}/${file.member.memberHandle}`}
                $useOpacity
              >
                <UserImage
                  icon={file.member.icon}
                  handle={file.member.memberHandle}
                  isRound
                />
              </Link>
            )}
            <A href={gitHubUrl} target="_blank">
              <GithubIcon size={20} />
            </A>
          </Flex>

          <Div flex="1" css={BorderCss} overflow="hidden">
            <Flex
              padding="4px"
              flexWrap="wrap"
              alignCenter
              css={BottomBorderCss}
            >
              <A href={gitHubUrl} target="_blank">
                {repo}
              </A>
              {restUrls.map((v, i) => {
                let r = gitHubUrl;
                for (let j = 0; j <= i; j++) {
                  r = r + "/" + restUrls[j];
                }
                return (
                  <Fragment key={v}>
                    /
                    <A href={r} target="_blank">
                      {v}
                    </A>
                  </Fragment>
                );
              })}
            </Flex>

            <CodeBlock url={url} code={files[url]} />
          </Div>
        </Flex>
      </Flex>
    </Page>
  );
};
