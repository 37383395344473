const appAdminInvite = (
  appAddress: string,
  memberAddress: string,
  isSuperuser: boolean
) => `
CALL_METHOD
  Address("${appAddress}")
  "app_admin_invite"
  Address("${memberAddress}")
  ${isSuperuser}
;
`;

const appAdminRemove = (
  appAddress: string,
  memberAddress: string,
  isRemoveAll: boolean
) => `
CALL_METHOD
  Address("${appAddress}")
  "app_admin_remove"
  Address("${memberAddress}")
  ${isRemoveAll}
;
`;

const adminAuth = (appComponent: string, memberAddress: string) => `
CALL_METHOD
  Address("${appComponent}")
  "admin_auth"
  Address("${memberAddress}")
  Proof("proof1")
;
`;

const adminInvite = (appAddress: string, memberAddress: string) => `
CALL_METHOD
  Address("${appAddress}")
  "admin_invite"
  Address("${memberAddress}")
;
`;

// NOT USED
// const adminJoin = (appComponent: string, memberAddress: string) => `
// CALL_METHOD
//   Address("${appComponent}")
//   "admin_join"
//   Address("${memberAddress}")
//   Proof("proof1")
// ;
// `;

const adminRemove = (appAddress: string, memberAddress: string) => `
CALL_METHOD
  Address("${appAddress}")
  "admin_remove"
  Address("${memberAddress}")
;
`;

const adminLeave = (
  appAddress: string,
  memberAddress: string,
  isRemoveAll: boolean
) => `
CALL_METHOD
  Address("${appAddress}")
  "admin_leave"
  Address("${memberAddress}")
  ${isRemoveAll}
  Proof("proof1")
;
`;

export const AppManifest = {
  appAdminInvite,
  appAdminRemove,
  adminAuth,
  adminInvite,
  // adminJoin,
  adminRemove,
  adminLeave,
};
