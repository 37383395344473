import {
  BookmarkRowButton,
  Flex,
  T20,
  TeamSymbol,
  UserIconNameHandle,
} from "library/components";
import { SavedHandlesRes, SearchFilesRes } from "redux/apiTypes";

export const LibraryRowRender = (
  d: SearchFilesRes[number],
  _: number,
  saved?: SavedHandlesRes["savedFiles"]
) => [
  <Flex alignCenter>
    {d.app && (
      <UserIconNameHandle
        {...d.app}
        memberHandle={d.app.appHandle}
        borderRadius="12px"
        small
      />
    )}
    {d.member && <UserIconNameHandle {...d.member} small />}
  </Flex>,
  <T20 marginBottom="4px" ellipsis medium>
    {d.name}
  </T20>,
  <Flex center>
    <TeamSymbol type={d.teamType} />
  </Flex>,
  d.category.toLowerCase(),
  d.tags.map((d) => d.name).join(", "),
  <BookmarkRowButton
    saved={saved?.some((s) => s.fileHandle === d.fileHandle)}
  />,
];
