import { KeyboardEvent, ReactNode, useRef, useState } from "react";
import styled from "styled-components";
import { Div, Li, Ul } from "../Common";
import { OptionButton } from "../Buttons/Button";
import { PX, T2, T3 } from "../Text";
import { ChevronDown } from "../../assets";
import { useClickOutside } from "../../hooks";
import { PopupStyle } from "../../styles";
import { CssPropsType } from "library/types";

// https://towardsdev.com/how-to-create-an-accessible-custom-select-dropdown-in-react-9d9858415f10
// https://codepen.io/tcomdev/pen/WNXeqoG

const OptionsWrapper = styled(Ul)<
  CssPropsType & {
    $active?: boolean;
    alignTop?: boolean;
    isOpen?: boolean;
    minWidth?: string;
  }
>`
  min-width: 100%;
  list-style: none;
  margin-left: 0;

  ${PopupStyle};
  max-height: 420px;
  overflow: auto;
`;

type OptionType<T> = {
  label?: ReactNode;
  value?: T;
  heading?: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
};

type DropdownType<T> = {
  alignTop?: boolean;
  disabled?: boolean;
  handleSelect: (val: T) => void;
  heading?: ReactNode;
  hideChevron?: boolean;
  icon?: ReactNode;
  medium?: boolean;
  selectValue: string;
  options: OptionType<T>[];
  scrollOptionTop?: boolean;
  buttonProps?: object;
  optionsProps?: object;
  wrapperProps?: object;
};

//
// Dropdown component that can include both headings and options
//
export const Dropdown = <T,>({
  alignTop = false,
  disabled = false,
  handleSelect,
  heading,
  hideChevron = false,
  icon,
  selectValue,
  medium = false,
  options: optionsAndHeadings,
  scrollOptionTop = false,
  buttonProps = {},
  optionsProps = {},
  wrapperProps = {},
}: DropdownType<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = optionsAndHeadings.filter(({ heading }) => !heading);

  const selectValueIndexFound = options.findIndex(
    (option) => option.value === selectValue
  );
  const selectValueIndex =
    selectValueIndexFound === -1 ? 0 : selectValueIndexFound;
  const [selectedIndex, setSelectedIndex] = useState(selectValueIndex);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => isOpen && setIsOpen(false));

  const optionsWrapperRef = useRef<HTMLUListElement>(null);
  const toggleIsOpen = () => {
    if (disabled) return;
    setSelectedIndex(selectValueIndex);
    if (!isOpen && scrollOptionTop) {
      const offsetTop = document.getElementById(
        `option-${selectValue}`
      )?.offsetTop;
      if (typeof offsetTop === "number" && optionsWrapperRef.current)
        optionsWrapperRef.current.scrollTop = offsetTop - 42;
    }
    setIsOpen(!isOpen);
  };

  const setOption = (index: number) => {
    const newOption = options[index];
    const newValue = newOption?.value;
    if (!newOption || newOption.disabled) {
      console.log(options);
      return;
    }
    if (typeof newValue === "string" && newValue !== selectValue) {
      handleSelect(newValue);
    }
    setSelectedIndex(index);
    setIsOpen(false);
  };

  const handleKeyDown = (
    e: KeyboardEvent<HTMLButtonElement | HTMLUListElement>
  ) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex(
          selectedIndex - 1 >= 0 ? selectedIndex - 1 : options.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex(
          selectedIndex === options.length - 1 ? 0 : selectedIndex + 1
        );
        break;
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        } else {
          setOption(selectedIndex);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Div
      ref={wrapperRef}
      display="inline-block"
      position="relative"
      whiteSpace="nowrap"
      {...wrapperProps}
    >
      <OptionButton
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        onClick={toggleIsOpen}
        onKeyDown={handleKeyDown}
        display="block"
        borderRadius="8px"
        fontSize={medium ? PX(17) : undefined}
        cursor={disabled ? "not-allowed" : undefined}
        textActive
        {...buttonProps}
      >
        {heading && (
          <T3 textAlign="left" marginBottom="4px" normal>
            {heading}
          </T3>
        )}
        <Div display="flex" alignItems="center">
          <Div flex="1">{options[selectValueIndex]?.label}</Div>
          {!hideChevron &&
            (icon || (
              <Div display="flex" marginLeft={medium ? "12px" : "8px"}>
                <ChevronDown size={medium ? 20 : 16} />
              </Div>
            ))}
        </Div>
      </OptionButton>

      <OptionsWrapper
        ref={optionsWrapperRef}
        isOpen={isOpen}
        role="listbox"
        aria-activedescendant={`options-${selectedIndex}`}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        alignTop={alignTop}
        {...optionsProps}
      >
        {optionsAndHeadings.map(
          ({ heading, label, description, value, disabled }, i) => {
            if (heading) {
              return (
                <Li key={i}>
                  <T2 padding="12px" medium>
                    {heading}
                  </T2>
                </Li>
              );
            }

            const optionIndex = options.findIndex(
              (option) => option.value === value
            );
            return (
              <Li
                key={i}
                id={`option-${value}`}
                role="option"
                aria-selected={selectedIndex === optionIndex}
                tabIndex={-1}
              >
                <OptionButton
                  type="button"
                  tabIndex={0}
                  onClick={() => setOption(optionIndex)}
                  $active={selectedIndex === optionIndex}
                  disabled={disabled}
                >
                  {description || label}
                </OptionButton>
              </Li>
            );
          }
        )}
      </OptionsWrapper>
    </Div>
  );
};
