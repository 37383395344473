import {
  BaseButton,
  Div,
  Error,
  Flex,
  H6,
  InputBase,
} from "library/components";

type MemberWalletProps = {
  disabled?: boolean;
  value: string | null;
  error: string | undefined;
  isLoading: boolean | undefined;
  label: string;
  placeholder: string | null;
  handleUpdate: () => Promise<string | undefined>;
};

export const MemberWallet = ({
  disabled,
  value,
  error,
  isLoading,
  label,
  placeholder,
  handleUpdate,
}: MemberWalletProps) => (
  <Flex>
    <H6
      display="none"
      sm={{ display: "block" }}
      minWidth="150px"
      marginTop="10px"
      marginRight="24px"
    >
      {label}
    </H6>
    <Div flex="1">
      <InputBase
        value={value || ""}
        placeholder={placeholder || "None"}
        readOnly
        secondary
      />
      <Error error={error} />
    </Div>

    <BaseButton
      disabled={disabled}
      type="button"
      marginLeft="24px"
      isLoading={isLoading}
      onClick={handleUpdate}
    >
      Update
    </BaseButton>
  </Flex>
);
