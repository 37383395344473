import { inviteApi, projectApi } from "redux/apis";
import { InviteModal } from "./InviteModal";
import { MemberInviteType, TeamType } from "library/types";
import { awaitTo, parseRtkError } from "library/utils";
import { AuthUserRes } from "redux/apiTypes";
import { ProjectContract, projectInviteCall, useRadixCall } from "radix";

type InviteProjectModalProps = {
  auth: AuthUserRes;
  contract: ProjectContract | undefined;
  resetContract: () => Promise<void>;
  hideModal: () => void;
  members: MemberInviteType[];
  projectAddress: string;
  projectHandle: string;
  projectName: string;
  show: boolean;
  teamType: TeamType;
};

export const InviteProjectModal = ({
  auth,
  contract,
  resetContract,
  hideModal,
  members,
  projectAddress,
  projectHandle,
  projectName,
  show,
  teamType,
}: InviteProjectModalProps) => {
  const [
    handleCreate,
    { data: inviteErrors, isLoading: isLoadingInvites, error },
  ] = inviteApi.useInvitesProjectCreateMutation();
  const { refetch: refetchInvites } = inviteApi.useInvitesProjectGetQuery({
    projectHandle,
  });
  const {
    refetch,
    isLoading: isLoadingMembers,
    isFetching: isFetchingMembers,
  } = projectApi.useProjectGetMembersQuery({ projectHandle });

  const [contractCall, { error: contractError, isLoading: isLoadingContract }] =
    useRadixCall(projectInviteCall);

  return (
    <InviteModal
      projectName={projectName}
      handleSubmit={async (selected) => {
        if (!contract) {
          return;
        }
        const newMembers = selected.filter(
          (v) => v.componentAddress && !contract.members[v.componentAddress]
        );
        if (newMembers.length) {
          const txDetails = await contractCall(
            auth,
            projectAddress,
            newMembers
          );
          if (!txDetails) {
            return;
          } else {
            resetContract();
          }
        }

        const [err, data] = await awaitTo(
          handleCreate({
            projectHandle,
            invitesProjectReq: {
              invites: selected.map((s) => ({
                memberId: s.id,
                teamRole: s.teamRole,
              })),
            },
          }).unwrap()
        );
        if (!err && data) {
          setTimeout(() => {
            refetch();
          }, 1000);
          if (data.errors.length === 0) {
            hideModal();
            // Unknown cause of delay between invite creation and invite in response array
            // 500ms: [], 600ms: either or, 1500ms: [{...}] guaranteed
            setTimeout(() => {
              refetchInvites();
            }, 1500);
          }
          return data;
        }
        return undefined;
      }}
      hideModal={hideModal}
      inviteErrors={inviteErrors}
      isLoading={
        isLoadingInvites ||
        isLoadingMembers ||
        isFetchingMembers ||
        isLoadingContract
      }
      members={members}
      serverError={parseRtkError(error) || contractError}
      show={show}
      teamType={teamType}
    />
  );
};
