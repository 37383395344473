import { SVGProps } from "react";

const IconDefaults = {
  fillRule: "evenodd",
  clipRule: "evenodd",
  size: 24,
  strokeWidth: 2,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
} as SVGProps<SVGSVGElement>;

export const AwardIcon = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} {...IconDefaults} {...rest} fill="none">
    <path d="M12 15a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z" stroke={color} />
    <path d="M12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill="currentColor" />
    <path d="m8 14-1 9 5-3 5 3-1-9" stroke={color} />
  </svg>
);

export const BlockIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V8.5C22 9.60457 21.1046 10.5 20 10.5H4C2.89543 10.5 2 9.60457 2 8.5V5Z" />
    <path d="M2 15.5C2 14.3954 2.89543 13.5 4 13.5H20C21.1046 13.5 22 14.3954 22 15.5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V15.5Z" />
  </svg>
);

export const Book = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M22.5 16.875V1.125C22.5.502 21.998 0 21.375 0H6a4.501 4.501 0 0 0-4.5 4.5v15C1.5 21.984 3.516 24 6 24h15.375c.623 0 1.125-.502 1.125-1.125v-.75c0-.352-.164-.67-.417-.877-.197-.721-.197-2.78 0-3.501.253-.202.417-.52.417-.872ZM19.378 21H6c-.83 0-1.5-.67-1.5-1.5 0-.825.675-1.5 1.5-1.5h13.378c-.089.802-.089 2.198 0 3Z" />
  </svg>
);

export const Bookmark = ({
  color = "currentColor",
  fill = "none",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
  >
    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
  </svg>
);

export const CalendarIcon = ({
  color = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="M21 20V10H3V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20Z" />
    <path
      fill="none"
      d="M21 20V6C21 4.89543 20.1046 4 19 4H5C3.89543 4 3 4.89543 3 6V20M21 20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20M21 20V10H3V20"
    />
    <path d="M16 2V6" />
    <path d="M8 2V6" />
  </svg>
);

export const Checkmark = ({
  color = "currentColor",
  fill = "none",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
  >
    <path d="M2 13.5L9 20.5L22.5 7L19.5 4L9 14.5L5 10.5L2 13.5Z" />
  </svg>
);

export const CheckmarkThin = ({
  color = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="M20 6 9 17l-5-5" />
  </svg>
);

export const ChevronUp = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="m18 15-6-6-6 6" />
  </svg>
);

export const ChevronDown = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="m6 9 6 6 6-6" />
  </svg>
);

export const ChevronLeft = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="m15 18-6-6 6-6" />
  </svg>
);

export const ChevronsLeft = ({
  color = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="m11 17-5-5 5-5M18 17l-5-5 5-5" />
  </svg>
);

export const ChevronRight = ({
  color = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="m9 18 6-6-6-6" />
  </svg>
);

export const ChevronsRight = ({
  color = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="m13 17 5-5-5-5M6 17l5-5-5-5" />
  </svg>
);

export const FlagIcon = ({
  color = "currentColor",
  fill = "none",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
  >
    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7" />
  </svg>
);

export const ExternalLinkIcon = ({
  color = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
  </svg>
);

export const FileIcon = ({
  color = "currentColor",
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill="none"
    {...IconDefaults}
    strokeWidth={2}
    {...rest}
  >
    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
    <polyline points="13 2 13 9 20 9"></polyline>
  </svg>
);

export const FolderIcon = ({
  color = "currentColor",
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    strokeWidth={1}
    {...rest}
  >
    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
  </svg>
);

export const GlobeIcon = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M2 12h20M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
  </svg>
);

export const GridIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M3 4C3 3.44772 3.44772 3 4 3H10C10.5523 3 11 3.44772 11 4V10C11 10.5523 10.5523 11 10 11H4C3.44772 11 3 10.5523 3 10V4Z" />
    <path d="M13 4C13 3.44772 13.4477 3 14 3H20C20.5523 3 21 3.44772 21 4V10C21 10.5523 20.5523 11 20 11H14C13.4477 11 13 10.5523 13 10V4Z" />
    <path d="M13 14C13 13.4477 13.4477 13 14 13H20C20.5523 13 21 13.4477 21 14V20C21 20.5523 20.5523 21 20 21H14C13.4477 21 13 20.5523 13 20V14Z" />
    <path d="M3 14C3 13.4477 3.44772 13 4 13H10C10.5523 13 11 13.4477 11 14V20C11 20.5523 10.5523 21 10 21H4C3.44772 21 3 20.5523 3 20V14Z" />
  </svg>
);

export const Home = ({
  color = "currentColor",
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
  >
    <path d="M3 9L12 2L21 9V20C21 21.1046 20.1046 22 19 22H16V14C16 12.8954 15.1046 12 14 12H10C8.89543 12 8 12.8954 8 14V22H5C3.89543 22 3 21.1046 3 20V9Z" />
  </svg>
);

export const InfoIcon = ({ fill = "currentColor", size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fill}
  >
    <g clipPath="url(#infoIcon)">
      <path d="M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0Zm0 4.92a1.56 1.56 0 1 1 0 3.12 1.56 1.56 0 0 1 0-3.12Zm2.88 13.56H9.6a.96.96 0 1 1 0-1.92h1.68v-5.28h-.96a.96.96 0 1 1 0-1.92h1.92a.96.96 0 0 1 .96.96v6.24h1.68a.96.96 0 1 1 0 1.92Z" />
    </g>
    <defs>
      <clipPath id="infoIcon">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const InboxIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M6.912 3a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H6.912Zm-.891 1.793a1.5 1.5 0 0 0-.543.766L3.265 12.75h2.843a3 3 0 0 1 2.684 1.658l.256.512a1.5 1.5 0 0 0 1.342.83h3.218a1.5 1.5 0 0 0 1.342-.829l.256-.513a3 3 0 0 1 2.684-1.658h2.845l-2.213-7.191A1.5 1.5 0 0 0 17.088 4.5H6.912a1.5 1.5 0 0 0-.891.293Z" />
  </svg>
);

export const MonitorIcon = ({
  color = "currentColor",
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
  >
    <path d="M18.4 5H5.6C4.716 5 4 5.64 4 6.429v7.142C4 14.361 4.716 15 5.6 15h12.8c.884 0 1.6-.64 1.6-1.429V6.43C20 5.639 19.284 5 18.4 5ZM8 21h8M12 15v6" />
  </svg>
);

export const MoonIcon = ({
  color = "currentColor",
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
    fillRule="nonzero"
  >
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79Z" />
  </svg>
);

export const MoreIcon = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </svg>
);

export const NinePointStar = ({
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="m19.35 8.95 1.39.55c.94.38 1.16 1.63.4 2.32l-1.12 1a1.4 1.4 0 0 0-.29 1.69l.71 1.32c.49.9-.14 2.01-1.16 2.04l-1.49.03a1.37 1.37 0 0 0-1.3 1.1l-.3 1.5a1.36 1.36 0 0 1-2.18.8l-1.16-.95c-.5-.4-1.2-.4-1.7 0l-1.17.95c-.8.64-1.98.2-2.18-.8L7.5 19a1.37 1.37 0 0 0-1.3-1.1l-1.48-.04a1.38 1.38 0 0 1-1.16-2.04l.71-1.32a1.4 1.4 0 0 0-.3-1.7l-1.1-1a1.39 1.39 0 0 1 .4-2.31l1.38-.55c.6-.24.95-.85.85-1.5l-.22-1.48a1.37 1.37 0 0 1 1.78-1.51l1.4.48a1.35 1.35 0 0 0 1.6-.6l.78-1.28a1.35 1.35 0 0 1 2.32 0l.78 1.29c.33.55.99.8 1.6.59l1.4-.48c.97-.33 1.93.49 1.78 1.5l-.22 1.5c-.1.64.26 1.25.85 1.49Z" />
  </svg>
);

export const PlusIcon = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <path d="M12 5v14M5 12h14" />
  </svg>
);

export const Rocket = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...rest}
  >
    <path d="M22.39 1.8a.22.22 0 0 0-.17-.18C19.48.95 13.13 3.34 9.7 6.78c-.62.6-1.17 1.27-1.67 1.97A6.24 6.24 0 0 0 5 9.13c-2.54 1.12-3.29 4.05-3.5 5.3a.45.45 0 0 0 .5.53l4.1-.45c0 .31.02.62.05.92.02.22.11.42.27.57L8 17.58c.15.15.35.24.56.27.3.03.62.05.92.05L9.03 22a.45.45 0 0 0 .53.5c1.25-.2 4.19-.95 5.3-3.5.4-.9.48-1.96.39-3.01.7-.5 1.36-1.06 1.97-1.67 3.45-3.43 5.83-9.63 5.17-12.52Zm-8.6 8.42a2.25 2.25 0 1 1 3.18-3.19 2.25 2.25 0 0 1-3.19 3.19Z" />
    <path d="M7.9 18.72c-.26.26-.68.36-1.17.45a1.64 1.64 0 0 1-1.9-1.9 3.17 3.17 0 0 1 .5-1.28.2.2 0 0 0-.23-.24c-.62.08-1.2.36-1.64.8-1.1 1.1-1.21 5.2-1.21 5.2s4.1-.1 5.2-1.2a2.8 2.8 0 0 0 .8-1.66c.02-.2-.22-.3-.36-.17Z" />
  </svg>
);

export const RowIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M3 14.286c0 .63.485 1.143 1.083 1.143h15.834c.287 0 .563-.12.766-.335.203-.214.317-.505.317-.808 0-.303-.114-.594-.317-.808a1.054 1.054 0 0 0-.766-.335H4.083c-.598 0-1.083.512-1.083 1.143Zm0 4.571C3 19.488 3.485 20 4.083 20h15.834c.287 0 .563-.12.766-.335.203-.214.317-.505.317-.808 0-.303-.114-.594-.317-.808a1.054 1.054 0 0 0-.766-.335H4.083c-.598 0-1.083.512-1.083 1.143Zm0-9.143c0 .631.485 1.143 1.083 1.143h15.834c.287 0 .563-.12.766-.335.203-.214.317-.505.317-.808 0-.303-.114-.594-.317-.808a1.055 1.055 0 0 0-.766-.335H4.083C3.485 8.571 3 9.083 3 9.714ZM4.083 4c-.287 0-.563.12-.766.335A1.176 1.176 0 0 0 3 5.143c0 .303.114.594.317.808.203.215.479.335.766.335h15.834c.287 0 .563-.12.766-.335.203-.214.317-.505.317-.808 0-.303-.114-.594-.317-.808A1.055 1.055 0 0 0 19.917 4H4.083Z" />
  </svg>
);

export const SearchIcon = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <circle cx="11" cy="11" r="8" />
    <line x1="21" y1="21" x2="16.65" y2="16.65" />
  </svg>
);

export const SettingsIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a1.998 1.998 0 0 1 0 2.83 1.998 1.998 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-4 0v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a1.998 1.998 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 1 1 0-4h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 1 1 2.83-2.83l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 1 1 4 0v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 3.417 1.415 2 2 0 0 1-.587 1.415l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 0 4h-.09a1.65 1.65 0 0 0-1.51 1Zm-7.4.667a3.667 3.667 0 1 0 0-7.334 3.667 3.667 0 0 0 0 7.334Z" />
  </svg>
);

export const SunIcon = ({
  color = "currentColor",
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
  >
    <path d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />
  </svg>
);

export const User = ({
  color = "currentColor",
  fill = "currentColor",
  size = 24,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    fill={fill}
    {...IconDefaults}
    {...rest}
  >
    <path d="M3 19C3 16.7909 4.79086 15 7 15H17C19.2091 15 21 16.7909 21 19V21H3V19Z" />
    <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" />
  </svg>
);

export const UserCircle = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M12.136 2.25c-5.483-.074-9.96 4.402-9.886 9.886.073 5.259 4.355 9.54 9.614 9.614 5.484.075 9.96-4.402 9.885-9.885-.072-5.26-4.354-9.542-9.613-9.615Zm5.926 15.34a.187.187 0 0 1-.288-.015 5.827 5.827 0 0 0-1.516-1.387C15.064 15.422 13.552 15 12 15s-3.064.422-4.258 1.188a5.825 5.825 0 0 0-1.516 1.386.188.188 0 0 1-.288.015 8.218 8.218 0 0 1-2.188-5.448c-.076-4.562 3.667-8.38 8.23-8.391 4.563-.011 8.27 3.694 8.27 8.25a8.218 8.218 0 0 1-2.188 5.59Z" />
    <path d="M12 6.75c-.924 0-1.76.346-2.354.976-.594.63-.89 1.5-.824 2.434C8.958 12 10.384 13.5 12 13.5c1.616 0 3.039-1.5 3.178-3.34.069-.925-.225-1.787-.83-2.428-.595-.633-1.43-.982-2.348-.982Z" />
  </svg>
);

export const VerifiedIcon = ({ fill = "currentColor", size = 24, ...rest }) => (
  <svg width={size} height={size} fill={fill} {...IconDefaults} {...rest}>
    <path d="M12 0c1.702 0 3.184.945 3.948 2.34a4.5 4.5 0 0 1 5.712 5.712A4.5 4.5 0 0 1 24 12a4.5 4.5 0 0 1-2.34 3.948 4.5 4.5 0 0 1-5.712 5.712A4.5 4.5 0 0 1 12 24a4.5 4.5 0 0 1-3.948-2.34 4.5 4.5 0 0 1-5.712-5.712A4.499 4.499 0 0 1 0 12c0-1.702.945-3.184 2.34-3.948A4.5 4.5 0 0 1 8.051 2.34 4.499 4.499 0 0 1 12 0Zm6.06 10.06a1.5 1.5 0 0 0-2.12-2.12l-5.44 5.439-2.44-2.44a1.5 1.5 0 1 0-2.12 2.122l3.5 3.5a1.5 1.5 0 0 0 2.12 0l6.5-6.5Z" />
  </svg>
);

export const XIcon = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    {...IconDefaults}
    {...rest}
    fill="none"
  >
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
);
