import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  PageDescriptions,
  PageTitles,
  PageNames,
  BasePaths,
  isDev,
} from "consts";
import { useProject } from "hooks";
import { Button, Div, H1, LoadingPage, Page } from "library/components";
import { awaitTo, parseRtkError } from "library/utils";
import { projectApi, searchApi } from "redux/apis";
import { ProjectSettingsForm } from "./ProjectSettingsForm";

export const ProjectSettings = () => {
  const { projectHandle } = useParams();
  const navigate = useNavigate();
  const { teams, error, isLoading, isAdmin, isProjectLead } = useProject();

  const [handleDelete, { error: deleteError }] =
    projectApi.useProjectDeleteMutation();
  const [handleUpdate, { isLoading: isLoadingUpdate, error: updateError }] =
    projectApi.useProjectUpdateMutation();
  const {
    refetch,
    data: p,
    isLoading: isLoadingP,
    error: errorP,
  } = projectApi.useProjectGetProfileQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  const [search, setSearch] = useState("");
  const { data: suggestions, isFetching: isLoadingTags } =
    searchApi.useSearchTagsQuery(
      search.length >= 3 ? { search, type: "PROJECT" } : skipToken
    );

  useEffect(() => {
    if (!isLoading && teams && !(isAdmin || isProjectLead)) {
      navigate("/");
    }
  }, [isAdmin, isLoading, isProjectLead, navigate, teams]);

  if (!p || errorP || isLoadingP || error || isLoading) {
    return (
      <LoadingPage
        error={error || errorP}
        isLoading={isLoading || isLoadingP}
      />
    );
  }

  return (
    <Page>
      <Helmet>
        <title>
          {p.name} - {PageTitles.projectSettings}
        </title>
        <meta name="description" content={PageDescriptions.projectSettings} />
      </Helmet>

      <Div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin="64px 0 32px"
      >
        <H1 bold>{PageNames.settings}</H1>
      </Div>

      <ProjectSettingsForm
        data={p}
        handleSearch={(v) => setSearch(v)}
        handleUpdate={async (d) => {
          const [err, project] = await awaitTo(
            handleUpdate({
              projectHandle: p.projectHandle,
              projectReq: d,
            }).unwrap()
          );
          if (!err && project) {
            await awaitTo(refetch());
            navigate(
              `/${BasePaths.p}/${project.projectHandle}/${BasePaths.settings}`,
              { replace: true }
            );
          }
        }}
        error={parseRtkError(errorP || updateError || deleteError)}
        isLoading={isLoadingP || isLoadingUpdate}
        isLoadingTags={isLoadingTags}
        suggestions={suggestions}
      />

      {isDev && (
        <Button
          marginTop="24px"
          onClick={async () => {
            if (!projectHandle) return;
            const [, d] = await awaitTo(
              handleDelete({ projectHandle }).unwrap()
            );
            if (d) {
              navigate(`/${BasePaths.a}/${p.app.appHandle}`);
            }
          }}
        >
          Delete
        </Button>
      )}
    </Page>
  );
};
