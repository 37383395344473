export const formatDateLong = (date: string | number) => {
  const d = new Date(date);
  const month = d.toLocaleString(undefined, {
    month: "short",
  });
  const time = d.toLocaleTimeString([], {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  });

  return `${month} ${d.getDate()} ${d.getFullYear()}, ${time}`;
};

export const formatDateShort = (date: string | number) => {
  const d = new Date(date);
  return `${d.toLocaleString(undefined, { month: "short" })} ${d.getDate()}`;
};

export const formatDate = (date?: string | number) => {
  if (!date) {
    return "--";
  }
  const d = new Date(date);
  const month = d.toLocaleString(undefined, {
    month: "short",
  });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

export const formatNumber = (n: number | string) => Number(n).toLocaleString();

export const formatNumberCompact = (n: number | string, options = {}) =>
  new Intl.NumberFormat(undefined, {
    maximumSignificantDigits: 3,
    notation: "compact",
    ...options,
  })
    .format(Number(n))
    .toLocaleLowerCase();

export const formatPercent = (n: number | string) =>
  Number(n).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });

export const formatMoney = (n: number | string, round = false) =>
  new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: round ? 0 : 2,
    maximumFractionDigits: round ? 0 : 2,
  }).format(Number(n));

export const formatValue = (amount?: string | null, price?: string | null) =>
  amount && price ? formatMoney(Number(amount) * Number(price)) : "--";

export const formatAddress = (a?: string) =>
  a && a.length > 10 ? `${a.slice(0, 4)}...${a.slice(-6)}` : "";
