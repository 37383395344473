import {
  BookmarkRowButton,
  Flex,
  IconNameSubtitle,
  ReviewStatusDescriptions,
  T20,
  TeamSymbol,
} from "library/components";
import { formatNumber } from "library/utils";
import { SavedHandlesRes, SearchProjectsRes } from "redux/apiTypes";

export const ProjectsRowRender = (
  d: SearchProjectsRes[number],
  _: number,
  saved?: SavedHandlesRes["savedProjects"]
) => [
  <Flex alignCenter>
    <IconNameSubtitle
      icon={d.app.icon}
      name={d.app.name}
      subtitle={d.app.subtitle}
    />
  </Flex>,
  <T20 ellipsis medium>
    {d.name}
  </T20>,
  <Flex center>
    <TeamSymbol type={d.teamType} />
  </Flex>,
  d.app.category.toLowerCase(),
  <Flex center>{ReviewStatusDescriptions[d.reviewStatus]}</Flex>,
  d.amount ? `${formatNumber(d.amount)} ${d.tokenSymbol}` : "--",
  <BookmarkRowButton
    saved={saved?.some((s) => s.projectHandle === d.projectHandle)}
  />,
];
