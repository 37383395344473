import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { BasePaths, Colors } from "library/consts";
import {
  Div,
  Flex,
  H1,
  H6,
  Img,
  T2,
  T3,
  TeamTypeOptionsMedium,
} from "library/components";
import { formatNumber } from "library/utils";
import { BoxShadowSmallStyle, FrostedLightStyle } from "library/styles";
import { MemberType, TeamType, TokenSymbol } from "library/types";
import { CardHeading } from "./CardHeading";
import { BookmarkButton, UserIcons } from "./CardComponents";
import { Card } from "./Card";
import { CoinIcons } from "library/assets";

const ProjectSubHeading = styled(Flex)`
  ${FrostedLightStyle}
`;

const Reward = styled(Flex)`
  ${FrostedLightStyle}
  border: 1px solid rgba(250, 250, 253, 0.3);
`;

type ProjectCardProps = {
  appName: string;
  amount: string | number | null;
  category: string;
  projectHandle: string;
  icon?: string;
  image?: string | null;
  members?: MemberType[];
  name: string;
  number: number | string;
  subtitle: string;
  showProjectSubheading?: boolean;
  tags: string[];
  teamType: TeamType;
  tokenSymbol: string | null;
  saved?: boolean;
};

export const ProjectCard = ({
  amount,
  appName,
  category,
  projectHandle,
  icon,
  image,
  members,
  name,
  number,
  subtitle,
  showProjectSubheading = false,
  tags,
  teamType,
  tokenSymbol,
  saved,
}: ProjectCardProps) => (
  <Div position="relative">
    <BookmarkButton color={Colors.white} saved={saved} />

    <Link to={`/${BasePaths.p}/${projectHandle}`}>
      <Card zIndex="1">
        <Img
          height="100%"
          width="100%"
          position="absolute"
          top="0"
          left="0"
          zIndex="2"
          // filter="brightness(60%)"
          // src="https://images.unsplash.com/photo-1523741543316-beb7fc7023d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFybXxlbnwwfHwwfHw%3D&w=1000&q=80"
          // src="https://c4.wallpaperflare.com/wallpaper/39/346/426/digital-art-men-city-futuristic-night-hd-wallpaper-preview.jpg"
          src={image || ""}
          alt={name}
        />

        <Flex
          position="relative"
          zIndex="3"
          backgroundColor="rgba(28, 28, 40, 0.55)"
          color={Colors.white}
          fullHeight
          fullWidth
          column
        >
          <Flex flex="1" column>
            <Flex margin="24px 24px 0 24px" alignCenter>
              {TeamTypeOptionsMedium.find((v) => v.value === teamType)?.label}
            </Flex>

            <H1 flex="1" minHeight="90px" margin="32px 24px">
              {name}
            </H1>

            <Flex margin="0 24px 24px 24px" alignEnd justifyBetween>
              <T2 medium>
                {[category.toLowerCase(), ...tags].slice(0, 3).join(", ")}
              </T2>
              <Reward
                whiteSpace="nowrap"
                padding="6px 8px"
                borderRadius="8px"
                marginLeft="8px"
                alignCenter
              >
                {tokenSymbol ? CoinIcons[tokenSymbol] : CoinIcons.XRD}
                <H6 marginLeft="8px">{formatNumber(amount || 0)}</H6>
                <T3 marginTop="2px" marginLeft="4px" medium>
                  {tokenSymbol || TokenSymbol.XRD}
                </T3>
              </Reward>
            </Flex>
          </Flex>

          {showProjectSubheading && members ? (
            <ProjectSubHeading padding="12px 24px" alignCenter justifyBetween>
              <Div
                borderRadius="4px"
                padding="4px 8px"
                fontSize="12px"
                fontWeight="500"
                color={Colors.blueBlack}
                css={css`
                  background-color: ${({ theme }) =>
                    theme._isDark ? Colors.background : Colors.white};
                  ${BoxShadowSmallStyle}
                `}
              >
                #{number}
              </Div>

              <Div flex="1" />

              <UserIcons members={members} />
            </ProjectSubHeading>
          ) : (
            <CardHeading
              css={FrostedLightStyle}
              icon={icon}
              name={appName}
              subtitle={subtitle}
            />
          )}
        </Flex>
      </Card>
    </Link>
  </Div>
);
