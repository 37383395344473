import { Helmet } from "react-helmet-async";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { proposalApi } from "redux/apis";
import { ProposalReq as ProposalReqType } from "redux/apiTypes";
import { awaitTo, parseRtkError } from "library/utils";
import { ProposalForm } from "./ProposalForm";
import { Modal } from "library/components";

export const ProposalCreateModal = ({
  hideModal,
  show,
  projectHandle,
}: {
  hideModal: () => void;
  show: boolean;
  projectHandle: string;
}) => {
  const [handleCreate, { error, isLoading }] =
    proposalApi.useProposalCreateMutation();

  const {
    refetch,
    isLoading: isLoadingP,
    error: errorP,
  } = proposalApi.useProposalsProjectGetQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  const onSubmit = async (data: ProposalReqType) => {
    const [, res] = await awaitTo(
      handleCreate({
        proposalReq: data,
        projectHandle,
      }).unwrap()
    );
    if (res?.id) {
      await refetch();
      hideModal();
      return res;
    }
    return undefined;
  };

  return (
    <>
      {show && (
        <Helmet>
          <title>Create Proposal</title>
          <meta name="description" content="Create Proposal" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}

      <Modal show={show} closeModal={() => hideModal()} large>
        <ProposalForm
          error={parseRtkError(error || errorP)}
          isLoading={isLoading || isLoadingP}
          handleCreateUpdate={onSubmit}
          isEditable
        />
      </Modal>
    </>
  );
};
