import { skipToken } from "@reduxjs/toolkit/dist/query";
import { MemberType } from "library/types";
import { awaitTo, parseRtkError } from "library/utils";
import { useState } from "react";
import { inviteApi, projectApi } from "redux/apis";
import { MembersModal } from "./MembersModal";
import { useProject } from "hooks";
import { AuthUserRes } from "redux/apiTypes";
import { ProjectContract, projectInviteRemoveCall } from "radix";

type MembersProjectModalProps = {
  auth: AuthUserRes;
  contract: ProjectContract | undefined;
  resetContract: () => Promise<void>;
  hideModal: () => void;
  show: boolean;
  projectAddress: string;
  projectHandle: string;
  members: (MemberType & {
    componentAddress: string | null;
  })[];
};

export const MembersProjectModal = ({
  auth,
  contract,
  resetContract,
  hideModal,
  show,
  projectAddress,
  projectHandle,
  members,
}: MembersProjectModalProps) => {
  const [loadingId, setLoadingId] = useState("");
  const [err, setErr] = useState("");

  // Invite Functions
  const { isProjectMember } = useProject();
  const {
    refetch: refetchI,
    data: invites,
    error: errorI,
  } = inviteApi.useInvitesProjectGetQuery(
    isProjectMember && projectHandle ? { projectHandle } : skipToken
  );

  const handleRemoveContract = async (componentAddress: string) => {
    if (!contract) {
      setErr("Unable to update contract");
      return undefined;
    }
    if (!contract.members[componentAddress]) {
      return "COMPLETED";
    }

    const txDetails = await projectInviteRemoveCall(
      auth,
      projectAddress,
      componentAddress
    );
    if (!txDetails) {
      setErr("Unable to update contract");
      return undefined;
    } else {
      resetContract();
      return "COMPLETED";
    }
  };

  const [handleRemoveM, { error: errorRM }] =
    projectApi.useProjectRemoveMemberMutation();
  const { refetch, error: errorM } = projectApi.useProjectGetMembersQuery(
    projectHandle ? { projectHandle } : skipToken
  );
  const handleRemoveMember = async (memberId: string) => {
    const member = members.find((m) => m.id === memberId);
    if (!member?.componentAddress) {
      setErr("Member component address not found");
      return;
    }
    setLoadingId(memberId);
    const cRes = await handleRemoveContract(member.componentAddress);
    if (!cRes) {
      setLoadingId("");
      return;
    }
    const [, res] = await awaitTo(
      handleRemoveM({
        memberId,
        projectHandle,
      }).unwrap()
    );
    if (res?.id) {
      await refetch();
    }
    setLoadingId("");
  };

  const [handleRemoveIn, { error: errorRI }] =
    inviteApi.useInviteDeleteMutation();
  const handleRemoveInvite = async (
    inviteId: string,
    memberAddress: string
  ) => {
    setLoadingId(inviteId);
    const cRes = await handleRemoveContract(memberAddress);
    if (!cRes) {
      setLoadingId("");
      return;
    }
    const [, res] = await awaitTo(
      handleRemoveIn({ inviteId, kind: "project" }).unwrap()
    );
    if (res?.id) {
      await refetchI();
    }
    setLoadingId("");
  };

  return (
    <MembersModal
      hideModal={hideModal}
      show={show}
      members={members}
      invites={invites}
      loadingId={loadingId}
      errorM={parseRtkError(errorRM || errorM)}
      errorI={parseRtkError(errorRI || errorI)}
      errorC={err}
      handleRemoveMember={handleRemoveMember}
      handleRemoveInvite={handleRemoveInvite}
    />
  );
};
