import { Colors, Gradients } from "../consts";

export const RadixIcon = ({ size = 24 }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="12" fill="#052CC0" />
    <path
      d="M10.608 16.9994c-.192 0-.384-.0923-.528-.2769l-2.448-3.2767H6v-1.1999h1.92c.24 0 .384.0923.528.2307l2.016 2.6768 3.12-6.78419c.044-.10242.1166-.19113.2098-.2563.0932-.06516.2033-.10425.3182-.11291H18v1.24607h-3.456l-3.36 7.38413c-.044.1024-.1166.1911-.2098.2563-.0932.0652-.2033.1043-.3182.1129-.016.0008-.032.0008-.048 0Z"
      fill="#00C389"
    />
  </svg>
);

export const ScryptoIcon = ({
  color = "#FFFFFF",
  fill = "#052CC0",
  shadowColor = "#060F8F",
  size = 24,
  hideBackground = false,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {!hideBackground && (
      <ellipse cx={100} cy={102.5} rx={100} ry={97.5} fill="url(#a)" />
    )}
    <ellipse cx={100} cy={97.5} rx={100} ry={97.5} fill={fill} />

    {!hideBackground && (
      <>
        <mask
          id="b"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={200}
          height={195}
        >
          <ellipse cx={100} cy={97.5} rx={100} ry={97.5} fill={fill} />
        </mask>
        <g mask="url(#b)">
          <g filter="url(#c)">
            <path
              d="M154.42-35.33 111.3 41.32 80.81 86.69c15.14-.76 4.38-26.15 47.38-15.83 43 10.33-6.9 57.16-37.22 79.29 12.5-1.56 49.66 59.19 136.32-8C248.59 96.22 259.25-57 154.42-35.34Z"
              fill="#FF43CA"
            />
          </g>
          <g filter="url(#d)">
            <path
              d="m85.96 137.76-47.4-45.59-27.05-30.43c-2.5 10.7 18.27 8.36 1.98 36.2-16.29 27.85-40.47-16.4-50.52-42-1.4 9.02-53.43 22.57-21.8 96.54 29.3 24.16 139.39 62.67 144.79-14.72Z"
              fill="#20E4FF"
            />
          </g>
        </g>
      </>
    )}

    <g filter="url(#e)">
      <path
        d="M86.38 148.35a5.1 5.1 0 0 1-4.12-2.08l-20.13-27.6H49v-10.04h15.73a5.1 5.1 0 0 1 4.12 2.08l16.45 22.55 25.1-56.73a5.09 5.09 0 0 1 4.66-3.01h31.38v10.04h-28.06l-27.34 61.78a5.07 5.07 0 0 1-4.66 3.01Z"
        fill={shadowColor}
      />
    </g>
    <path
      d="M86.38 138.84a5.1 5.1 0 0 1-4.12-2.08l-20.13-27.6H49V99.1h15.73a5.1 5.1 0 0 1 4.12 2.08l16.45 22.55L110.4 67a5.09 5.09 0 0 1 4.66-3.01h31.38v10.05h-28.06l-27.34 61.78a5.07 5.07 0 0 1-4.66 3Z"
      fill={color}
    />
    <defs>
      <filter
        id="c"
        x={30.81}
        y={-87.42}
        width={260.46}
        height={311.26}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation={25}
          result="effect1_foregroundBlur_501_19"
        />
      </filter>
      <filter
        id="d"
        x={-119.01}
        y={5.94}
        width={254.97}
        height={227.76}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation={25}
          result="effect1_foregroundBlur_501_19"
        />
      </filter>
      <filter
        id="e"
        x={39}
        y={63.52}
        width={117.44}
        height={94.84}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation={5}
          result="effect1_foregroundBlur_501_19"
        />
      </filter>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(157.62713 199.39946 -8249.5133 6521.31735 23.7 -75.1)"
      >
        <stop stopColor="#18AAEB" />
        <stop offset={0.41} stopColor="#1544F5" />
        <stop offset={0.8} stopColor="#1D2664" />
        <stop offset={1} stopColor="#820B89" />
      </radialGradient>
    </defs>
  </svg>
);

export const GoldScryptoIcon = ({ size = 24 }) => (
  <div
    style={{
      height: size,
      width: size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: Gradients.goldCircleGradient,
      borderRadius: "50%",
    }}
  >
    <ScryptoIcon
      color={Colors.white}
      fill="none"
      shadowColor={Colors.grayBlack}
      size={size}
      hideBackground
    />
  </div>
);

export const USDCIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#usdcStyle)">
      <path
        d="M12 24c6.65 0 12-5.35 12-12S18.65 0 12 0 0 5.35 0 12s5.35 12 12 12Z"
        fill="#2775CA"
      />
      <path
        d="M15.3 13.9c0-1.75-1.05-2.35-3.15-2.6-1.5-.2-1.8-.6-1.8-1.3 0-.7.5-1.15 1.5-1.15.9 0 1.4.3 1.65 1.05.05.15.2.25.35.25h.8c.2 0 .35-.15.35-.35v-.05a2.5 2.5 0 0 0-2.25-2.05V6.5c0-.2-.15-.35-.4-.4h-.75c-.2 0-.35.15-.4.4v1.15c-1.5.2-2.45 1.2-2.45 2.45 0 1.65 1 2.3 3.1 2.55 1.4.25 1.85.55 1.85 1.35s-.7 1.35-1.65 1.35c-1.3 0-1.75-.55-1.9-1.3-.05-.2-.2-.3-.35-.3h-.85c-.2 0-.35.15-.35.35v.05c.2 1.25 1 2.15 2.65 2.4v1.2c0 .2.15.35.4.4h.75c.2 0 .35-.15.4-.4v-1.2c1.5-.25 2.5-1.3 2.5-2.65Z"
        fill="#fff"
      />
      <path
        d="M9.45 19.15A7.45 7.45 0 0 1 5 9.55 7.4 7.4 0 0 1 9.45 5.1c.2-.1.3-.25.3-.5v-.7c0-.2-.1-.35-.3-.4a8.98 8.98 0 0 0-.2 17.2c.2.1.4 0 .45-.2.05-.05.05-.1.05-.2v-.7c0-.15-.15-.35-.3-.45Zm5.3-15.6c-.2-.1-.4 0-.45.2-.05.05-.05.1-.05.2v.7c0 .2.15.4.3.5a7.45 7.45 0 0 1 4.45 9.6 7.4 7.4 0 0 1-4.45 4.45c-.2.1-.3.25-.3.5v.7c0 .2.1.35.3.4.05 0 .15 0 .2-.05a8.98 8.98 0 0 0 5.85-11.3c-.9-2.85-3.1-5-5.85-5.9Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="usdcStyle">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const EthIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#ethIconStyle)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#627EEA"
      />
      <path
        d="M11.9985 3V9.6525L17.4963 12.165L11.9985 3Z"
        fill="white"
        fillOpacity="0.602"
      />
      <path d="M11.9985 3L6.5 12.165L11.9985 9.6525V3Z" fill="white" />
      <path
        d="M11.9985 16.476V20.9963L17.5 13.212L11.9985 16.476Z"
        fill="white"
        fillOpacity="0.602"
      />
      <path
        d="M11.9985 20.9963V16.4752L6.5 13.212L11.9985 20.9963Z"
        fill="white"
      />
      <path
        d="M11.9985 15.4297L17.4963 12.165L11.9985 9.654V15.4297Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M6.5 12.165L11.9985 15.4297V9.654L6.5 12.165Z"
        fill="white"
        fillOpacity="0.602"
      />
    </g>
    <defs>
      <clipPath id="ethIconStyle">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const SolidityIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.45"
      d="M15.3577 1.5L11.9827 7.5H5.23275L8.60775 1.5H15.3577Z"
      fill="white"
    />
    <path
      opacity="0.45"
      d="M8.64 22.5L12.015 16.5H18.765L15.39 22.5H8.64Z"
      fill="white"
    />
    <path
      opacity="0.6"
      d="M11.9812 7.5H18.7312L15.3562 1.5H8.60625L11.9812 7.5Z"
      fill="white"
    />
    <path
      opacity="0.6"
      d="M12.0165 16.5H5.2665L8.6415 22.5H15.3915L12.0165 16.5Z"
      fill="white"
    />
    <path
      opacity="0.8"
      d="M8.60775 13.5L11.9827 7.5L8.60775 1.5L5.23275 7.5L8.60775 13.5Z"
      fill="white"
    />
    <path
      opacity="0.8"
      d="M15.39 10.5L12.015 16.5L15.39 22.5L18.765 16.5L15.39 10.5Z"
      fill="white"
    />
  </svg>
);

export const CoinIcons: { [key: string]: JSX.Element } = {
  XRD: <RadixIcon size={20} />,
  USDC: <USDCIcon size={20} />,
};
