import { StateNonFungibleDetailsResponseItem } from "@radixdlt/radix-dapp-toolkit";

// mutate object for performance
export const parseTxNftData = (data?: StateNonFungibleDetailsResponseItem[]) =>
  data?.map((v) => {
    const nft =
      v.data?.programmatic_json.kind === "Tuple"
        ? v.data.programmatic_json.fields.reduce(
            (acc: Record<string, string | undefined>, curr) => {
              const c = curr as {
                field_name?: string;
                value?: string;
                variant_name?: string;
              };
              if (c.field_name) {
                acc[c.field_name] = c.value || c.variant_name;
              }
              return acc;
            },
            {}
          )
        : {};
    nft.id = v.non_fungible_id.slice(1, -1);
    return nft;
  });
