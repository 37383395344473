import styled from "styled-components";
import { Width } from "../../consts";
import { Div } from "../Common";

export const CardGrid = styled(Div)`
  // Mobile (XS)
  display: grid;
  grid-template-columns: repeat(1, minmax(150px, 1fr));
  grid-gap: 24px;

  // Tablet (SM)
  @media only screen and (min-width: ${Width.SM}px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-gap: 32px;
  }

  // Computer (MD)
  @media only screen and (min-width: ${Width.MD}px) {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }
`;

export const TwoColGrid = styled(Div)`
  // Mobile (XS)
  display: grid;
  grid-template-columns: repeat(1, minmax(150px, 1fr));
  grid-gap: 24px;

  // Tablet (SM)
  @media only screen and (min-width: ${Width.SM}px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-gap: 32px;
  }
`;

export const OneColGrid = styled(Div)`
  display: grid;
  grid-template-columns: repeat(1, minmax(150px, 1fr));
`;
