import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJwt } from "redux/hooks";

export const JwtRedirect = () => {
  const navigate = useNavigate();
  const { state } = useJwt();
  const { isSignedIn } = state;
  const [isIn, setIsIn] = useState(isSignedIn);

  useEffect(() => {
    if (isSignedIn && !isIn) {
      setIsIn(true);
    } else if (!isSignedIn && isIn) {
      navigate("/");
      setIsIn(false);
    }
  }, [isIn, isSignedIn, navigate]);

  return null;
};
