import { FileReqType } from "schema";

const createFile = (componentAddress: string, f: FileReqType) => `
CALL_METHOD
  Address("${componentAddress}")
  "create_file"
  "${f.name}"
  Map<String, String>(
    "image" => "${f.image}",
    "team_type" => "${f.teamType}",
    "category" => "${f.category}",
    "tags" => "${f.tags.join(", ")}",
    "videoIds" => "${f.videoIds ? f.videoIds.join(", ") : ""}",
    "is_active" => "${f.isActive}",
  )
  Array<String>(
    ${f.images.map((v) => `"${v}"`).join(", ")}
  )
  Array<String>(
    ${f.urls.map((v) => `"${v}"`).join(", ")}
  )
;
`;

const updateFile = (componentAddress: string, f: FileReqType, n: number) => `
CALL_METHOD
  Address("${componentAddress}")
  "update_file"
  NonFungibleLocalId("<${n}>")
  "${f.name}"
  Map<String, String>(
    "image" => "${f.image}",
    "team_type" => "${f.teamType}",
    "category" => "${f.category}",
    "tags" => "${f.tags.join(", ")}",
    "videoIds" => "${f.videoIds ? f.videoIds.join(", ") : ""}",
    "is_active" => "${f.isActive}",
  )
  Array<String>(
    ${f.images.map((v) => `"${v}"`).join(", ")}
  )
  Array<String>(
    ${f.urls.map((v) => `"${v}"`).join(", ")}
  )
;
`;

export const FileManifest = {
  createFile,
  updateFile,
};
