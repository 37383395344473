import { z } from "zod";
import { DateTimeSchema, NumberSchema, StringSchema } from "./common-schema";

export const VestingReq = z
  .object({
    memberAddress: StringSchema,

    name: StringSchema,
    description: StringSchema,
    startDate: DateTimeSchema,
    cliffDate: DateTimeSchema.or(z.string().length(0)).optional(),
    endDate: DateTimeSchema,
    vestInterval: NumberSchema.int(),

    resourceAddress: StringSchema,
    amount: NumberSchema,
  })
  .refine(
    (data) => {
      const start = new Date(data.startDate).getTime();
      const end = new Date(data.endDate).getTime();
      return end > start;
    },
    {
      message: "End date must be after start date",
      path: ["endDate"],
    }
  )
  .refine(
    (data) => {
      const start = new Date(data.startDate).getFullYear();
      const end = new Date(data.endDate).getFullYear();
      return end - start <= 5;
    },
    {
      message: "Vesting period must within 5 years",
      path: ["endDate"],
    }
  )
  .refine(
    (data) => {
      const start = new Date(data.startDate).getTime();
      const cliff = data.cliffDate ? new Date(data.cliffDate).getTime() : null;
      const end = new Date(data.endDate).getTime();
      return !cliff || (end > cliff && cliff > start);
    },
    {
      message: "Cliff date must be after start date and before end date",
      path: ["cliffDate"],
    }
  )
  .refine(
    (data) => {
      const intervalInSecs = data.vestInterval * 60 * 60 * 24;
      const start = new Date(data.startDate).getTime();
      const end = new Date(data.endDate).getTime();
      return intervalInSecs < end - start;
    },
    {
      message: "Vest interval (days) must be less than the total vest duration",
      path: ["vestInterval"],
    }
  );

export type VestingReqType = z.infer<typeof VestingReq>;

export const VestingEditReq = z.object({
  name: StringSchema,
  description: StringSchema,
});

export type VestingEditReqType = z.infer<typeof VestingEditReq>;
