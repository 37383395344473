import {
  Flex,
  ProposalStatusDescriptions,
  T2,
  T20,
  UserIconNameHandle,
} from "library/components";
import { formatDate } from "library/utils";
import { ProposalsProjectRes } from "redux/apiTypes";

export const ProjectProposalsRowRender = (r: ProposalsProjectRes[number]) => [
  <Flex alignCenter>
    <UserIconNameHandle
      icon={r.member.icon}
      name={r.member.name}
      memberHandle={r.member.memberHandle}
    />
  </Flex>,
  <T20 ellipsis medium>
    {r.name}
  </T20>,
  <Flex center>{ProposalStatusDescriptions[r.status]}</Flex>,
  formatDate(r.createdAt),
  <T2 ellipsis medium>
    {r.comment || "--"}
  </T2>,
];
