import { Ref } from "react";
import {
  CoinOptions,
  Div,
  Dropdown,
  Flex,
  InputBase,
  PX,
  Span,
  T2,
  T3,
} from "library/components";
import { formatMoney } from "library/utils";
import { Colors } from "library/consts";
import { ChangeHandler } from "react-hook-form";

type CoinInputProps = {
  amount: number;
  price?: number;
  error?: string;
  rProps: {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: Ref<any>;
  };
  tokenSymbol: string;
  readOnly?: boolean;
};

export const CoinInput = ({
  amount = 0,
  price,
  error,
  rProps,
  tokenSymbol,
  readOnly,
}: CoinInputProps) => (
  <Div>
    <Div position="relative">
      <InputBase
        type="number"
        placeholder="0"
        fontSize={PX(24)}
        fontWeight="500"
        padding="8px 100px 40px 12px"
        secondary
        readOnly={readOnly}
        {...rProps}
      />
      <Flex position="absolute" right="4px" top="4px" alignCenter>
        <Dropdown
          options={CoinOptions}
          handleSelect={() => {}}
          selectValue={tokenSymbol || ""}
        />
      </Flex>
      <T3 position="absolute" bottom="12px" left="12px">
        {amount && price ? `~ ${formatMoney(amount * price)}` : "--"}
      </T3>
      <T3 position="absolute" bottom="12px" right="12px">
        {tokenSymbol} Price:{" "}
        <Span medium>{price ? formatMoney(price) : "--"}</Span>
      </T3>
    </Div>

    {error && (
      <T2 color={Colors.red} marginTop="8px" medium>
        {error}
      </T2>
    )}
  </Div>
);
