import { useEffect, useRef, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  BaseButton,
  Button,
  cardBackgroundStyle,
  Div,
  Dropdown,
  Flex,
  Modal,
  OptionButton,
  RadixButton,
  SearchInput,
  SecondaryButton,
  T1,
  T2,
  T3,
  TeamTypeOptionsMedium,
  UserIconNameHandle,
} from "library/components";
import { css } from "styled-components";
import {
  MemberInviteType,
  MemberType,
  TeamRole,
  TeamType,
} from "library/types";
import { Colors, TeamRoleOptions, TransitionConst } from "library/consts";
import { PlusIcon } from "library/assets";
import { searchApi } from "redux/apis";
import { InvitesToAppRes, InvitesToProjectRes } from "redux/apiTypes";

type AddMemberType = MemberType & {
  componentAddress: string | null;
  teamRole: TeamRole;
};

type InviteModalProps = {
  handleSubmit: (
    selected: AddMemberType[],
    teamType: TeamType
  ) => Promise<InvitesToAppRes | InvitesToProjectRes | undefined>;
  hideModal: () => void;
  inviteErrors?: InvitesToAppRes | InvitesToProjectRes;
  isLoading?: boolean;
  members: MemberInviteType[];
  projectName?: string;
  serverError?: string;
  show: boolean;
  teamType: TeamType;
};

export const InviteModal = ({
  handleSubmit,
  hideModal,
  inviteErrors,
  isLoading,
  members: m,
  projectName,
  serverError,
  show,
  teamType: t,
}: InviteModalProps) => {
  const [selected, setSelected] = useState<AddMemberType[]>([]);
  const [teamType, setTeamType] = useState<TeamType>(t);
  const [search, setSearch] = useState("");
  const disabled = selected.length >= 3;

  const overflowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (overflowRef.current && show) {
      overflowRef.current.scrollTop = 0;
      setSelected([]);
      setTeamType(t);
      setSearch("");
    }
  }, [show, t]);

  const {
    data: sm,
    isLoading: isLoadingSearch,
    isFetching: isFetchingSearch,
  } = searchApi.useSearchMembersQuery(search ? { search } : skipToken);

  const members = m.filter((v) => !selected.some((s) => s.id === v.id));
  const searchMembers =
    sm?.filter((v) => !selected.some((s) => s.id === v.id)) || [];
  const teamMembers = projectName
    ? members.filter((m) => m.teams.some((v) => v.teamType === teamType))
    : [];
  const appMembers = !projectName
    ? members.filter((m) => !m.teams.some((v) => v.teamType === teamType))
    : [];

  const handleSelect = (m: MemberType & { componentAddress: string | null }) =>
    selected.some((s) => s.id === m.id)
      ? setSelected(selected.filter((s) => s.id !== m.id))
      : setSelected([...selected, { ...m, teamRole: TeamRole.MEMBER }]);

  const displayedErrors = inviteErrors?.errors.map((err) => {
    const handle = selected.find((s) => s.id === err.memberId)?.memberHandle;
    if (!handle) return null;
    else {
      return (
        <T2 key={handle} color={Colors.red} margin="16px 0 8px" medium>
          {handle}: {err.error}
        </T2>
      );
    }
  });

  return (
    <Modal show={show} closeModal={hideModal}>
      <Flex column fullHeight>
        <Flex gap="12px">
          <SearchInput
            inputProps={{ secondary: true }}
            handleSearch={(s) => setSearch(s)}
            isLoading={isLoadingSearch || isFetchingSearch}
            isReset={show}
            flex="1"
          />
          {show && <RadixButton small />}
        </Flex>

        <Div ref={overflowRef} flex="1" margin="8px 0 16px" overflow="auto">
          {serverError && (
            <T2 color={Colors.red} margin="16px 0 8px" medium>
              {serverError}
            </T2>
          )}
          {displayedErrors?.map((err) => err)}

          {selected.length > 0 && (
            <T2 margin="16px 0 8px" medium secondary>
              Selected
            </T2>
          )}
          {selected.map((m) => (
            <Flex
              key={m.id}
              borderRadius="16px"
              paddingRight="6px"
              css={css`
                transition: ${TransitionConst};
                ${cardBackgroundStyle}
              `}
              alignCenter
            >
              <Button
                flex="1"
                display="flex"
                alignItems="center"
                borderRadius="16px"
                padding="12px"
                textAlign="left"
                fontWeight="500"
                onClick={() => {
                  setSelected(selected.filter((s) => s.id !== m.id));
                }}
              >
                <UserIconNameHandle {...m} />
              </Button>
              <Dropdown
                handleSelect={(r) => {
                  if (r !== m.teamRole) {
                    const newSelected = selected.map((s) =>
                      s.id === m.id ? { ...s, teamRole: r } : s
                    );
                    setSelected(newSelected);
                  }
                }}
                selectValue={m.teamRole}
                options={TeamRoleOptions}
              />
            </Flex>
          ))}

          {!search && projectName && teamMembers.length > 0 && (
            <>
              <T2 margin="16px 0 8px" medium secondary>
                Team Members
              </T2>
              {teamMembers.map((m) => (
                <OptionButton
                  key={m.id}
                  borderRadius="16px"
                  padding="12px"
                  textActive
                  disabled={disabled}
                  onClick={() => {
                    handleSelect(m);
                  }}
                >
                  <UserIconNameHandle {...m} />
                </OptionButton>
              ))}
            </>
          )}

          {!search && appMembers.length > 0 && (
            <>
              <T2 margin="16px 0 8px" medium secondary>
                App Members
              </T2>
              {appMembers.map((m) => (
                <OptionButton
                  key={m.id}
                  borderRadius="16px"
                  padding="12px"
                  textActive
                  disabled={disabled}
                  onClick={() => {
                    handleSelect(m);
                  }}
                >
                  <UserIconNameHandle {...m} />
                </OptionButton>
              ))}
            </>
          )}

          {search && searchMembers.length > 0 && (
            <>
              <T2 margin="16px 0 8px" medium secondary>
                Search Results
              </T2>
              {searchMembers?.map((m) => (
                <OptionButton
                  key={m.id}
                  borderRadius="16px"
                  padding="12px"
                  textActive
                  disabled={disabled}
                  onClick={() => {
                    handleSelect(m);
                  }}
                >
                  <UserIconNameHandle {...m} />
                </OptionButton>
              ))}
            </>
          )}
        </Div>

        {projectName && (
          <Div
            padding="8px 12px"
            borderRadius="12px"
            css={css`
              border: 1px solid ${({ theme }) => theme.borderColor};
            `}
          >
            <T3 marginBottom="4px">Project</T3>
            <T1 ellipsis medium>
              {projectName}
            </T1>
          </Div>
        )}

        {!projectName && (
          <Dropdown
            handleSelect={(t) => setTeamType(t)}
            selectValue={teamType}
            options={TeamTypeOptionsMedium}
            buttonProps={{ showBorder: true }}
            medium
            alignTop
          />
        )}

        <Flex marginTop="16px" gap="16px">
          <SecondaryButton flex="1" onClick={hideModal}>
            Cancel
          </SecondaryButton>
          <BaseButton
            flex="1"
            isLoading={isLoading}
            onClick={async () => {
              const data = await handleSubmit(selected, teamType);
              if (data) {
                setSelected(
                  selected.filter((s) =>
                    data.errors.some((err) => err.memberId === s.id)
                  )
                );
              }
            }}
          >
            <PlusIcon size={16} /> <Div marginLeft="8px">Add Members</Div>
          </BaseButton>
        </Flex>
      </Flex>
    </Modal>
  );
};
