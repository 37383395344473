import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";
import { BasePaths } from "consts";
import { useHideTab } from "hooks";
import { Book, FolderIcon, Home, Rocket, User } from "library/assets";
import { parseBaseRoute } from "utils";
import { Nav, TabLink } from "library/components";
import { FrostedBackgroundStyle } from "library/styles";
import { Width } from "library/consts";
import { useTab } from "redux/hooks";
import { CssPropsType, TabStateEnum } from "library/types";

const Hide = css`
  transform: translateY(50px);
`;

const TabBarContainer = styled(Nav)<CssPropsType & { hide?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  ${FrostedBackgroundStyle}

  will-change: transform;
  transition: transform 300ms;
  ${({ hide }) => hide && Hide}

  // Mobile (SM)
  display: flex;

  // Tablet (MD) & Computer (LG)
  @media only screen and (min-width: ${Width.SM}px) {
    display: none;
  }
`;

const Tab = styled(TabLink)`
  padding: 0;
  flex: 1;
`;

const HideTabBar = () => {
  useHideTab();
  return null;
};

export const TabBar = () => {
  const { pathname } = useLocation();
  const { state } = useTab();
  const { tabState } = state;

  if (document.body.clientWidth >= Width.SM) return null;
  const { home, projects, library, apps, members, signIn } = BasePaths;
  const route = parseBaseRoute(pathname);

  return (
    <TabBarContainer
      height="50px"
      width="100%"
      position="fixed"
      bottom="0"
      zIndex="50"
      padding="0 3vw"
      hide={tabState !== TabStateEnum.VISIBLE}
    >
      <HideTabBar />

      <Tab to={home} $active={pathname === home}>
        <Home />
      </Tab>

      <Tab to={projects} $active={route === projects}>
        <Rocket />
      </Tab>

      <Tab to={library} $active={route === library}>
        <FolderIcon />
      </Tab>

      <Tab to={apps} $active={route === apps || route === members}>
        <Book size={22} />
      </Tab>

      <Tab to={signIn} $active={route === signIn}>
        <User />
      </Tab>
    </TabBarContainer>
  );
};
