import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BaseButton,
  Button,
  Div,
  Dropdown,
  Error,
  Flex,
  Input,
  ProposalStatusOptions,
} from "library/components";
import { css } from "styled-components";
import { ChevronLeft, ChevronRight } from "library/assets";
import {
  IdRes,
  ProposalReviewReq as ProposalReviewReqType,
  ProposalsProjectRes,
} from "redux/apiTypes";
import { ProposalReviewReq } from "schema";
import { ProposalStatus } from "library/types";

const parseData = (data?: ProposalsProjectRes[number]) =>
  data
    ? {
        comment: data.comment || "",
        status: data.status || ProposalStatus.PENDING,
      }
    : {};

type ProposalReviewFormProps = {
  error?: string;
  isLoading?: boolean;
  handleReview: (data: ProposalReviewReqType) => Promise<IdRes | undefined>;
  handleLeft?: () => void;
  handleRight?: () => void;
  data?: ProposalsProjectRes[number];
  authHandle?: string;
};

export const ProposalReviewForm = ({
  error,
  isLoading,
  handleReview,
  handleLeft,
  handleRight,
  data,
  authHandle,
}: ProposalReviewFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProposalReviewReqType>({
    resolver: zodResolver(ProposalReviewReq),
    defaultValues: parseData(data),
  });

  const onSubmit = (d: ProposalReviewReqType) => handleReview(d);

  const [isMember, setIsMember] = useState(false);

  // reset form if input data changes
  useEffect(() => {
    if (data) {
      reset(parseData(data));
      setIsMember(data.member.memberHandle === authHandle);
    }
  }, [authHandle, data, reset]);

  if (isMember) {
    return null;
  }

  const inputs = (
    <>
      <Input
        error={errors.comment?.message}
        rProps={register("comment")}
        iProps={{ placeholder: "Comment..." }}
        wrapperProps={{ flex: "1" }}
      />
      <Div marginLeft="12px">
        <Controller
          control={control}
          name="status"
          render={({ field: { value, onChange } }) => (
            <Div>
              <Dropdown
                handleSelect={onChange}
                selectValue={value}
                options={ProposalStatusOptions}
                wrapperProps={{ width: "100%" }}
                buttonProps={{ showBorder: true, padding: "10px 12px" }}
                alignTop
                hideChevron
              />
              <Error error={errors.status?.message} />
            </Div>
          )}
        />
      </Div>
    </>
  );

  return (
    <Div
      width="100%"
      position="absolute"
      bottom="0"
      left="0"
      css={css`
        background-color: ${({ theme }) => theme.borderColorLight};
      `}
    >
      <Error error={error} margin="0 0 16px" />

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Mobile */}
        <Flex padding="16px 16px 0 16px" alignCenter sm={{ display: "none " }}>
          {inputs}
        </Flex>

        {/* Desktop */}
        <Flex alignCenter>
          <Button
            type="button"
            padding="24px"
            disabled={!handleLeft}
            onClick={handleLeft}
          >
            <ChevronLeft />
          </Button>

          <Flex display="none" alignCenter sm={{ display: "flex", flex: "1" }}>
            {inputs}
          </Flex>

          <Div flex="1" sm={{ display: "none" }} />

          <BaseButton
            marginLeft="12px"
            borderRadius="8px"
            isLoading={isLoading}
          >
            Update
          </BaseButton>
          <Button
            type="button"
            padding="24px"
            disabled={!handleRight}
            onClick={handleRight}
          >
            <ChevronRight />
          </Button>
        </Flex>
      </form>
    </Div>
  );
};
