import { z } from "zod";
import {
  StringSchema,
  TeamRoleEnumZod,
  TeamTypeEnumZod,
  UuidSchema,
} from "./common-schema";

export const InviteAppReq = z.object({
  appHandle: StringSchema,
  memberId: UuidSchema,
  teamType: TeamTypeEnumZod,
  teamRole: TeamRoleEnumZod,
});

export const InviteProjectReq = z.object({
  projectHandle: StringSchema,
  memberId: UuidSchema,
  teamRole: TeamRoleEnumZod,
});
