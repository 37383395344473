import { MemberType, TeamRole, TeamType } from "library/types";
import { AppManifest, FileManifest, MemberManifest } from "radix/manifests";
import { parseAppContract } from "radix/parsers";
import { Blueprints } from "radix/radix-types";
import {
  getEntityDetails,
  getWalletAccount,
  pollEntityDetails,
  pollTxStatus,
} from "radix/radix-util";
import { AuthUserRes } from "redux/apiTypes";
import { FileReqType } from "schema";

export const appCreateCall = async (
  auth: AuthUserRes,
  appName: string,
  appHandle: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, componentAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const components = await pollEntityDetails(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      MemberManifest.createApp(componentAddress, appName, appHandle)
  );
  if (components) {
    const app = components.find(
      (v) =>
        v.details?.type === "Component" &&
        v.details.blueprint_name === Blueprints.AppContract
    );
    if (app?.address) {
      return {
        componentAddress: app.address,
      };
    }
  }
};

export const appInviteCall = async (
  auth: AuthUserRes,
  appComponent: string,
  newAdmins: (MemberType & {
    componentAddress: string | null;
    teamRole: TeamRole;
  })[],
  teamType: TeamType
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, componentAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  // TEAM_LEADs and ADMINs are contract admins
  const teamLeads = newAdmins.filter((v) => v.teamRole === TeamRole.TEAM_LEAD);
  const appAdmins =
    teamType === TeamType.ADMIN
      ? newAdmins.filter((v) => v.teamRole !== TeamRole.TEAM_LEAD)
      : [];
  const leadManifests = teamLeads.map((v) =>
    teamType === TeamType.ADMIN
      ? AppManifest.appAdminInvite(appComponent, v.componentAddress || "", true)
      : AppManifest.adminInvite(appComponent, v.componentAddress || "")
  );
  const appAdminManifests = appAdmins.map((v) =>
    AppManifest.appAdminInvite(appComponent, v.componentAddress || "", false)
  );
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle, true) +
      AppManifest.adminAuth(appComponent, componentAddress) +
      (leadManifests.length ? leadManifests.join(`\n`) : "") +
      (appAdminManifests.length ? appAdminManifests.join(`\n`) : "")
  );
  return txDetails;
};

export const appInviteRemoveCall = async (
  auth: AuthUserRes,
  appComponent: string,
  teamType: TeamType,
  memberAddress: string,
  isRemoveAll: boolean
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, componentAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const manifest =
    memberAddress === componentAddress
      ? AppManifest.adminLeave(appComponent, memberAddress, isRemoveAll)
      : teamType === TeamType.ADMIN
      ? AppManifest.appAdminRemove(appComponent, memberAddress, isRemoveAll)
      : AppManifest.adminRemove(appComponent, memberAddress);
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle, true) +
      AppManifest.adminAuth(appComponent, componentAddress) +
      manifest
  );
  return txDetails;
};

export const appInviteJoinCall = async (
  auth: AuthUserRes,
  appComponent: string
) => {
  const [appDetails] = (await getEntityDetails([appComponent])) || [];
  const contract = appDetails.details
    ? parseAppContract(appDetails.details, appComponent)
    : undefined;

  if (!contract || !auth.componentAddress) {
    return undefined;
  } else if (!contract.admin_invites[auth.componentAddress]) {
    return "COMPLETED";
  }

  const accountAddress = await getWalletAccount();
  const { resourceAddress, componentAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      MemberManifest.joinAdmin(componentAddress, appComponent)
  );
  return txDetails ? "COMPLETED" : undefined;
};

export const createAppFile = async (
  appComponent: string,
  auth: AuthUserRes,
  f: FileReqType
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle, componentAddress } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle, true) +
      AppManifest.adminAuth(appComponent, componentAddress) +
      FileManifest.createFile(appComponent, f)
  );
  return txDetails;
};

export const updateAppFile = async (
  appComponent: string,
  auth: AuthUserRes,
  f: FileReqType,
  n: number
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle, componentAddress } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle, true) +
      AppManifest.adminAuth(appComponent, componentAddress) +
      FileManifest.updateFile(appComponent, f, n)
  );
  return txDetails;
};
