import {
  SearchAppsApiRes,
  SearchAppsApiArg,
  SearchProjectsApiRes,
  SearchProjectsApiArg,
  SearchMembersApiRes,
  SearchMembersApiArg,
  SearchFilesApiRes,
  SearchFilesApiArg,
  SearchTagsApiRes,
  SearchTagsApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchApps: build.query<SearchAppsApiRes, SearchAppsApiArg>({
      query: (queryArg) => ({
        url: `/search/apps`,
        params: {
          sort: queryArg.sort,
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    searchProjects: build.query<SearchProjectsApiRes, SearchProjectsApiArg>({
      query: (queryArg) => ({
        url: `/search/projects`,
        params: {
          appHandle: queryArg.appHandle,
          teamType: queryArg.teamType,
          state: queryArg.state,
          isCancelled: queryArg.isCancelled,
          reviewStatus: queryArg.reviewStatus,
          tokenSymbol: queryArg.tokenSymbol,
          sort: queryArg.sort,
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    searchMembers: build.query<SearchMembersApiRes, SearchMembersApiArg>({
      query: (queryArg) => ({
        url: `/search/members`,
        params: {
          teamType: queryArg.teamType,
          sort: queryArg.sort,
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    searchFiles: build.query<SearchFilesApiRes, SearchFilesApiArg>({
      query: (queryArg) => ({
        url: `/search/files`,
        params: {
          teamType: queryArg.teamType,
          kind: queryArg.kind,
          isActive: queryArg.isActive,
          sort: queryArg.sort,
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    searchTags: build.query<SearchTagsApiRes, SearchTagsApiArg>({
      query: (queryArg) => ({
        url: `/search/tags`,
        params: { search: queryArg.search, type: queryArg["type"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as searchApi };
