import { css } from "styled-components";
import {
  Button,
  Card,
  Div,
  Flex,
  InputBase,
  NoneCard,
  OptionButton,
  T1,
  T2,
} from "library/components";
import { LoadingDots, XIcon } from "library/assets";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { TagSchema } from "schema";
import { Colors, TransitionConst } from "library/consts";
import { useDebounce } from "library/hooks";

type TagInputProps = {
  formError?: string;
  handleChange: (val: string[]) => void;
  handleSearch?: (val: string) => void;
  label?: string;
  values?: string[];
  iProps?: object;
  suggestions?: string[];
  isLoading?: boolean;
};

export const TagInput = ({
  formError,
  handleChange,
  handleSearch,
  label,
  values,
  iProps,
  suggestions,
  isLoading,
}: TagInputProps) => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  const [debounced, setDebounced] = useDebounce(text);
  useEffect(() => {
    if (handleSearch && (debounced.length === 0 || debounced.length >= 3)) {
      handleSearch(debounced);
    }
  }, [debounced, handleSearch]);

  const suggests = values
    ? suggestions?.filter((s) => !values.includes(s))
    : suggestions;

  const handleReset = () => {
    setText("");
    setDebounced("");
    if (error) setError("");
  };

  return (
    <Div>
      {label && (
        <T1 marginBottom="8px" medium>
          {label}
        </T1>
      )}

      <Div position="relative">
        <InputBase
          value={text}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              e.preventDefault();
              const parsed = TagSchema.safeParse(text);
              if (parsed.success === false) {
                const err = parsed.error.issues.find(Boolean);
                setError(err?.message || "");
              } else if (values?.includes(text)) {
                setError("Tag already added");
              } else if (text) {
                handleChange(values ? [...values, text] : [text]);
                handleReset();
              }
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setText(e.target.value)
          }
          maxLength={24}
          disabled={values && values.length > 7}
          padding="8px 48px 8px 8px"
          {...iProps}
        />

        <Div
          height="100%"
          width="48px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          right="0"
          visibility={!text ? "hidden" : undefined}
          opacity={!text ? "0" : "1"}
          transition={TransitionConst}
        >
          {isLoading ? (
            <LoadingDots />
          ) : (
            <Button
              display="flex"
              alignItems="center"
              onClick={() => {
                handleReset();
                if (handleSearch) {
                  handleSearch("");
                }
              }}
            >
              <XIcon />
            </Button>
          )}
        </Div>
      </Div>

      {(error || formError) && (
        <T2 color={Colors.red} margin="8px 0">
          {error || formError}
        </T2>
      )}

      <Div position="relative">
        {text && suggests && suggests.length > 0 && (
          <Card position="absolute" top="8px" width="100%" borderRadius="8px">
            {suggests.map((s) => (
              <OptionButton
                key={s}
                type="button"
                onClick={() => {
                  handleChange(values ? [...values, s] : [s]);
                  handleReset();
                }}
              >
                {s}
              </OptionButton>
            ))}
          </Card>
        )}
      </Div>

      {values && values.length > 0 && (
        <Flex gap="16px" flexWrap="wrap" marginTop="16px">
          {values.map((t) => (
            <Button
              key={t}
              type="button"
              borderRadius="8px"
              css={css`
                border: 1px solid ${({ theme }) => theme.borderColor};
              `}
              onClick={() => handleChange(values.filter((tag) => tag !== t))}
            >
              <T1 marginRight="8px" ellipsis>
                {t}
              </T1>
              <XIcon size={16} />
            </Button>
          ))}
        </Flex>
      )}

      {(!values || (values && values.length === 0)) && <NoneCard />}
    </Div>
  );
};
