import { SortMember } from "redux/apiTypes";

export const SortMemberTypes: { [key in SortMember]: SortMember } = {
  MOST_REWARDS_ALL: "MOST_REWARDS_ALL",
  MOST_REWARDS_YEAR: "MOST_REWARDS_YEAR",
  MOST_MEMBERSHIPS: "MOST_MEMBERSHIPS",
  MOST_PROJECTS: "MOST_PROJECTS",
};

export const SortMemberOptions = [
  { label: "Most Rewards All", value: SortMemberTypes.MOST_REWARDS_ALL },
  { label: "Most Rewards Year", value: SortMemberTypes.MOST_REWARDS_YEAR },
  { label: "Most Memberships", value: SortMemberTypes.MOST_MEMBERSHIPS },
  { label: "Most Projects", value: SortMemberTypes.MOST_PROJECTS },
];

export const MembersTableCols = [
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center", width: "120px" },
];

export const MembersTableHeadings = [
  "Member",
  "Teams",
  "Project Rewards",
  "Vesting Rewards",
  "Total Rewards",
  "Memberships",
  "Projects",
  "Follow",
];
