// Vesting Manifest

const withdraw = (componentAddress: string, accountAddress: string) => `
CALL_METHOD
  Address("${componentAddress}")
  "withdraw"
;
CALL_METHOD
  Address("${accountAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

const leave = (componentAddress: string) => `
CALL_METHOD
  Address("${componentAddress}")
  "leave"
;
`;

const cancellation = (vestingComponent: string, depositAddress: string) => `
CALL_METHOD
  Address("${vestingComponent}")
  "cancellation"
;
CALL_METHOD
  Address("${depositAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

// ----- Manifest -----

export const VestingManifest = {
  withdraw,
  leave,
  cancellation,
};
