import { createGlobalStyle } from "styled-components";

const RadixButtonSM = createGlobalStyle`
  body {
    --radix-connect-button-width: 40px;
  }
`;

export const RadixButton = ({ small }: { small?: boolean }) => (
  <>
    {small && <RadixButtonSM />}
    <radix-connect-button style={{ fontFamily: "inherit", zIndex: 1 }} />
  </>
);
