import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { HttpError } from "library/types";

// rtk query error handling
// https://redux-toolkit.js.org/rtk-query/usage-with-typescript#error-result-example
export const parseRtkError = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  if (!error) {
    return undefined;
  } else if ("status" in error) {
    const data = "data" in error ? (error.data as HttpError) : null;
    // you can access all properties of `FetchBaseQueryError` here
    return data
      ? data.message
      : "error" in error
      ? error.error
      : "Unknown error";
  } else {
    // you can access all properties of `SerializedError` here
    return error.message;
  }
};
