import { skipToken } from "@reduxjs/toolkit/dist/query";
import { getEntityDetails, parseMemberContract } from "radix";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { authApi, memberApi } from "redux/apis";
import { useContract, useJwt } from "redux/hooks";

export const useMember = (isGetContract?: boolean) => {
  const { memberHandle } = useParams();
  const { state: contractState, actions: contractActions } = useContract();
  const { memberContract } = contractState;
  const { resetContract, setMemberContract } = contractActions;

  const { state } = useJwt();
  const { isSignedIn } = state;
  const {
    data: auth,
    error: errorAuth,
    isLoading: isLoadingAuth,
  } = authApi.useAuthGetUserQuery(undefined, {
    skip: !isSignedIn,
  });
  const {
    data: counts,
    error: errorCounts,
    isLoading: isLoadingCounts,
  } = authApi.useAuthGetCountsQuery(undefined, {
    skip: !isSignedIn,
  });
  const {
    data: member,
    error: errorMember,
    isLoading: isLoadingMember,
  } = memberApi.useMemberGetProfileQuery(
    memberHandle ? { memberHandle } : skipToken
  );

  const contract =
    memberContract &&
    member &&
    memberContract.address === member.componentAddress
      ? memberContract
      : undefined;

  useEffect(() => {
    const getContract = async () => {
      if (
        member?.componentAddress &&
        (!contract || contract.IS_RESET) &&
        isGetContract
      ) {
        const res = await getEntityDetails([member.componentAddress]);
        const com = res?.find((v) => v);
        if (!com?.details) return;
        const contract = parseMemberContract(com.details, com.address);
        if (contract) {
          setMemberContract({
            ...contract,
            nfts: com.nonfungibles,
          });
        }
      }
    };
    getContract();
  }, [contract, isGetContract, member?.componentAddress, setMemberContract]);

  return {
    auth,
    contract,
    resetContract: async () => {
      resetContract("member");
    },
    member,
    counts,
    isMember: memberHandle === auth?.memberHandle,
    error: errorAuth || errorMember || errorCounts,
    isLoading: isLoadingAuth || isLoadingMember || isLoadingCounts,
    isSignedIn,
  };
};
