import { Component, ErrorInfo, ReactNode } from "react";
import { Flex } from "./Common";
import { H1 } from "./Text";

// https://reactjs.org/docs/error-boundaries.html
// https://stackoverflow.com/a/71045376

type EBProps = {
  children: ReactNode;
};

type EBState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<EBProps, EBState> {
  public state: EBState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): EBState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex
          flex="1"
          padding="0 16px 290px 16px"
          sm={{ padding: "0 32px 240px 32px" }}
          center
        >
          <H1>Something went wrong</H1>
        </Flex>
      );
    }

    return this.props.children;
  }
}
