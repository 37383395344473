import { createAction } from "@reduxjs/toolkit";
import { ContractTypes } from "library/types";
import {
  AdminContract,
  AppContract,
  MemberContract,
  ProjectContract,
  VestingContract,
} from "radix";
import { AppDispatch } from "redux/store";

export const contractActionConsts = {
  RESET_CONTRACT: "RESET_CONTRACT",
  SET_ADMIN_CONTRACT: "SET_ADMIN_CONTRACT",
  SET_APP_CONTRACT: "SET_APP_CONTRACT",
  SET_MEMBER_CONTRACT: "SET_MEMBER_CONTRACT",
  SET_PROJECT_CONTRACT: "SET_PROJECT_CONTRACT",
  SET_VESTING_CONTRACT: "SET_VESTING_CONTRACT",
};

const resetContract = (c: ContractTypes) => async (dispatch: AppDispatch) =>
  dispatch(createAction<ContractTypes>(contractActionConsts.RESET_CONTRACT)(c));

const setAdminContract = (c: AdminContract) => async (dispatch: AppDispatch) =>
  dispatch(
    createAction<AdminContract>(contractActionConsts.SET_ADMIN_CONTRACT)(c)
  );

const setAppContract = (c: AppContract) => async (dispatch: AppDispatch) =>
  dispatch(createAction<AppContract>(contractActionConsts.SET_APP_CONTRACT)(c));

const setMemberContract =
  (c: MemberContract) => async (dispatch: AppDispatch) =>
    dispatch(
      createAction<MemberContract>(contractActionConsts.SET_MEMBER_CONTRACT)(c)
    );

const setProjectContract =
  (c: ProjectContract) => async (dispatch: AppDispatch) =>
    dispatch(
      createAction<ProjectContract>(contractActionConsts.SET_PROJECT_CONTRACT)(
        c
      )
    );

const setVestingContract =
  (c: VestingContract) => async (dispatch: AppDispatch) =>
    dispatch(
      createAction<VestingContract>(contractActionConsts.SET_VESTING_CONTRACT)(
        c
      )
    );

export const contractActions = {
  resetContract,
  setAdminContract,
  setAppContract,
  setMemberContract,
  setProjectContract,
  setVestingContract,
};
