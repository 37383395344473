import { ReactNode, Ref } from "react";
import { ChangeHandler } from "react-hook-form";
import styled from "styled-components";
import { Colors, TransitionConst } from "library/consts";
import { Div, Flex } from "../Common";
import { T1, T2 } from "../Text";
import { InputBase } from "./Input";
import { BoxShadowSmallStyle } from "library/styles";
import { ChevronDown } from "library/assets";

type InputProps = {
  label?: string;
  error?: string;
  rProps: {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: Ref<any>;
  };
  iProps?: object;
  wrapperProps?: object;
};

export const Input = ({
  label,
  error,
  rProps,
  iProps,
  wrapperProps,
}: InputProps) => (
  <Div {...wrapperProps}>
    {label && (
      <T1 marginBottom="8px" medium>
        {label}
      </T1>
    )}
    <InputBase {...rProps} {...iProps} />
    {error && (
      <T2 color={Colors.red} marginTop="8px" medium>
        {error}
      </T2>
    )}
  </Div>
);

export const CheckboxForm = ({
  label,
  description,
  error,
  rProps,
}: InputProps & { description: string }) => (
  <Div>
    {label && (
      <T1 marginBottom="12px" medium>
        {label}
      </T1>
    )}
    <Flex as="label" gap="8px" userSelect="none" alignCenter>
      <input type="checkbox" {...rProps} />
      <T1>{description}</T1>
    </Flex>
    {error && (
      <T2 color={Colors.red} marginTop="8px" medium>
        {error}
      </T2>
    )}
  </Div>
);

type SelectStyleProps = {
  fontWeight?: string;
  showBorder?: boolean;
};

const SelectBase = styled.select<SelectStyleProps>`
  height: 40px;
  width: 100%;
  padding: 8px 12px;

  font-weight: ${({ fontWeight }) => fontWeight || "500"};

  border-radius: 8px;
  appearance: none; // hide default arrow
  transition: ${TransitionConst};
  ${BoxShadowSmallStyle}
  border: ${({ showBorder, theme }) =>
    showBorder ? `2px solid ${theme.borderColor}` : "none"};
  // &:focus { outline: none; }

  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  &:active {
    background-color: ${({ theme }) => theme.cardBackgroundColorActive};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme }) => theme.cardBackgroundColorActive};
    }
  }
`;

type SelectProps = {
  options: {
    label: ReactNode;
    value: string;
  }[];
};

export const Select = ({
  label,
  error,
  options,
  rProps,
  iProps,
}: InputProps & SelectProps) => (
  <Div>
    {label && (
      <T1 marginBottom="8px" medium>
        {label}
      </T1>
    )}

    <Div position="relative">
      <SelectBase {...rProps} {...iProps}>
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </SelectBase>
      <Flex
        height="100%"
        position="absolute"
        top="0"
        right="0"
        paddingRight="10px"
        css={`
          pointer-events: none;
        `}
        center
      >
        <ChevronDown size={20} />
      </Flex>
    </Div>

    {error && (
      <T2 color={Colors.red} marginTop="8px" medium>
        {error}
      </T2>
    )}
  </Div>
);
