// ----- Vested Amount Util -----

// https://futurestud.io/tutorials/get-number-of-seconds-since-epoch-in-javascript
const getEpoch = (d: Date) => {
  // const utcMillisecond = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
  return Math.floor(d.getTime() / 1000);
};
const SEC_IN_DAY = 60 * 60 * 24;

type VestingType = {
  start_epoch: number;
  cliff_epoch: number | undefined;
  end_epoch: number;
  amount: number;
  vest_interval: number;
};

// Matches vesting_schedule.rs
export const getVestedAmount = (vesting: VestingType, d?: Date) => {
  const { start_epoch, cliff_epoch, end_epoch, amount, vest_interval } =
    vesting;
  const curr_epoch = getEpoch(d || new Date());
  const cutoff_epoch = cliff_epoch || start_epoch;
  if (curr_epoch >= end_epoch) {
    return amount;
  }
  if (curr_epoch <= cutoff_epoch) {
    return 0;
  }

  const interval = vest_interval * SEC_IN_DAY;
  const elapsed_time = curr_epoch - start_epoch;
  const elapsedIntervals = Math.floor(elapsed_time / interval);

  const vest_time = end_epoch - start_epoch;
  const vest_per_interval = (amount / vest_time) * interval;

  const total_vested = vest_per_interval * elapsedIntervals;

  return total_vested;
};
