import { useCallback, useMemo } from "react";
import { ThemeEnum, ThemeOptionType } from "library/types";
import { themeActions } from "redux/actions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "redux/store";

export const useTheme = () => {
  const state = useAppSelector((state) => state.themeReducer);
  const dispatch = useAppDispatch();
  const actions = useMemo(
    () => bindActionCreators(themeActions, dispatch),
    [dispatch]
  );

  const { theme } = state;
  const { setTheme } = actions;

  const isDarkSystem = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const systemTheme = isDarkSystem ? ThemeEnum.dark : ThemeEnum.light;

  const setThemeCallback = useCallback(
    (newTheme: ThemeOptionType) => {
      // removes style attribute on body, set once on load in public/theme.js, used to remove fouc
      if (document.body.style) {
        document.body.removeAttribute("style");
      }

      if (newTheme) {
        localStorage.setItem("theme", newTheme);
      } else {
        localStorage.removeItem("theme");
      }
      setTheme(newTheme);
    },
    [setTheme]
  );

  return {
    state: { ...state, currentTheme: theme || systemTheme },
    actions: { ...actions, setTheme: setThemeCallback },
  };
};
