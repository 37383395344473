import { Link } from "react-router-dom";
import { Div, T3 } from "library/components";
import { BasePaths } from "library/consts";
import { Card } from "./Card";
import { MemberTasksList } from "../TasksList";
import { MemberTaskType, MemberType } from "library/types";
import { IdRes } from "redux/apiTypes";

type TaskCardProps = {
  disabled?: boolean;
  handleTask: (
    taskId: string,
    isCompleted: boolean
  ) => Promise<IdRes | undefined>;
  memberHandle?: string;
  objectiveHandle: string | number;
  objectiveName: string;
  projectHandle: string;
  projectName: string;
  tasks: MemberTaskType[];
  members: MemberType[];
};

// needs state for backIdx, not navigating to page with new NavBar
export const TaskCard = ({
  disabled,
  handleTask,
  memberHandle,
  objectiveHandle,
  objectiveName,
  projectHandle,
  projectName,
  tasks,
  members,
}: TaskCardProps) => (
  <Card zIndex="1" borderRadius="12px">
    <Div margin="16px 20px">
      <Link
        to={`/${BasePaths.m}/${memberHandle}/${BasePaths.d}/${projectHandle}/${objectiveHandle}`}
      >
        <T3 marginBottom="4px" secondary>
          {projectName}
        </T3>
        <T3 medium>{objectiveName}</T3>
      </Link>
    </Div>

    <Div margin="12px">
      <MemberTasksList
        tasks={tasks}
        members={members}
        disabled={disabled}
        handleTask={handleTask}
      />
    </Div>
  </Card>
);
