import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { jwtActionConsts } from "redux/actions";

const { SET_IS_SIGNED_IN } = jwtActionConsts;

type JwtReducerType = {
  isSignedIn: boolean;
};

const JwtReducerState: JwtReducerType = {
  isSignedIn: Boolean(localStorage.getItem("jwt")),
};

export const jwtReducer = createReducer(JwtReducerState, (builder) => {
  builder.addCase(
    SET_IS_SIGNED_IN,
    (state, { payload }: PayloadAction<boolean>) => {
      state.isSignedIn = payload;
    }
  );
});
