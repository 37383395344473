import { XRD_ADDRESS } from "consts";
import { MemberType, TeamRole } from "library/types";
import { MemberManifest, ProjectManifest } from "radix/manifests";
import { parseProjectContract } from "radix/parsers";
import {
  getEntityDetails,
  getWalletAccount,
  pollTxStatus,
} from "radix/radix-util";
import { AuthUserRes } from "redux/apiTypes";
import { ContractReqType } from "schema";

export const projectInviteCall = async (
  auth: AuthUserRes,
  projectComponent: string,
  newMembers: (MemberType & {
    componentAddress: string | null;
    teamRole: TeamRole;
  })[]
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const manifests = newMembers.map((v) =>
    ProjectManifest.invite(projectComponent, v.componentAddress || "")
  );
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      manifests.join(`\n`)
  );
  return txDetails;
};

export const projectInviteRemoveCall = async (
  auth: AuthUserRes,
  projectComponent: string,
  memberAddress: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle, componentAddress } = auth;
  if (!accountAddress) {
    return;
  }
  const isMember = memberAddress === componentAddress;
  const manifest = isMember
    ? ProjectManifest.leave(projectComponent, memberAddress)
    : ProjectManifest.removeMember(projectComponent, memberAddress);
  const txDetails = await pollTxStatus(
    MemberManifest.proof(
      accountAddress,
      resourceAddress,
      memberHandle,
      isMember
    ) + manifest
  );
  return txDetails;
};

export const projectInviteJoinCall = async (
  auth: AuthUserRes,
  projectComponent: string
) => {
  const [projectDetails] = (await getEntityDetails([projectComponent])) || [];
  const contract = projectDetails.details
    ? parseProjectContract(projectDetails.details, projectComponent)
    : undefined;

  if (!contract || !auth.componentAddress) {
    return undefined;
  } else if (contract.signatures.includes(auth.componentAddress)) {
    return "COMPLETED";
  }

  const accountAddress = await getWalletAccount();
  const { resourceAddress, componentAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      MemberManifest.joinProject(
        componentAddress,
        projectComponent,
        accountAddress
      )
  );
  return txDetails ? "COMPLETED" : undefined;
};

export const projectAdminJoinCall = async (
  auth: AuthUserRes,
  projectComponent: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, componentAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      ProjectManifest.invite(projectComponent, componentAddress) +
      MemberManifest.joinProject(
        componentAddress,
        projectComponent,
        accountAddress
      )
  );
  return txDetails;
};

export const projectUpdateCall = async (
  auth: AuthUserRes,
  projectComponent: string,
  deposit: number,
  update: ContractReqType
) => {
  const isDeposit = Boolean(deposit > 0);
  const withdrawAccount = isDeposit ? await getWalletAccount(true) : undefined;
  const accountAddress = await getWalletAccount(isDeposit);
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress || (isDeposit && !withdrawAccount)) {
    return;
  }
  const depositManifest =
    isDeposit && withdrawAccount
      ? ProjectManifest.depositFunds(
          projectComponent,
          withdrawAccount,
          XRD_ADDRESS,
          deposit
        )
      : "";

  const isEmpty = update.objectives.every((v) => v.distributions.length === 0);
  const updateManifest =
    update.objectives.length > 0 && !isEmpty
      ? ProjectManifest.update(projectComponent, update.objectives)
      : "";
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      depositManifest +
      updateManifest
  );
  return txDetails;
};

export const projectRewardCall = async (
  auth: AuthUserRes,
  projectComponent: string,
  objNum: number
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      ProjectManifest.reward_obj(projectComponent, objNum)
  );
  return txDetails;
};

export const projectWithdrawCall = async (
  auth: AuthUserRes,
  projectComponent: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, componentAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle, true) +
      ProjectManifest.withdraw(
        projectComponent,
        componentAddress,
        accountAddress
      )
  );
  return txDetails;
};

export const projectCancelCall = async (
  auth: AuthUserRes,
  projectComponent: string
) => {
  const depositAccount = await getWalletAccount(true);
  const accountAddress = await getWalletAccount(true);
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress || !depositAccount) {
    return;
  }
  const txDetails = await pollTxStatus(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      ProjectManifest.cancellation(projectComponent, depositAccount)
  );
  return txDetails;
};
