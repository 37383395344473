import { css } from "styled-components";
import {
  Button,
  Div,
  Flex,
  Img,
  InputBase,
  NoneCard,
  T1,
  T2,
} from "library/components";
import { XIcon } from "library/assets";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { getYoutubeImg } from "utils";
import { Colors } from "library/consts";
import { AlphaNumericSchema, UrlSchema } from "schema";

type ImageInputProps = {
  formError?: string;
  handleChange: (val: string[]) => void;
  isVideoId?: boolean;
  hidePreview?: boolean;
  label?: string;
  values?: string[];
  iProps?: object;
};

export const ImageInput = ({
  formError,
  handleChange,
  isVideoId,
  hidePreview,
  label,
  values,
  iProps,
}: ImageInputProps) => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  return (
    <Div>
      {label && (
        <T1 marginBottom="8px" medium>
          {label}
        </T1>
      )}

      <InputBase
        value={text}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            const schema = isVideoId
              ? AlphaNumericSchema.length(11)
              : UrlSchema;
            const parsed = schema.safeParse(text);
            if (parsed.success === false) {
              const err = parsed.error.issues.find(Boolean);
              setError(err?.message || "");
            } else if (values?.includes(text)) {
              setError("Already added");
            } else if (text) {
              handleChange(values ? [...values, text] : [text]);
              setText("");
              if (error) setError("");
            }
          }
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
        maxLength={160}
        disabled={values && values.length > 7}
        {...iProps}
      />

      {(error || formError) && (
        <T2 color={Colors.red} margin="8px 0">
          {error || formError}
        </T2>
      )}

      {values && values.length > 0 && (
        <Flex gap="8px" marginTop="16px" column>
          {values.map((v) => (
            <Button
              key={v}
              type="button"
              width="100%"
              borderRadius="8px"
              gap="16px"
              textAlign="left"
              css={css`
                border: 1px solid ${({ theme }) => theme.borderColor};
              `}
              onClick={() => handleChange(values.filter((val) => val !== v))}
            >
              {!hidePreview && (
                <Img
                  height="64px"
                  width="64px"
                  borderRadius="4px"
                  src={isVideoId ? getYoutubeImg(v) : v}
                  alt={isVideoId ? `VideoId: ${v}` : v}
                />
              )}
              <T1 flex="1" marginRight="8px" ellipsis>
                {v}
              </T1>
              <XIcon size={16} />
            </Button>
          ))}
        </Flex>
      )}

      {(!values || (values && values.length === 0)) && <NoneCard />}
    </Div>
  );
};
