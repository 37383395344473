import { StateValue, ValueKind } from "../radix-types";

// MUTATING REDUCE OBJECT FOR PERFORMANCE
// https://medium.com/@kazazo/js-reduce-spread-vs-mutation-who-wins-779dee0b65cf

export const parseHashMap = (data: StateValue) =>
  data.kind === ValueKind.Map
    ? data.entries.reduce((acc: Record<string, string>, { key, value }) => {
        if (
          key.kind !== ValueKind.Bool &&
          key.value &&
          value.kind !== ValueKind.Bool &&
          value.value
        ) {
          acc[key.value] = value.value;
        }
        return acc;
      }, {})
    : {};

export const parseArray = (data: StateValue) =>
  data.kind === ValueKind.Array
    ? data.elements.map((v) => (v.value || "").toString())
    : [];

export const parseTuple = (data: StateValue) =>
  data.kind === ValueKind.Tuple
    ? data.fields.reduce(
        (acc: Record<string, StateValue | undefined>, curr) => {
          if (curr.field_name) {
            acc[curr.field_name] = curr;
          }
          return acc;
        },
        {}
      )
    : {};

// ---

export type ObjectiveData = Record<string, Record<string, number>>;

export const parseObjectives = (data: StateValue) =>
  data.kind === ValueKind.Map
    ? data.entries.reduce((acc: ObjectiveData, { key, value }) => {
        if (key.kind === ValueKind.Decimal && value.kind === ValueKind.Map) {
          acc[key.value] = value.entries.reduce(
            (acc: Record<string, number>, { key, value }) => {
              acc[`${key.value}`] = Number(value.value);
              return acc;
            },
            {}
          );
        }
        return acc;
      }, {})
    : {};
