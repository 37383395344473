import { Flex, Img, T3, TX } from "library/components";
import { css } from "styled-components";
import { Bookmark, CheckmarkThin } from "library/assets";
import { BoxShadowSmallStyle } from "library/styles";
import { Colors } from "library/consts";

export const BookmarkButton = ({
  color,
  saved,
}: {
  color?: string;
  saved?: boolean;
}) => (
  <Flex
    position="absolute"
    top="0"
    right="0"
    zIndex="4"
    padding="20px"
    color={color}
    css={css`
      svg {
        filter: ${color && "drop-shadow(0 2px 3px rgb(0 0 20 / 0.4))"};
      }
    `}
  >
    <Bookmark fill={saved ? "currentColor" : "none"} />
  </Flex>
);

export const BookmarkRowButton = ({ saved }: { saved?: boolean }) => (
  <Flex center fullHeight fullWidth>
    <Bookmark fill={saved ? "currentColor" : "none"} />
  </Flex>
);

const getInitials = (name: string) =>
  name
    .split(" ")
    .map((n) => n.charAt(0).toUpperCase())
    .join("");

type UserIconProps = {
  id?: string;
  icon?: string | null;
  name: string;
  marginLeft?: string;
  marginRight?: string;
  height?: string;
  width?: string;
};

export const UserIcon = ({ icon, name, ...rest }: UserIconProps) =>
  icon ? (
    <Img
      height="24px"
      width="24px"
      minWidth="24px"
      borderRadius="50%"
      backgroundColor={Colors.border}
      {...rest}
      src={icon}
      alt={name}
    />
  ) : (
    <Flex
      height="24px"
      width="24px"
      minWidth="24px"
      borderRadius="50%"
      overflow="hidden"
      fontWeight="500"
      color={Colors.blueBlack}
      css={css`
        background-color: ${({ theme }) =>
          theme._isDark ? Colors.background : Colors.white};
        border: ${({ theme }) =>
          !theme._isDark && `1px solid ${Colors.borderLight}`};
        ${BoxShadowSmallStyle}
      `}
      center
      {...rest}
    >
      <TX fontSize="10px">{getInitials(name)}</TX>
    </Flex>
  );

type UserIconsProps = {
  members: UserIconProps[];
};

export const UserIcons = ({ members }: UserIconsProps) => (
  <Flex overflow="auto" gap="4px" alignCenter>
    {members.map((m, i) => (
      <UserIcon key={m.id || i} icon={m.icon} name={m.name} />
    ))}
  </Flex>
);

type CardCheckmarksProps = {
  count: number;
  total: number;
  justifyCenter?: boolean;
};

export const CardCheckmarks = ({
  count,
  total,
  ...rest
}: CardCheckmarksProps) => (
  <Flex margin="0 16px" alignCenter {...rest}>
    <Flex
      height="15px"
      width="15px"
      backgroundColor={
        count && count === total ? Colors.greenDark : Colors.gray
      }
      borderRadius="5px"
      center
    >
      {count > 0 && count === total && (
        <CheckmarkThin size={10} strokeWidth={5} color={Colors.white} />
      )}
    </Flex>
    <T3 marginLeft="4px" medium>
      {`${count}/${total}`}
    </T3>
  </Flex>
);
