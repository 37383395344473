import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import styled from "styled-components";
import {
  BaseButton,
  Card,
  CardGrid,
  CoinInput,
  Div,
  Error,
  Flex,
  H2,
  H4,
  NoneCard,
  RadixButton,
  T3,
} from "library/components";
import { Width } from "library/consts";
import { ContractReq, ContractReqType } from "schema";
import { Objectives } from "./Objectives";
import {
  HomePricesRes,
  ProjectFinancialsRes,
  ProjectMembersRes,
} from "redux/apiTypes";
import { ProjectContract } from "radix";
import { parseProjectValues } from "./FinancialsUtil";
import { useEffect, useMemo, useState } from "react";
import { awaitTo } from "library/utils";

const SideContent = styled(Div)`
  grid-column-start: 1;
  grid-column-end: 2;

  @media only screen and (min-width: ${Width.SM}px) {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  @media only screen and (min-width: ${Width.MD}px) {
    grid-column-end: 4;
  }
`;

type ProjectFinancialsFormProps = {
  isAdmin: boolean;
  isMember: boolean;
  adminAddress: string | undefined;
  contract: ProjectContract;
  data: ProjectFinancialsRes;
  members: ProjectMembersRes;
  prices?: HomePricesRes;
  handleUpdate: (deposit: number, update: ContractReqType) => Promise<boolean>;
  handleReward: (objNum: number) => Promise<boolean>;
  handleWithdraw: () => Promise<boolean>;
  rewardNum?: number;
  isLoadingUpdate: boolean;
  isLoadingWithdraw: boolean;
  error?: string;
};

export const ProjectFinancialsForm = ({
  isAdmin,
  isMember,
  adminAddress,
  contract,
  data,
  members,
  prices,
  handleUpdate,
  handleReward,
  handleWithdraw,
  rewardNum,
  isLoadingUpdate,
  isLoadingWithdraw,
  error,
}: ProjectFinancialsFormProps) => {
  const ogTotal = contract.funds + contract.paid;
  const defaultValues = useMemo(
    () => parseProjectValues(adminAddress, contract, data, members),
    [adminAddress, contract, data, members]
  );

  const [readOnly, setReadOnly] = useState(Boolean(contract.funds));
  const [formError, setError] = useState("");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<ContractReqType>({
    resolver: zodResolver(ContractReq),
  });
  const onSubmit = async (d: ContractReqType) => {
    setError("");
    if (d.amount < ogTotal) {
      setError(`Amount must be greater than ${ogTotal}`);
      return;
    }
    const [, isSuccess] = await awaitTo(handleUpdate(d.amount - ogTotal, d));
    if (isSuccess) {
      setReadOnly(true);
    }
  };

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const price = prices ? Number(prices.XRD) : undefined;
  const amount = watch("amount");
  const isDeposit = Boolean(amount - ogTotal);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardGrid marginTop="64px" marginBottom="32px">
        <Div>
          <Card padding="24px" borderRadius="12px" overflow="visible">
            <H2 marginBottom="32px">Total Budget</H2>
            <Flex gap="16px" column>
              <CoinInput
                amount={amount || 0}
                price={price}
                tokenSymbol={data?.tokenSymbol || ""}
                error={errors.amount?.message}
                readOnly={readOnly}
                rProps={register("amount", { valueAsNumber: true })}
              />
            </Flex>
          </Card>
        </Div>

        {(isAdmin || isMember) && (
          <SideContent
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap="16px"
          >
            <Card
              padding="24px"
              borderRadius="12px"
              overflow="visible"
              width="100%"
              md={{ maxWidth: "320px" }}
            >
              <Flex width="100%" gap="12px">
                <Flex width="100%" gap="12px" column>
                  {isAdmin && !readOnly && (
                    <>
                      <BaseButton isLoading={isLoadingUpdate}>
                        Update Contract
                      </BaseButton>
                      {!isLoadingUpdate && contract.funds > 0 && (
                        <BaseButton
                          onClick={() => {
                            setReadOnly(true);
                            reset(defaultValues);
                          }}
                          type="button"
                          isSecondary
                        >
                          Cancel
                        </BaseButton>
                      )}
                      {isLoadingUpdate && isDeposit && (
                        <T3>
                          * Wallet will first request the withdraw account, then
                          the badge account
                        </T3>
                      )}
                    </>
                  )}
                  {isAdmin && readOnly && !contract.is_cancelled && (
                    <BaseButton
                      type="button"
                      width="100%"
                      onClick={() => setReadOnly(false)}
                    >
                      Edit Contract
                    </BaseButton>
                  )}
                  {isMember && (readOnly || contract.is_cancelled) && (
                    <BaseButton
                      type="button"
                      width="100%"
                      onClick={() => handleWithdraw()}
                      isLoading={isLoadingWithdraw}
                    >
                      Withdraw Rewards
                    </BaseButton>
                  )}
                  <Error marginTop="24px" error={formError || error} />
                </Flex>
                <RadixButton small />
              </Flex>
            </Card>
          </SideContent>
        )}
      </CardGrid>

      <H4 marginTop="32px" marginBottom="16px" bold>
        Rewards
      </H4>
      {data?.objectives.length === 0 ? (
        <NoneCard label="No Objectives" large />
      ) : (
        <CardGrid>
          <Objectives
            control={control}
            register={register}
            watch={watch}
            errors={errors}
            readOnly={readOnly}
            handleReward={handleReward}
            rewardNum={rewardNum}
          />
        </CardGrid>
      )}
    </form>
  );
};
