import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Modal } from "library/components";
import { appApi, projectApi, searchApi } from "redux/apis";
import { awaitTo, parseRtkError } from "library/utils";
import { useNavigate } from "react-router-dom";
import { BasePaths, XRD_ADDRESS } from "consts";
import { ProjectSettingsForm } from "views/pages/Project";
import { useState } from "react";
import { Category } from "library/types";
import { AppContract, adminCreateProjectCall, useRadixCall } from "radix";
import { AuthUserRes } from "redux/apiTypes";

type ProjectCreateModalProps = {
  appCategory: Category;
  appHandle: string;
  auth: AuthUserRes;
  contract: AppContract | undefined;
  hideModal: () => void;
  show: boolean;
};

export const ProjectCreateModal = ({
  appCategory,
  appHandle,
  auth,
  contract,
  hideModal,
  show,
}: ProjectCreateModalProps) => {
  const navigate = useNavigate();
  const [handleCreate, { isLoading: isLoadingProject, error: projectError }] =
    projectApi.useProjectCreateMutation();
  const {
    refetch: refetchProjects,
    isFetching: isFetchingProjects,
    error: projectsError,
  } = appApi.useAppGetProjectsQuery(
    appHandle ? { pathAppHandle: appHandle } : skipToken
  );

  const [search, setSearch] = useState("");
  const { data: suggestions, isFetching: isLoadingTags } =
    searchApi.useSearchTagsQuery(
      search.length >= 3 ? { search, type: "PROJECT" } : skipToken
    );

  const [contractCall, { error, isLoading }] = useRadixCall(
    adminCreateProjectCall
  );

  return (
    <Modal show={show} closeModal={hideModal} large>
      <ProjectSettingsForm
        appCategory={appCategory}
        handleCreate={async (d) => {
          if (!contract) {
            return;
          }
          // Get admin component from componentAddress
          // Record<adminAddress, componentAddress>
          const [adminComponent] =
            Object.entries(contract.admin_components).find(
              ([_, v]) => v === auth.componentAddress
            ) || [];
          if (!adminComponent) {
            return;
          }
          const fundsResource = XRD_ADDRESS;
          const contractRes = await contractCall(
            auth,
            adminComponent,
            fundsResource
          );
          if (!contractRes) {
            return;
          }
          const [err, project] = await awaitTo(
            handleCreate({
              appHandle,
              projectReq: {
                ...d,
                componentAddress: contractRes.componentAddress,
              },
            }).unwrap()
          );
          if (!err && project) {
            // refresh projects list
            refetchProjects();
            hideModal();
            navigate(`/${BasePaths.p}/${project.projectHandle}`);
          }
        }}
        handleSearch={(v) => setSearch(v)}
        error={parseRtkError(projectError || projectsError) || error}
        isLoading={isLoadingProject || isFetchingProjects || isLoading}
        isLoadingTags={isLoadingTags}
        suggestions={suggestions}
      />
    </Modal>
  );
};
