import { z } from "zod";
import {
  DateTimeSchema,
  ProjectStateEnumZod,
  TagSchema,
  TeamTypeEnumZod,
  DefaultProfileReqSchema,
  UrlSchema,
  BooleanSchema,
  ReviewStatusEnumZod,
  StringSchema,
  SymbolSchema,
  NumberSchema,
  CategoryEnumZod,
  LongNameSchema,
} from "./common-schema";

export const ProjectReq = z
  .object({
    name: LongNameSchema,
    image: UrlSchema,
    teamType: TeamTypeEnumZod,
    category: CategoryEnumZod,
    tags: z
      .array(TagSchema)
      .min(3, "Minimum of 3 tags")
      .max(5, "Maximum of 5 tags"),
    isHidden: BooleanSchema.optional(),

    state: ProjectStateEnumZod,
    startDate: DateTimeSchema,
    endDate: DateTimeSchema,
    reviewStatus: ReviewStatusEnumZod,

    projectProfile: DefaultProfileReqSchema,

    componentAddress: StringSchema,
    tokenSymbol: SymbolSchema,
    amount: NumberSchema,
  })
  .refine(
    (data) => {
      const start = new Date(data.startDate).getTime();
      const end = new Date(data.endDate).getTime();
      return end > start;
    },
    {
      message: "End date must be after start date",
      path: ["endDate"],
    }
  );
