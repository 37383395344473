import { AdminManifest, MemberManifest } from "radix/manifests";
import { parseVestingContract } from "radix/parsers";
import { Blueprints } from "radix/radix-types";
import { getWalletAccount, pollEntityDetails } from "radix/radix-util";
import { AuthUserRes } from "redux/apiTypes";
import { VestingReqType } from "schema";

export const adminCreateProjectCall = async (
  auth: AuthUserRes,
  adminComponent: string,
  fundsResource: string
) => {
  const accountAddress = await getWalletAccount();
  const { resourceAddress, memberHandle } = auth;
  if (!accountAddress) {
    return;
  }
  const components = await pollEntityDetails(
    MemberManifest.proof(accountAddress, resourceAddress, memberHandle) +
      AdminManifest.createProject(adminComponent, fundsResource, accountAddress)
  );
  if (components) {
    const project = components.find(
      (v) =>
        v.details?.type === "Component" &&
        v.details.blueprint_name === Blueprints.ProjectContract
    );
    if (project?.address) {
      return {
        componentAddress: project.address,
      };
    }
  }
};

export const adminCreateVestingCall = async (
  auth: AuthUserRes,
  adminComponent: string,
  memberComponent: string,
  vesting: VestingReqType
) => {
  const withdrawAccount = await getWalletAccount(true);
  const badgeAccount = await getWalletAccount(true);
  if (!withdrawAccount || !badgeAccount) {
    return;
  }
  const { resourceAddress, memberHandle } = auth;
  const components = await pollEntityDetails(
    MemberManifest.proof(badgeAccount, resourceAddress, memberHandle) +
      AdminManifest.createVesting(
        adminComponent,
        memberComponent,
        vesting,
        withdrawAccount,
        badgeAccount
      )
  );
  if (components) {
    const vesting = components.find(
      (v) =>
        v.details?.type === "Component" &&
        v.details.blueprint_name === Blueprints.VestingContract
    );
    if (vesting?.address) {
      return vesting.details
        ? parseVestingContract(vesting.details, vesting.address)
        : undefined;
    }
  }
};
