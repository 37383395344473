export const TxTypeDescriptions: Record<string, string | undefined> = {
  Create: "🛠️ \xa0 Create",
  Deposit: "🏛️ \xa0 Deposit",
  Invite: "📥 \xa0 Invite",
  RemoveMember: "👋 \xa0 Remove Member",
  Leave: "🚪 \xa0 Leave",
  Update: "💾 \xa0 Update",
  Join: "🤝 \xa0 Join",
  Reward: "💎 \xa0 Reward",
  Withdraw: "💰 \xa0 Withdraw",
  Cancellation: "❌ \xa0 Cancellation",
};
