import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Modal } from "library/components";
import { appApi, fileApi, searchApi } from "redux/apis";
import { awaitTo, parseRtkError } from "library/utils";
import { useNavigate } from "react-router-dom";
import { BasePaths, FileMetadata } from "consts";
import { useState } from "react";
import { Category } from "library/types";
import { AppContract, createAppFile, useRadixCall } from "radix";
import { AuthUserRes } from "redux/apiTypes";
import { FileSettingsForm } from "views/pages/File";

type FileAppCreateModalProps = {
  appHandle?: string;
  appCategory: Category;
  auth: AuthUserRes;
  contract: AppContract | undefined;
  hideModal: () => void;
  show: boolean;
};

export const FileAppCreateModal = ({
  appHandle,
  appCategory,
  auth,
  contract,
  hideModal,
  show,
}: FileAppCreateModalProps) => {
  const navigate = useNavigate();
  const [handleCreate, { isLoading: isLoadingA, error: errorA }] =
    fileApi.useFilesAppCreateMutation();

  const {
    refetch,
    isFetching,
    error: errorF,
  } = appApi.useAppGetFilesQuery(
    appHandle ? { pathAppHandle: appHandle } : skipToken
  );

  const [search, setSearch] = useState("");
  const { data: suggestions, isFetching: isLoadingTags } =
    searchApi.useSearchTagsQuery(
      search.length >= 3 ? { search, type: "FILE" } : skipToken
    );

  const [createCall, { isLoading: isLoadingC, error: errorC }] =
    useRadixCall(createAppFile);

  return (
    <Modal show={show} closeModal={hideModal} large>
      <FileSettingsForm
        creatorHandle={appHandle}
        appCategory={appCategory}
        handleCreate={async (d) => {
          if (!contract || !appHandle) {
            return;
          }
          const nfts = contract?.nfts.find(
            (v) => v.metadata?.["name"] === FileMetadata.app
          );
          const number = (nfts?.total || 0) + 1;

          const contractRes = await createCall(contract.address, auth, d);
          if (!contractRes) {
            return;
          }

          const [err, res] = await awaitTo(
            handleCreate({
              appHandle,
              fileNumberReq: { number, description: d.description },
            }).unwrap()
          );

          if (!err && res) {
            // refresh list
            refetch();
            hideModal();
            navigate(`/${BasePaths.f}/${res.fileHandle}`);
          }
        }}
        handleSearch={(v) => setSearch(v)}
        error={parseRtkError(errorA || errorF) || errorC}
        isLoading={isLoadingA || isFetching || isLoadingC}
        isLoadingTags={isLoadingTags}
        suggestions={suggestions}
      />
    </Modal>
  );
};
