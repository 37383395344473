import {
  TaskUpdateApiRes,
  TaskUpdateApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    taskUpdate: build.mutation<TaskUpdateApiRes, TaskUpdateApiArg>({
      query: (queryArg) => ({
        url: `/task/${queryArg.taskId}`,
        method: "PATCH",
        body: queryArg.taskUpdateReq,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as taskApi };
