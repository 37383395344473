import { ProjectTxsTableCols, ProjectTxsTableHeadings } from "consts";
import { BaseButton, Card, DataTable, Flex } from "library/components";
import { ProjectContract, getNftData, parseTxNftData } from "radix";
import { useCallback, useEffect, useState } from "react";
import { ProjectTransactionsRowRender } from "./ProjectFinancialsRows";
import { ProjectMembersRes } from "redux/apiTypes";

type ProjectTxsProps = {
  contract: ProjectContract;
  members: ProjectMembersRes;
};

export const ProjectTxs = ({ contract, members }: ProjectTxsProps) => {
  const [count, setCount] = useState<number>();
  const [txs, setTxs] = useState<Record<string, string | undefined>[]>();
  const [isLoading, setIsLoading] = useState(false);

  const getTxs = useCallback(
    async (s?: number) => {
      setIsLoading(true);
      const start = s || contract.tx_nft_count;
      const end = Math.max(start - 30, 0);
      const data = await getNftData(contract.tx_nft_address, start, end);
      const parsed = parseTxNftData(data);
      if (parsed) {
        if (s && txs) {
          setTxs([...txs, ...parsed]);
        } else {
          setTxs(parsed);
        }
        setCount(contract.tx_nft_count);
      }
      setIsLoading(false);
    },
    [contract.tx_nft_address, contract.tx_nft_count, txs]
  );

  useEffect(() => {
    if (!txs || contract.tx_nft_count !== count) {
      getTxs();
    }
  }, [contract.tx_nft_count, count, getTxs, txs]);

  return (
    <>
      <Card borderRadius="12px">
        <DataTable
          cols={ProjectTxsTableCols}
          headings={ProjectTxsTableHeadings}
          data={txs}
          rowRender={ProjectTransactionsRowRender}
          rowData={{ members }}
        />
      </Card>
      {txs && txs.length < contract.tx_nft_count && (
        <Flex margin="32px 0" center>
          <BaseButton
            type="button"
            isLoading={isLoading}
            onClick={() => {
              const lastTx = txs ? txs[txs.length - 1] : undefined;
              const start = lastTx ? Number(lastTx.id) - 1 : undefined;
              getTxs(start);
            }}
          >
            Load More
          </BaseButton>
        </Flex>
      )}
    </>
  );
};
