import {
  Category,
  ObjectiveState,
  ProjectState,
  ReviewStatus,
  TeamType,
} from "../types";

// https://bobbyhadz.com/blog/typescript-check-if-string-is-in-union-type

export const isCategory = (value: string): value is Category => {
  return Object.values(Category).includes(value as Category);
};
export const isObjectiveState = (value: string): value is ObjectiveState => {
  return Object.values(ObjectiveState).includes(value as ObjectiveState);
};
export const isProjectState = (value: string): value is ProjectState => {
  return Object.values(ProjectState).includes(value as ProjectState);
};
export const isReviewStatus = (value: string): value is ReviewStatus => {
  return Object.values(ReviewStatus).includes(value as ReviewStatus);
};
export const isTeamType = (value: string): value is TeamType => {
  return Object.values(TeamType).includes(value as TeamType);
};
