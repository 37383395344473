import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { BasePaths } from "consts";
import {
  BaseButton,
  Div,
  Error,
  Flex,
  H3,
  H6,
  InputBase,
  MdEditor,
  MdRender,
  T2,
  UserIconNameHandle,
} from "library/components";
import styled, { css } from "styled-components";
import {
  IdRes,
  ProposalReq as ProposalReqType,
  ProposalsProjectRes,
} from "redux/apiTypes";
import { awaitTo, formatDate } from "library/utils";
import { ProposalReq } from "schema";

const Input = styled(InputBase)`
  font-size: 1.375rem; // 22px
  font-weight: 500;
`;

const Form = styled.form`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const parseData = (data?: ProposalsProjectRes[number]) =>
  data
    ? {
        name: data.name || "",
        description: data.description || "",
      }
    : { name: "", description: "" };

type ProposalFormProps = {
  error?: string;
  isLoading?: boolean;
  handleCreateUpdate: (data: ProposalReqType) => Promise<IdRes | undefined>;
  data?: ProposalsProjectRes[number];
  isEditable?: boolean;
};

export const ProposalForm = ({
  error,
  isLoading,
  handleCreateUpdate,
  data,
  isEditable,
}: ProposalFormProps) => {
  const [isView, setIsView] = useState(Boolean(data));
  const [info, setInfo] = useState(data);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProposalReqType>({
    resolver: zodResolver(ProposalReq),
    defaultValues: parseData(data),
  });

  const onSubmit = async (d: ProposalReqType) => {
    const [, res] = await awaitTo(handleCreateUpdate(d));
    if (res?.id) {
      if (!data) {
        reset({});
      } else {
        setIsView(true);
      }
    }
  };

  // reset form if input data changes
  useEffect(() => {
    if (data) {
      reset(parseData(data));
      setInfo(data);
      setIsView(true);
    }
  }, [data, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Div marginBottom="24px">
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange } }) =>
            isView ? (
              <H3 marginBottom="16px">{value}</H3>
            ) : (
              <Div marginBottom="16px">
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Proposal Name*"
                />
                <Error error={errors.name?.message} />
              </Div>
            )
          }
        />

        {info && (
          <>
            <Link to={`/${BasePaths.m}/${info.member.memberHandle}`}>
              <Flex alignCenter>
                <UserIconNameHandle
                  icon={info.member.icon}
                  name={info.member.name}
                  memberHandle={info.member.memberHandle}
                  small
                />
              </Flex>
            </Link>
            <T2 marginTop="16px">{formatDate(info.createdAt)}</T2>
          </>
        )}
      </Div>

      <Controller
        control={control}
        name="description"
        render={({ field: { value, onChange } }) =>
          isView ? (
            <MdRender text={value || "--"} />
          ) : (
            <MdEditor
              formError={errors.description?.message}
              value={value}
              setValue={onChange}
              style={{ flex: "1" }}
              wrapperStyle={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "48px",
              }}
            />
          )
        }
      />

      {info && (
        <>
          <H6 margin="64px 0 16px">Comment</H6>
          <T2>{info.comment}</T2>
        </>
      )}

      {isEditable && (
        <Flex
          width="100%"
          position="absolute"
          bottom="0"
          left="0"
          padding="16px"
          gap="16px"
          flexWrap="wrap"
          alignCenter
          justifyEnd
          css={css`
            background-color: ${({ theme }) => theme.borderColorLight};
          `}
        >
          <Error error={error} margin="0" />
          {!isView && data && (
            <BaseButton
              type="button"
              onClick={() => {
                setIsView(true);
                reset(parseData(data));
              }}
              isSecondary
            >
              Cancel
            </BaseButton>
          )}
          {!isView && (
            <BaseButton isLoading={isLoading}>
              {data ? "Update" : "Create"}
            </BaseButton>
          )}
          {isView && (
            <BaseButton type="button" onClick={() => setIsView(false)}>
              Edit
            </BaseButton>
          )}
        </Flex>
      )}
    </Form>
  );
};
