import {
  ProjectCreateApiRes,
  ProjectCreateApiArg,
  ProjectUpdateApiRes,
  ProjectUpdateApiArg,
  ProjectDeleteApiRes,
  ProjectDeleteApiArg,
  ProjectGetProfileApiRes,
  ProjectGetProfileApiArg,
  ProjectRemoveMemberApiRes,
  ProjectRemoveMemberApiArg,
  ProjectGetObjectivesApiRes,
  ProjectGetObjectivesApiArg,
  ProjectGetMembersApiRes,
  ProjectGetMembersApiArg,
  ProjectGetAppTeamsApiRes,
  ProjectGetAppTeamsApiArg,
  ProjectGetFinancialsApiRes,
  ProjectGetFinancialsApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    projectCreate: build.mutation<ProjectCreateApiRes, ProjectCreateApiArg>({
      query: (queryArg) => ({
        url: `/project/${queryArg.appHandle}`,
        method: "POST",
        body: queryArg.projectReq,
      }),
    }),
    projectUpdate: build.mutation<ProjectUpdateApiRes, ProjectUpdateApiArg>({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectHandle}`,
        method: "PUT",
        body: queryArg.projectReq,
      }),
    }),
    projectDelete: build.mutation<ProjectDeleteApiRes, ProjectDeleteApiArg>({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectHandle}`,
        method: "DELETE",
      }),
    }),
    projectGetProfile: build.query<
      ProjectGetProfileApiRes,
      ProjectGetProfileApiArg
    >({
      query: (queryArg) => ({ url: `/project/${queryArg.projectHandle}` }),
    }),
    projectRemoveMember: build.mutation<
      ProjectRemoveMemberApiRes,
      ProjectRemoveMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectHandle}/remove/${queryArg.memberId}`,
        method: "PATCH",
      }),
    }),
    projectGetObjectives: build.query<
      ProjectGetObjectivesApiRes,
      ProjectGetObjectivesApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectHandle}/objectives`,
      }),
    }),
    projectGetMembers: build.query<
      ProjectGetMembersApiRes,
      ProjectGetMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectHandle}/members`,
      }),
    }),
    projectGetAppTeams: build.query<
      ProjectGetAppTeamsApiRes,
      ProjectGetAppTeamsApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectHandle}/app-teams`,
      }),
    }),
    projectGetFinancials: build.query<
      ProjectGetFinancialsApiRes,
      ProjectGetFinancialsApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectHandle}/financials`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as projectApi };
