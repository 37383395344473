import { SortProject } from "redux/apiTypes";

export const SortProjectTypes: { [key in SortProject]: SortProject } = {
  NEWEST_FIRST: "NEWEST_FIRST",
  OLDEST_FIRST: "OLDEST_FIRST",
  MOST_BUDGET: "MOST_BUDGET",
  LEAST_BUDGET: "LEAST_BUDGET",
};

export const SortProjectOptions = [
  { label: "Newest First", value: SortProjectTypes.NEWEST_FIRST },
  { label: "Oldest First", value: SortProjectTypes.OLDEST_FIRST },
  { label: "Most Budget", value: SortProjectTypes.MOST_BUDGET },
  { label: "Least Budget", value: SortProjectTypes.LEAST_BUDGET },
];

export const ProjectsTableCols = [
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center", width: "120px" },
];

export const ProjectsTableHeadings = [
  "App",
  "Project",
  "Team",
  "Category",
  "Applications",
  "Budget",
  "Follow",
];

export const AppProjectsTableCols = [
  { textAlign: "center", width: "64px" },
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center", width: "124px" },
  { textAlign: "center" },
];

export const AppProjectsTableHeadings = [
  "#",
  "Project",
  "State",
  "Team",
  "Start",
  "End",
  "Applications",
  "Members",
  "Budget",
];

export const MemberProjectsTableCols = [
  { textAlign: "center", width: "64px" },
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const MemberProjectsTableHeadings = [
  "#",
  "Project",
  "State",
  "Team",
  "Start",
  "End",
  "Budget",
  "User Objectives",
];

// Payments

export const ProjectPaymentsTableCols = [
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const ProjectPaymentsTableHeadings = [
  "Member",
  "User Objectives",
  "Distribution",
  "Total Amount",
  "Total Paid",
];

export const ProjectTxsTableCols = [
  { textAlign: "center" },
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const ProjectTxsTableHeadings = [
  "Id",
  "From",
  "To",
  "Type",
  "Amount",
  "Date Time",
  "Tx",
];
