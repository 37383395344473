import styled from "styled-components";
import { TransitionConst } from "../../consts";
import { PX } from "../Text";

// ABOUT: DO NOT use baseMixin with inputs, onChange causes style rerendering

type InputProps = {
  width?: string;
  minWidth?: string;
  fontSize?: string;
  fontWeight?: string;
  padding?: string;
  secondary?: boolean;
  showBorder?: boolean;
};

export const InputBase = styled.input<InputProps>`
  min-height: 40px;
  width: ${({ width }) => (width ? width : "100%")};
  min-width: ${({ minWidth }) => minWidth};
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : PX(15))};
  font-weight: ${({ fontWeight }) => fontWeight};

  overflow: hidden;
  border-radius: 8px;
  border: ${({ showBorder, theme }) =>
    showBorder && `2px solid ${theme.borderColor}`};

  padding: ${({ padding }) => (padding ? padding : "8px")};
  background-color: ${({ secondary, theme }) =>
    secondary ? theme.borderColorLight : theme.cardBackgroundColor};
  &:disabled {
    background-color: ${({ secondary, theme }) =>
      secondary ? theme.borderColorLight : theme.cardBackgroundColor};
    &:hover {
      cursor: not-allowed;
    }
  }

  ::placeholder {
    transition: ${TransitionConst};
    color: ${({ theme }) => theme.textColorActive};
  }

  + div svg {
    transition: ${TransitionConst};
    color: ${({ theme }) => theme.buttonTextColorActive};
  }
  &:focus {
    + div svg {
      transition: ${TransitionConst};
      color: ${({ theme }) => theme.buttonTextColor};
    }
  }
`;

export const Textarea = styled.textarea`
  color: ${({ theme }) => theme.textColor};
  font-size: 0.9375rem; // 15px
  padding: 8px;
`;
