import { useEffect } from "react";
import { Outlet, useLocation, useMatch, useParams } from "react-router-dom";
import {
  AppPathOptions,
  AppParams,
  BasePaths,
  isDev,
  MemberPathOptions,
  MemberPaths,
  ProjectPathOptions,
  ProjectPaths,
  AboutPathOptions,
  FilePathOptions,
} from "consts";
import { BackNavBar, NavBar } from "./NavBar";
import { TabBar } from "./TabBar";
import { DevBar } from "./DevBar";
import { useApp, useFile, useMember, useProject, useSaved } from "hooks";

export const Navigation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NavBar />
      <Outlet />
      <TabBar />
      {isDev && <DevBar />}
    </>
  );
};

export const AppNavigation = () => {
  const { pathname } = useLocation();
  const { appHandle } = useParams();
  const { isSignedIn, isAdmin, isLoading } = useApp(true);

  const { handleSaveApp, saved, isLoading: isLoadingS } = useSaved();
  const isSaved = saved?.savedApps.some((s) => s.appHandle === appHandle);
  const handleSave = () => appHandle && handleSaveApp(appHandle, !isSaved);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <BackNavBar
        routes={AppPathOptions}
        isLoading={isLoading || isLoadingS}
        isSettings={isAdmin}
        isSaved={isSaved}
        showSave={isSignedIn}
        handleSave={handleSave}
      />
      <Outlet />
      {isDev && <DevBar />}
    </>
  );
};

export const ProjectNavigation = () => {
  const { pathname } = useLocation();
  const { projectHandle, objectiveHandle } = useParams();
  const { isSignedIn, isProjectLead, isAdmin, isLoading } = useProject(true);

  const { handleSaveProject, saved, isLoading: isLoadingS } = useSaved();
  const isSaved = saved?.savedProjects.some(
    (s) => s.projectHandle === projectHandle
  );
  const handleSave = () =>
    projectHandle && handleSaveProject(projectHandle, !isSaved);

  const objectivesPath = useMatch(
    `${BasePaths.p}/:${AppParams.projectHandle}/${ProjectPaths.objectives}`
  );

  useEffect(() => {
    if (!objectiveHandle && !objectivesPath) {
      window.scrollTo(0, 0);
    }
  }, [objectiveHandle, objectivesPath, pathname]);

  return (
    <>
      <BackNavBar
        routes={ProjectPathOptions}
        isLoading={isLoading || isLoadingS}
        isSettings={isProjectLead || isAdmin}
        isSaved={isSaved}
        showSave={isSignedIn}
        handleSave={handleSave}
      />
      <Outlet />
      {isDev && <DevBar />}
    </>
  );
};

export const MemberNavigation = () => {
  const { pathname } = useLocation();
  const { memberHandle, objectiveHandle } = useParams();
  const { counts, isSignedIn, isMember, isLoading } = useMember(true);

  const { handleSaveMember, saved, isLoading: isLoadingS } = useSaved();
  const isSaved = saved?.savedMembers.some(
    (s) => s.memberHandle === memberHandle
  );
  const handleSave = () =>
    memberHandle && handleSaveMember(memberHandle, !isSaved);

  const memberDashboardPath = useMatch(
    `${BasePaths.m}/:${AppParams.memberHandle}/${MemberPaths.dashboard}`
  );

  useEffect(() => {
    if (!objectiveHandle && !memberDashboardPath) {
      window.scrollTo(0, 0);
    }
  }, [memberDashboardPath, objectiveHandle, pathname]);

  return (
    <>
      <BackNavBar
        routes={MemberPathOptions}
        isLoading={isLoading || isLoadingS}
        isSettings={isMember}
        isSaved={isSaved}
        showSave={isSignedIn}
        handleSave={handleSave}
        counts={
          counts && isMember
            ? {
                2: counts.proposals + counts.projectInvites,
                3: counts.appInvites,
              }
            : undefined
        }
      />
      <Outlet />
      {isDev && <DevBar />}
    </>
  );
};

export const FileNavigation = () => {
  const { pathname } = useLocation();
  const { fileHandle } = useParams();
  const { isSignedIn, isAdmin, isMember, isLoading } = useFile(true);

  const { handleSaveFile, saved, isLoading: isLoadingS } = useSaved();
  const isSaved = saved?.savedFiles.some((s) => s.fileHandle === fileHandle);
  const handleSave = () => fileHandle && handleSaveFile(fileHandle, !isSaved);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <BackNavBar
        routes={FilePathOptions}
        isLoading={isLoading || isLoadingS}
        isSettings={isAdmin || isMember}
        isSaved={isSaved}
        showSave={isSignedIn}
        handleSave={handleSave}
      />
      <Outlet />
      {isDev && <DevBar />}
    </>
  );
};

export const AboutNavigation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <BackNavBar
        routes={AboutPathOptions}
        currRoute={pathname.split("/")[2]}
      />
      <Outlet />
      {isDev && <DevBar />}
    </>
  );
};

export const BackNavigation = () => (
  <>
    <BackNavBar routes={[]} />
    <Outlet />
    {isDev && <DevBar />}
  </>
);
