import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { LoadingDots } from "library/assets";
import { Div, Flex, H1, Main } from "library/components";
import { Width } from "library/consts";
import { parseRtkError } from "library/utils";

type LoadingPageProps = {
  error?: FetchBaseQueryError | SerializedError;
  stringError?: string;
  isLoading?: boolean;
  isLocked?: boolean;
};

export const LoadingPage = ({
  error,
  stringError,
  isLoading,
  isLocked,
}: LoadingPageProps) => (
  <Main flex="1" display="flex" justifyContent="center">
    <Div
      width="100%"
      maxWidth={`${Width.LG}px`}
      padding="0 16px 290px 16px"
      sm={{ padding: "0 32px 240px 32px" }}
    >
      <Flex height="100%" center column>
        {error ? (
          <H1>{parseRtkError(error)}</H1>
        ) : isLoading ? (
          <LoadingDots size={100} r={2} />
        ) : isLocked ? (
          <H1>Page is Locked</H1>
        ) : stringError ? (
          <H1>{stringError}</H1>
        ) : (
          <H1>Not Found</H1>
        )}
      </Flex>
    </Div>
  </Main>
);
