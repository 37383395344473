export const ProjectProposalsTableCols = [
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "left" },
];

export const ProjectProposalsTableHeadings = [
  "Member",
  "Proposal",
  "Status",
  "Date",
  "Comment",
];

export const ProposalsTableCols = [
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const ProposalsTableHeadings = [
  "App",
  "Project",
  "Team",
  "Status",
  "Date",
];

// --- Invites ---

export const InvitesATableCols = [
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const InvitesATableHeadings = ["App", "Team", "Role", "Actions"];

export const InvitesPTableCols = [
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const InvitesPTableHeadings = [
  "App",
  "Project",
  "Team",
  "Role",
  "Actions",
];
