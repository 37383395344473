import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AboutPaths, BasePaths, PageDescriptions, PageTitles } from "consts";
import {
  BaseButton,
  Error,
  Flex,
  Page,
  RadixButton,
  Span,
  T3,
  UserIconNameHandle,
} from "library/components";
import { LoadingDots } from "library/assets";
import { authApi, memberApi } from "redux/apis";
import { useEffect } from "react";
import { css } from "styled-components";
import { useJwt } from "redux/hooks";
import { createMemberContract, useRadixCall } from "radix";
import { awaitTo, parseRtkError } from "library/utils";

// https://docs.github.com/en/developers/apps/building-oauth-apps/authorizing-oauth-apps

// TODO: Add Radix Connect Button, require adding account to prevent mass bot signups

export const OAuth = () => {
  const navigate = useNavigate();
  const { actions: jwtActions } = useJwt();
  const [urlSearchParams] = useSearchParams();
  const searchParams: Record<string, string> = {};
  urlSearchParams.forEach((value, key) => (searchParams[key] = value));

  const code = searchParams.code;
  const oauthState = localStorage.getItem("oauthState");
  const isInvalidCode = !code || searchParams.state !== oauthState;

  // Get JWT query, redirect if member has memberHandle
  const { data: authData, error: authError } = authApi.useAuthGetCodeQuery(
    { code },
    {
      skip: isInvalidCode,
    }
  );
  useEffect(() => {
    const memberHandle = authData?.memberHandle;
    if (authData?.jwt) {
      localStorage.setItem("jwt", authData.jwt);
    }

    if (memberHandle) {
      localStorage.removeItem("oauthState");
      jwtActions.setIsSignedIn(true);
      navigate("/", { replace: true });
    }
  }, [authData, jwtActions, navigate]);

  // Create member mutation
  const [createMember, { error: createError, isLoading }] =
    memberApi.useMemberCreateMutation();

  const invalidCodeErr =
    isInvalidCode && !authData ? "Invalid code" : undefined;
  const rtkError = parseRtkError(authError || createError);

  const [createContract, { error: cError, isLoading: isLoadingC }] =
    useRadixCall(createMemberContract);

  const createMemberFunc = async () => {
    if (!authData) {
      return;
    }
    const addresses = await createContract(authData.username);
    if (addresses) {
      const [, res] = await awaitTo(
        createMember({
          memberComponentReq: addresses,
        }).unwrap()
      );
      if (res?.id) {
        localStorage.removeItem("oauthState");
        localStorage.setItem("jwt", res.jwt);
        jwtActions.setIsSignedIn(true);
        navigate("/", { replace: true });
      }
    }
  };

  return (
    <Page>
      <Helmet>
        <title>{PageTitles.oauth}</title>
        <meta name="description" content={PageDescriptions.oauth} />
      </Helmet>

      <Flex height="100%" center column>
        {authData && !authData.memberHandle ? (
          <Flex column>
            <Flex
              minWidth="320px"
              padding="24px"
              gap="24px"
              borderRadius="24px"
              css={css`
                border: 2px solid ${({ theme }) => theme.borderColor};
              `}
              column
            >
              <Flex padding="12px" center>
                <UserIconNameHandle
                  memberHandle={authData.username}
                  name={authData.name || authData.username}
                  icon={authData.icon}
                />
              </Flex>
              <Flex gap="12px" alignCenter>
                <BaseButton
                  width="100%"
                  onClick={createMemberFunc}
                  isLoading={isLoading || isLoadingC}
                >
                  {isLoading ? <LoadingDots /> : "Create Account"}
                </BaseButton>
                <RadixButton small />
              </Flex>

              <Error error={invalidCodeErr || rtkError || cError} />
            </Flex>

            <T3 padding="24px" secondary>
              By creating an account, you accept the{" "}
              <Link to={`/${BasePaths.about}/${AboutPaths.terms}`}>
                <Span textDecoration="underline">Terms</Span>
              </Link>{" "}
              &{" "}
              <Link to={`/${BasePaths.about}/${AboutPaths.privacy}`}>
                <Span textDecoration="underline">Privacy</Span>
              </Link>
            </T3>
          </Flex>
        ) : (
          <LoadingDots size={100} r={2} />
        )}
      </Flex>
    </Page>
  );
};
