import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  BasePaths,
  PageDescriptions,
  PageTitles,
  ProjectPaths,
  ProjectProposalsTableCols,
  ProjectProposalsTableHeadings,
} from "consts";
import { useProject } from "hooks";
import {
  AppIconName,
  Card,
  DataTable,
  Div,
  Dropdown,
  Flex,
  LoadingPage,
  Page,
  PrimaryButton,
  SearchInput,
  SearchProposalStatusOptions,
  TX,
  TeamTypeOptionsMedium,
  useModal,
} from "library/components";
import { PlusIcon } from "library/assets";
import { ProposalStatus, StateFilter } from "library/types";
import { proposalApi } from "redux/apis";
import { ProposalsProjectRes } from "redux/apiTypes";
import { ProjectProposalsRowRender } from "./ProjectProposalsRow";
import { ProposalCreateModal, ProposalViewModal } from "views/components";

export const ProjectProposals = () => {
  const { projectHandle } = useParams();
  const navigate = useNavigate();
  const { show, showModal, hideModal } = useModal();

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<ProposalStatus | string>(
    StateFilter.ALL
  );
  const {
    auth,
    profile: p,
    members,
    error: errorP,
    isLoading: isLoadingP,
    isAMember,
  } = useProject();

  const {
    data: allProposals,
    isLoading,
    error,
  } = proposalApi.useProposalsProjectGetQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  if (
    !projectHandle ||
    !allProposals ||
    !p ||
    !members ||
    p.isLocked ||
    errorP ||
    isLoadingP ||
    error ||
    isLoading
  ) {
    return (
      <LoadingPage
        error={error || errorP}
        isLoading={isLoading || isLoadingP}
        isLocked={p?.isLocked}
      />
    );
  }

  const searchCase = search.toLowerCase();
  const proposals = searchCase
    ? allProposals.filter(
        (p) =>
          p.name.toLowerCase().includes(searchCase) ||
          p.member.memberHandle.toLowerCase().includes(searchCase) ||
          p.member.name.toLowerCase().includes(searchCase)
      )
    : allProposals;

  return (
    <Page>
      <Helmet>
        <title>
          {p.name} - {PageTitles.projectProposals}
        </title>
        <meta
          name="description"
          content={`${p.name} - ${PageDescriptions.projectProposals}`}
        />
      </Helmet>

      <Flex height="40px" margin="32px 0" gap="24px" alignCenter>
        <SearchInput handleSearch={(v) => setSearch(v)} flex="1" />
        <PrimaryButton
          height="100%"
          sm={{ padding: "0 12px" }}
          onClick={showModal}
        >
          <PlusIcon />
        </PrimaryButton>
      </Flex>

      <Div
        display="block"
        alignItems="center"
        margin="8px 0"
        sm={{ display: "flex", margin: "24px 0" }}
      >
        <Div flex="1" margin="32px 0 16px" sm={{ margin: "0 24px 0 12px" }}>
          <Div display="flex" alignItems="center">
            <AppIconName icon={p.app.icon} name={p.app.name} />
            <Div marginRight="24px">
              {TeamTypeOptionsMedium.find((o) => o.value === p.teamType)?.label}
            </Div>
            <TX ellipsis medium>
              {p.name}
            </TX>
          </Div>
        </Div>

        <Div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          margin="16px 0"
          sm={{ justifyContent: "flex-end", margin: "0" }}
        >
          <Dropdown
            handleSelect={(v) => setStatus(v)}
            selectValue={status}
            options={SearchProposalStatusOptions}
            buttonProps={{ minWidth: "100px" }}
          />
        </Div>
      </Div>

      <Card marginTop="32px">
        <DataTable
          cols={ProjectProposalsTableCols}
          headings={ProjectProposalsTableHeadings}
          data={proposals}
          rowRender={ProjectProposalsRowRender}
          handleRowClick={(d: ProposalsProjectRes[number]) => {
            navigate(
              `/${BasePaths.p}/${projectHandle}/${ProjectPaths.proposals}/${d.member.memberHandle}`
            );
          }}
        />
      </Card>

      <ProposalCreateModal
        hideModal={hideModal}
        show={show}
        projectHandle={projectHandle}
      />
      <ProposalViewModal
        proposals={proposals}
        authHandle={auth?.memberHandle}
        isAMember={isAMember}
      />
    </Page>
  );
};
