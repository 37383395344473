import { GoldScryptoIcon, ScryptoIcon } from "../../assets/token-icons";
import { Div, Flex, T1, T2, TX } from "..";
import {
  TeamTypeDescriptions,
  ScryptoTypesArray,
  TeamHiddenOptions,
} from "library/consts";
import { StateFilter, TeamType, TeamsPage } from "library/types";
import { TeamSymbol } from "../Other";
import { ReactNode } from "react";

type TeamOptionProps = {
  description: string;
  label: string;
  value: TeamType;
};

const TeamOption = ({ description, label, value }: TeamOptionProps) => (
  <Div display="flex">
    <Div paddingTop="2px">
      <TeamSymbol type={value} />
    </Div>
    <Div marginLeft="8px" textAlign="left">
      <T1 marginBottom="4px" bold>
        {label}
      </T1>
      <T2>{description}</T2>
    </Div>
  </Div>
);

type TeamTypeOption = {
  label?: ReactNode;
  value?: TeamType;
  heading?: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
};

export const TeamTypeOptions: TeamTypeOption[] = [
  {
    heading: (
      <Div display="flex" alignItems="center">
        {/* svg visibility affects all svgs */}
        <Flex visibility="visible" center>
          <ScryptoIcon size={18} />
        </Flex>
        <T2 marginLeft="6px" medium>
          Scrypto
        </T2>
      </Div>
    ),
  },
  {
    label: (
      <Div display="flex" alignItems="center">
        <Flex visibility="visible" center>
          <ScryptoIcon size={36} />
        </Flex>
        <TX fontSize="2rem" marginLeft="12px" bold>
          Blueprint
        </TX>
      </Div>
    ),
    description: (
      <TeamOption
        description="Scrypto Blueprints for Radix apps"
        label="Blueprint"
        value={TeamType.BLUEPRINT}
      />
    ),
    value: TeamType.BLUEPRINT,
  },
  {
    label: (
      <Div display="flex" alignItems="center">
        <Flex visibility="visible" center>
          <GoldScryptoIcon size={36} />
        </Flex>
        <TX fontSize="2rem" marginLeft="12px" bold>
          Audit
        </TX>
      </Div>
    ),
    description: (
      <TeamOption
        description="Audits for Scrypto Blueprints"
        label="Audit"
        value={TeamType.AUDIT}
      />
    ),
    value: TeamType.AUDIT,
  },
  {
    heading: "Engineering",
  },
  {
    label: (
      <Flex gap="12px" alignCenter>
        <TeamSymbol
          type="FRONTEND"
          fontSize="1.375rem" // 22px
        />
        <TX
          fontSize="2rem" // 32px
          bold
        >
          Frontend
        </TX>
      </Flex>
    ),
    description: (
      <TeamOption
        description="Interfaces for decentralized apps"
        label="Frontend"
        value={TeamType.FRONTEND}
      />
    ),
    value: TeamType.FRONTEND,
  },
  {
    label: (
      <Flex gap="12px" alignCenter>
        <TeamSymbol type="BACKEND" fontSize="1.375rem" />
        <TX fontSize="2rem" bold>
          Backend
        </TX>
      </Flex>
    ),
    description: (
      <TeamOption
        description="Services & API endpoints"
        label="Backend"
        value={TeamType.BACKEND}
      />
    ),
    value: TeamType.BACKEND,
  },
  {
    label: (
      <Flex gap="12px" alignCenter>
        <TeamSymbol type="DEVOPS" fontSize="1.375rem" />
        <TX fontSize="2rem" bold>
          DevOps
        </TX>
      </Flex>
    ),
    description: (
      <TeamOption
        description="Infrastructure & service operations"
        label="DevOps"
        value={TeamType.DEVOPS}
      />
    ),
    value: TeamType.DEVOPS,
  },
  {
    heading: "Management",
  },
  {
    label: (
      <Flex gap="12px" alignCenter>
        <TeamSymbol type="DESIGN" fontSize="1.375rem" />
        <TX fontSize="2rem" bold>
          Design
        </TX>
      </Flex>
    ),
    description: (
      <TeamOption
        description="User experience & interface design"
        label="Design"
        value={TeamType.DESIGN}
      />
    ),
    value: TeamType.DESIGN,
  },
  {
    label: (
      <TX fontSize="2rem" bold>
        Growth
      </TX>
    ),
    description: (
      <TeamOption
        description="User acquisition & marketing"
        label="Growth"
        value={TeamType.GROWTH}
      />
    ),
    value: TeamType.GROWTH,
  },
  // {
  //   label: (
  //     <TX fontSize="2rem" bold>
  //       Product
  //     </TX>
  //   ),
  //   description: (
  //     <TeamOption
  //       description="App features & specifications design"
  //       label="Product"
  //       value={TeamType.PRODUCT}
  //     />
  //   ),
  //   value: TeamType.PRODUCT,
  // },
  // {
  //   label: (
  //     <TX fontSize="2rem" bold>
  //       Legal
  //     </TX>
  //   ),
  //   description: (
  //     <TeamOption
  //       description="Legal counsel & regulatory compliance"
  //       label="Legal"
  //       value={TeamType.LEGAL}
  //     />
  //   ),
  //   value: TeamType.LEGAL,
  // },
];

export const TeamTypeOptionsMedium = Object.values(TeamType)
  .map((value) => ({
    label: (
      <Flex alignCenter fullWidth>
        {value === TeamType.BLUEPRINT && (
          <Flex visibility="visible" center>
            <ScryptoIcon size={20} />
          </Flex>
        )}
        {value === TeamType.AUDIT && (
          <Flex visibility="visible" center>
            <GoldScryptoIcon size={20} />
          </Flex>
        )}
        {!ScryptoTypesArray.includes(value) && <TeamSymbol type={value} />}
        <TX marginLeft="10px" medium>
          {TeamTypeDescriptions[value]}
        </TX>
      </Flex>
    ),
    value,
  }))
  .filter((o) => !TeamHiddenOptions.includes(o.value));

export const TeamTypeFilterOptions = [
  {
    label: (
      <TX marginLeft="10px" medium>
        All Teams
      </TX>
    ),
    value: StateFilter.ALL,
  },
  ...TeamTypeOptionsMedium,
];

export const TeamTypeOptionsSmall = Object.values(TeamType)
  .map((value) => ({
    label: (
      <Flex alignCenter fullWidth>
        <TeamSymbol
          type={value}
          fontSize="0.625rem" // 10px
        />
        <T2 flex="1" marginLeft="8px" medium>
          {TeamTypeDescriptions[value]}
        </T2>
        {value === TeamType.BLUEPRINT && (
          <Flex visibility="visible" center>
            <ScryptoIcon size={16} />
          </Flex>
        )}
        {value === TeamType.AUDIT && (
          <Flex visibility="visible" center>
            <GoldScryptoIcon size={16} />
          </Flex>
        )}
      </Flex>
    ),
    value,
  }))
  .filter((o) => !TeamHiddenOptions.includes(o.value));

export const TeamsPageOptions = [
  {
    label: (
      <TX fontSize="2rem" bold>
        Apps
      </TX>
    ),
    description: <TX medium>Apps</TX>,
    value: TeamsPage.APPS,
  },
  {
    label: (
      <TX fontSize="2rem" bold>
        Members
      </TX>
    ),
    description: <TX medium>Members</TX>,
    value: TeamsPage.MEMBERS,
  },
];
