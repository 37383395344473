// https://gist.github.com/velopert/554ab444fd1731e3047c4adde10ed36d

import { useState } from "react";

type AnyType = any[];
type PromiseFn<T, U extends AnyType> = (...args: U) => Promise<T>;

export const useRadixCall = <T, U extends AnyType>(call: PromiseFn<T, U>) => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const callFunc = async (...params: Parameters<PromiseFn<T, U>>) => {
    setLoading(true);
    setError("");
    const res = await call(...params);
    if (!res) {
      setError("Unable to complete contract call");
    }
    setLoading(false);
    return res;
  };

  return [
    callFunc,
    {
      error,
      isLoading,
    },
  ] as const;
};
