import { TokenSymbol } from "library/types";
import { ProjectContract } from "radix";
import { ProjectFinancialsRes, ProjectMembersRes } from "redux/apiTypes";

//
//
// Helper function to get member financial summary
export type MemberFinancialType = {
  [key: string]: {
    objectives: number;
    completed: number;
    amount: number;
    paid: number;
  };
};
const FinancialDefault = {
  objectives: 0,
  completed: 0,
  amount: 0,
  paid: 0,
};

export const getProjectMemberValues = (contract: ProjectContract) => {
  const acc: MemberFinancialType = {};
  // Add not completed objectives
  Object.values(contract.objectives).forEach((dis) => {
    Object.entries(dis).forEach(([address, amount]) => {
      const ref = acc[address] || FinancialDefault;
      acc[address] = {
        objectives: ref.objectives + 1,
        completed: ref.completed + 0,
        amount: ref.amount + amount,
        paid: ref.paid + 0,
      };
    });
  });
  // Add completed objectives
  Object.values(contract.completed).forEach((dis) => {
    Object.entries(dis).forEach(([address, amount]) => {
      const ref = acc[address] || FinancialDefault;
      acc[address] = {
        objectives: ref.objectives + 1,
        completed: ref.completed + 1,
        amount: ref.amount + amount,
        paid: ref.paid + amount,
      };
    });
  });

  return acc;
};

//
//
// Get status
export const getProjectStatus = (contract: ProjectContract) => {
  const members = Object.keys(contract.members);
  const objs = Object.keys(contract.objectives);
  const completed = Object.keys(contract.completed);
  if (contract.funds <= 0 && completed.length === 0) {
    return 0;
  } else if (members.length <= 0) {
    return 1;
  } else if (!objs.length && !completed.length) {
    return 2;
  } else if (objs.length > 0 && completed.length >= 0) {
    return 3;
  } else if (!objs.length && completed.length > 0) {
    return 4;
  }
};

export const getMemberStatus = (m: ProjectMembersRes[number]) =>
  m.isCancelled ? "Cancelled" : "";

//
//
// Verify that Contract Values and Database Values are equal

type CVType = Record<
  string,
  | {
      amount: number;
      isCompleted: boolean;
      distributions: Record<string, number>;
    }
  | undefined
>;

export const parseProjectValues = (
  adminAddress: string | undefined,
  contract: ProjectContract,
  data: ProjectFinancialsRes,
  members: ProjectMembersRes
) => {
  // object of memberProjectRoleId to componentAddress
  const membersObj = members.reduce(
    (acc: Record<string, string | undefined>, curr) => {
      acc[curr.id] = curr.member.componentAddress;
      return acc;
    },
    {}
  );
  // Convert contract into object for objectives and distributions
  const cv = {
    objectives: Object.entries({
      ...contract.objectives,
      ...contract.completed,
    }).reduce((acc: CVType, [o, d]) => {
      acc[o] = {
        amount: Object.entries(d).reduce((acc, [_, v]) => acc + v, 0),
        isCompleted: Boolean(contract.completed[o]),
        distributions: d,
      };
      return acc;
    }, {}),
  };

  // Convert data into default values for form
  const dv = {
    amount: contract.funds + contract.paid,
    tokenSymbol: data.tokenSymbol || TokenSymbol.XRD,
    isCancelled: contract.is_cancelled,
    objectives: data.objectives.map((o) => {
      const obj = cv.objectives[o.number];
      const dis = o.owners.map((d) => ({
        id: d.id,
        componentAddress: membersObj[d.id] || "",
        amount: obj
          ? obj.distributions[membersObj[d.id] || ""] || 0
          : undefined,
      }));

      return {
        id: o.id,
        number: o.number,
        name: o.name,
        amount: obj?.amount || undefined,
        isAdded: Boolean(obj),
        isCompleted: obj?.isCompleted || false,
        distributions: adminAddress
          ? dis.filter((v) => v.componentAddress !== adminAddress)
          : dis,
      };
    }),
  };

  return dv;
};
