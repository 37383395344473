import {
  AppCreateApiRes,
  AppCreateApiArg,
  AppUpdateApiRes,
  AppUpdateApiArg,
  AppDeleteApiRes,
  AppDeleteApiArg,
  AppGetProfileApiRes,
  AppGetProfileApiArg,
  AppRemoveMemberApiRes,
  AppRemoveMemberApiArg,
  AppGetProjectsApiRes,
  AppGetProjectsApiArg,
  AppGetTeamsApiRes,
  AppGetTeamsApiArg,
  AppGetVestingsApiRes,
  AppGetVestingsApiArg,
  AppGetFilesApiRes,
  AppGetFilesApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appCreate: build.mutation<AppCreateApiRes, AppCreateApiArg>({
      query: (queryArg) => ({
        url: `/app/create`,
        method: "POST",
        body: queryArg.appReq,
      }),
    }),
    appUpdate: build.mutation<AppUpdateApiRes, AppUpdateApiArg>({
      query: (queryArg) => ({
        url: `/app/${queryArg.appHandle}`,
        method: "PATCH",
        body: queryArg.appReq,
      }),
    }),
    appDelete: build.mutation<AppDeleteApiRes, AppDeleteApiArg>({
      query: (queryArg) => ({
        url: `/app/${queryArg.appHandle}`,
        method: "DELETE",
      }),
    }),
    appGetProfile: build.query<AppGetProfileApiRes, AppGetProfileApiArg>({
      query: (queryArg) => ({ url: `/app/${queryArg.appHandle}` }),
    }),
    appRemoveMember: build.mutation<
      AppRemoveMemberApiRes,
      AppRemoveMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/app/${queryArg.appHandle}/remove/${queryArg.memberId}/${queryArg.teamType}`,
        method: "PATCH",
      }),
    }),
    appGetProjects: build.query<AppGetProjectsApiRes, AppGetProjectsApiArg>({
      query: (queryArg) => ({
        url: `/app/${queryArg.pathAppHandle}/projects`,
        params: {
          appHandle: queryArg.queryAppHandle,
          teamType: queryArg.teamType,
          state: queryArg.state,
          isCancelled: queryArg.isCancelled,
          reviewStatus: queryArg.reviewStatus,
          tokenSymbol: queryArg.tokenSymbol,
          sort: queryArg.sort,
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    appGetTeams: build.query<AppGetTeamsApiRes, AppGetTeamsApiArg>({
      query: (queryArg) => ({ url: `/app/${queryArg.appHandle}/teams` }),
    }),
    appGetVestings: build.query<AppGetVestingsApiRes, AppGetVestingsApiArg>({
      query: (queryArg) => ({
        url: `/app/${queryArg.appHandle}/vestings`,
        params: {
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    appGetFiles: build.query<AppGetFilesApiRes, AppGetFilesApiArg>({
      query: (queryArg) => ({
        url: `/app/${queryArg.pathAppHandle}/files`,
        params: {
          appHandle: queryArg.queryAppHandle,
          search: queryArg.search,
          isUpdated: queryArg.isUpdated,
          take: queryArg.take,
          skip: queryArg.skip,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as appApi };
