import { Link } from "react-router-dom";
import styled from "styled-components";
import { Div, Flex, Img, T1, T2, T3 } from "library/components";
import { AwardIcon, FolderIcon, Rocket, ScryptoIcon } from "library/assets";
import { TeamSymbol } from "../Other";
import { BookmarkButton } from "./CardComponents";
import { Card } from "./Card";
import { TeamType } from "library/types";
import { BasePaths, Colors } from "library/consts";
import { formatNumberCompact } from "library/utils";

const BottomBackground = styled(Div)`
  background-color: ${({ theme }) => theme?.cardBackgroundColorActive};
`;

type MemberCardProps = {
  icon?: string | null;
  bio?: string | null;
  teamTypes: TeamType[];
  memberHandle: string;
  name: string;
  rewards: string;
  apps: number;
  projects: number;
  saved?: boolean;
};

export const MemberCard = ({
  icon,
  bio,
  teamTypes,
  memberHandle,
  name,
  rewards,
  apps,
  projects,
  saved,
}: MemberCardProps) => (
  <Div position="relative">
    <BookmarkButton saved={saved} />

    <Link to={`/${BasePaths.m}/${memberHandle}`}>
      <Card zIndex="1">
        <Flex height="100%" width="100%" position="relative" zIndex="2" column>
          <Flex flex="1" padding="24px" column>
            <T2 marginRight="48px" medium>
              @{memberHandle}
            </T2>
            <Flex marginTop="20px" alignCenter>
              <Img
                as={!icon ? "div" : "img"}
                height="54px"
                width="54px"
                borderRadius="50%"
                backgroundColor={Colors.border}
                aspectRatio="1"
                src={icon || ""}
                alt={name}
              />
              <Div marginLeft="16px" flex="1" overflow="hidden">
                {/* Name max length of 30 Characters, bio max 160 */}
                {/* https://developer.apple.com/app-store/product-page/ */}
                <T1 marginBottom="4px" ellipsis medium>
                  {name}
                </T1>
                <T2 marginBottom="4px" ellipsis>
                  {bio || ""}
                </T2>
              </Div>
            </Flex>
          </Flex>
          <BottomBackground display="flex" padding="12px 24px">
            <Flex flex="1" overflow="hidden" alignCenter>
              <ScryptoIcon size={18} />
              <T3
                display="flex"
                alignItems="center"
                marginLeft="12px"
                marginRight="16px"
                gap="8px"
                ellipsis
                medium
              >
                {teamTypes.map((role) => (
                  <TeamSymbol key={role} type={role} />
                ))}
              </T3>
            </Flex>
            <Flex alignCenter>
              <AwardIcon size={14} />
              <T2 margin="0 8px 0 3px" medium>
                {formatNumberCompact(rewards)}
              </T2>
              <Rocket size={14} />
              <T2 margin="0 8px 0 3px" medium>
                {formatNumberCompact(apps)}
              </T2>
              <FolderIcon size={14} />
              <T2 marginLeft="3px" medium>
                {formatNumberCompact(projects)}
              </T2>
            </Flex>
          </BottomBackground>
        </Flex>
      </Card>
    </Link>
  </Div>
);
