import { z } from "zod";
import {
  BooleanSchema,
  CategoryEnumZod,
  DescriptionSchema,
  HandleSchema,
  LongNameSchema,
  TagSchema,
  TeamTypeEnumZod,
  UrlSchema,
  VideoIdSchema,
} from "./common-schema";
import { isDev } from "consts";

export const FileReq = z
  .object({
    creatorHandle: HandleSchema,
    name: LongNameSchema,
    description: DescriptionSchema,

    image: UrlSchema,
    teamType: TeamTypeEnumZod,
    category: CategoryEnumZod,
    tags: z
      .array(TagSchema)
      .min(3, "Minimum of 3 tags")
      .max(5, "Maximum of 5 tags"),
    isActive: BooleanSchema.optional(),

    urls: z
      .array(
        UrlSchema.startsWith(
          "https://github.com/",
          "Must begin with: https://github.com"
        )
      )
      .min(1)
      .max(30),
    videoIds: z.array(VideoIdSchema).max(3).optional(),
    images: z.array(UrlSchema).min(1).max(7),
  })
  .refine(
    (data) => {
      return (
        isDev ||
        !data.urls.some((v) => {
          const [user] = v.replace("https://github.com/", "").split("/");
          return user !== data.creatorHandle;
        })
      );
    },
    {
      message: "Urls must be from your GitHub account",
      path: ["urls"],
    }
  );

export type FileReqType = z.infer<typeof FileReq>;
