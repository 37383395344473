import { RefObject, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  AppIconName,
  BaseButton,
  Button,
  DataTable,
  Div,
  Dropdown,
  Error,
  Flex,
  H2,
  H4,
  H6,
  IconNameSubtitle,
  InfiniteList,
  Link,
  MdRender,
  Modal,
  ProposalStatusDescriptions,
  SearchInput,
  T2,
  T20,
  TeamSymbol,
  useModal,
} from "library/components";
import {
  AppParams,
  BasePaths,
  MemberPaths,
  ProposalsTableCols,
  ProposalsTableHeadings,
} from "consts";
import { ChevronLeft, LoadingDots } from "library/assets";
import { awaitTo, formatDateShort, parseRtkError } from "library/utils";
import { authApi, proposalApi } from "redux/apis";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ProposalsMemberRes } from "redux/apiTypes";
import { PageSize } from "library/consts";
import { useMember } from "hooks";

const ProposalsRowRender = (d: ProposalsMemberRes[number]) => [
  <Div display="flex" alignItems="center">
    <IconNameSubtitle
      icon={d.project.app.icon}
      name={d.project.app.name}
      subtitle={d.project.app.subtitle}
    />
  </Div>,
  <T20 ellipsis medium>
    {d.project.name}
  </T20>,
  <Div display="flex" alignItems="center" justifyContent="center">
    <TeamSymbol type={d.project.teamType} />
  </Div>,
  ProposalStatusDescriptions[d.status],
  formatDateShort(d.createdAt),
];

const ProposalView = ({
  handleHide,
  overflowRef,
  proposal,
  isMember,
  refetchProposals,
}: {
  handleHide: () => void;
  overflowRef: RefObject<HTMLDivElement>;
  proposal: ProposalsMemberRes[number];
  isMember: Boolean;
  refetchProposals: () => Promise<void>;
}) => {
  const [handleViewMut] = proposalApi.useProposalViewMutation();
  const { refetch: refetchCounts } = authApi.useAuthGetCountsQuery();

  useEffect(() => {
    if (isMember && proposal.isUpdated) {
      const handleView = async () => {
        const [, res] = await awaitTo(
          handleViewMut({ proposalId: proposal.id }).unwrap()
        );
        if (res?.id) {
          refetchProposals();
          refetchCounts();
        }
      };
      handleView();
    }
  }, [handleViewMut, isMember, proposal, refetchCounts, refetchProposals]);

  return (
    <>
      <Flex marginBottom="24px">
        <Button display="flex" alignItems="center" onClick={() => handleHide()}>
          <ChevronLeft />
          <T2 marginLeft="4px" medium>
            Back
          </T2>
        </Button>
      </Flex>

      <Flex gap="24px" overflow="auto" alignCenter>
        <Link to={`/${BasePaths.a}/${proposal.project.app.appHandle}`}>
          <Flex alignCenter>
            <AppIconName
              icon={proposal.project.app.icon}
              name={proposal.project.app.name}
              noRightMargin
            />
          </Flex>
        </Link>

        <Link to={`/${BasePaths.p}/${proposal.project.projectHandle}`}>
          <H4 ellipsis>{proposal.name}</H4>
        </Link>
      </Flex>

      {proposal.comment && (
        <>
          <H6 margin="24px 0 16px">Comment</H6>
          <T2>{proposal.comment}</T2>
        </>
      )}

      <Div ref={overflowRef} flex="1" margin="24px 0 16px" overflow="auto">
        <MdRender text={proposal.description} />
      </Div>
    </>
  );
};

//
//
// Proposals Modal

export const ProposalsModal = () => {
  const { hideModal, show, showModal } = useModal();
  const proposalsPath = useMatch(
    `/${BasePaths.m}/:${AppParams.memberHandle}/${MemberPaths.projects}/${MemberPaths.proposals}`
  );
  const { memberHandle } = useParams();
  const navigate = useNavigate();
  const { isMember } = useMember();

  // Handle view proposal and resetting scroll position
  const [proposal, setProposal] = useState<ProposalsMemberRes[number]>();

  // Searching
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<ProposalsMemberRes>();
  const [isMounted, setIsMounted] = useState(false);
  const { data, error, isLoading, isFetching, refetch } =
    proposalApi.useProposalsMemberGetQuery(
      show && memberHandle
        ? {
            memberHandle,
            search: search || undefined,
            isUpdated: filter === "UPDATED" ? true : false,
            skip: skip || undefined,
          }
        : skipToken
    );
  const isLoadingAll = isLoading || isFetching;

  // Infinite Loader
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    } else {
      setList(undefined);
      setSkip(0);
    }
  }, [search, filter, isMounted]);

  // Show if match path
  useEffect(() => {
    if (proposalsPath) {
      showModal();
    } else {
      hideModal();
      setSearch("");
      setFilter("");
    }
  }, [proposalsPath, hideModal, showModal]);

  const overflowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (overflowRef.current && show) {
      overflowRef.current.scrollTop = 0;
    }
    if (show) {
      setProposal(undefined);
    }
  }, [show]);

  return (
    <>
      {show && (
        <Helmet>
          <title>Proposals</title>
          <meta name="description" content="View proposals of a member" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}

      <InfiniteList
        data={data}
        isLoading={isFetching}
        list={list}
        setList={(d) => {
          setList(d);
        }}
      />

      <Modal
        show={show}
        closeModal={() =>
          navigate(`/${BasePaths.m}/${memberHandle}/${MemberPaths.projects}`)
        }
        large
      >
        <Flex column fullHeight>
          {proposal ? (
            <ProposalView
              handleHide={() => setProposal(undefined)}
              overflowRef={overflowRef}
              proposal={proposal}
              isMember={isMember}
              refetchProposals={async () => {
                await refetch();
              }}
            />
          ) : (
            <>
              <H2 marginBottom="16px">Proposals</H2>
              <SearchInput
                handleSearch={(v) => setSearch(v)}
                isLoading={isLoadingAll}
                inputProps={{ secondary: true }}
                marginBottom="16px"
              />

              {isMember && (
                <Flex justifyEnd>
                  <Dropdown
                    handleSelect={(v) => setFilter(v)}
                    selectValue={filter}
                    options={[
                      { label: "All", value: "" },
                      { label: "Updated", value: "UPDATED" },
                    ]}
                    buttonProps={{ showBorder: true }}
                  />
                </Flex>
              )}

              <Div
                ref={overflowRef}
                flex="1"
                margin="24px 0 16px"
                overflow="auto"
              >
                {isLoading && (
                  <Flex height="300px" center>
                    <LoadingDots />
                  </Flex>
                )}

                {!isLoading && (
                  <DataTable
                    cols={ProposalsTableCols}
                    headings={ProposalsTableHeadings}
                    data={list}
                    rowRender={ProposalsRowRender}
                    handleRowClick={(d) => setProposal(d)}
                  />
                )}

                {list && !isLoadingAll && data?.length === PageSize && (
                  <Flex marginTop="32px" justifyCenter>
                    <BaseButton
                      onClick={() => setSkip(list.length)}
                      isLoading={isLoadingAll}
                      isSecondary
                    >
                      Load More
                    </BaseButton>
                  </Flex>
                )}

                {!isLoadingAll && (
                  <Error error={parseRtkError(error)} margin="16px 0" />
                )}
              </Div>
            </>
          )}
        </Flex>
      </Modal>
    </>
  );
};
