import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { css } from "styled-components";
import {
  PageDescriptions,
  PageTitles,
  PageNames,
  AppUrl,
  BasePaths,
  AboutPaths,
} from "consts";
import { GithubIcon } from "library/assets";
import {
  Div,
  Flex,
  H1,
  Link,
  Page,
  ThemeButtons,
  SocialButton,
  PX,
} from "library/components";
import { Colors } from "library/consts";
import { useTheme } from "redux/hooks";
import { getGithubOauth, getRandomState } from "utils";

const GitHubStyle = css`
  color: ${({ theme }) => (theme._isDark ? Colors.black : Colors.white)};
  background-color: ${({ theme }) =>
    theme._isDark ? Colors.white : Colors.black};
`;

export const SignIn = () => {
  const { state, actions } = useTheme();
  const { theme } = state;
  const { setTheme } = actions;

  const [oauthState, setOauthState] = useState("");

  useEffect(() => {
    const newState = getRandomState();
    setOauthState(newState);
    localStorage.setItem("oauthState", newState);
  }, []);

  return (
    <Page>
      <Helmet>
        <title>{PageTitles.signIn}</title>
        <meta name="description" content={PageDescriptions.signIn} />
        <link rel="canonical" href={`${AppUrl}/${BasePaths.signIn}`} />
      </Helmet>

      <Flex height="100%" center>
        <Div flex="1" maxWidth="320px">
          <H1 marginBottom="64px" bold>
            {PageNames.signIn}
          </H1>

          <SocialButton
            css={GitHubStyle}
            icon={<GithubIcon />}
            text="Sign in with"
            social="GitHub"
            href={getGithubOauth(oauthState)}
          />

          <Flex fontSize={PX(13)} gap="4px" marginTop="64px" alignCenter>
            <Link to={`/${BasePaths.about}/${AboutPaths.about}`}>About</Link>
            <Link to={`/${BasePaths.about}/${AboutPaths.terms}`}>Terms</Link>
            <Link to={`/${BasePaths.about}/${AboutPaths.privacy}`}>
              Privacy
            </Link>

            <Div flex="1" />

            <ThemeButtons theme={theme} setTheme={setTheme} />
          </Flex>
        </Div>
      </Flex>
    </Page>
  );
};
