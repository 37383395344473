import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BasePaths, ProjectPaths } from "consts";
import { Modal, useModal } from "library/components";
import { proposalApi } from "redux/apis";
import {
  ProposalReq as ProposalReqType,
  ProposalReviewReq as ProposalReviewReqType,
  ProposalsProjectRes,
} from "redux/apiTypes";
import { awaitTo, parseRtkError } from "library/utils";
import { ProposalReviewForm } from "./ProposalReviewForm";
import { ProposalForm } from "./ProposalForm";

type ProposalViewModalProps = {
  authHandle?: string;
  proposals: ProposalsProjectRes;
  isAMember?: boolean;
};

export const ProposalViewModal = ({
  authHandle,
  proposals,
  isAMember,
}: ProposalViewModalProps) => {
  const { hideModal, show, showModal } = useModal();
  const { projectHandle, memberHandle } = useParams();
  const navigate = useNavigate();
  const basePath = `/${BasePaths.p}/${projectHandle}/${ProjectPaths.proposals}`;

  const [handleUpdateMutation, { error: errorU, isLoading: isLoadingU }] =
    proposalApi.useProposalUpdateMutation();

  const [handleReviewMutation, { error: errorR, isLoading: isLoadingR }] =
    proposalApi.useProposalReviewMutation();

  const {
    refetch,
    isLoading: isLoadingP,
    error: errorP,
  } = proposalApi.useProposalsProjectGetQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  useEffect(() => {
    if (memberHandle) {
      showModal();
    } else {
      hideModal();
    }
  }, [memberHandle, hideModal, showModal]);

  const proposalIdx = proposals.findIndex(
    (p) => p.member.memberHandle === memberHandle
  );
  const proposal = proposalIdx !== -1 ? proposals[proposalIdx] : undefined;
  const title = `${proposal?.name} | ${memberHandle}` || "";

  const handleExit = () => navigate(basePath);

  const handleNav = (idx: 1 | -1) => {
    if (proposalIdx === undefined || proposalIdx === -1) return undefined;

    const handle =
      proposalIdx + idx >= 0 && proposalIdx + idx < proposals.length
        ? proposals[proposalIdx + idx].member.memberHandle
        : undefined;
    return handle ? () => navigate(`${basePath}/${handle}`) : undefined;
  };

  const handleReview = async (data: ProposalReviewReqType) => {
    if (!proposal) return;
    if (!data.comment) return;
    const [, res] = await awaitTo(
      handleReviewMutation({
        proposalReviewReq: data,
        proposalId: proposal.id,
      }).unwrap()
    );
    if (res?.id) {
      await refetch();
      return res;
    }
    return undefined;
  };

  const handleUpdate = async (data: ProposalReqType) => {
    if (!proposal) return;
    const [, res] = await awaitTo(
      handleUpdateMutation({
        proposalReq: data,
        proposalId: proposal.id,
      }).unwrap()
    );
    if (res?.id) {
      await refetch();
      hideModal();
      return res;
    }
    return undefined;
  };

  const isEditable =
    authHandle && proposal
      ? proposal.member.memberHandle === authHandle
      : false;

  return (
    <>
      {show && proposal && (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={title} />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}

      <Modal show={show} closeModal={handleExit} large>
        <ProposalForm
          error={parseRtkError(errorU || errorP)}
          isLoading={isLoadingU || isLoadingP}
          handleCreateUpdate={handleUpdate}
          data={proposal}
          isEditable={isEditable}
        />
        {isAMember && (
          <ProposalReviewForm
            error={parseRtkError(errorR || errorP)}
            isLoading={isLoadingR || isLoadingP}
            data={proposal}
            handleReview={handleReview}
            handleLeft={handleNav(-1)}
            handleRight={handleNav(1)}
            authHandle={authHandle}
          />
        )}
      </Modal>
    </>
  );
};
