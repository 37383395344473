import {
  MemberCreateApiRes,
  MemberCreateApiArg,
  MemberUpdateApiRes,
  MemberUpdateApiArg,
  MemberUpdateComponentApiRes,
  MemberUpdateComponentApiArg,
  MemberDeleteApiRes,
  MemberDeleteApiArg,
  MemberGetProfileApiRes,
  MemberGetProfileApiArg,
  MemberGetDashboardApiRes,
  MemberGetDashboardApiArg,
  MemberGetProjectsApiRes,
  MemberGetProjectsApiArg,
  MemberGetTeamsApiRes,
  MemberGetTeamsApiArg,
  MemberGetVestingsApiRes,
  MemberGetVestingsApiArg,
  MemberGetFilesApiRes,
  MemberGetFilesApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    memberCreate: build.mutation<MemberCreateApiRes, MemberCreateApiArg>({
      query: (queryArg) => ({
        url: `/member/create/github`,
        method: "POST",
        body: queryArg.memberComponentReq,
      }),
    }),
    memberUpdate: build.mutation<MemberUpdateApiRes, MemberUpdateApiArg>({
      query: (queryArg) => ({
        url: `/member/update`,
        method: "PUT",
        body: queryArg.memberReq,
      }),
    }),
    memberUpdateComponent: build.mutation<
      MemberUpdateComponentApiRes,
      MemberUpdateComponentApiArg
    >({
      query: (queryArg) => ({
        url: `/member/update-component`,
        method: "PUT",
        body: queryArg.memberComponentReq,
      }),
    }),
    memberDelete: build.mutation<MemberDeleteApiRes, MemberDeleteApiArg>({
      query: () => ({ url: `/member/delete`, method: "DELETE" }),
    }),
    memberGetProfile: build.query<
      MemberGetProfileApiRes,
      MemberGetProfileApiArg
    >({
      query: (queryArg) => ({ url: `/member/${queryArg.memberHandle}` }),
    }),
    memberGetDashboard: build.query<
      MemberGetDashboardApiRes,
      MemberGetDashboardApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberHandle}/dashboard`,
        params: {
          appHandle: queryArg.appHandle,
          search: queryArg.search,
          isUpdated: queryArg.isUpdated,
          take: queryArg.take,
          skip: queryArg.skip,
        },
      }),
    }),
    memberGetProjects: build.query<
      MemberGetProjectsApiRes,
      MemberGetProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberHandle}/projects`,
        params: {
          appHandle: queryArg.appHandle,
          teamType: queryArg.teamType,
          state: queryArg.state,
          isCancelled: queryArg.isCancelled,
          reviewStatus: queryArg.reviewStatus,
          tokenSymbol: queryArg.tokenSymbol,
          sort: queryArg.sort,
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    memberGetTeams: build.query<MemberGetTeamsApiRes, MemberGetTeamsApiArg>({
      query: (queryArg) => ({ url: `/member/${queryArg.memberHandle}/teams` }),
    }),
    memberGetVestings: build.query<
      MemberGetVestingsApiRes,
      MemberGetVestingsApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberHandle}/vestings`,
        params: {
          search: queryArg.search,
          category: queryArg.category,
          take: queryArg.take,
          skip: queryArg.skip,
          isSaved: queryArg.isSaved,
        },
      }),
    }),
    memberGetFiles: build.query<MemberGetFilesApiRes, MemberGetFilesApiArg>({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberHandle}/files`,
        params: {
          appHandle: queryArg.appHandle,
          search: queryArg.search,
          isUpdated: queryArg.isUpdated,
          take: queryArg.take,
          skip: queryArg.skip,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as memberApi };
