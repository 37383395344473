import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";

export const jwtActionConsts = {
  SET_IS_SIGNED_IN: "SET_IS_SIGNED_IN",
};

const setIsSignedIn = (isSignedIn: boolean) => async (dispatch: AppDispatch) =>
  dispatch(createAction<boolean>(jwtActionConsts.SET_IS_SIGNED_IN)(isSignedIn));

export const jwtActions = {
  setIsSignedIn,
};
