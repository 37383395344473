import { ReactNode } from "react";
import { Div, Main } from "library/components";
import { Width } from "library/consts";

type PageProps = {
  children: ReactNode;
};

export const Page = ({ children }: PageProps) => (
  <Main flex="1" display="flex" justifyContent="center">
    <Div
      width="100%"
      maxWidth={`${Width.LG}px`}
      padding="0 16px 290px 16px"
      sm={{ padding: "0 32px 240px 32px" }}
    >
      {children}
    </Div>
  </Main>
);
