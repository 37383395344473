import { useMemo } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { contractActions } from "redux/actions";
import { useAppDispatch, useAppSelector } from "redux/store";

export const useContract = () => {
  const state = useAppSelector((state) => state.contractReducer);
  const dispatch = useAppDispatch();
  const actions = useMemo(
    () => bindActionCreators(contractActions, dispatch),
    [dispatch]
  );

  return { state, actions };
};
