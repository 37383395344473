import { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Div, Iframe as BaseIframe, Img } from "library/components";
import { Width } from "library/consts";
import { YoutubeIcon } from "library/assets";

const MobileGrid = styled(Div)`
  // Mobile (XS)
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding: 0 16px;

  // Tablet (SM)
  @media only screen and (min-width: ${Width.SM}px) {
    display: none;
  }
`;

const PreviewGrid = styled(Div)`
  // Mobile (XS)
  display: none;

  // Tablet (SM)
  @media only screen and (min-width: ${Width.SM}px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(128px, 1fr));
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
    grid-gap: 32px;
  }
`;

const Iframe = styled(BaseIframe)`
  height: 100%;
  width: 100%;
  user-drag: none;
  user-select: none;
`;

type AssetProp = {
  imageUrl?: string;
  videoUrl?: string;
};

type GalleryProps = {
  data: AssetProp[];
};

export const Gallery = ({ data: dataArray }: GalleryProps) => {
  const [index, setIndex] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);

  const setOverlay = (val: boolean) => {
    if (val) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
    setShowOverlay(val);
  };

  useEffect(() => {
    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  const data =
    dataArray.length > 0 ? dataArray : [{ imageUrl: "", videoUrl: null }];
  const { imageUrl, videoUrl } = data[index];

  const isDesktop = window.matchMedia(`(min-width: ${Width.SM}px)`).matches;

  return (
    <Div
      width="calc(100% + 32px)"
      position="relative"
      left="-16px"
      sm={{ width: "100%", left: "0" }}
    >
      <Card
        display="none"
        width="100%"
        aspectRatio="3/4"
        marginBottom="16px"
        sm={{ display: "block", aspectRatio: "16/9", marginBottom: "32px" }}
      >
        {videoUrl && isDesktop ? (
          <Iframe
            src={`${videoUrl}?autoplay=1`}
            title={`${"AppName"} YouTube video player`}
            frameBorder="0"
            allow="autoplay; encrypted-media; fullscreen"
          />
        ) : (
          <Img
            as={!imageUrl ? "div" : "img"}
            height="100%"
            width="100%"
            cursor={imageUrl ? "pointer" : undefined}
            onClick={() => imageUrl && setOverlay(true)}
            src={imageUrl}
            alt={`Gallery ${index + 1}`}
          />
        )}
      </Card>

      <PreviewGrid>
        {data.map((d, i) => (
          <Card
            key={i}
            width="100%"
            aspectRatio="4/3"
            position="relative"
            cursor={d.imageUrl ? "pointer" : undefined}
            borderRadius="16px"
            onClick={() => d.imageUrl && setIndex(i)}
          >
            <Img
              as={!d.imageUrl ? "div" : "img"}
              height="100%"
              width="100%"
              src={d.imageUrl}
              alt={`Gallery ${i + 1}`}
            />
            {d.videoUrl && (
              <Div
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                top="0"
                left="0"
              >
                <Div zIndex="2" display="flex">
                  <YoutubeIcon fill="#FF0000" size={36} />
                </Div>
                <Div
                  height="12px"
                  width="12px"
                  position="absolute"
                  zIndex="1"
                  margin="auto"
                  backgroundColor="white"
                />
              </Div>
            )}
          </Card>
        ))}
      </PreviewGrid>

      <MobileGrid>
        {data.map((d, i) => (
          <Card
            key={i}
            minWidth="calc(100% - 8px)"
            aspectRatio="3/4"
            cursor={d.imageUrl ? "pointer" : undefined}
            borderRadius="16px"
            onClick={() => {
              if (!d.imageUrl || d.videoUrl) return;
              setIndex(i);
              setOverlay(true);
            }}
          >
            {d.videoUrl && !isDesktop ? (
              <Iframe
                src={d.videoUrl}
                title={`${"AppName"} YouTube video player`}
                frameBorder="0"
                allow="encrypted-media; fullscreen"
              />
            ) : (
              <Img
                as={!d.imageUrl ? "div" : "img"}
                height="100%"
                width="100%"
                src={d.imageUrl}
                alt={`Gallery ${i + 1}`}
              />
            )}
          </Card>
        ))}
      </MobileGrid>

      {showOverlay && (
        <Card
          height="100%"
          width="100%"
          position="fixed"
          top="0"
          left="0"
          zIndex="100"
          borderRadius="0"
          cursor="pointer"
        >
          <Img
            as={!imageUrl ? "div" : "img"}
            height="100%"
            width="100%"
            objectFit="contain"
            onClick={() => setOverlay(false)}
            src={imageUrl}
            alt={`Gallery ${index + 1}`}
          />
        </Card>
      )}
    </Div>
  );
};
