import styled from "styled-components";
import { baseMixin } from "library/components";
import { BoxShadowStyle } from "library/styles";
import { CssPropsType } from "library/types";

export const Card = styled.div<CssPropsType>`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  ${BoxShadowStyle}
  ${baseMixin}
`;
