import { z } from "zod";
import { HandleSchema, StringSchema } from "./common-schema";

export const WalletReq = z
  .object({
    memberHandle: HandleSchema,
    accountAddress: StringSchema,
  })
  .refine((data) => data.accountAddress.includes("account_"), {
    message: "Must be a valid account address",
    path: ["accountAddress"],
  });

export type WalletReqType = z.infer<typeof WalletReq>;
