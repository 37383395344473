import {
  BackgroundButton,
  Div,
  Flex,
  H1,
  H2,
  Img,
  PX,
  Span,
  T1,
  T2,
  T3,
  TX,
} from "library/components";
import { Colors } from "library/consts";
import { CSSProp } from "styled-components";

type IconNameSubtitleProps = {
  icon?: string;
  name: string;
  subtitle: string;
};

export const IconNameSubtitle = ({
  icon,
  name,
  subtitle,
}: IconNameSubtitleProps) => (
  <>
    <Img
      as={!icon ? "div" : "img"}
      height="48px"
      width="48px"
      borderRadius="16px"
      backgroundColor={Colors.border}
      aspectRatio="1"
      src={icon}
      alt={name}
    />
    <Div marginLeft="12px" flex="1" overflow="hidden">
      {/* Both name and subtitle have max length of 30 Characters */}
      {/* https://developer.apple.com/app-store/product-page/ */}
      <T1 fontWeight="500" marginBottom="4px" ellipsis medium>
        {name}
      </T1>
      <T2 ellipsis>{subtitle}</T2>
    </Div>
  </>
);

type CardHeadingProps = IconNameSubtitleProps & {
  css?: CSSProp;
};

export const CardHeading = ({
  css,
  icon,
  name,
  subtitle,
}: CardHeadingProps) => (
  <Flex
    css={css}
    maxWidth="100%"
    padding="16px"
    sm={{ padding: "18px" }}
    alignCenter
  >
    <IconNameSubtitle icon={icon} name={name} subtitle={subtitle} />

    <Div
      backgroundColor={Colors.blue}
      marginLeft="20px"
      padding="6px 12px"
      borderRadius="16px"
    >
      <T3 color={Colors.white} medium>
        Join
      </T3>
    </Div>
  </Flex>
);

type LargeCardHeadingProps = CardHeadingProps & {
  appUrl?: string | null;
};

export const LargeCardHeading = ({
  appUrl,
  css,
  icon,
  name,
  subtitle,
}: LargeCardHeadingProps) => (
  <Flex css={css} padding="16px 20px" sm={{ padding: "32px 24px" }} alignCenter>
    <Img
      as={!icon ? "div" : "img"}
      sm={{ height: "72px", width: "72px", borderRadius: "24px" }}
      height="54px"
      width="54px"
      borderRadius="18px"
      backgroundColor={Colors.border}
      aspectRatio="1"
      src={icon}
      alt={name}
    />
    <Div
      marginLeft="16px"
      flex="1"
      overflow="hidden"
      sm={{ marginLeft: "20px" }}
    >
      <H1
        fontSize={PX(18)}
        marginBottom="6px"
        sm={{ fontSize: PX(22), marginBottom: "8px" }}
        bold
        ellipsis
      >
        {name}
      </H1>
      <H2
        as="p"
        fontSize={PX(12)}
        fontWeight="400"
        sm={{ fontSize: PX(15) }}
        ellipsis
      >
        {subtitle}
      </H2>
    </Div>

    {appUrl && (
      <BackgroundButton
        marginLeft="16px"
        padding="8px 12px"
        borderRadius="32px"
        as="a"
        href={appUrl}
        target="_blank"
      >
        <Span color={Colors.blue} fontSize={PX(13)} medium>
          Open
        </Span>
      </BackgroundButton>
    )}
  </Flex>
);

type MemberHeadingProps = {
  icon?: string | null;
  memberHandle: string;
  name: string;
};

type UserIconNameHandleProps = MemberHeadingProps & {
  small?: boolean;
  borderRadius?: string;
};

export const UserIconNameHandle = ({
  icon,
  memberHandle,
  name,
  small = false,
  borderRadius,
}: UserIconNameHandleProps) => (
  <>
    <Img
      as={!icon ? "div" : "img"}
      height={small ? "40px" : "48px"}
      width={small ? "40px" : "48px"}
      borderRadius={borderRadius || "50%"}
      backgroundColor={Colors.border}
      aspectRatio="1"
      src={icon || ""}
      alt={memberHandle}
    />
    <Div marginLeft="12px" flex="1" overflow="hidden">
      {/* Subtitle have max length of 50 Characters */}
      <TX
        fontSize={small ? "12px" : "15px"}
        marginBottom={small ? "2px" : "4px"}
        ellipsis
        medium
      >
        {name}
      </TX>
      <TX fontSize={small ? "11px" : "13px"} ellipsis>
        @{memberHandle}
      </TX>
    </Div>
  </>
);

export const MemberHeading = ({
  icon,
  memberHandle,
  name,
}: MemberHeadingProps) => (
  <Flex padding="24px" sm={{ padding: "32px 24px" }} alignCenter>
    <Img
      as={!icon ? "div" : "img"}
      sm={{ height: "72px", width: "72px" }}
      height="54px"
      width="54px"
      borderRadius="50%"
      backgroundColor={Colors.border}
      aspectRatio="1"
      src={icon || ""}
      alt={name}
    />
    <Div
      marginLeft="16px"
      flex="1"
      overflow="hidden"
      sm={{ marginLeft: "20px" }}
    >
      <H1
        fontSize={PX(18)}
        marginBottom="6px"
        sm={{ fontSize: PX(22), marginBottom: "8px" }}
        bold
        ellipsis
      >
        {name}
      </H1>
      <T1 fontSize={PX(12)} sm={{ fontSize: PX(15) }} ellipsis medium>
        @{memberHandle}
      </T1>
    </Div>
  </Flex>
);

type AppIconNameProps = {
  icon?: string | null;
  name: string;
  medium?: boolean;
  noRightMargin?: boolean;
};

export const AppIconName = ({
  icon,
  name,
  medium = false,
  noRightMargin = false,
}: AppIconNameProps) => (
  <>
    <Img
      as={!icon ? "div" : "img"}
      height={medium ? "32px" : "28px"}
      width={medium ? "32px" : "28px"}
      minWidth={medium ? "32px" : "28px"}
      borderRadius={medium ? "10px" : "9px"}
      backgroundColor={Colors.border}
      aspectRatio="1"
      src={icon || ""}
      alt={name}
    />
    <Div
      marginLeft="12px"
      marginRight={!noRightMargin ? "24px" : undefined}
      overflow="hidden"
    >
      <T1 fontSize={medium ? PX(17) : undefined} ellipsis medium>
        {name}
      </T1>
    </Div>
  </>
);

export const UserImage = ({
  icon,
  handle,
  isRound,
}: {
  icon?: string | null;
  handle: string;
  isRound?: boolean;
}) => (
  <>
    <Img
      as={!icon ? "div" : "img"}
      height={"20px"}
      width={"20px"}
      minWidth={"20px"}
      borderRadius={isRound ? "50%" : "7px"}
      backgroundColor={Colors.border}
      aspectRatio="1"
      src={icon || ""}
      alt={handle}
    />
    <Div marginLeft="8px" overflow="hidden">
      <T2 ellipsis medium>
        {handle}
      </T2>
    </Div>
  </>
);
