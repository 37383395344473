import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ContractTypes } from "library/types";
import {
  AdminContract,
  AppContract,
  MemberContract,
  ProjectContract,
  VestingContract,
} from "radix";
import { contractActionConsts } from "redux/actions";

const {
  RESET_CONTRACT,
  SET_ADMIN_CONTRACT,
  SET_APP_CONTRACT,
  SET_MEMBER_CONTRACT,
  SET_PROJECT_CONTRACT,
  SET_VESTING_CONTRACT,
} = contractActionConsts;

type ContractReducerType = {
  adminContract: AdminContract | undefined;
  appContract: AppContract | undefined;
  memberContract: MemberContract | undefined;
  projectContract: ProjectContract | undefined;
  vestingContract: VestingContract | undefined;
};

const ContractReducerState: ContractReducerType = {
  adminContract: undefined,
  appContract: undefined,
  memberContract: undefined,
  projectContract: undefined,
  vestingContract: undefined,
};

export const contractReducer = createReducer(
  ContractReducerState,
  (builder) => {
    builder
      .addCase(
        RESET_CONTRACT,
        (state, { payload }: PayloadAction<ContractTypes>) => {
          if (payload === "admin" && state.adminContract) {
            state.adminContract.IS_RESET = true;
          } else if (payload === "app" && state.appContract) {
            state.appContract.IS_RESET = true;
          } else if (payload === "member" && state.memberContract) {
            state.memberContract.IS_RESET = true;
          } else if (payload === "project" && state.projectContract) {
            state.projectContract.IS_RESET = true;
          } else if (payload === "vesting" && state.vestingContract) {
            state.vestingContract.IS_RESET = true;
          }
        }
      )
      .addCase(
        SET_ADMIN_CONTRACT,
        (state, { payload }: PayloadAction<AdminContract>) => {
          state.adminContract = payload;
        }
      )
      .addCase(
        SET_APP_CONTRACT,
        (state, { payload }: PayloadAction<AppContract>) => {
          state.appContract = payload;
        }
      )
      .addCase(
        SET_MEMBER_CONTRACT,
        (state, { payload }: PayloadAction<MemberContract>) => {
          state.memberContract = payload;
        }
      )
      .addCase(
        SET_PROJECT_CONTRACT,
        (state, { payload }: PayloadAction<ProjectContract>) => {
          state.projectContract = payload;
        }
      )
      .addCase(
        SET_VESTING_CONTRACT,
        (state, { payload }: PayloadAction<VestingContract>) => {
          state.vestingContract = payload;
        }
      );
  }
);
