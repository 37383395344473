import { MemberDropdown } from "../Dropdown/MemberDropdown";
import { Flex, OptionButton, T2 } from "library/components";
import { RadixIcon, USDCIcon } from "library/assets";
import { formatNumber } from "library/utils";
import { MemberType, TokenSymbol } from "library/types";

type MemberCardOptionsProps = {
  amount?: string | null;
  handleSelectRequester?: (val: string) => void;
  handleSelectOwner?: (val: string) => void;
  members: MemberType[];
  owners?: MemberType[];
  requesters?: MemberType[];
  tokenSymbol?: string | null;
  showMembers?: boolean;
};

export const MemberCardOptions = ({
  amount,
  handleSelectRequester,
  handleSelectOwner,
  members,
  owners,
  requesters,
  tokenSymbol,
  showMembers,
}: MemberCardOptionsProps) => (
  <>
    {showMembers && (
      <MemberDropdown
        handleSelectMember={() => {}}
        heading="Members"
        selected={members.map((r) => r.id)}
        members={members}
        wrapperProps={{ marginBottom: "8px" }}
        // disabled
      />
    )}

    {handleSelectRequester && requesters && (
      <MemberDropdown
        handleSelectMember={handleSelectRequester}
        heading="Requesters"
        selected={requesters.map((r) => r.id)}
        members={members}
        wrapperProps={{ marginBottom: "8px" }}
        disabled
      />
    )}

    {handleSelectOwner && owners && (
      <MemberDropdown
        handleSelectMember={handleSelectOwner}
        heading="Owners"
        selected={owners.map((o) => o.id)}
        members={members}
        wrapperProps={{ marginBottom: "8px" }}
        disabled
      />
    )}

    <OptionButton display="block" borderRadius="8px" showBorder textActive>
      <T2 marginBottom="4px" normal>
        Budget
      </T2>
      <Flex alignCenter>
        {tokenSymbol === TokenSymbol.XRD && <RadixIcon size={16} />}
        {tokenSymbol === TokenSymbol.USDC && <USDCIcon size={16} />}
        <T2 marginLeft="6px" medium>
          {formatNumber(amount || 0)} {tokenSymbol || TokenSymbol.XRD}
        </T2>
      </Flex>
    </OptionButton>
  </>
);
