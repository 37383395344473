import { Dispatch, SetStateAction, useState } from "react";
import styled, { css } from "styled-components";
import { To, useLocation } from "react-router-dom";
import { BasePaths } from "consts";
import {
  Bookmark,
  ChevronLeft,
  LoadingDots,
  SettingsIcon,
  UserCircle,
} from "library/assets";
import {
  CountTag,
  Div,
  Flex,
  Link,
  Nav,
  T1,
  Tab as BaseTab,
  TabLink as BaseTabLink,
  UserIcon,
} from "library/components";
import { Width } from "library/consts";
import { useBack } from "library/hooks";
import { parseBaseRoute } from "utils";
import { useMember } from "hooks";

const HomeLink = styled(Link)`
  align-items: center;
`;

const TabStyle = css`
  height: 100%;
  font-weight: 500;
  user-select: none;

  padding: 0px 12px;
  @media only screen and (min-width: ${Width.SM}px) {
    padding: 0px 24px;
  }
`;

const TabLink = styled(BaseTabLink)`
  ${TabStyle}
`;

const Tab = styled(BaseTab)`
  ${TabStyle}
`;

export const NavBar = () => {
  const { pathname } = useLocation();
  const route = parseBaseRoute(pathname);
  const { home, projects, library, apps, members, signIn } = BasePaths;
  const { auth, counts, isLoading } = useMember();
  const count = counts
    ? counts.appInvites + counts.projectInvites + counts.proposals
    : 0;

  return (
    <Nav
      height="50px"
      display="none"
      justifyContent="center"
      sm={{ display: "flex" }}
    >
      <Div
        height="100%"
        width="100%"
        maxWidth={`${Width.LG}px`}
        display="flex"
        justifyContent="space-between"
      >
        <Div height="100%" width="170px">
          <HomeLink
            to={home}
            height="100%"
            display="flex"
            padding="0 24px 0 32px"
          >
            <T1
              userSelect="none"
              textShadow="0 0 4px rgba(255, 255, 255, 0.5)"
              fontWeight="700"
            >
              launchspace
            </T1>
          </HomeLink>
        </Div>

        <Div
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <TabLink to={projects} $active={route === projects}>
            Projects
          </TabLink>
          <TabLink to={library} $active={route === library}>
            Library
          </TabLink>
          <TabLink to={apps} $active={route === apps || route === members}>
            Teams
          </TabLink>
        </Div>

        <Div
          height="100%"
          width="170px"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          paddingRight="8px"
        >
          {isLoading ? (
            <Flex css={TabStyle} center>
              <Flex height="28px" width="28px" center>
                <LoadingDots />
              </Flex>
            </Flex>
          ) : auth ? (
            <TabLink
              to={`/${BasePaths.m}/${auth.memberHandle}`}
              position="relative"
            >
              <CountTag count={count} extraMargin />
              <UserIcon {...auth} height="28px" width="28px" />
            </TabLink>
          ) : (
            <TabLink to={signIn} $active={route === signIn}>
              <UserCircle size={28} />
            </TabLink>
          )}
        </Div>
      </Div>
    </Nav>
  );
};

// --- Back Button

type WindowState =
  | {
      idx?: number;
      backIdx?: number;
      [key: string]: any;
    }
  | undefined;

const replaceState = (state: object) => {
  window.history.replaceState({ ...window.history.state, ...state }, "");
};

const isNum = (n?: number) => typeof n === "number" && n >= 0;

const setIdxs = (
  backIdx: number | undefined,
  setBackIdx: Dispatch<SetStateAction<number | undefined>>
) => {
  const state = window.history.state as WindowState;
  if (typeof state !== "object") {
    const idx = window.history.length - 1;
    const back = idx;
    replaceState({ idx, backIdx: back });
    setBackIdx(back);
  } else if (!isNum(state.idx) || !isNum(state.backIdx) || !isNum(backIdx)) {
    const idx = isNum(state.idx) ? state.idx : window.history.length - 1;
    const back = isNum(backIdx)
      ? backIdx
      : isNum(state.backIdx)
      ? state.backIdx
      : idx;
    replaceState({ idx, backIdx: back });
    setBackIdx(back);
  }
};

// --- Back Bar

type BackNavBarProps = {
  isLoading?: boolean;
  isSaved?: boolean;
  isSettings?: boolean;
  showSave?: boolean;
  handleSave?: () => void;
  routes: {
    label: string;
    value: To;
  }[];
  currRoute?: string;
  counts?: {
    [key: number]: number;
  };
};

export const BackNavBar = ({
  isLoading = false,
  isSaved = false,
  isSettings = false,
  showSave = false,
  handleSave,
  routes,
  currRoute,
  counts,
}: BackNavBarProps) => {
  const { pathname } = useLocation();
  const splits = pathname.split("/");
  const route = splits[3] || currRoute;
  const { handleBack } = useBack();

  const [backIdx, setBackIdx] = useState<number | undefined>(undefined);
  setIdxs(backIdx, setBackIdx);

  return (
    <Div height="50px" display="flex" justifyContent="center">
      <Div
        height="100%"
        width="100%"
        maxWidth={`${Width.LG}px`}
        display="flex"
        sm={{ padding: "0 8px" }}
      >
        <Div>
          <Tab
            height="100%"
            onClick={() => {
              handleBack();
            }}
          >
            <ChevronLeft size={28} />
          </Tab>
        </Div>

        <Div flex="1" display="flex" overflow="auto">
          {routes.map((r, i) => (
            <TabLink
              key={r.label}
              to={r.value}
              $active={!i ? !route : route === r.value}
              position="relative"
            >
              {counts?.[i] ? <CountTag count={counts[i]} /> : null}
              {r.label}
            </TabLink>
          ))}
        </Div>

        {routes.length > 0 && (
          <Div>
            {isLoading ? (
              <Flex css={TabStyle} center>
                <LoadingDots />
              </Flex>
            ) : isSettings ? (
              <TabLink
                to={BasePaths.settings}
                $active={route === BasePaths.settings}
              >
                <SettingsIcon />
              </TabLink>
            ) : showSave && handleSave ? (
              <Tab height="100%" onClick={() => handleSave()}>
                <Bookmark fill={isSaved ? "currentColor" : "none"} />
              </Tab>
            ) : null}
          </Div>
        )}
      </Div>
    </Div>
  );
};
