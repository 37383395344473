import styled from "styled-components";
import { baseMixin } from "library/components";
import { CssPropsType } from "library/types";

export const Div = styled.div<
  CssPropsType & { fullHeight?: boolean; fullWidth?: boolean }
>`
  height: ${({ fullHeight }) => fullHeight && "100%"};
  width: ${({ fullWidth }) => fullWidth && "100%"};
  ${baseMixin}
`;

type FlexType = {
  fullHeight: boolean;
  fullWidth: boolean;
  column: boolean;
  center: boolean;
  alignStart: boolean;
  alignCenter: boolean;
  alignEnd: boolean;
  justifyCenter: boolean;
  justifyEnd: boolean;
  justifyBetween: boolean;
};

export const Flex = styled.div<CssPropsType & Partial<FlexType>>`
  height: ${({ fullHeight }) => fullHeight && "100%"};
  width: ${({ fullWidth }) => fullWidth && "100%"};

  display: flex;
  flex-direction: ${({ column }) => column && "column"};
  align-items: ${({ center, alignStart, alignCenter, alignEnd }) =>
    center || alignCenter
      ? "center"
      : alignStart
      ? "flex-start"
      : alignEnd
      ? "flex-end"
      : ""};
  justify-content: ${({ center, justifyCenter, justifyEnd, justifyBetween }) =>
    center || justifyCenter
      ? "center"
      : justifyEnd
      ? "flex-end"
      : justifyBetween
      ? "space-between"
      : ""};

  ${baseMixin}
`;

export const Main = styled.main<CssPropsType>`
  ${baseMixin}
`;

export const Section = styled.section<CssPropsType>`
  ${baseMixin}
`;

export const Header = styled.header<CssPropsType>`
  ${baseMixin}
`;

export const Nav = styled.nav<CssPropsType>`
  ${baseMixin}
`;

export const Footer = styled.footer<CssPropsType>`
  ${baseMixin}
`;

export const Ul = styled.ul<CssPropsType>`
  ${baseMixin}
`;

export const Li = styled.li<CssPropsType>`
  ${baseMixin}
`;

export const Hr = styled.hr<CssPropsType & { isDashed?: boolean }>`
  border: 1px ${({ isDashed }) => (isDashed ? "dashed" : "solid")}
    ${({ theme }) => theme.borderColor};
  ${baseMixin}
`;

export const Divider = styled.div<CssPropsType & { isBorder?: boolean }>`
  height: 20px;
  width: 2px;
  min-width: 2px;
  border-radius: 1px;
  background-color: ${({ theme, isBorder }) =>
    isBorder ? theme.borderColor : theme.buttonTextColorActive};
  ${baseMixin}
`;

export const Img = styled.img<CssPropsType>`
  overflow: hidden;
  object-fit: cover;
  user-drag: none;
  user-select: none;
  ${baseMixin}
`;

export const Iframe = styled.iframe<CssPropsType>`
  ${baseMixin}
`;
