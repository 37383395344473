import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TeamRole, TeamType } from "library/types";
import { getNftData, parseFileData } from "radix";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { authApi, fileApi } from "redux/apis";
import { useFileState, useJwt } from "redux/hooks";

export const useFile = (isGetContract?: boolean) => {
  const { fileHandle } = useParams();
  const { state: fileState, actions: fileActions } = useFileState();
  const { file: nft } = fileState;
  const { setFile, resetFile } = fileActions;

  const { state } = useJwt();
  const { isSignedIn } = state;
  const {
    data: auth,
    error: errorAuth,
    isLoading: isLoadingAuth,
  } = authApi.useAuthGetUserQuery(undefined, {
    skip: !isSignedIn,
  });
  const {
    data: file,
    error: errorFile,
    isLoading: isLoadingFile,
  } = fileApi.useFilesGetQuery(fileHandle ? { fileHandle } : skipToken);

  const isAdmin =
    auth &&
    file?.app?.memberTeamRoles.some(
      (v) =>
        v.memberId === auth.id &&
        (v.teamRole === TeamRole.TEAM_LEAD || v.teamType === TeamType.ADMIN)
    );

  const fileResourceAddress =
    file?.app?.fileResourceAddress || file?.member?.fileResourceAddress;

  useEffect(() => {
    const splits = fileHandle?.split("-");
    const number = splits ? Number(splits[splits.length - 1]) : undefined;
    if (!fileResourceAddress || !number) {
      return;
    }
    const getNft = async () => {
      const res = await getNftData(fileResourceAddress, number, number - 1);
      const [parsed] = res ? parseFileData(res) : [];
      if (parsed) {
        setFile(parsed);
      }
    };
    if (isGetContract && (!nft || nft.IS_RESET)) {
      getNft();
    }
  }, [fileHandle, fileResourceAddress, isGetContract, nft, setFile]);

  return {
    auth,
    file,
    nft,
    fileResourceAddress,
    resetFile: async () => {
      resetFile();
    },
    isMember: file && auth && file.memberId === auth.id,
    isAdmin,
    error: errorAuth || errorFile,
    isLoading: isLoadingAuth || isLoadingFile || !nft,
    isSignedIn,
  };
};
