import { StateEntityDetailsResponseItemDetails } from "@radixdlt/radix-dapp-toolkit";
import { PACKAGE_ADDRESS } from "consts";
import { Blueprints, NonFungibleData, StateValue } from "../radix-types";
import { parseTuple } from "./parser-utils";

export type AdminContract = {
  address: string;
  IS_RESET: boolean; // used for redux store reset
  nfts: NonFungibleData[];
};

export const parseAdminContract = (
  details: StateEntityDetailsResponseItemDetails,
  address: string
) => {
  if (
    details.type !== "Component" ||
    details.package_address !== PACKAGE_ADDRESS ||
    details.blueprint_name !== Blueprints.AdminContract ||
    !details.state
  ) {
    return undefined;
  }
  const s = parseTuple(details.state as StateValue);
  if (!s) {
    return undefined;
  }

  const adminState: AdminContract = {
    address,
    IS_RESET: false,
    nfts: [],
  };

  return adminState;
};
