import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import isPropValid from "@emotion/is-prop-valid";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { AppParams, BasePaths } from "consts";
import { ErrorBoundary } from "library/components";
import { Themes } from "library/consts";
import { GlobalStyle } from "library/styles";
import { useTheme } from "redux/hooks";
import { JwtRedirect, Navigation } from "./components";
import {
  Home,
  Library,
  NotFound,
  Projects,
  SignIn,
  OAuth,
  Apps,
  Members,
} from "./pages";

const AboutRoutes = lazy(() => import("./routes/AboutRoutes"));
const ProjectRoutes = lazy(() => import("./routes/ProjectRoutes"));
const AppRoutes = lazy(() => import("./routes/AppRoutes"));
const AppCreateRoutes = lazy(() => import("./routes/AppCreateRoutes"));
const MemberRoutes = lazy(() => import("./routes/MemberRoutes"));
const FileRoutes = lazy(() => import("./routes/FileRoutes"));

export const ViewRoutes = () => {
  const { state } = useTheme();
  const { currentTheme } = state;
  const {
    appHandle: AH,
    memberHandle: MH,
    projectHandle: PH,
    fileHandle: FH,
  } = AppParams;
  const { a, p, m, f, createApp: ca } = BasePaths;

  return (
    <StyleSheetManager shouldForwardProp={isPropValid} enableVendorPrefixes>
      <ThemeProvider theme={Themes[currentTheme]}>
        <GlobalStyle />
        <BrowserRouter>
          <JwtRedirect />
          <Suspense fallback={<></>}>
            <ErrorBoundary>
              <Routes>
                <Route path={BasePaths.home} element={<Navigation />}>
                  <Route index element={<Home />} />
                  <Route path={BasePaths.projects} element={<Projects />} />
                  <Route path={BasePaths.library} element={<Library />} />
                  <Route path={BasePaths.apps} element={<Apps />} />
                  <Route path={BasePaths.members} element={<Members />} />
                  <Route path={BasePaths.signIn} element={<SignIn />} />
                </Route>

                <Route path={BasePaths.oauth} element={<OAuth />} />

                <Route
                  path={`${BasePaths.about}/*`}
                  element={<AboutRoutes />}
                />

                <Route path={`${p}/:${PH}/*`} element={<ProjectRoutes />} />
                <Route path={`${ca}/*`} element={<AppCreateRoutes />} />
                <Route path={`${a}/:${AH}/*`} element={<AppRoutes />} />
                <Route path={`${m}/:${MH}/*`} element={<MemberRoutes />} />
                <Route path={`${f}/:${FH}/*`} element={<FileRoutes />} />

                <Route path={BasePaths.home} element={<Navigation />}>
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </StyleSheetManager>
  );
};
