import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { PageDescriptions, PageNames, BasePaths } from "consts";
import { useFile } from "hooks";
import { Div, H1, LoadingPage, Page } from "library/components";
import { awaitTo, parseRtkError } from "library/utils";
import { fileApi, searchApi } from "redux/apis";
import { FileSettingsForm } from "./FileSettingsForm";
import { updateMemberFile, useRadixCall } from "radix";

export const FileSettings = () => {
  const { fileHandle } = useParams();
  const navigate = useNavigate();

  const { error, isLoading, isAdmin, isMember, auth, nft, resetFile } =
    useFile();

  const [updateCall, { isLoading: isLoadingU, error: errorU }] =
    useRadixCall(updateMemberFile);

  const [handleUpdate, { isLoading: isLoadingUpdate, error: updateError }] =
    fileApi.useFilesUpdateMutation();
  const {
    refetch,
    data: f,
    isLoading: isLoadingF,
    error: errorF,
  } = fileApi.useFilesGetQuery(fileHandle ? { fileHandle } : skipToken);

  const [search, setSearch] = useState("");
  const { data: suggestions, isFetching: isLoadingTags } =
    searchApi.useSearchTagsQuery(
      search.length >= 3 ? { search, type: "FILE" } : skipToken
    );

  useEffect(() => {
    if (!isLoading && !(isAdmin || isMember)) {
      navigate("/");
    }
  }, [isAdmin, isLoading, isMember, navigate]);

  if (!f || errorF || isLoadingF || error || isLoading) {
    return (
      <LoadingPage
        error={error || errorF}
        isLoading={isLoading || isLoadingF}
      />
    );
  }

  return (
    <Page>
      <Helmet>
        <title>{`${f.name} | Files Settings`}</title>
        <meta name="description" content={PageDescriptions.filesSettings} />
      </Helmet>

      <Div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin="64px 0 32px"
      >
        <H1 bold>{PageNames.settings}</H1>
      </Div>

      <FileSettingsForm
        creatorHandle={f.app?.appHandle || f.member?.memberHandle}
        data={f}
        nft={nft}
        handleSearch={(v) => setSearch(v)}
        handleUpdate={async (d) => {
          if (!auth) {
            return;
          }
          const res = await updateCall(auth, d, f.number);
          if (!res) {
            return;
          }
          await resetFile();

          const [err, updated] = await awaitTo(
            handleUpdate({
              fileHandle: f.fileHandle,
              fileNumberReq: { number: f.number, description: d.description },
            }).unwrap()
          );
          if (!err && updated) {
            await awaitTo(refetch());
            navigate(`/${BasePaths.f}/${updated.fileHandle}`);
          }
        }}
        error={parseRtkError(errorF || updateError) || errorU}
        isLoading={isLoadingF || isLoadingUpdate || isLoadingU}
        isLoadingTags={isLoadingTags}
        suggestions={suggestions}
      />
    </Page>
  );
};
