import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { TabStateEnum } from "library/types";
import { tabActionConsts } from "redux/actions";

const { SET_TAB_STATE } = tabActionConsts;

type TabReducerType = {
  tabState: TabStateEnum;
};

const TabReducerState: TabReducerType = {
  tabState: TabStateEnum.VISIBLE,
};

export const tabReducer = createReducer(TabReducerState, (builder) => {
  builder.addCase(
    SET_TAB_STATE,
    (state, { payload }: PayloadAction<TabStateEnum>) => {
      state.tabState = payload;
    }
  );
});
