import { ReactNode } from "react";
import { css } from "styled-components";
import { Flex } from "../Common";
import { T2, T3 } from "../Text";
import { Colors } from "library/consts";

export const Tag = ({ tag }: { tag: string }) => (
  <Flex
    padding="4px 8px"
    borderRadius="4px"
    css={css`
      background-color: ${({ theme }) => theme.cardBackgroundColor};
      border: 1px solid ${({ theme }) => theme.borderColor};
    `}
    center
  >
    <T3 medium>{tag}</T3>
  </Flex>
);

export const Tags = ({ tags }: { tags: string[] }) => (
  <Flex gap="12px" flexWrap="wrap">
    {tags.map((tag) => (
      <Tag key={tag} tag={tag} />
    ))}
  </Flex>
);

export const BorderTag = ({
  children,
  tag,
}: {
  children?: ReactNode;
  tag?: string;
}) => (
  <Flex
    padding="4px"
    borderRadius="4px"
    css={css`
      border: 1px solid ${({ theme }) => theme.borderColor};
    `}
    center
  >
    <T2 medium>{tag || children}</T2>
  </Flex>
);

export const CountTag = ({
  count,
  extraMargin,
}: {
  count: number;
  extraMargin?: boolean;
}) =>
  count > 0 ? (
    <Flex
      position="absolute"
      top="8px"
      right={extraMargin ? "16px" : "4px"}
      minHeight="20px"
      minWidth="20px"
      padding="0 4px"
      borderRadius="20px"
      backgroundColor={Colors.blue}
      center
    >
      <T3 color={Colors.white} bold>
        {count}
      </T3>
    </Flex>
  ) : null;

export const Dot = ({ count }: { count: number }) =>
  count > 0 ? (
    <Flex
      height="8px"
      width="8px"
      borderRadius="50%"
      position="absolute"
      top="8px"
      right="8px"
      backgroundColor={Colors.blue}
    />
  ) : null;
