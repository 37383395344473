export const ObjectivesTableCols = [
  { textAlign: "center", width: "64px" },
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center", width: "124px" },
];

export const ObjectivesTableHeadings = [
  "#",
  "Objective",
  "State",
  "Start",
  "End",
  "Tasks",
  "Members",
];
