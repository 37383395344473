import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import styled, { css } from "styled-components";
import {
  A,
  BaseButton,
  CardGrid,
  CreatedUpdatedCard,
  Div,
  Error,
  Flex,
  H3,
  H6,
  Img,
  Input,
  InputBase,
  MdEditor,
  MdRender,
  Modal,
  PX,
  PrimaryButton,
  RadixButton,
  SecondaryButton,
  T2,
  T3,
  TX,
  UserIcon,
} from "library/components";
import { Colors, Width } from "library/consts";
import {
  awaitTo,
  formatAddress,
  formatDate,
  formatNumber,
  getVestedAmount,
} from "library/utils";
import { AppVestingsRes, IdRes } from "redux/apiTypes";
import { VestingEditReq, VestingEditReqType } from "schema";
import { VestingContract } from "radix";
import { getExplorerUrl } from "utils";
import { CalendarIcon, ExternalLinkIcon, LoadingDots } from "library/assets";

// TwoColGrid with gap of 20px
export const TwoCol = styled(Div)`
  // Mobile (XS)
  display: grid;
  grid-template-columns: repeat(1, minmax(150px, 1fr));
  grid-gap: 20px;

  // Tablet (SM)
  @media only screen and (min-width: ${Width.SM}px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
`;

const BorderCss = css`
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: 12px;
  padding: 12px;
`;

const parseData = (data?: AppVestingsRes[number]) =>
  data
    ? {
        name: data.name,
        description: data.description,
      }
    : {};

type VestingEditModalProps = {
  hideModal: () => void;
  show: boolean;
  error?: string;
  isLoading?: boolean;
  isLoadingMember?: boolean;
  isLoadingCancel?: boolean;
  handleUpdate: (data: VestingEditReqType) => Promise<IdRes | undefined>;
  handleJoin: () => Promise<void>;
  handleWithdraw: () => Promise<void>;
  handleLeave: () => Promise<void>;
  handleCancel: () => Promise<void>;
  data?: AppVestingsRes[number];
  contract?: VestingContract;
  authHandle?: string;
};

export const VestingEditModal = ({
  hideModal,
  show,
  error,
  isLoading,
  isLoadingMember,
  isLoadingCancel,
  handleUpdate,
  handleJoin,
  handleWithdraw,
  handleLeave,
  handleCancel,
  data,
  contract,
  authHandle,
}: VestingEditModalProps) => {
  const [isPreview, setIsPreview] = useState(Boolean(data));

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<VestingEditReqType>({
    resolver: zodResolver(VestingEditReq),
    defaultValues: parseData(data),
  });

  const onSubmit = async (d: VestingEditReqType) => {
    const [err, res] = await awaitTo(handleUpdate(d));
    if (!err && res?.id) {
      hideModal();
      if (!data) {
        reset({});
        setIsPreview(false);
      }
    }
  };

  // reset form if input data changes
  useEffect(() => {
    if (data) {
      reset(parseData(data));
      setIsPreview(true);
    }
  }, [data, reset]);

  const member = data?.member;
  const admin = data?.admin;

  if (!contract) {
    return (
      <Modal show={show} closeModal={hideModal} large>
        <Flex height="100%" center>
          <LoadingDots size={100} r={2} />
        </Flex>
      </Modal>
    );
  }

  const {
    address,
    vesting_schedule,
    signature,
    token,
    resource_address,
    funds,
    reserved,
    is_cancelled,
  } = contract;
  const {
    amount,
    withdrawn,
    cliff_epoch,
    end_epoch,
    start_epoch,
    vest_interval,
  } = vesting_schedule;
  const symbol = token["symbol"] || "--";
  const name = token["name"] || "--";
  const icon_url = token["icon_url"] || "";
  const isSigned = signature.length > 0;
  const isMember = member && authHandle === member.memberHandle;
  const isAdmin = admin && authHandle === admin.memberHandle;

  return (
    <Modal show={show} closeModal={hideModal} large>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap="40px" flexDirection="column" sm={{ flexDirection: "row" }}>
          <Flex flex="1" flexDirection="column" gap="20px">
            {isPreview && data ? (
              <H3>{data.name}</H3>
            ) : (
              <Input
                error={errors.name?.message}
                rProps={register("name")}
                iProps={{
                  placeholder: "Vesting Name*",
                  fontSize: PX(20),
                  fontWeight: "500",
                  secondary: true,
                }}
              />
            )}

            <TwoCol marginBottom="16px">
              <Div css={BorderCss}>
                <H6 marginBottom="8px">Admin</H6>
                <Flex alignCenter>
                  <UserIcon icon={admin?.icon} name={admin?.name || "None"} />
                  <T2 marginLeft="8px" ellipsis>
                    {admin?.name || "--"} ({admin?.memberHandle || "--"})
                  </T2>
                </Flex>
              </Div>
              <Div css={BorderCss}>
                <H6 marginBottom="8px">Vesting Member</H6>
                <Flex alignCenter>
                  <UserIcon icon={member?.icon} name={member?.name || "None"} />
                  <T2 marginLeft="8px" ellipsis>
                    {member?.name || "--"} ({member?.memberHandle || "--"})
                  </T2>
                </Flex>
              </Div>
            </TwoCol>

            <Div css={BorderCss}>
              <CardGrid padding="12px">
                {[
                  {
                    label: "Amount",
                    value: `${formatNumber(amount)} ${symbol}`,
                  },
                  {
                    label: "Vested",
                    value: `${formatNumber(
                      getVestedAmount(contract.vesting_schedule)
                    )} ${symbol}`,
                  },
                  {
                    label: "Withdrawn",
                    value: `${formatNumber(withdrawn)} ${symbol}`,
                  },
                  {
                    label: "Reserved",
                    value: `${formatNumber(reserved)} ${symbol}`,
                  },
                  {
                    label: "Member Status",
                    value: !isSigned ? "Not Joined" : "Joined",
                  },
                  {
                    label: "Vesting Status",
                    value: is_cancelled
                      ? "Cancelled"
                      : funds === 0
                      ? "Completed"
                      : "Active",
                  },
                  {
                    label: "Token Name",
                    value: (
                      <Flex gap="8px" alignCenter>
                        <Img
                          height="24px"
                          width="24px"
                          minWidth="24px"
                          borderRadius="50%"
                          backgroundColor={Colors.border}
                          src={icon_url}
                          alt={name}
                        />
                        <TX>{name}</TX>
                      </Flex>
                    ),
                  },
                  {
                    label: "Token Address",
                    value: (
                      <Flex gap="8px" alignCenter>
                        {formatAddress(resource_address)}
                        <A
                          href={getExplorerUrl(resource_address)}
                          target="_blank"
                        >
                          <ExternalLinkIcon size={14} />
                        </A>
                      </Flex>
                    ),
                  },
                ].map((v) => (
                  <Div key={v.label}>
                    <H6 marginBottom="6px">{v.label}</H6>
                    <TX>{v.value}</TX>
                  </Div>
                ))}
              </CardGrid>
            </Div>

            {isPreview && data ? (
              <MdRender text={data.description || "--"} />
            ) : (
              <Controller
                control={control}
                name="description"
                render={({ field: { value, onChange } }) => (
                  <MdEditor
                    formError={errors.description?.message}
                    value={value}
                    setValue={onChange}
                    style={{ height: "320px" }}
                  />
                )}
              />
            )}
          </Flex>

          <Flex
            gap="8px"
            flexDirection="column"
            width="100%"
            sm={{ width: "300px" }}
          >
            <Div marginBottom="12px">
              <Flex
                borderRadius="8px"
                padding="8px 12px"
                css={css`
                  border: 2px solid ${({ theme }) => theme.borderColor};
                `}
                justifyBetween
              >
                <T2 medium>Vesting #:</T2>
                <T2 medium>{data?.number || "--"}</T2>
              </Flex>

              <Flex marginTop="12px" gap="4px" alignCenter justifyBetween>
                <InputBase value={address} readOnly secondary />
                <A href={getExplorerUrl(address)} target="_blank">
                  <ExternalLinkIcon size={16} />
                </A>
              </Flex>
            </Div>

            {[
              { label: "Start Date ", value: start_epoch },
              { label: "Cliff Date ", value: cliff_epoch },
              { label: "End Date ", value: end_epoch },
            ].map(({ label, value }) => (
              <Div key={label} css={BorderCss}>
                <Flex marginBottom="8px" justifyBetween>
                  <T2 medium>{label}</T2>
                  <CalendarIcon size={14} />
                </Flex>
                <Flex alignCenter>
                  <T2 ellipsis>{value ? formatDate(value * 1000) : "--"}</T2>
                </Flex>
              </Div>
            ))}

            <Flex css={BorderCss} alignCenter justifyBetween>
              <T2 medium>Vest Interval</T2>
              <T2 ellipsis>{formatNumber(vest_interval)} Days</T2>
            </Flex>

            <Div marginTop="12px">
              <CreatedUpdatedCard
                created={data?.createdAt}
                updated={data?.updatedAt}
              />
            </Div>

            {(isAdmin || isMember) && (
              <Flex marginTop="32px" gap="12px">
                <Flex flex="1" gap="12px" column>
                  {isMember && (
                    <>
                      {!isSigned && (
                        <BaseButton
                          type="button"
                          isLoading={isLoadingMember}
                          onClick={handleJoin}
                        >
                          Join Vesting
                        </BaseButton>
                      )}
                      {isSigned && funds && (
                        <BaseButton
                          type="button"
                          isLoading={isLoadingMember}
                          onClick={handleWithdraw}
                        >
                          Withdraw
                        </BaseButton>
                      )}
                      {isSigned && !is_cancelled && (
                        <BaseButton
                          type="button"
                          isLoading={isLoadingCancel}
                          onClick={handleLeave}
                          isSecondary
                        >
                          Leave Vesting
                        </BaseButton>
                      )}
                    </>
                  )}
                  {isAdmin && (
                    <>
                      {isPreview && (
                        <>
                          <PrimaryButton
                            type="button"
                            onClick={() => setIsPreview(false)}
                          >
                            Edit Vesting
                          </PrimaryButton>
                          <BaseButton
                            type="button"
                            isLoading={isLoadingCancel}
                            onClick={handleCancel}
                            isSecondary
                          >
                            Cancel Vesting
                          </BaseButton>
                          {isLoadingCancel && (
                            <T3>
                              * Wallet will first request the deposit account,
                              then the badge account
                            </T3>
                          )}
                        </>
                      )}
                      {!isPreview && (
                        <>
                          <BaseButton isLoading={isLoading}>
                            Update Vesting
                          </BaseButton>
                          <SecondaryButton
                            type="button"
                            onClick={() => setIsPreview(true)}
                          >
                            Cancel
                          </SecondaryButton>
                        </>
                      )}
                    </>
                  )}
                </Flex>
                <RadixButton small />
              </Flex>
            )}
            <Error error={error} />
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
