import styled, { css } from "styled-components";
import { TransitionConst } from "../../consts";
import { baseMixin } from "../_mixins";
import { Div, Flex } from "../Common";
import { Span } from "../Text";
import { CssPropsType } from "library/types";
import { MouseEventHandler, ReactNode } from "react";
import { LoadingDots } from "library/assets";

const Tl = styled(Span)`
  font-size: 0.8125rem; // 13px
  font-weight: 500;
`;

const TableCellStyle = css`
  max-width: 300px;
  position: relative;
  padding: 16px;

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;

export const Td = styled.td<CssPropsType>`
  ${TableCellStyle}
  ${baseMixin}
`;

export const Th = styled.th<CssPropsType>`
  ${TableCellStyle}
  ${baseMixin}
`;

export const Tr = styled.tr<
  CssPropsType & { $noBorder?: boolean; $noActive?: boolean }
>`
  border-bottom: ${({ theme, $noBorder }) =>
    !$noBorder && `1px solid ${theme.borderColor}`};
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  transition: ${TransitionConst};

  &:active {
    background-color: ${({ theme, $noActive }) =>
      !$noActive && theme.cardBackgroundColorActive};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme, $noActive }) =>
        !$noActive && theme.cardBackgroundColorActive};
    }
  }

  ${baseMixin}
`;

export const Tbody = styled.tbody`
  ${baseMixin}
`;

export const Thead = styled.thead`
  ${baseMixin}
`;

export const Table = styled.table<CssPropsType>`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  white-space: nowrap;
  border-collapse: collapse;
  ${baseMixin}
`;

const isStr = (v: ReactNode) => typeof v === "string";

type DataRowType = {
  cols: object[];
  data: ReactNode[];
  handleRowClick: MouseEventHandler<HTMLTableRowElement> | undefined;
  noBorder: boolean;
};

export const DataRow = ({
  cols,
  data,
  handleRowClick,
  noBorder = false,
}: DataRowType) => (
  <Tr
    $noBorder={noBorder}
    cursor={!!handleRowClick ? "pointer" : undefined}
    onClick={handleRowClick}
  >
    {cols.map((props, i) => (
      <Td key={i} {...props}>
        {isStr(data[i]) ? <Tl>{data[i]}</Tl> : data[i]}
      </Td>
    ))}
  </Tr>
);

type DataTableType<T, D> = {
  cols: object[];
  data?: T[];
  handleRowClick?: ((val: T) => void) | undefined;
  headings: ReactNode[];
  rowData?: D;
  rowRender: (val: T, index: number, rowData?: D) => ReactNode[];
};

type IdType = {
  id?: string;
};

export const DataTable = <T extends IdType, D>({
  cols,
  data,
  handleRowClick,
  headings,
  rowData,
  rowRender,
}: DataTableType<T, D>) => (
  <Div overflow="auto">
    <Table>
      <Thead>
        <Tr height="50px" position="sticky" top="0" zIndex="1" $noActive>
          {cols.map((props, i) => (
            <Th key={i} {...props}>
              {isStr(headings[i]) ? <Tl>{headings[i]}</Tl> : headings[i]}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data?.map((d, i) => (
          <DataRow
            key={d.id || i}
            cols={cols}
            data={rowRender(d, i, rowData)}
            noBorder={i === data.length - 1}
            handleRowClick={
              handleRowClick ? () => handleRowClick(d) : undefined
            }
          />
        ))}
      </Tbody>
    </Table>
    {(!data || data.length === 0) && (
      <Flex height="300px" center>
        {!data && <LoadingDots />}
        {data?.length === 0 && <Tl>No data</Tl>}
      </Flex>
    )}
  </Div>
);
