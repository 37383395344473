import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BasePaths } from "consts";
import { TeamRole, TeamType } from "library/types";
import { getEntityDetails, parseProjectContract } from "radix";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authApi, projectApi } from "redux/apis";
import { useContract, useJwt } from "redux/hooks";
import { getTeamMembers } from "utils";

export const useProject = (isGetContract?: boolean) => {
  const { projectHandle } = useParams();
  const navigate = useNavigate();
  const { state: contractState, actions: contractActions } = useContract();
  const { projectContract } = contractState;
  const { resetContract, setProjectContract } = contractActions;

  const { state } = useJwt();
  const { isSignedIn } = state;
  const {
    data: auth,
    error: authError,
    isLoading: authLoading,
  } = authApi.useAuthGetUserQuery(undefined, {
    skip: !isSignedIn,
  });

  // User Team Roles
  const {
    data: teams,
    error: teamsError,
    isLoading: teamsLoading,
  } = projectApi.useProjectGetAppTeamsQuery(
    projectHandle ? { projectHandle } : skipToken
  );
  const isAdmin =
    auth && teams
      ? teams.some(
          (r) => r.memberId === auth.id && r.teamType === TeamType.ADMIN
        )
      : false;

  const isAppMember =
    auth && teams ? teams?.some((r) => r.memberId === auth.id) : false;

  // Project
  const {
    data: profile,
    error: profileError,
    isLoading: profileLoading,
  } = projectApi.useProjectGetProfileQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  // Project Roles
  const {
    data: members,
    error: membersError,
    isLoading: membersLoading,
  } = projectApi.useProjectGetMembersQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  const isProjectLead =
    auth && members
      ? members.some(
          (m) => m.memberId === auth.id && m.teamRole === TeamRole.TEAM_LEAD
        )
      : false;
  const isProjectMember =
    auth && members ? members.some((m) => m.memberId === auth.id) : false;

  // Get unique app members from team roles
  const teamMembers = getTeamMembers(teams);

  useEffect(() => {
    if (profile && profile.projectHandle !== projectHandle) {
      const newRoute = `/${BasePaths.p}/${profile.projectHandle}`;
      navigate(newRoute, { replace: true });
    }
  }, [navigate, profile, projectHandle]);

  const contract =
    projectContract &&
    profile &&
    projectContract.address === profile.componentAddress
      ? projectContract
      : undefined;

  useEffect(() => {
    const getContract = async () => {
      if (
        profile?.componentAddress &&
        (!contract || contract.IS_RESET) &&
        isGetContract
      ) {
        const res = await getEntityDetails([profile.componentAddress]);
        const com = res?.find((v) => v);
        if (!com?.details) return;
        const contract = parseProjectContract(com.details, com.address);
        if (contract) {
          const funds = com.fungibles.find(
            (v) => typeof v.vaults[contract.funds_vault] === "number"
          );
          const fees = com.fungibles.find(
            (v) => typeof v.vaults[contract.fees_vault] === "number"
          );
          const nft = com.nonfungibles.find((v) => v);
          if (!funds || !fees) return;
          setProjectContract({
            ...contract,
            funds: funds.vaults[contract.funds_vault] || 0,
            fees: fees.vaults[contract.fees_vault] || 0,
            resource_address: funds.resourceAddress,
            tx_nft_address: nft?.resourceAddress || "",
            tx_nft_count: nft?.total || 0,
          });
        }
      }
    };
    getContract();
  }, [contract, isGetContract, profile?.componentAddress, setProjectContract]);

  return {
    auth,
    contract,
    resetContract: async () => {
      resetContract("project");
    },
    profile,
    members,
    teams,
    teamMembers,
    error: authError || membersError || profileError || teamsError,
    isAdmin,
    isAppMember,
    isProjectLead,
    isProjectMember,
    isAMember: isAdmin || isProjectMember,
    isLoading: authLoading || membersLoading || teamsLoading || profileLoading,
    isSignedIn,
  };
};
