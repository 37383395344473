import { FEE_VAL } from "consts";

// ----- OWNER -----

const invite = (componentAddress: string, memberComponent: string) => `
CALL_METHOD
  Address("${componentAddress}")
  "invite"
  Address("${memberComponent}")
;
`;

const removeMember = (componentAddress: string, memberComponent: string) => `
CALL_METHOD
  Address("${componentAddress}")
  "remove_member"
  Address("${memberComponent}")
;
`;

const depositFunds = (
  componentAddress: string,
  accountAddress: string,
  fundResource: string,
  amount: number
) => {
  const fee = amount * FEE_VAL;
  return `
CALL_METHOD
  Address("${accountAddress}")
  "withdraw"
  Address("${fundResource}")
  Decimal("${amount + fee}")
;
TAKE_FROM_WORKTOP
  Address("${fundResource}")
  Decimal("${amount}")
  Bucket("bucket1")
;
TAKE_FROM_WORKTOP
  Address("${fundResource}")
  Decimal("${fee}")
  Bucket("bucket2")
;
CALL_METHOD
  Address("${componentAddress}")
  "deposit_funds"
  Bucket("bucket1")
  Bucket("bucket2")
;
`;
};

type MemberDistribution = {
  isCompleted: boolean;
  number: number;
  amount: number;
  distributions: {
    componentAddress: string;
    amount: number;
  }[];
};

const update = (componentAddr: string, objs: MemberDistribution[]) => `
CALL_METHOD
  Address("${componentAddr}")
  "update"
  Map<Decimal, Map>(
    ${objs
      .filter((v) => v.amount > 0 && !v.isCompleted)
      .map(
        (o) => `
    Decimal("${o.number}") => Map<Address, Decimal>(
      ${o.distributions
        .filter((v) => v.amount > 0)
        .map(
          (d) => `
        Address("${d.componentAddress}") => Decimal("${d.amount}")
      `
        )
        .join(",\n")}
    )
    `
      )
      .join(",\n")}
  )
;
`;

const reward_obj = (componentAddr: string, objNum: number) => `
CALL_METHOD
  Address("${componentAddr}")
  "reward_obj"
  Decimal("${objNum}")
;
`;

const cancellation = (componentAddr: string, accountAddr: string) => `
CALL_METHOD
  Address("${componentAddr}")
  "cancellation"
;
CALL_METHOD
  Address("${accountAddr}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

// ----- MEMBER -----

const leave = (componentAddress: string, memberComponent: string) => `
CALL_METHOD
  Address("${componentAddress}")
  "leave"
  Address("${memberComponent}")
  Proof("proof1")
;
`;

const withdraw = (
  componentAddress: string,
  memberComponent: string,
  accountAddress: string
) => `
CALL_METHOD
  Address("${componentAddress}")
  "withdraw"
  Address("${memberComponent}")
  Proof("proof1")
;
CALL_METHOD
  Address("${accountAddress}")
  "deposit_batch"
  Expression("ENTIRE_WORKTOP")
;
`;

// ----- Manifest -----

export const ProjectManifest = {
  invite,
  removeMember,
  depositFunds,
  update,
  reward_obj,
  cancellation,

  leave,
  withdraw,
};
