import { z } from "zod";
import {
  BooleanSchema,
  NumberSchema,
  StringSchema,
  SymbolSchema,
  UuidSchema,
} from "./common-schema";

const DistributionReq = z.object({
  id: UuidSchema,
  componentAddress: StringSchema,
  amount: NumberSchema,
});

const ContractObjectiveReq = z
  .object({
    id: UuidSchema,
    number: NumberSchema,
    name: StringSchema,
    amount: NumberSchema,
    isAdded: BooleanSchema,
    isCompleted: BooleanSchema,
    distributions: z.array(DistributionReq),
  })
  .refine(
    (data) => {
      const sum = data.distributions.reduce(
        (acc, curr) => acc + curr.amount,
        0
      );
      return data.distributions.length === 0 || data.amount === sum;
    },
    {
      message: "Objective amount does not match sum of distributions",
      path: ["amount"],
    }
  );

export const ContractReq = z
  .object({
    amount: NumberSchema,
    tokenSymbol: SymbolSchema,
    isCancelled: BooleanSchema,
    objectives: z.array(ContractObjectiveReq),
  })
  .refine(
    (data) => {
      const sum = data.objectives.reduce((acc, curr) => acc + curr.amount, 0);
      return data.objectives.length === 0 || data.amount === sum;
    },
    {
      message: "Contract amount does not match sum of objectives",
      path: ["amount"],
    }
  );

export type ContractReqType = z.infer<typeof ContractReq>;
