import { useRef, useState } from "react";
import styled from "styled-components";
import {
  Div,
  Flex,
  OptionButton,
  T2,
  T3,
  Tab,
  UserIcon,
} from "library/components";
import { CssPropsType, MemberType } from "library/types";
import { useClickOutside } from "library/hooks";
import { CheckmarkThin, User } from "library/assets";
import { PopupStyle } from "../../styles";

const PopupWrapper = styled(Div)<
  CssPropsType & { isOpen?: boolean; small?: boolean }
>`
  max-height: 320px;
  width: ${({ small }) => small && "240px"};
  min-width: 100%;
  width
  display: flex;
  flex-direction: column;

  ${PopupStyle}
  top: 0;
  right: ${({ small }) => small && "0"};
  margin-top: 0;
`;

type MemberDropdownProps = {
  disabled?: boolean;
  handleSelectMember: (val: string) => void;
  heading?: string;
  selected: string[];
  members: MemberType[];
  wrapperProps?: object;
  small?: boolean;
};

export const MemberDropdown = ({
  disabled = false,
  handleSelectMember,
  heading,
  selected,
  members,
  wrapperProps = {},
  small,
}: MemberDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const overflowRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, () => isOpen && setIsOpen(false));

  const toggleIsOpen = () => {
    if (!isOpen && overflowRef.current) {
      overflowRef.current.scrollTop = 0;
    }
    setIsOpen(!isOpen);
  };

  const selectedMembers = selected
    ? members.filter((m) => selected.includes(m.id))
    : [];
  const unselectedMembers = selected
    ? members.filter((m) => !selected.includes(m.id))
    : members;

  return (
    <Div
      ref={wrapperRef}
      width="100%"
      display="inline-block"
      position="relative"
      whiteSpace="nowrap"
      {...wrapperProps}
    >
      {small ? (
        <Tab
          type="button"
          padding="6px 4px"
          gap="4px"
          sm={{ padding: "6px 4px" }}
          onClick={toggleIsOpen}
          disabled={disabled}
        >
          {selectedMembers.length > 0 ? (
            selectedMembers.map((u, i) => <UserIcon key={i} {...u} />)
          ) : (
            <Div paddingTop="3px">
              <User size={18} />
            </Div>
          )}
        </Tab>
      ) : (
        <OptionButton
          type="button"
          onClick={toggleIsOpen}
          minHeight="60px"
          display="block"
          borderRadius="8px"
          showBorder
          textActive
          disabled={disabled}
        >
          <T3 textAlign="left" marginBottom="8px" normal>
            {heading}
          </T3>
          {selectedMembers.map((m, i) => (
            <Flex key={m.id} marginTop={i ? "8px" : undefined} alignCenter>
              <UserIcon icon={m.icon} name={m.name} />
              <T2 marginLeft="8px" ellipsis>
                {m.name}
              </T2>
            </Flex>
          ))}
          {selectedMembers.length === 0 && (
            <Flex alignCenter>
              <T2>--</T2>
            </Flex>
          )}
        </OptionButton>
      )}

      <PopupWrapper isOpen={isOpen} tabIndex={-1} small={small}>
        <Div ref={overflowRef} flex="1" overflow="auto">
          {selectedMembers.length > 0 && (
            <T2 padding="8px 12px" medium secondary>
              Selected
            </T2>
          )}
          {selectedMembers.map((m) => (
            <OptionButton
              key={m.id}
              type="button"
              $active
              onClick={() => handleSelectMember(m.id)}
            >
              <UserIcon icon={m.icon} name={m.name} />
              <T2 flex="1" margin="0 8px" ellipsis>
                {m.name}
              </T2>
              <CheckmarkThin size={16} />
            </OptionButton>
          ))}

          {unselectedMembers.length > 0 && (
            <T2 padding="8px 12px" medium secondary>
              Team Members
            </T2>
          )}
          {unselectedMembers.map((m) => (
            <OptionButton
              key={m.id}
              type="button"
              onClick={() => handleSelectMember(m.id)}
            >
              <UserIcon icon={m.icon} name={m.name} />
              <T2 flex="1" marginLeft="8px" ellipsis>
                {m.name}
              </T2>
            </OptionButton>
          ))}
        </Div>
      </PopupWrapper>
    </Div>
  );
};
