import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Modal } from "library/components";
import { fileApi, memberApi, searchApi } from "redux/apis";
import { awaitTo, parseRtkError } from "library/utils";
import { useNavigate } from "react-router-dom";
import { BasePaths, FileMetadata } from "consts";
import { useState } from "react";
import { MemberContract, createMemberFile, useRadixCall } from "radix";
import { AuthUserRes } from "redux/apiTypes";
import { FileSettingsForm } from "views/pages/File";

type FileMemberCreateModalProps = {
  memberHandle?: string;
  auth: AuthUserRes;
  contract: MemberContract | undefined;
  hideModal: () => void;
  show: boolean;
};

export const FileMemberCreateModal = ({
  memberHandle,
  auth,
  contract,
  hideModal,
  show,
}: FileMemberCreateModalProps) => {
  const navigate = useNavigate();
  const [handleCreate, { isLoading: isLoadingM, error: errorM }] =
    fileApi.useFilesMemberCreateMutation();

  const {
    refetch,
    isFetching,
    error: errorF,
  } = memberApi.useMemberGetFilesQuery(
    memberHandle ? { memberHandle } : skipToken
  );

  const [search, setSearch] = useState("");
  const { data: suggestions, isFetching: isLoadingTags } =
    searchApi.useSearchTagsQuery(
      search.length >= 3 ? { search, type: "FILE" } : skipToken
    );

  const [createCall, { isLoading: isLoadingC, error: errorC }] =
    useRadixCall(createMemberFile);

  return (
    <Modal show={show} closeModal={hideModal} large>
      <FileSettingsForm
        creatorHandle={memberHandle}
        handleCreate={async (d) => {
          if (!contract) {
            return;
          }
          const nfts = contract.nfts.find(
            (v) => v.metadata?.["name"] === FileMetadata.member
          );
          const number = (nfts?.total || 0) + 1;

          const contractRes = await createCall(auth, d);
          if (!contractRes) {
            return;
          }

          const [err, res] = await awaitTo(
            handleCreate({
              fileNumberReq: { number, description: d.description },
            }).unwrap()
          );

          if (!err && res) {
            // refresh list
            refetch();
            hideModal();
            navigate(`/${BasePaths.f}/${res.fileHandle}`);
          }
        }}
        handleSearch={(v) => setSearch(v)}
        error={parseRtkError(errorM || errorF) || errorC}
        isLoading={isLoadingM || isFetching || isLoadingC}
        isLoadingTags={isLoadingTags}
        suggestions={suggestions}
      />
    </Modal>
  );
};
