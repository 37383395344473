import { MonitorIcon, MoonIcon, SunIcon } from "library/assets";
import { ThemeEnum } from "library/types";
import { Tab } from "./Button";
import { Flex } from "../Common";

type ThemeButtonsProps = {
  setTheme: (val: ThemeEnum | null) => void;
  theme: ThemeEnum | null;
};

export const ThemeButtons = ({ setTheme, theme }: ThemeButtonsProps) => (
  <Flex gap="8px" alignCenter>
    <Tab
      onClick={() => setTheme(ThemeEnum.light)}
      padding="0 2px"
      sm={{ padding: "0 2px" }}
      $active={theme === ThemeEnum.light}
    >
      <SunIcon />
    </Tab>
    <Tab
      onClick={() => setTheme(null)}
      padding="0 2px"
      sm={{ padding: "0 2px" }}
      $active={!theme}
    >
      <MonitorIcon />
    </Tab>
    <Tab
      onClick={() => setTheme(ThemeEnum.dark)}
      padding="0 2px"
      sm={{ padding: "0 2px" }}
      $active={theme === ThemeEnum.dark}
    >
      <MoonIcon />
    </Tab>
  </Flex>
);
