import {
  DataRequestBuilder,
  EntityMetadataCollection,
  RadixDappToolkit,
  RadixNetwork,
} from "@radixdlt/radix-dapp-toolkit";
import { DAPP_ADDRESS, isBeta, isDev } from "consts";
import { awaitTo } from "library/utils";
import { FungibleData, NonFungibleData } from "./radix-types";

// https://github.com/radixdlt/rola-examples/blob/main/apps/client/src/main.ts

export const rdt = RadixDappToolkit({
  dAppDefinitionAddress: DAPP_ADDRESS,
  networkId: isDev || isBeta ? RadixNetwork.Stokenet : RadixNetwork.Mainnet,
});

//
//
// Get Wallet Account
export const getWalletAccount = async (isNew?: boolean) => {
  const accounts = rdt.walletApi.getWalletData().accounts;
  if (isNew || accounts.length !== 1) {
    const request = isNew
      ? DataRequestBuilder.accounts().reset().exactly(1)
      : DataRequestBuilder.accounts().exactly(1);
    rdt.walletApi.setRequestData(request);
    const result = await rdt.walletApi.sendRequest();
    if (result.isErr()) return undefined;
  }
  return rdt.walletApi.getWalletData().accounts.find((v) => v)?.address;
};

export const getWalletAccounts = () => rdt.walletApi.getWalletData().accounts;

export const resetAccounts = async () => {
  rdt.walletApi.setRequestData(DataRequestBuilder.accounts().reset());
  const result = await rdt.walletApi.sendRequest();
  if (result.isErr()) return undefined;
  return getWalletAccounts();
};

//
//
// Get Account & Component State

// metadata: symbol, name, description
const parseMetadata = (data?: EntityMetadataCollection) =>
  data?.items.reduce((acc: Record<string, string>, curr) => {
    const typed = curr.value.typed;
    const val =
      typed.type === "String" || typed.type === "Url" ? typed.value : "";
    acc[curr.key] = val;
    return acc;
  }, {}) || {};

export const getEntityDetails = async (addresses: string[]) => {
  const api = rdt.gatewayApi.state;
  const [, res] = await awaitTo(
    api.getEntityDetailsVaultAggregated(addresses, {
      explicitMetadata: ["name", "symbol", "icon_url"],
    })
  );
  if (!res) {
    return undefined;
  }

  return res.map((r) => {
    const fungibles: FungibleData[] = [];
    const nonfungibles: NonFungibleData[] = [];

    r.fungible_resources.items.forEach((i) => {
      if (i.aggregation_level === "Vault") {
        const resourceAddress = i.resource_address;
        const amount = i.vaults.items.reduce(
          (acc, curr) => acc + Number(curr.amount),
          0
        );
        const vaults = i.vaults.items.reduce(
          (acc: Record<string, number>, curr) => {
            acc[curr.vault_address] = Number(curr.amount);
            return acc;
          },
          {}
        );
        const metadata = parseMetadata(i.explicit_metadata);
        fungibles.push({ resourceAddress, amount, vaults, metadata });
      }
    });

    r.non_fungible_resources.items.forEach((i) => {
      if (i.aggregation_level === "Vault") {
        const resourceAddress = i.resource_address;
        // const ids: string[] = [];
        // i.vaults.items.forEach((v) =>
        //   v.items ? ids.concat(v.items) : undefined
        // );
        const total = i.vaults.items.reduce(
          (acc, curr) => curr.total_count + acc,
          0
        );
        const metadata = parseMetadata(i.explicit_metadata);
        nonfungibles.push({ resourceAddress, total, metadata });
      }
    });

    return { address: r.address, fungibles, nonfungibles, details: r.details };
  });
};

const getNftIds = (start: number, end: number) => {
  let ids: string[] = [];
  for (let i = start; i > end; i--) {
    ids.push(`<${i}>`);
  }
  return ids;
};
export const getNftData = async (
  address: string,
  start: number,
  end: number
) => {
  const api = rdt.gatewayApi.state;
  const [, res] = await awaitTo(
    api.getNonFungibleData(address, getNftIds(start, end))
  );
  return res?.toSorted((a, b) => {
    const aNum = Number(a.non_fungible_id.slice(1, -1));
    const bNum = Number(b.non_fungible_id.slice(1, -1));
    return bNum - aNum;
  });
};

// Submit Transaction
// https://github.com/radixdlt/radix-dapp-toolkit#sendtransaction

const pollTx = async (txId: string) => {
  let pending = true;
  let error = false;
  while (pending) {
    await new Promise((res) => setTimeout(res, 3000));
    const [err, res] = await awaitTo(
      rdt.gatewayApi.transaction.getStatus(txId)
    );
    if (err || !res) {
      pending = false;
      error = true;
    } else if (res.status !== "Pending") {
      pending = false;
    }
  }
  return !error;
};

export const pollTxStatus = async (txManifest: string) => {
  let tx = await rdt.walletApi.sendTransaction({
    transactionManifest: txManifest,
  });
  if (tx.isOk()) {
    const txId = tx.value.transactionIntentHash;
    return pollTx(txId);
  }
};

export const pollTxDetails = async (txManifest: string) => {
  let tx = await rdt.walletApi.sendTransaction({
    transactionManifest: txManifest,
  });
  if (tx.isOk()) {
    const txId = tx.value.transactionIntentHash;
    const txStatus = await pollTx(txId);
    if (txStatus) {
      const [, res] = await awaitTo(
        rdt.gatewayApi.transaction.getCommittedDetails(txId)
      );
      return res;
    }
  }
};

export const pollEntityDetails = async (txManifest: string) => {
  const txDetails = await pollTxDetails(txManifest);
  if (txDetails) {
    const componentAddresses =
      txDetails.transaction.affected_global_entities?.filter((v) =>
        v.startsWith("component_")
      );
    if (componentAddresses && componentAddresses.length) {
      const components = await getEntityDetails(componentAddresses);
      return components;
    }
  }
};
