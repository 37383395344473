import { useProject } from "hooks";
import { BorderTag, Flex, Input, UserIconNameHandle } from "library/components";
import { formatPercent } from "library/utils";
import { Control, useFieldArray, UseFormRegister } from "react-hook-form";
import { ContractReqType } from "schema";

// Nested Field Array
// https://codesandbox.io/embed/react-hook-form-usefieldarray-nested-arrays-x7btr

type DistributionProps = {
  nestIndex: number;
  control: Control<ContractReqType>;
  register: UseFormRegister<ContractReqType>;
  values: ContractReqType;
  readOnly?: boolean;
};

export const Distributions = ({
  nestIndex,
  control,
  register,
  values,
  readOnly,
}: DistributionProps) => {
  const { members } = useProject();
  const { fields } = useFieldArray({
    control,
    name: `objectives.${nestIndex}.distributions`,
  });

  const amount = values.objectives[nestIndex].amount;

  return (
    <Flex flexDirection="column" gap="16px">
      {fields.map((item, k) => {
        const member = members?.find(
          (m) => m.member.componentAddress === item.componentAddress
        )?.member;
        const dis = values.objectives[nestIndex].distributions[k];

        return (
          <Flex key={item.id} gap="16px" alignCenter>
            <Flex flex="1" alignCenter>
              <UserIconNameHandle
                icon={member?.icon}
                name={member?.name || "Unknown Member"}
                memberHandle={member?.memberHandle || `unknown${k}`}
                small
              />
            </Flex>
            {amount > 0 && (
              <BorderTag>{formatPercent((dis.amount || 0) / amount)}</BorderTag>
            )}
            <Input
              rProps={register(
                `objectives.${nestIndex}.distributions.${k}.amount`,
                { valueAsNumber: true }
              )}
              iProps={{
                type: "number",
                placeholder: "0",
                width: "88px",
                secondary: true,
                fontWeight: "500",
                readOnly: readOnly || values.objectives[nestIndex].isCompleted,
              }}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};
