import { z } from "zod";
import {
  DescriptionSchema,
  LongNameSchema,
  ProposalStatusEnumZod,
  StringSchema,
} from "./common-schema";

export const ProposalReq = z.object({
  name: LongNameSchema,
  description: DescriptionSchema,
});

export const ProposalReviewReq = z.object({
  status: ProposalStatusEnumZod,
  comment: StringSchema.or(z.string().length(0)),
});
