import { ReactNode } from "react";
import { T1 } from "../Text";
import { A } from "./Button";
import { CSSProp } from "styled-components";

type SocialButtonProps = {
  css?: CSSProp;
  icon: ReactNode;
  text: string;
  social: string;
  props?: object;
  href: string;
};

export const SocialButton = ({
  css,
  icon,
  text,
  social,
  props,
  href,
}: SocialButtonProps) => (
  <A
    height="50px"
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="12px"
    $useOpacity
    {...(props || {})}
    href={href}
    css={css}
  >
    {icon}
    <T1 marginLeft="12px" medium>
      {text} <b>{social}</b>
    </T1>
  </A>
);
