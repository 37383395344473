import { Category } from "library/types";

// Page paths
export const BasePaths = {
  home: "/",
  apps: "apps",
  projects: "projects",
  members: "members",
  library: "library",
  signIn: "signin",
  oauth: "oauth",
  about: "about",
  settings: "settings",
  createApp: "create-app",
  a: "a",
  p: "p",
  o: "o",
  m: "m",
  d: "d",
  v: "v",
  f: "f",
};

export const AppPaths = {
  app: "",
  projects: "projects",
  teams: "teams",
  library: "library",
  vestings: "vestings",
  financials: "financials",
};

export const AppPathOptions = [
  { label: "App", value: AppPaths.app },
  { label: "Projects", value: AppPaths.projects },
  { label: "Teams", value: AppPaths.teams },
  { label: "Library", value: AppPaths.library },
  { label: "Vestings", value: AppPaths.vestings },
  { label: "Financials", value: AppPaths.financials },
];

export const ProjectPaths = {
  project: "",
  objectives: "objectives",
  members: "members",
  proposals: "proposals",
  financials: "financials",
};

export const ProjectPathOptions = [
  { label: "Project", value: ProjectPaths.project },
  { label: "Objectives", value: ProjectPaths.objectives },
  { label: "Members", value: ProjectPaths.members },
  { label: "Proposals", value: ProjectPaths.proposals },
  { label: "Financials", value: ProjectPaths.financials },
];

export const MemberPaths = {
  profile: "",
  dashboard: "dashboard",
  projects: "projects",
  proposals: "proposals",
  teams: "teams",
  library: "library",
  invites: "invites",
  vestings: "vestings",
  financials: "financials",
};

export const MemberPathOptions = [
  { label: "Profile", value: MemberPaths.profile },
  { label: "Dashboard", value: MemberPaths.dashboard },
  { label: "Projects", value: MemberPaths.projects },
  { label: "Teams", value: MemberPaths.teams },
  { label: "Library", value: MemberPaths.library },
  { label: "Vestings", value: MemberPaths.vestings },
  { label: "Financials", value: MemberPaths.financials },
];

export const AboutPaths = {
  about: "",
  features: "features",
  pricing: "pricing",
  terms: "terms",
  privacy: "privacy",
};

export const AboutPathOptions = [
  { label: "About", value: AboutPaths.about },
  { label: "Features", value: AboutPaths.features },
  { label: "Pricing", value: AboutPaths.pricing },
  { label: "Terms", value: AboutPaths.terms },
  { label: "Privacy", value: AboutPaths.privacy },
];

export const FilePaths = {
  files: "",
  about: "about",
};

export const FilePathOptions = [
  { label: "Files", value: FilePaths.files },
  { label: "About", value: FilePaths.about },
];

// ---

export const AppParams = {
  appHandle: "appHandle",
  memberHandle: "memberHandle",
  objectiveHandle: "objectiveHandle",
  projectHandle: "projectHandle",
  vestingHandle: "vestingHandle",
  fileHandle: "fileHandle",
};

export const CategoryDescriptions: { [key in Category]: string } = {
  DEFI: "DeFi",
  EXCHANGE: "Exchange",
  FUND: "Fund",
  GAMEFI: "GameFi",
  GOVERNANCE: "Governance",
  IDENTITY: "Identity",
  INSURANCE: "Insurance",
  LENDING: "Lending",
  MARKETPLACE: "Marketplace",
  NFT: "NFT",
  ORACLE: "Oracle",
  SOCIAL: "Social",
  TOOL: "Tool",
  OTHER: "Other",
};

export const CategoryOptions = Object.values(Category).map((value) => ({
  label: CategoryDescriptions[value],
  value,
}));

export const CategoryFormOptions = [
  { label: "", value: "" },
  ...CategoryOptions,
];

export const PageNames = {
  apps: "Apps",
  projects: "Projects",
  members: "Members",
  signIn: "Sign In",
  notFound: "Not Found",

  financials: "Financials",
  teams: "Teams",
  library: "Library",
  settings: "Settings",
};

export const PageTitles = {
  home: "Launchspace - Accelerator for dApps",
  apps: "Apps | Launchspace",
  projects: "Projects | Launchspace",
  members: "Members | Launchspace",
  library: "Library | Launchspace",
  signIn: "Sign In | Launchspace",
  oauth: "OAuth | Launchspace",
  notFound: "Not Found | Launchspace",

  appProjects: "Projects | Launchspace",
  appTeams: "Teams | Launchspace",
  appVesting: "Vestings | Launchspace",
  appFinancials: "Financials | Launchspace",
  appSettings: "Settings | Launchspace",
  appCreate: "Create App | Launchspace",

  projectObjectives: "Objectives | Launchspace",
  projectMembers: "Members | Launchspace",
  projectProposals: "Proposals | Launchspace",
  projectFinancials: "Financials | Launchspace",
  projectSettings: "Settings | Launchspace",

  memberDashboard: "Dashboard | Launchspace",
  memberProjects: "Member Projects | Launchspace",
  memberTeams: "Member Teams | Launchspace",
  memberVestings: "Member Vestings | Launchspace",
  memberFinancials: "Member Financials | Launchspace",
  memberSettings: "Settings | Launchspace",

  about: "About | Launchspace",
  features: "Features | Launchspace",
  pricing: "Pricing | Launchspace",
  terms: "Terms | Launchspace",
  privacy: "Privacy | Launchspace",
};

export const PageDescriptions = {
  home: "Launchspace - Accelerator for dApps",
  apps: "Apps on Launchspace",
  projects: "Projects on Launchspace",
  members: "Members on Launchspace",
  library: "Library on Launchspace",
  signIn: "Sign in",
  oauth: "OAuth redirect page",
  notFound: "Page not found",

  appProjects: "App projects page",
  appTeams: "App teams page",
  appVestings: "App vestings",
  appFinancials: "App financials page",
  appSettings: "App settings",
  appCreate: "Create a new app",

  projectObjectives: "Project objectives",
  projectMembers: "Project members",
  projectProposals: "Project proposals",
  projectFinancials: "Project financials",
  projectSettings: "Project settings",

  memberProfile: "View the member's profile",
  memberDashboard: "View all ongoing projects, objectives, and tasks",
  memberProjects: "View all projects of a member",
  memberTeams: "View all teams of a member",
  memberVestings: "View vestings of a member",
  memberFinancials: "View financial history of a member",
  memberSettings: "Member settings",

  about: "About Launchspace",
  features: "Features on Launchspace",
  pricing: "Pricing for Launchspace",
  terms: "Terms for Launchspace",
  privacy: "Privacy for Launchspace",

  files: "View files added by teams & creators",
  filesAbout: "About page for files",
  filesSettings: "File settings",
};
