import { Helmet } from "react-helmet-async";
import { BasePaths, PageDescriptions, PageTitles } from "consts";
import { useProject } from "hooks";
import { PlusIcon } from "library/assets";
import {
  AppIconName,
  Card,
  CardGrid,
  Div,
  Flex,
  Link,
  LoadingPage,
  NoneCard,
  Page,
  PrimaryButton,
  SecondaryButton,
  T2,
  T3,
  TeamTypeOptionsMedium,
  TX,
  useModal,
  UserIconNameHandle,
} from "library/components";
import { TeamRole } from "library/types";
import {
  FeedbackModal,
  InviteProjectModal,
  MembersProjectModal,
} from "views/components";

export const ProjectMembers = () => {
  const { show, showModal, hideModal } = useModal();
  const editModal = useModal();
  const feedbackModal = useModal();
  const {
    auth,
    contract,
    resetContract,
    profile: p,
    members,
    teamMembers,
    error,
    isLoading,
    isProjectLead,
    isAMember,
  } = useProject();

  if (!p || p.isLocked || !members || !teamMembers || error || isLoading) {
    return (
      <LoadingPage error={error} isLoading={isLoading} isLocked={p?.isLocked} />
    );
  }

  const teamLeads = members.filter((m) => m.teamRole === TeamRole.TEAM_LEAD);
  const memberRoles = members.filter((m) => m.teamRole === TeamRole.MEMBER);
  const contributors = members.filter(
    (m) => m.teamRole === TeamRole.CONTRIBUTOR
  );

  return (
    <Page>
      <Helmet>
        <title>
          {p.name} - {PageTitles.projectMembers}
        </title>
        <meta
          name="description"
          content={`${p.name} - ${PageDescriptions.projectMembers}`}
        />
      </Helmet>

      <Div
        display="block"
        alignItems="center"
        margin="8px 0"
        sm={{ display: "flex", margin: "24px 0" }}
      >
        <Div flex="1" margin="32px 0 16px" sm={{ margin: "0 24px 0 12px" }}>
          <Div display="flex" alignItems="center" flexWrap="wrap">
            <AppIconName icon={p.app.icon} name={p.app.name} />
            <Div marginRight="24px">
              {TeamTypeOptionsMedium.find((o) => o.value === p.teamType)?.label}
            </Div>
            <TX ellipsis medium>
              {p.name}
            </TX>
          </Div>
        </Div>

        <Div
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap="16px"
          margin="16px 0"
          sm={{ justifyContent: "flex-end", margin: "0" }}
        >
          <SecondaryButton
            height="40px"
            padding="0 8px"
            sm={{ padding: "0 24px" }}
            onClick={feedbackModal.showModal}
          >
            Feedback
          </SecondaryButton>
          {isAMember && (
            <SecondaryButton
              height="40px"
              padding="0 8px"
              sm={{ padding: "0 24px" }}
              onClick={editModal.showModal}
            >
              Edit
            </SecondaryButton>
          )}
          {isProjectLead && (
            <PrimaryButton
              height="40px"
              padding="0 8px"
              sm={{ padding: "0 24px" }}
              onClick={showModal}
            >
              <PlusIcon />
            </PrimaryButton>
          )}
        </Div>
      </Div>

      <CardGrid>
        <Card padding="16px" sm={{ padding: "24px" }}>
          <T2 marginBottom="24px" medium>
            Team Leads
          </T2>

          <Flex gap="8px" column>
            {teamLeads.map((m) => (
              <Link
                key={m.id}
                to={`/${BasePaths.m}/${m.member.memberHandle}`}
                $useOpacity
              >
                <UserIconNameHandle {...m.member} />
                {m.isAdmin && <T3>Admin</T3>}
              </Link>
            ))}
            {teamLeads.length === 0 && <NoneCard />}
          </Flex>
        </Card>

        <Card padding="16px" sm={{ padding: "24px" }}>
          <T2 marginBottom="24px" medium>
            Members
          </T2>

          <Flex gap="8px" column>
            {memberRoles.map((m) => (
              <Link
                key={m.id}
                to={`/${BasePaths.m}/${m.member.memberHandle}`}
                $useOpacity
              >
                <UserIconNameHandle {...m.member} />
              </Link>
            ))}
            {memberRoles.length === 0 && <NoneCard />}
          </Flex>
        </Card>

        <Card padding="16px" sm={{ padding: "24px" }}>
          <T2 marginBottom="24px" medium>
            Contributors
          </T2>

          <Flex gap="8px" column>
            {contributors.map((m) => (
              <Link
                key={m.id}
                to={`/${BasePaths.m}/${m.member.memberHandle}`}
                $useOpacity
              >
                <UserIconNameHandle {...m.member} />
              </Link>
            ))}
            {contributors.length === 0 && <NoneCard />}
          </Flex>
        </Card>
      </CardGrid>

      {auth && isAMember && (
        <>
          <InviteProjectModal
            auth={auth}
            contract={contract}
            resetContract={resetContract}
            projectAddress={p.componentAddress}
            projectHandle={p.projectHandle}
            projectName={p.name}
            members={teamMembers}
            teamType={p.teamType}
            hideModal={hideModal}
            show={show}
          />

          <MembersProjectModal
            auth={auth}
            contract={contract}
            resetContract={resetContract}
            projectAddress={p.componentAddress}
            show={editModal.show}
            hideModal={editModal.hideModal}
            projectHandle={p.projectHandle}
            members={members.map((m) => m.member)}
          />
        </>
      )}

      <FeedbackModal
        hideModal={feedbackModal.hideModal}
        show={feedbackModal.show}
        projectHandle={p.projectHandle}
        userId={auth?.id}
        isAMember={isAMember}
      />
    </Page>
  );
};
