import { z } from "zod";
import {
  DateTimeSchema,
  DescriptionSchema,
  ObjectiveStateEnumZod,
  StringSchema,
  UuidSchema,
} from "./common-schema";
import { TaskReq } from "./task-schema";

export const ObjectiveReq = z
  .object({
    name: StringSchema.min(3),
    description: DescriptionSchema,
    state: ObjectiveStateEnumZod,
    startDate: DateTimeSchema,
    endDate: DateTimeSchema,
    tasks: z.array(TaskReq).max(10, "Max of 10 tasks"),
    requesterIds: z.array(UuidSchema).max(10, "Max of 10 members"),
    ownerIds: z.array(UuidSchema).max(10, "Max of 10 members"),
  })
  .refine(
    (data) => {
      const start = new Date(data.startDate).getTime();
      const end = new Date(data.endDate).getTime();
      return end > start;
    },
    {
      message: "End date must be after start date",
      path: ["endDate"],
    }
  );
