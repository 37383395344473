import { StateEntityDetailsResponseItemDetails } from "@radixdlt/radix-dapp-toolkit";
import { PACKAGE_ADDRESS } from "consts";
import { Blueprints, StateValue, ValueKind } from "../radix-types";
import { parseArray, parseTuple } from "./parser-utils";

export type VestingSchedule = {
  member_address: string;
  member_handle: string;
  start_epoch: number;
  cliff_epoch: number | undefined;
  end_epoch: number;
  vest_interval: number;
  amount: number;
  withdrawn: number;
  cancel_epoch: number;
};

export const parseVestingSchedule = (data: StateValue) => {
  const s = parseTuple(data);
  const vesting_schedule: VestingSchedule = {
    member_address: s.member_address?.value?.toString() || "",
    member_handle: s.member_handle?.value?.toString() || "",
    start_epoch: Number(s.start_epoch?.value?.toString() || ""),
    cliff_epoch:
      s.cliff_epoch?.kind === ValueKind.Enum
        ? Number(
            s.cliff_epoch.fields.find((v) => v.value)?.value?.toString() || 0
          )
        : undefined,
    end_epoch: Number(s.end_epoch?.value?.toString() || 0),
    vest_interval: Number(s.vest_interval?.value?.toString() || 0),
    amount: Number(s.amount?.value?.toString() || 0),
    withdrawn: Number(s.withdrawn?.value?.toString() || 0),
    cancel_epoch: Number(s.cancel_epoch?.value?.toString() || 0),
  };
  return vesting_schedule;
};

export type VestingContract = {
  address: string;
  signature: string[];
  funds_vault: string;
  funds: number;
  reserved_vault: string;
  reserved: number;
  resource_address: string;
  token: Record<string, string>;

  vesting_schedule: VestingSchedule;

  is_cancelled: boolean;
  IS_RESET: boolean; // used for redux store reset
};

export const parseVestingContract = (
  details: StateEntityDetailsResponseItemDetails,
  address: string
) => {
  if (
    details.type !== "Component" ||
    details.package_address !== PACKAGE_ADDRESS ||
    details.blueprint_name !== Blueprints.VestingContract ||
    !details.state
  ) {
    return undefined;
  }
  const s = parseTuple(details.state as StateValue);
  if (!s) {
    return undefined;
  }

  const vestingState: VestingContract = {
    address,
    signature: s.signature ? parseArray(s.signature) : [],
    funds_vault: s.funds?.value?.toString() || "",
    funds: 0,
    reserved_vault: s.reserved?.value?.toString() || "",
    reserved: 0,
    resource_address: "",
    token: {},

    vesting_schedule: s.vesting_schedule
      ? parseVestingSchedule(s.vesting_schedule)
      : parseVestingSchedule({
          kind: ValueKind.Tuple,
          type_name: "",
          fields: [],
        }),

    is_cancelled:
      s.is_cancelled?.kind === ValueKind.Bool ? s.is_cancelled.value : false,
    IS_RESET: false,
  };

  return vestingState;
};
