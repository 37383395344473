import {
  FilesAppCreateApiRes,
  FilesAppCreateApiArg,
  FilesMemberCreateApiRes,
  FilesMemberCreateApiArg,
  FilesUpdateApiRes,
  FilesUpdateApiArg,
  FilesGetApiRes,
  FilesGetApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    filesAppCreate: build.mutation<FilesAppCreateApiRes, FilesAppCreateApiArg>({
      query: (queryArg) => ({
        url: `/file/app/${queryArg.appHandle}`,
        method: "POST",
        body: queryArg.fileNumberReq,
      }),
    }),
    filesMemberCreate: build.mutation<
      FilesMemberCreateApiRes,
      FilesMemberCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/file/member`,
        method: "POST",
        body: queryArg.fileNumberReq,
      }),
    }),
    filesUpdate: build.mutation<FilesUpdateApiRes, FilesUpdateApiArg>({
      query: (queryArg) => ({
        url: `/file/${queryArg.fileHandle}`,
        method: "PATCH",
        body: queryArg.fileNumberReq,
      }),
    }),
    filesGet: build.query<FilesGetApiRes, FilesGetApiArg>({
      query: (queryArg) => ({ url: `/file/${queryArg.fileHandle}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as fileApi };
