import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { FileNftType } from "radix";
import { fileActionConsts } from "redux/actions";

const { SET_FILE, RESET_FILE } = fileActionConsts;

type FileReducerType = {
  file: FileNftType | undefined;
};

const FileReducerState: FileReducerType = {
  file: undefined,
};

export const fileReducer = createReducer(FileReducerState, (builder) => {
  builder
    .addCase(
      SET_FILE,
      (state, { payload }: PayloadAction<FileNftType | undefined>) => {
        state.file = payload;
      }
    )
    .addCase(RESET_FILE, (state) => {
      if (state.file) {
        state.file.IS_RESET = true;
      }
    });
});
