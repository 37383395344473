import { LoadingDots, XIcon } from "library/assets";
import {
  Button,
  Div,
  Error,
  Flex,
  H4,
  Modal,
  NoneCard,
  RadixButton,
  T2,
  T3,
  TeamTypeOptionsMedium,
  UserIconNameHandle,
} from "library/components";
import { MemberType, TeamType } from "library/types";
import { InvitesAppRes, InvitesProjectRes } from "redux/apiTypes";
import { css } from "styled-components";

type MembersModalProps = {
  hideModal: () => void;
  show: boolean;
  teamType?: TeamType;
  members: MemberType[];
  invites?: InvitesAppRes | InvitesProjectRes;
  loadingId?: string;
  errorM?: string;
  errorI?: string;
  errorC?: string;
  handleRemoveMember: (memberId: string) => Promise<void>;
  handleRemoveInvite: (
    inviteId: string,
    componentAddress: string
  ) => Promise<void>;
};

export const MembersModal = ({
  hideModal,
  show,
  teamType,
  members,
  invites,
  loadingId,
  errorM,
  errorI,
  errorC,
  handleRemoveMember,
  handleRemoveInvite,
}: MembersModalProps) => {
  return (
    <Modal show={show} closeModal={hideModal}>
      <Flex marginBottom="16px" gap="12px" justifyBetween>
        <H4 marginBottom="16px">Edit Members</H4>
        {show && <RadixButton small />}
      </Flex>

      {teamType && (
        <Div
          borderRadius="8px"
          padding="8px 12px"
          marginBottom="24px"
          css={css`
            border: 2px solid ${({ theme }) => theme.borderColor};
          `}
        >
          <T3 marginBottom="4px" normal>
            Team
          </T3>
          <T2 medium>
            {TeamTypeOptionsMedium.find((o) => o.value === teamType)?.label}
          </T2>
        </Div>
      )}

      <Error error={errorC} marginBottom="16px" />

      {/* Members */}

      <T3 marginBottom="16px" medium>
        Members
      </T3>
      {members.map((m, i) => (
        <Flex key={m.id} marginTop={i ? "16px" : undefined}>
          <UserIconNameHandle {...m} />
          <Button
            onClick={() => handleRemoveMember(m.id)}
            disabled={Boolean(loadingId)}
          >
            {m.id === loadingId ? <LoadingDots /> : <XIcon />}
          </Button>
        </Flex>
      ))}
      {!members.length && <NoneCard />}
      <Error error={errorM} />

      {/* Invites */}

      {invites && invites.length > 0 && (
        <T3 margin="32px 0 16px" medium>
          Invites
        </T3>
      )}
      {invites?.map((invite, i) => (
        <Flex key={invite.id} marginTop={i ? "16px" : undefined}>
          <UserIconNameHandle
            icon={invite.member?.icon}
            name={invite.member?.name || "Deleted"}
            memberHandle={invite.member?.memberHandle || "unknown"}
          />
          <Button
            onClick={() =>
              handleRemoveInvite(invite.id, invite.componentAddress)
            }
            disabled={Boolean(loadingId)}
          >
            {invite.id === loadingId ? <LoadingDots /> : <XIcon />}
          </Button>
        </Flex>
      ))}
      <Error error={errorI} />
    </Modal>
  );
};
