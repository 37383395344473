// TODO: ThemeProvider vs CSS variables - https://epicreact.dev/css-variables/
// TODO: Color System - https://components.ai/theme?tab=editor
// TODO: Use color palette

import { ThemeEnum } from "library/types";
import { DefaultTheme } from "styled-components";

export const Colors = {
  black: "#000000",
  darkBlack: "#151519",
  grayBlack: "#202029",
  borderBlack0: "#2c2c3a",
  borderBlack: "#353545",
  borderBlack2: "#46465b",

  blueBlack: "#001352",
  blueDarkGray: "#8391B3",
  blueGray: "#94A2C5",
  border: "#E2E8F0",
  borderLight: "#ECEFF5",
  background: "#F5F5FA",
  backgroundLight: "#FAFAFD",
  white: "#FFFFFF",

  gray: "#A8A8A8",
  red: "#FF6565",
  redDark: "#B83021",
  orange: "#FFBB80",
  orangeDark: "#DB7114",
  yellow: "#FFE595",
  yellowDark: "#D1A908",
  green: "#8CFF87",
  greenDark: "#27AE60",
  blue: "#2F80FF",
  blueDark: "#052CC0",
  purpleLight: "#C585FF",
  purple: "#8300ff",
  purpleDark: "#50009C",
  navy: "#0080ff",
  aqua: "#00b7ff",
  teal: "#39CCCC",
  maroon: "#730037",
  darkRed: "#c0005c",

  discord: "#5865f2",
  twitter: "#1DA1F2",
  reddit: "#FF5700",
  telegram: "#229ED9",
};

export const Gradients = {
  goldGradient:
    "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
  goldCircleGradient:
    "radial-gradient(ellipse farthest-corner at 90.4% 90.4%, #feda39ff 0%, #fdb930ff 8%, #9f7928ff 30%, #896e2fff 40%, #00000000 80%), radial-gradient(at 14.6% 14.6%, #ffffffff 0%, #ffffadff 8%, #d1b566ff 25%, #5c491fff 62%, #5c491fff 100%)",
  scryptoGradient:
    "radial-gradient(ellipse farthest-corner at left bottom, #1fe5ffff 0%, #1fe5ffff 8%, #00000000 55%), radial-gradient(ellipse farthest-corner at right 40%, #ff42caff 0%, #ff42caff 10%, #052cc0ff 65%)",
};

export const LightTheme: DefaultTheme = {
  backgroundColor: Colors.backgroundLight,
  backgroundColorActive: Colors.background,
  borderColor: Colors.border,
  borderColorLight: Colors.borderLight,
  borderColorActive: "lightgray",
  cardBackgroundColor: Colors.white,
  cardBackgroundColorActive: Colors.background,
  iconColor: Colors.blueBlack,
  iconColorActive: Colors.blueGray,
  buttonTextColor: Colors.blueBlack,
  buttonTextColorActive: Colors.blueGray,
  buttonTextColorInverted: Colors.background,
  secondaryButtonTextColor: Colors.border,
  secondaryButtonTextColorInverted: Colors.blueBlack,
  backgroundButton: Colors.white,
  backgroundButtonActive: Colors.background,
  textColor: Colors.grayBlack,
  textColorActive: Colors.blueGray,
  _isDark: false,
  _theme: ThemeEnum.light,
};

export const DarkTheme: DefaultTheme = {
  backgroundColor: Colors.darkBlack,
  backgroundColorActive: Colors.grayBlack,
  borderColor: Colors.borderBlack,
  borderColorLight: Colors.borderBlack0,
  borderColorActive: "slategray",
  cardBackgroundColor: Colors.grayBlack,
  cardBackgroundColorActive: Colors.borderBlack,
  iconColor: Colors.background,
  iconColorActive: Colors.blueGray,
  buttonTextColor: Colors.background,
  buttonTextColorActive: Colors.blueGray,
  buttonTextColorInverted: Colors.blueBlack,
  secondaryButtonTextColor: Colors.borderBlack2,
  secondaryButtonTextColorInverted: Colors.background,
  backgroundButton: Colors.grayBlack,
  backgroundButtonActive: Colors.borderBlack,
  textColor: Colors.background,
  textColorActive: Colors.blueGray,
  _isDark: true,
  _theme: ThemeEnum.dark,
};

export const Themes: { [key in ThemeEnum]: DefaultTheme } = {
  light: LightTheme,
  dark: DarkTheme,
};
