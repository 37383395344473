import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { BasePaths, ProjectPaths } from "consts";
import { useModal } from "library/components";
import { ObjectiveModal } from "./ObjectiveModal";
import { objectiveApi, projectApi } from "redux/apis";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { awaitTo, parseRtkError } from "library/utils";
import { ObjectiveReq as ObjectiveReqType } from "redux/apiTypes";
import { MemberType, TeamType } from "library/types";

type ObjectiveEditModalProps = {
  members: MemberType[];
  teamType: TeamType;
  projectName: string;
  readOnly: boolean;
};

export const ObjectiveEditModal = ({
  members,
  teamType,
  projectName,
  readOnly,
}: ObjectiveEditModalProps) => {
  const { hideModal, show, showModal } = useModal();
  const { objectiveHandle, projectHandle } = useParams();
  const navigate = useNavigate();

  const [handleUpdate, { error, isLoading }] =
    objectiveApi.useObjectiveUpdateMutation();

  const {
    refetch,
    data: objectives,
    error: errorP,
    isLoading: isLoadingP,
  } = projectApi.useProjectGetObjectivesQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  const objective = objectives?.find(
    (o) => o.number === Number(objectiveHandle)
  );

  useEffect(() => {
    if (objective) {
      showModal();
    } else {
      hideModal();
    }
  }, [hideModal, objective, showModal]);

  const handleClose = () => {
    navigate(`/${BasePaths.p}/${projectHandle}/${ProjectPaths.objectives}`);
  };

  const onSubmit = async (data: ObjectiveReqType) => {
    if (!objective) return;
    const [, res] = await awaitTo(
      handleUpdate({
        objectiveReq: data,
        objectiveId: objective.id,
      }).unwrap()
    );
    if (res?.id) {
      await refetch();
      return res;
    }
    return undefined;
  };

  return (
    <>
      {show && objective && (
        <Helmet>
          <title>{objective.name}</title>
          <meta name="description" content={objective.name} />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}

      <ObjectiveModal
        show={show}
        hideModal={handleClose}
        members={members}
        objectiveNumber={objectiveHandle}
        teamType={teamType}
        error={parseRtkError(error || errorP)}
        isLoading={isLoading || isLoadingP}
        handleUpdate={(data) => onSubmit(data)}
        projectHandle={projectHandle}
        projectName={projectName}
        data={objective}
        readOnly={readOnly}
      />
    </>
  );
};
