import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { css } from "styled-components";
import {
  ContractStageOptions,
  PageDescriptions,
  PageTitles,
  ProjectPaymentsTableCols,
  ProjectPaymentsTableHeadings,
} from "consts";
import {
  A,
  BaseButton,
  Card,
  DataTable,
  Div,
  Error,
  Flex,
  H2,
  H6,
  InputBase,
  LoadingPage,
  NoneCard,
  Page,
  T2,
  T3,
  UserIconNameHandle,
} from "library/components";
import { ProjectPaymentsRowRender } from "./ProjectFinancialsRows";
import { ProjectFinancialsForm } from "./ProjectFinancialsForm";
import { useProject } from "hooks";
import { contractApi, homeApi, projectApi } from "redux/apis";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  projectAdminJoinCall,
  projectCancelCall,
  projectRewardCall,
  projectUpdateCall,
  projectWithdrawCall,
  useRadixCall,
} from "radix";
import { ExternalLinkIcon, PlusIcon, XIcon } from "library/assets";
import {
  getMemberStatus,
  getProjectMemberValues,
  getProjectStatus,
} from "./FinancialsUtil";
import { getExplorerUrl } from "utils";
import { awaitTo, parseRtkError } from "library/utils";
import { ContractReqType } from "schema";
import { useState } from "react";
import { ProjectTxs } from "./ProjectTxs";

export const ProjectFinancials = () => {
  const { projectHandle } = useParams();
  const {
    auth,
    profile: p,
    contract,
    resetContract,
    members,
    teamMembers,
    error: errorP,
    isLoading: isLoadingP,
  } = useProject();

  const [refresh, { error: errorRefresh }] =
    contractApi.useContractRefreshMutation();

  const [updateCall, { error: errorUpdate, isLoading: isLoadingUpdate }] =
    useRadixCall(projectUpdateCall);
  const [rewardNum, setRewardNum] = useState<number>();
  const [rewardCall, { error: errorReward }] = useRadixCall(projectRewardCall);
  const [withdrawCall, { error: errorWithdraw, isLoading: isLoadingWithdraw }] =
    useRadixCall(projectWithdrawCall);
  const [cancelCall, { error: errorCancel, isLoading: isLoadingCancel }] =
    useRadixCall(projectCancelCall);

  const [adminJoin, { error: errorJoin, isLoading: isLoadingJoin }] =
    useRadixCall(projectAdminJoinCall);

  const { data, error, isLoading } = projectApi.useProjectGetFinancialsQuery(
    projectHandle ? { projectHandle } : skipToken
  );

  const { data: prices } = homeApi.useHomeGetPricesQuery();

  // useEffect(() => {
  //   if (p?.projectHandle) {
  //     refresh({ projectHandle: p.projectHandle, rewards: true });
  //   }
  // }, [p?.projectHandle, refresh]);

  if (
    !p ||
    p.isLocked ||
    !members ||
    !teamMembers ||
    errorP ||
    isLoadingP ||
    error ||
    isLoading ||
    !data ||
    !contract
  ) {
    return (
      <LoadingPage
        error={error || errorP}
        isLoading={isLoading || isLoadingP || !contract}
        isLocked={p?.isLocked}
      />
    );
  }

  const statusIdx = contract ? getProjectStatus(contract) : 0;
  const financialValues = getProjectMemberValues(contract);

  const admin = members.find((v) => v.isAdmin);
  const isAdmin = auth && admin && admin.memberId === auth.id;
  const isAdminMember =
    admin?.member.componentAddress && contract
      ? !!contract.members[admin.member.componentAddress]
      : false;

  const handleUpdate = async (deposit: number, update: ContractReqType) => {
    if (!auth) return false;
    const [, isSuccess] = await awaitTo(
      updateCall(auth, p.componentAddress, deposit, update)
    );
    if (isSuccess) {
      refresh({ projectHandle: p.projectHandle, rewards: false });
      await resetContract();
      return true;
    }
    return false;
  };

  const handleReward = async (objNum: number) => {
    if (!auth) return false;
    setRewardNum(objNum);
    const [, isSuccess] = await awaitTo(
      rewardCall(auth, p.componentAddress, objNum)
    );
    if (isSuccess) {
      resetContract();
      refresh({ projectHandle: p.projectHandle, rewards: true });
      setRewardNum(undefined);
      return true;
    }
    setRewardNum(undefined);
    return false;
  };

  const handleWithdraw = async () => {
    if (!auth) return false;
    const [, isSuccess] = await awaitTo(withdrawCall(auth, p.componentAddress));
    if (isSuccess) {
      resetContract();
      refresh({ projectHandle: p.projectHandle, rewards: true });
      return true;
    }
    return false;
  };

  const handleCancel = async () => {
    if (!auth) return false;
    const [, isSuccess] = await awaitTo(cancelCall(auth, p.componentAddress));
    if (isSuccess) {
      resetContract();
      refresh({ projectHandle: p.projectHandle, rewards: false });
      return true;
    }
    return false;
  };

  return (
    <Page>
      <Helmet>
        <title>
          {p.name} - {PageTitles.projectFinancials}
        </title>
        <meta
          name="description"
          content={`${p.name} - ${PageDescriptions.projectFinancials}`}
        />
      </Helmet>

      <ProjectFinancialsForm
        isAdmin={!!auth && admin?.memberId === auth.id}
        isMember={!!auth && members.some((v) => v.memberId === auth.id)}
        data={data}
        contract={contract}
        members={members}
        prices={prices}
        adminAddress={
          isAdminMember ? undefined : admin?.member.componentAddress
        }
        handleUpdate={handleUpdate}
        handleReward={handleReward}
        handleWithdraw={handleWithdraw}
        rewardNum={rewardNum}
        isLoadingUpdate={isLoadingUpdate}
        isLoadingWithdraw={isLoadingWithdraw}
        error={
          errorUpdate ||
          errorReward ||
          errorWithdraw ||
          parseRtkError(errorRefresh)
        }
      />

      <Div
        marginTop="64px"
        display="flex"
        flexDirection="column"
        md={{ flexDirection: "row" }}
      >
        <Div flex="1" minWidth="0">
          <H2 marginBottom="16px" bold>
            Financials
          </H2>
          <Card borderRadius="12px">
            <DataTable
              cols={ProjectPaymentsTableCols}
              headings={ProjectPaymentsTableHeadings}
              data={isAdminMember ? members : members.filter((v) => !v.isAdmin)}
              rowRender={ProjectPaymentsRowRender}
              rowData={{
                amount: contract.funds + contract.paid,
                tokenSymbol: data.tokenSymbol,
                financialValues,
              }}
            />
          </Card>

          <H2 marginTop="64px" marginBottom="16px" bold>
            Transactions
          </H2>
          <ProjectTxs contract={contract} members={members} />
        </Div>

        <Div
          width="100%"
          margin="32px 0"
          md={{ width: "320px", margin: "0 0 0 32px" }}
        >
          <Card
            marginBottom="32px"
            padding="24px"
            borderRadius="12px"
            overflow="visible"
          >
            <Flex marginBottom="12px" alignCenter justifyBetween>
              <H6>Address</H6>
              <A href={getExplorerUrl(contract.address)} target="_blank">
                <ExternalLinkIcon size={16} />
              </A>
            </Flex>
            <InputBase value={contract.address} readOnly showBorder />
          </Card>

          <Card marginBottom="32px" padding="24px" borderRadius="12px">
            <H6 marginBottom="16px">Contract Stage</H6>
            {ContractStageOptions.map((s, i) => (
              <Flex
                key={s.value}
                padding="12px 16px"
                borderRadius="8px"
                css={css`
                  background-color: ${({ theme }) =>
                    i === statusIdx && theme.cardBackgroundColorActive};
                `}
                alignCenter
              >
                <T2 secondary={statusIdx ? i > statusIdx : undefined} medium>
                  {s.label}
                </T2>
              </Flex>
            ))}
          </Card>

          {admin && (
            <Card marginTop="32px" padding="24px" borderRadius="12px">
              <H6 marginBottom="16px">Admin</H6>
              <Flex gap="16px" column>
                <Flex key={admin.id} alignCenter>
                  <UserIconNameHandle {...admin.member} small />
                  <T3>{getMemberStatus(admin)}</T3>
                </Flex>
              </Flex>
            </Card>
          )}

          <Card marginTop="32px" padding="24px" borderRadius="12px">
            <H6 marginBottom="16px">Members</H6>
            <Flex gap="16px" column>
              {members.map((m) =>
                m.isAdmin && !isAdminMember ? null : (
                  <Flex key={m.id} alignCenter>
                    <UserIconNameHandle {...m.member} small />
                    <T3>{getMemberStatus(m)}</T3>
                  </Flex>
                )
              )}
              {members.length === 1 && !isAdminMember && <NoneCard />}
            </Flex>
          </Card>

          {isAdmin && !isAdminMember && (
            <Card marginTop="32px" padding="24px" borderRadius="12px">
              <Flex marginBottom="16px" alignCenter justifyBetween>
                <H6>Admin Join as Member</H6>
                <BaseButton
                  onClick={async () => {
                    const res = await adminJoin(auth, p.componentAddress);
                    if (res) {
                      resetContract();
                    }
                  }}
                  isLoading={isLoadingJoin}
                >
                  <PlusIcon />
                </BaseButton>
              </Flex>
              <T3>* Admins must join as a member to receive rewards</T3>
              <Error error={errorJoin} />
            </Card>
          )}

          {isAdmin && (
            <Card marginTop="32px" padding="24px" borderRadius="12px">
              <Flex marginBottom="16px" alignCenter justifyBetween>
                <H6>{`❌ \xa0 Cancel project`}</H6>
                <BaseButton onClick={handleCancel} isLoading={isLoadingCancel}>
                  <XIcon />
                </BaseButton>
              </Flex>
              <T3>
                * Wallet will first request the deposit account, then the badge
                account
              </T3>
              <Error error={errorCancel} />
            </Card>
          )}
        </Div>
      </Div>
    </Page>
  );
};
