import { cloneElement, ReactNode, useRef, useState } from "react";
import styled from "styled-components";
import { Div } from "../Common";
import { OptionButton } from "../Buttons/Button";
import { useClickOutside } from "../../hooks";
import { T3 } from "../Text";
import { PopupStyle } from "library/styles";
import { CssPropsType } from "library/types";

const PopupWrapper = styled(Div)<
  CssPropsType & {
    $active?: boolean;
    alignTop?: boolean;
    isOpen?: boolean;
    minWidth?: string;
  }
>`
  min-width: 100%;
  ${PopupStyle}
`;

type PopupType = {
  alignTop?: boolean;
  buttonProps?: object;
  children: ReactNode;
  heading?: ReactNode;
  label: ReactNode;
  popupProps?: object;
  wrapperProps?: object;
};

export const Popup = ({
  alignTop = false,
  buttonProps = {},
  children,
  heading,
  label,
  popupProps = {},
  wrapperProps = {},
}: PopupType) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, () => isOpen && setIsOpen(false));

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Div
      ref={wrapperRef}
      display="inline-block"
      position="relative"
      whiteSpace="nowrap"
      {...wrapperProps}
    >
      <OptionButton
        onClick={toggleIsOpen}
        display="block"
        borderRadius="8px"
        textActive
        {...buttonProps}
      >
        {heading && (
          <T3 textAlign="left" marginBottom="4px" normal>
            {heading}
          </T3>
        )}
        {label}
      </OptionButton>

      <PopupWrapper
        isOpen={isOpen}
        tabIndex={-1}
        alignTop={alignTop}
        {...popupProps}
      >
        {cloneElement(<>{children}</>, { setIsOpen })}
      </PopupWrapper>
    </Div>
  );
};
