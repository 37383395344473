import { isAction, isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { rdt } from "radix";
import { jwtActions } from "redux/actions";
import { baseApi } from "redux/apis";
import { AppDispatch } from "redux/store";

// middleware to signout user
// https://stackoverflow.com/a/68522705

// reset all caches
// https://stackoverflow.com/a/71573318

export const jwtMiddleware: Middleware =
  (store: MiddlewareAPI) => (next) => (action) => {
    if (isAction(action) && isRejectedWithValue(action)) {
      // console.log(action);
      const { meta, payload } = action;
      const arg = meta?.arg as object;
      const p = payload as { status?: number };
      if (
        "endpointName" in arg &&
        arg.endpointName === "authGetUser" &&
        p?.status &&
        [401, 403, 404].includes(p.status)
      ) {
        // sign out
        localStorage.removeItem("jwt");
        const dispatch: AppDispatch = store.dispatch;
        dispatch(baseApi.util.resetApiState());
        dispatch(jwtActions.setIsSignedIn(false));
        rdt.disconnect();
      }
    }
    return next(action);
  };
