import { css } from "styled-components";
import { Width } from "library/consts";
import { CssPropsType } from "library/types";

// https://paulie.dev/posts/2020/08/styled-components-style-objects/
// https://stackoverflow.com/a/53443514

type FilteredProps = {
  children?: any;
  href?: any;
  onClick?: any;
  to?: any;
};

const cssMixin = css<CssPropsType & FilteredProps>(
  ({ children, href, onClick, to, theme, css, sm, md, ...props }) => ({
    ...(props as object),
    [`@media only screen and (min-width: ${Width.SM}px)`]: {
      ...(sm as object),
    },
    [`@media only screen and (min-width: ${Width.MD}px)`]: {
      ...(md as object),
    },
  })
);

export const baseMixin = css<CssPropsType>`
  ${cssMixin}
  ${({ css }) => css}
`;
