import { ObjectiveState, ProjectState, StateFilter } from "library/types";

export const ProjectStateDescriptions = {
  [ProjectState.DRAFT]: "📝 \xa0 Draft",
  [ProjectState.READY_FOR_DEVELOPMENT]: "📣 \xa0 Ready for Development",
  [ProjectState.IN_PROGRESS]: "🛠️ \xa0 In Progress",
  [ProjectState.COMPLETED]: "✅ \xa0 Completed",
};

export const ProjectStateOptions = Object.values(ProjectState).map((value) => ({
  label: ProjectStateDescriptions[value],
  value,
}));

export const ProjectStateFilterOptions = [
  {
    label: "🗄️ \xa0 All States",
    value: StateFilter.ALL,
  },
  ...ProjectStateOptions,
  {
    label: "❌ \xa0 Cancelled",
    value: StateFilter.CANCELLED,
  },
  // {
  //   label: "🗂️ \xa0 Archived",
  //   value: StateFilter.ARCHIVED,
  // },
];

export const ObjectiveStateDescriptions = {
  [ObjectiveState.DRAFT]: "📝 \xa0 Draft",
  [ObjectiveState.READY_FOR_DEVELOPMENT]: "📣 \xa0 Ready for Development",
  [ObjectiveState.IN_PROGRESS]: "🛠️ \xa0 In Progress",
  [ObjectiveState.REVIEW]: "🔍 \xa0 Review",
  [ObjectiveState.DEPLOYMENT]: "🚢 \xa0 Deployment",
  [ObjectiveState.QA]: "💥 \xa0 QA",
  [ObjectiveState.COMPLETED]: "✅ \xa0 Completed",
};

export const ObjectiveStateOptions = Object.values(ObjectiveState).map(
  (value) => ({
    label: ObjectiveStateDescriptions[value],
    value,
  })
);

export const ObjectiveStateFilterOptions = [
  {
    label: "🗄️ \xa0 All States",
    value: StateFilter.ALL,
  },
  ...ObjectiveStateOptions,
  {
    label: "❌ \xa0 Cancelled",
    value: StateFilter.CANCELLED,
  },
];
