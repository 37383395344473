import {
  AuthGetCodeApiRes,
  AuthGetCodeApiArg,
  AuthGetUserApiRes,
  AuthGetUserApiArg,
  AuthGetCountsApiRes,
  AuthGetCountsApiArg
} from "../apiTypes"
import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authGetCode: build.query<AuthGetCodeApiRes, AuthGetCodeApiArg>({
      query: (queryArg) => ({ url: `/auth/github/${queryArg.code}` }),
    }),
    authGetUser: build.query<AuthGetUserApiRes, AuthGetUserApiArg>({
      query: () => ({ url: `/auth/user` }),
    }),
    authGetCounts: build.query<AuthGetCountsApiRes, AuthGetCountsApiArg>({
      query: () => ({ url: `/auth/counts` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as authApi };
