import { SortApp } from "redux/apiTypes";

export const SortAppTypes: { [key in SortApp]: SortApp } = {
  MOST_REWARDS_ALL: "MOST_REWARDS_ALL",
  MOST_REWARDS_YEAR: "MOST_REWARDS_YEAR",
  NEWEST_FIRST: "NEWEST_FIRST",
  OLDEST_FIRST: "OLDEST_FIRST",
  MOST_MEMBERS: "MOST_MEMBERS",
  LEAST_MEMBERS: "LEAST_MEMBERS",
};

export const SortAppOptions = [
  { label: "Most Rewards", value: SortAppTypes.MOST_REWARDS_ALL },
  { label: "Most Rewards Year", value: SortAppTypes.MOST_REWARDS_YEAR },
  { label: "Newest First", value: SortAppTypes.NEWEST_FIRST },
  { label: "Oldest First", value: SortAppTypes.OLDEST_FIRST },
  { label: "Most Members", value: SortAppTypes.MOST_MEMBERS },
  { label: "Least Members", value: SortAppTypes.LEAST_MEMBERS },
];

export const AppsTableCols = [
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center", width: "120px" },
];

export const AppsTableHeadings = [
  "App",
  "Category",
  "Members",
  "Projects",
  "Project Rewards",
  "Vesting Rewards",
  "Total Rewards",
  "Follow",
];
