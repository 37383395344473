import { StateEntityDetailsResponseItemDetails } from "@radixdlt/radix-dapp-toolkit";
import { PACKAGE_ADDRESS } from "consts";
import { Blueprints, NonFungibleData, StateValue } from "../radix-types";
import { parseArray, parseHashMap, parseTuple } from "./parser-utils";

export type AppContract = {
  address: string;
  superusers: string[]; // MemberContract
  app_admins: string[]; // MemberContract

  admin_components: Record<string, string>; // AdminContract:MemberContract
  admin_badges: Record<string, string | undefined>; // MemberContract
  admin_invites: Record<string, string | undefined>; // MemberContract

  IS_RESET: boolean; // used for redux store reset
  nfts: NonFungibleData[];
};

export const parseAppContract = (
  details: StateEntityDetailsResponseItemDetails,
  address: string
) => {
  if (
    details.type !== "Component" ||
    details.package_address !== PACKAGE_ADDRESS ||
    details.blueprint_name !== Blueprints.AppContract ||
    !details.state
  ) {
    return undefined;
  }
  const s = parseTuple(details.state as StateValue);
  if (!s) {
    return undefined;
  }

  const appData: AppContract = {
    address,
    superusers: s.superusers ? parseArray(s.superusers) : [],
    app_admins: s.app_admins ? parseArray(s.app_admins) : [],

    admin_components: s.admin_components
      ? parseHashMap(s.admin_components)
      : {},
    admin_badges: s.admin_badges ? parseHashMap(s.admin_badges) : {},
    admin_invites: s.admin_invites ? parseHashMap(s.admin_invites) : {},

    IS_RESET: false,
    nfts: [],
  };

  return appData;
};
