import { Link } from "react-router-dom";
import { css } from "styled-components";
import { Div, Flex, Img, PX, T3, TX } from "library/components";
import { BasePaths, Colors, ProjectPaths } from "library/consts";
import { BoxShadowSmallStyle } from "library/styles";
import { UserIcons } from "./CardComponents";
import { Card } from "./Card";
import { TeamSymbol } from "../Other";
import { MemberType, TeamType } from "library/types";

type ProjectSmallCardProps = {
  appName: string;
  icon?: string;
  members: MemberType[];
  name: string;
  number: number;
  projectHandle: string;
  teamType: TeamType;
};

export const ProjectSmallCard = ({
  appName,
  icon,
  members,
  name,
  number,
  projectHandle,
  teamType,
}: ProjectSmallCardProps) => (
  <Link to={`/${BasePaths.p}/${projectHandle}/${ProjectPaths.objectives}`}>
    <Card zIndex="1" borderRadius="12px">
      <Flex position="relative" zIndex="3" fullHeight fullWidth column>
        <Div flex="1" margin="16px 20px 20px 20px">
          <Flex marginBottom="12px" alignCenter>
            <Img
              as={!icon ? "div" : "img"}
              height="20px"
              width="20px"
              minWidth="20px"
              borderRadius="6px"
              backgroundColor={Colors.border}
              aspectRatio="1"
              src={icon}
              alt={name}
            />
            <T3 flex="1" margin="0 10px" ellipsis medium>
              {appName}
            </T3>
            <TeamSymbol type={teamType} fontSize={PX(12)} />
          </Flex>

          <TX medium>{name}</TX>
        </Div>

        <Flex
          padding="8px 16px"
          css={css`
            background-color: ${({ theme }) => theme.cardBackgroundColorActive};
          `}
          alignCenter
        >
          <Div
            borderRadius="4px"
            padding="4px 8px"
            fontSize="12px"
            fontWeight="500"
            color={Colors.blueBlack}
            css={css`
              background-color: ${({ theme }) =>
                theme._isDark ? Colors.background : Colors.white};
              ${BoxShadowSmallStyle}
            `}
          >
            #{number}
          </Div>

          <Div flex="1" />

          <UserIcons members={members} />
        </Flex>
      </Flex>
    </Card>
  </Link>
);
