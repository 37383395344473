import { Link } from "react-router-dom";
import { css } from "styled-components";
import { Div, Flex, H5, T3 } from "library/components";
import { BasePaths, Colors } from "library/consts";
import { BoxShadowSmallStyle } from "library/styles";
import { CardCheckmarks, UserIcons } from "./CardComponents";
import { Card } from "./Card";
import { MemberType } from "library/types";

type ObjectiveCardProps = {
  memberHandle?: string;
  members: MemberType[];
  name: string;
  number: number;
  objectiveHandle: string | number;
  project: string;
  projectHandle: string;
  tasksCount: number;
  tasksTotal: number;
};

// needs state for backIdx, not navigating to page with new NavBar
export const ObjectiveCard = ({
  memberHandle,
  members,
  name,
  number,
  objectiveHandle,
  project,
  projectHandle,
  tasksCount,
  tasksTotal,
}: ObjectiveCardProps) => (
  <Link
    to={
      memberHandle
        ? `/${BasePaths.m}/${memberHandle}/${BasePaths.d}/${projectHandle}/${objectiveHandle}`
        : `/${BasePaths.p}/${projectHandle}/${BasePaths.o}/${objectiveHandle}`
    }
  >
    <Card zIndex="1" borderRadius="12px">
      <Flex position="relative" zIndex="3" fullHeight fullWidth column>
        <Flex flex="1" margin="20px" column>
          <T3 marginBottom="12px" secondary>
            {project}
          </T3>
          <H5 flex="1">{name}</H5>
        </Flex>

        <Flex
          padding="8px 16px"
          css={css`
            background-color: ${({ theme }) => theme.cardBackgroundColorActive};
          `}
          alignCenter
        >
          <Div
            borderRadius="4px"
            padding="4px 8px"
            fontSize="12px"
            fontWeight="500"
            color={Colors.blueBlack}
            css={css`
              background-color: ${({ theme }) =>
                theme._isDark ? Colors.background : Colors.white};
              ${BoxShadowSmallStyle}
            `}
          >
            #{number}
          </Div>

          <CardCheckmarks count={tasksCount} total={tasksTotal} />

          <Div flex="1" />

          <UserIcons members={members} />
        </Flex>
      </Flex>
    </Card>
  </Link>
);
