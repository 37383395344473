import styled, { css } from "styled-components";
import { baseMixin } from "library/components";
import { Colors } from "library/consts";
import { CssPropsType } from "library/types";

// html/:root font-size = 16px

type TextProps = CssPropsType & {
  normal?: boolean;
  medium?: boolean;
  bold?: boolean;
  secondary?: boolean;
  ellipsis?: boolean;
};
const textMixin = css<TextProps>`
  ${baseMixin}
  font-weight: ${({ normal, medium, bold }) =>
    normal ? "400" : medium ? "500" : bold ? "700" : null};
  ${({ secondary, theme }) => secondary && `color: ${theme.textColorActive}`}
  ${({ ellipsis }) =>
    ellipsis &&
    "overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"}
`;

export const PX = (num: number) => `${num / 16}rem`;

export const Span = styled.span<TextProps>`
  ${textMixin}
`;
// 18px
export const P = styled.p<TextProps>`
  font-size: 1.125rem;
  ${textMixin}
`;

// 20px
export const T20 = styled.div<TextProps>`
  font-size: 1.25rem;
  ${textMixin}
`;
// 17px
export const TX = styled.div<TextProps>`
  font-size: 1.0625rem;
  ${textMixin}
`;
// 15px
export const T1 = styled.div<TextProps>`
  font-size: 0.9375rem;
  ${textMixin}
`;
// 13px
export const T2 = styled.div<TextProps>`
  font-size: 0.8125rem;
  ${textMixin}
`;
// 12px
export const T3 = styled.div<TextProps>`
  font-size: 0.75rem;
  ${textMixin}
`;

// 34px
export const H1 = styled.h1<TextProps>`
  font-size: 2.125rem;
  ${textMixin}
`;
// 28px
export const H2 = styled.h2<TextProps>`
  font-size: 1.75rem;
  ${textMixin}
`;
// 22px
export const H3 = styled.h3<TextProps>`
  font-size: 1.375rem;
  ${textMixin}
`;
// 20px
export const H4 = styled.h4<TextProps>`
  font-size: 1.25rem;
  ${textMixin}
`;
// 17px
export const H5 = styled.h5<TextProps>`
  font-size: 1.0625rem;
  ${textMixin}
`;
// 15px
export const H6 = styled.h6<TextProps>`
  font-size: 0.9375rem;
  ${textMixin}
`;

export const Error = ({
  error,
  ...rest
}: { error: string | undefined } & TextProps) =>
  error ? (
    <T2 color={Colors.red} marginTop="8px" medium {...rest}>
      {error}
    </T2>
  ) : null;
