import { Stage } from "library/types";

export const StageDescriptions: Record<Stage, string> = {
  IDEA: "💡 \xa0 Idea",
  DESIGN: "🎨 \xa0 Design",
  BUILD: "🛠️ \xa0 Build",
  VALIDATE: "🔥 \xa0 Validate",
  GROWTH: "🚀 \xa0 Growth",
};

export const StageOptions = Object.values(Stage).map((value) => ({
  label: StageDescriptions[value],
  value,
}));
