import { Colors } from "library/consts";
import { ReviewStatus, ProposalStatus, StateFilter } from "library/types";
import { Status } from "../Other";

export const ReviewStatusDescriptions: {
  [key in ReviewStatus]: JSX.Element;
} = {
  OPEN: <Status color={Colors.green} label="Open" />,
  UNDER_REVIEW: <Status color={Colors.yellow} label="Under Review" />,
  CLOSED: <Status color={Colors.gray} label="Closed" />,
};

export const ReviewStatusOptions = Object.values(ReviewStatus).map((value) => ({
  label: ReviewStatusDescriptions[value],
  value,
}));

export const SearchReviewStatusOptions = [
  { label: <Status color={Colors.blue} label="All" />, value: StateFilter.ALL },
  ...ReviewStatusOptions,
];

export const ProposalStatusDescriptions: {
  [key in ProposalStatus]: JSX.Element;
} = {
  PENDING: <Status color={Colors.yellow} label="Pending" />,
  ACCEPTED: <Status color={Colors.green} label="Accepted" />,
  CLOSED: <Status color={Colors.gray} label="Closed" />,
};

export const ProposalStatusOptions = Object.values(ProposalStatus).map(
  (value) => ({
    label: ProposalStatusDescriptions[value],
    value,
  })
);

export const SearchProposalStatusOptions = [
  { label: <Status color={Colors.blue} label="All" />, value: StateFilter.ALL },
  ...ProposalStatusOptions,
];
