import { useEffect } from "react";
import { LoadingDots } from "library/assets";
import { Div, Flex } from "../Common";

type ListType = {
  id: string;
};
export const InfiniteList = <T extends ListType>({
  data,
  isLoading,
  list,
  setList,
}: {
  data: T[] | undefined;
  isLoading: boolean;
  list: T[] | undefined;
  setList: (d: T[]) => void;
}) => {
  useEffect(() => {
    if (!isLoading && data) {
      if (!list) {
        setList(data);
      } else if (list.length > 0 && data.length > 0) {
        const dataLast = data[data.length - 1];
        const listLast = list[list.length - 1];
        if (dataLast.id !== listLast.id) {
          // update list if last item ids don't match
          setList(list.concat(data));
        }
      }
    }
  }, [data, isLoading, list, setList]);

  return null;
};

export const InfiniteLoader = ({
  callback,
  show,
}: {
  callback: () => void;
  show?: boolean;
}) => {
  useEffect(() => {
    const loading = document.getElementById("loading");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback();
        }
      });
    });
    if (loading) {
      observer.observe(loading);
    }
  });

  return (
    <Div id="loading">
      {show && (
        <Flex padding="160px 0 32px" alignEnd justifyCenter>
          <LoadingDots size={100} r={2} />
        </Flex>
      )}
    </Div>
  );
};

/**
 * InfiniteLoader Test
 *
 * const nData = [...Array(30)].map((_, i) => ({ id: i }));
 * const [newData, setNewData] = useState(nData);
 * ...
 * <CardGrid>
 *   {newData.map((v) => (
 *     <Card key={v.id} minHeight="300px" />
 *   ))}
 * </CardGrid>
 * <InfiniteLoader
 *   show={newData.length === PageSize}
 *   callback={async () => {
 *     await new Promise((resolve) => setTimeout(resolve, 10000));
 *     if (newData.length === PageSize) {
 *       setNewData([...newData, ...newData.slice(0, 10)]);
 *     }
 *   }}
 * />
 */
