/* eslint-disable @typescript-eslint/no-redeclare */

import { TeamRole, TeamType } from "./generated-types";

export const TableStateEnum = {
  BLOCK: "BLOCK",
  GRID: "GRID",
  ROW: "ROW",
} as const;
export type TableStateEnum = keyof typeof TableStateEnum;

export const TabStateEnum = {
  VISIBLE: "VISIBLE",
  HIDDEN: "HIDDEN",
  LOCKED: "LOCKED",
} as const;
export type TabStateEnum = keyof typeof TabStateEnum;

export const ThemeEnum = {
  dark: "dark",
  light: "light",
} as const;
export type ThemeEnum = keyof typeof ThemeEnum;

export type MemberType = {
  id: string;
  icon?: string | null;
  name: string;
  memberHandle: string;
};

export type MemberInviteType = MemberType & {
  componentAddress: string | null;
  teams: { teamType: TeamType; teamRole: TeamRole }[];
};

export type TaskType = {
  id: string;
  isCompleted: boolean;
  name: string;
  owners: string[];
};

export type MemberTaskType = {
  id: string;
  isCompleted: boolean;
  name: string;
  memberProjectRoles: { memberId: string }[];
};

export const Currency = {
  usd: "usd",
} as const;
export type Currency = keyof typeof Currency;

export const TokenSymbol = {
  XRD: "XRD",
  USDC: "USDC",
} as const;
export type TokenSymbol = keyof typeof TokenSymbol;

export type ContractTypes = "admin" | "app" | "member" | "project" | "vesting";

export const TeamsPage = {
  APPS: "APPS",
  MEMBERS: "MEMBERS",
} as const;
export type TeamsPage = keyof typeof TeamsPage;
