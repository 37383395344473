import { StateNonFungibleDetailsResponseItem } from "@radixdlt/radix-dapp-toolkit";
import { StateValue } from "radix/radix-types";
import { parseArray, parseHashMap } from "./parser-utils";
import { Category, TeamType } from "library/types";

type FileType = Record<string, string | Record<string, string> | undefined>;

const parseFileNft = (nft: FileType, images: string[], urls: string[]) => {
  const nftData = typeof nft.data === "object" ? nft.data : {};

  return {
    id: nft.id?.toString(),
    name: nft.name?.toString(),
    image: nftData.image?.toString(),
    teamType: nftData.team_type as TeamType,
    category: nftData.category as Category,
    tags: nftData.tags?.split(", "),
    images,
    videoIds: nftData.videoIds?.split(", "),
    isActive: nftData.is_active === "true",
    urls,
    IS_RESET: false, // used for redux store reset
  };
};

export const parseFileData = (data: StateNonFungibleDetailsResponseItem[]) =>
  data.map((v) => {
    let images: string[] = [];
    let urls: string[] = [];
    const nft =
      v.data?.programmatic_json.kind === "Tuple"
        ? v.data.programmatic_json.fields.reduce((acc: FileType, curr) => {
            const c = curr as StateValue;
            if (c.field_name) {
              if (c.kind === "Array") {
                if (c.field_name === "images") {
                  images = parseArray(c);
                } else {
                  urls = parseArray(c);
                }
              } else if (c.kind === "Map") {
                acc[c.field_name] = parseHashMap(c);
              } else {
                acc[c.field_name] = `${c.value}`;
              }
            }
            return acc;
          }, {})
        : {};
    nft.id = v.non_fungible_id.slice(1, -1);
    return parseFileNft(nft, images, urls);
  });

export type FileNftType = ReturnType<typeof parseFileData>[number];
