import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { baseApi } from "./apis";
import { jwtMiddleware } from "./middleware";
import * as reducer from "./reducers";

// https://redux-toolkit.js.org/tutorials/typescript
// https://redux-toolkit.js.org/usage/usage-with-typescript
// https://redux-toolkit.js.org/rtk-query/overview

// store with code splitting
// https://github.com/reduxjs/redux-toolkit/discussions/2506#discussioncomment-3124916

export const store = configureStore({
  reducer: { ...reducer, [baseApi.reducerPath]: baseApi.reducer },
  middleware: (middleware) =>
    middleware().concat([baseApi.middleware, jwtMiddleware]),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
