import { skipToken } from "@reduxjs/toolkit/dist/query";
import { MemberInviteType, TeamRole, TeamType } from "library/types";
import { awaitTo, parseRtkError } from "library/utils";
import { useState } from "react";
import { appApi, inviteApi } from "redux/apis";
import { MembersModal } from "./MembersModal";
import { useApp } from "hooks";
import { AuthUserRes } from "redux/apiTypes";
import { AppContract, appInviteRemoveCall } from "radix";

type MembersAppModalProps = {
  appAddress: string;
  appHandle: string;
  auth: AuthUserRes;
  contract: AppContract | undefined;
  members: MemberInviteType[];
  resetContract: () => Promise<void>;
  teamType: TeamType;
  hideModal: () => void;
  show: boolean;
};

export const MembersAppModal = ({
  appAddress,
  appHandle,
  auth,
  contract,
  members,
  resetContract,
  teamType,
  hideModal,
  show,
}: MembersAppModalProps) => {
  const [loadingId, setLoadingId] = useState("");
  const [err, setErr] = useState("");

  // Get Invites
  const { isAppMember } = useApp();
  const {
    refetch: refetchI,
    data: invites,
    error: errorI,
  } = inviteApi.useInvitesAppGetQuery(
    isAppMember && appHandle ? { appHandle } : skipToken
  );

  const handleRemoveContract = async (
    componentAddress: string,
    isRemoveAll: boolean
  ) => {
    const txDetails = await appInviteRemoveCall(
      auth,
      appAddress,
      teamType,
      componentAddress,
      isRemoveAll
    );
    if (!txDetails) {
      setErr("Unable to update contract");
      return undefined;
    } else {
      resetContract();
      return "COMPLETED";
    }
  };

  const handleRemoveContractMember = async (memberId: string) => {
    if (!contract?.admin_badges) {
      setErr("App contract not found, please try again later");
      return;
    }
    const member = members.find((m) => m.id === memberId);
    if (!member?.componentAddress) {
      setErr("Member component address not found");
      return;
    }
    const componentAddress = member.componentAddress;
    const isContractAdmin = !!contract.admin_badges[componentAddress];

    const leadTeams = member.teams.filter(
      (t) => t.teamRole === TeamRole.TEAM_LEAD && t.teamType !== TeamType.ADMIN
    );
    const adminTeams = member.teams.filter(
      (t) => t.teamType === TeamType.ADMIN
    );

    // Remove member from contract only if no longer a lead or admin
    // if removing TeamType.ADMIN, isRemoveAll only if not a team lead on other teams
    if (isContractAdmin) {
      if (teamType === TeamType.ADMIN) {
        return handleRemoveContract(componentAddress, leadTeams.length === 0);
      } else if (leadTeams.length <= 1 && adminTeams.length === 0) {
        return handleRemoveContract(componentAddress, false);
      }
    }
    return "COMPLETED";
  };

  const handleRemoveContractInvite = async (componentAddress: string) => {
    if (!contract?.admin_invites) {
      setErr("App contract not found, please try again later");
      return;
    }
    const isInvited = !!contract.admin_invites[componentAddress];
    return isInvited
      ? handleRemoveContract(componentAddress, true)
      : "COMPLETED";
  };

  // Remove Member Functions
  const [handleRemoveMutation, { error: errorRM }] =
    appApi.useAppRemoveMemberMutation();
  const { refetch, error: errorM } = appApi.useAppGetTeamsQuery(
    appHandle ? { appHandle } : skipToken
  );
  const handleRemoveMember = async (memberId: string) => {
    setLoadingId(memberId);
    const cRes = await handleRemoveContractMember(memberId);
    if (!cRes) {
      setLoadingId("");
      return;
    }
    const [, res] = await awaitTo(
      handleRemoveMutation({ memberId, appHandle, teamType }).unwrap()
    );
    if (res?.id) {
      await refetch();
    }
    setLoadingId("");
  };

  // Remove Invite Functions
  const [handleRemoveIn, { error: errorRI }] =
    inviteApi.useInviteDeleteMutation();
  const handleRemoveInvite = async (
    inviteId: string,
    memberAddress: string
  ) => {
    setLoadingId(inviteId);
    const cRes = await handleRemoveContractInvite(memberAddress);
    if (!cRes) {
      setLoadingId("");
      return;
    }
    const [, res] = await awaitTo(
      handleRemoveIn({ inviteId, kind: "app" }).unwrap()
    );
    if (res?.id) {
      await refetchI();
    }
    setLoadingId("");
  };

  const teamInvites = invites?.filter((i) => i.teamType === teamType);

  return (
    <MembersModal
      hideModal={hideModal}
      show={show}
      teamType={teamType}
      members={members}
      invites={teamInvites}
      loadingId={loadingId}
      errorM={parseRtkError(errorRM || errorM)}
      errorI={parseRtkError(errorRI || errorI)}
      errorC={err}
      handleRemoveMember={handleRemoveMember}
      handleRemoveInvite={handleRemoveInvite}
    />
  );
};
