import {
  BookmarkRowButton,
  Div,
  TeamSymbol,
  UserIconNameHandle,
} from "library/components";
import { formatNumber } from "library/utils";
import { SavedHandlesRes, SearchMembersRes } from "redux/apiTypes";

export const MembersRowRender = (
  d: SearchMembersRes[number],
  _: number,
  saved?: SavedHandlesRes["savedMembers"]
) => [
  <Div display="flex" alignItems="center">
    <UserIconNameHandle
      icon={d.icon}
      name={d.name}
      memberHandle={d.memberHandle}
    />
  </Div>,
  <Div display="flex" alignItems="center" justifyContent="center" gap="8px">
    {d.teamTypes.map((role) => (
      <TeamSymbol key={role} type={role} />
    ))}
  </Div>,
  formatNumber(d.statsProject),
  formatNumber(d.statsVesting),
  formatNumber(d.statsTotal),
  formatNumber(d._count.apps),
  formatNumber(d._count.memberProjectRoles),
  // formatNumber(d.statsTotal),
  <BookmarkRowButton
    saved={saved?.some((s) => s.memberHandle === d.memberHandle)}
  />,
];
