import { StateEntityDetailsResponseItemDetails } from "@radixdlt/radix-dapp-toolkit";
import { PACKAGE_ADDRESS } from "consts";
import { Blueprints, NonFungibleData, StateValue } from "../radix-types";
import { parseTuple } from "./parser-utils";

export type MemberContract = {
  address: string;
  IS_RESET: boolean; // used for redux store reset
  nfts: NonFungibleData[];
};

export const parseMemberContract = (
  details: StateEntityDetailsResponseItemDetails,
  address: string
) => {
  if (
    details.type !== "Component" ||
    details.package_address !== PACKAGE_ADDRESS ||
    details.blueprint_name !== Blueprints.MemberContract ||
    !details.state
  ) {
    return undefined;
  }
  const s = parseTuple(details.state as StateValue);
  if (!s) {
    return undefined;
  }

  const memberState: MemberContract = {
    address,
    IS_RESET: false,
    nfts: [],
  };

  return memberState;
};
