import { MemberInviteType } from "library/types";
import { AppTeamsRes } from "redux/apiTypes";

export const getTeamMembers = (teams?: AppTeamsRes) =>
  teams
    ? Object.values(
        teams.reduce((acc: Record<string, MemberInviteType>, curr) => {
          if (!acc[curr.memberId]) {
            acc[curr.memberId] = {
              id: curr.memberId,
              memberHandle: curr.member.memberHandle,
              name: curr.member.name,
              icon: curr.member.icon,
              componentAddress: curr.member.componentAddress,
              teams: [{ teamType: curr.teamType, teamRole: curr.teamRole }],
            };
          } else {
            acc[curr.memberId].teams.push({
              teamType: curr.teamType,
              teamRole: curr.teamRole,
            });
          }
          return acc;
        }, {})
      )
    : undefined;
