import { Helmet } from "react-helmet-async";
import { BasePaths, PageDescriptions } from "consts";
import { useFile } from "hooks";
import { getExplorerUrl, getYoutubeUrls } from "utils";
import {
  A,
  Card,
  CreatedUpdatedCard,
  Div,
  Flex,
  Gallery,
  H1,
  IconNameSubtitle,
  InputBase,
  LoadingPage,
  MdRender,
  OptionButton,
  P,
  Page,
  T2,
  T3,
  Tags,
  TeamTypeOptionsSmall,
  UserIconNameHandle,
} from "library/components";
import { ExternalLinkIcon } from "library/assets";
import { css } from "styled-components";
import { Link } from "react-router-dom";

const BorderCss = css`
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: 12px;
  padding: 12px;
`;

export const FileAbout = () => {
  const { file, fileResourceAddress, nft, error, isLoading } = useFile();

  if (!file || !nft || file.isLocked || error || isLoading) {
    return (
      <LoadingPage
        error={error}
        isLoading={isLoading}
        isLocked={file?.isLocked}
      />
    );
  }

  return (
    <Page>
      <Helmet>
        <title>{`${file.name} | About Files`}</title>
        <meta name="description" content={PageDescriptions.filesAbout} />
      </Helmet>

      <Div display="flex" flexDirection="column" md={{ flexDirection: "row" }}>
        <Div flex="1">
          <H1 margin="16px 20px" sm={{ margin: "32px 24px" }}>
            {nft.name}
          </H1>

          <Gallery
            data={[
              ...(nft.videoIds || []).map((v) => getYoutubeUrls(v)),
              ...(nft.images || []).map((s) => ({
                imageUrl: s,
              })),
            ]}
          />

          <Div marginTop="32px">
            {file.description ? (
              <MdRender text={file.description} />
            ) : (
              <P lineHeight="2.5" fontStyle="italic" secondary>
                No description
              </P>
            )}
          </Div>
        </Div>

        <Div
          width="100%"
          margin="32px 0"
          md={{ width: "320px", margin: "0 0 0 32px" }}
        >
          <Card
            margin="32px 0"
            padding="16px"
            sm={{ padding: "24px" }}
            borderRadius="12px"
          >
            <OptionButton
              justifyContent="space-between"
              borderRadius="8px"
              marginBottom="8px"
              showBorder
              textActive
              onClick={() => {
                navigator.clipboard.writeText(file.number.toString());
              }}
            >
              <T3 normal>File #</T3>
              <T2 medium>{file.number}</T2>
            </OptionButton>

            {fileResourceAddress && (
              <Flex marginTop="12px" gap="4px" alignCenter justifyBetween>
                <InputBase value={fileResourceAddress} readOnly secondary />
                <A
                  href={getExplorerUrl(
                    `${fileResourceAddress}:<${file.number}>`
                  )}
                  target="_blank"
                >
                  <ExternalLinkIcon size={16} />
                </A>
              </Flex>
            )}
            <Div css={BorderCss} marginTop="12px">
              <T2 marginBottom="8px" medium>
                Team Type
              </T2>
              {
                TeamTypeOptionsSmall.find((v) => v.value === file.teamType)
                  ?.label
              }
            </Div>
          </Card>

          <Card
            borderRadius="12px"
            marginTop="24px"
            padding="20px"
            overflow="visible"
          >
            <T2 marginBottom="12px" medium>
              Creator
            </T2>
            {file.app && (
              <Link to={`/${BasePaths.a}/${file.app.appHandle}`}>
                <Flex alignCenter>
                  <IconNameSubtitle {...file.app} />
                </Flex>
              </Link>
            )}
            {file.member && (
              <Link to={`/${BasePaths.m}/${file.member.memberHandle}`}>
                <Flex alignCenter>
                  <UserIconNameHandle {...file.member} />
                </Flex>
              </Link>
            )}
          </Card>

          <Card
            borderRadius="12px"
            marginTop="24px"
            padding="20px"
            overflow="visible"
          >
            <CreatedUpdatedCard
              created={file.createdAt}
              updated={file.updatedAt}
            />
          </Card>

          <Div marginTop="32px">
            <Tags
              tags={[
                file.category.toLowerCase(),
                ...file.tags.map((t) => t.name),
              ]}
            />
          </Div>
        </Div>
      </Div>
    </Page>
  );
};
