import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  SortMemberOptions,
  SortMemberTypes,
  MembersTableCols,
  MembersTableHeadings,
  PageDescriptions,
  PageTitles,
  AppUrl,
  BasePaths,
} from "consts";
import {
  Card,
  CardGrid,
  DataTable,
  Div,
  Divider,
  Dropdown,
  Flex,
  InfiniteList,
  InfiniteLoader,
  MemberCard,
  NoneCard,
  Page,
  SearchInput,
  Tab,
  TableButtons,
  TeamTypeOptionsMedium,
  TeamsPageOptions,
} from "library/components";
import { TableStateEnum, TeamsPage, TeamType } from "library/types";
import { PageSize, TeamTypeDescriptions } from "library/consts";
import { Bookmark, ChevronDown } from "library/assets";
import { MembersRowRender } from "./MembersRow";
import { useMember, useSaved } from "hooks";
import { searchApi } from "redux/apis";
import { SearchMembersRes, SortMember } from "redux/apiTypes";

export const Members = () => {
  const navigate = useNavigate();
  const [tableState, setTableState] = useState<TableStateEnum>(
    TableStateEnum.GRID
  );

  const [search, setSearch] = useState("");
  const [teamType, setTeamType] = useState<TeamType>(TeamType.BLUEPRINT);
  const [sort, setSort] = useState(SortMemberTypes.MOST_REWARDS_ALL);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<SearchMembersRes>();
  const [isMounted, setIsMounted] = useState(false);
  const { saved } = useSaved();

  const { isSignedIn } = useMember();

  const { data, isFetching } = searchApi.useSearchMembersQuery({
    teamType,
    sort,
    search: search || undefined,
    skip: skip || undefined,
    isSaved: isSignedIn ? isSaved : false,
  });

  // Infinite Loader
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    } else {
      setList(undefined);
      setSkip(0);
    }
  }, [search, teamType, sort, isSaved, isMounted]);

  return (
    <Page>
      <Helmet>
        <title>{`${TeamTypeDescriptions[teamType]} - ${PageTitles.members}`}</title>
        <meta name="description" content={PageDescriptions.members} />
        <link rel="canonical" href={`${AppUrl}/${BasePaths.members}`} />
      </Helmet>

      <InfiniteList
        data={data}
        isLoading={isFetching}
        list={list}
        setList={(d) => {
          setList(d);
        }}
      />

      <Flex gap="8px" margin="32px 0" alignCenter>
        <SearchInput
          flex="1"
          handleSearch={(v) => setSearch(v)}
          isLoading={isFetching}
        />
      </Flex>

      <Div
        display="block"
        alignItems="center"
        margin="8px 0"
        sm={{ display: "flex", margin: "24px 0" }}
      >
        <Flex
          flex="1"
          margin="32px 0 16px"
          sm={{ margin: "0 24px 0 12px" }}
          gap="8px"
          alignCenter
        >
          <Dropdown
            handleSelect={(v) => {
              if (v === TeamsPage.APPS) {
                navigate(`/${BasePaths.apps}`);
              }
            }}
            selectValue={TeamsPage.MEMBERS}
            options={TeamsPageOptions}
            icon={
              <Div display="flex" marginLeft="20px">
                <ChevronDown size={32} />
              </Div>
            }
          />
          <Dropdown
            handleSelect={(v) => setTeamType(v as TeamType)}
            selectValue={teamType}
            options={TeamTypeOptionsMedium.slice(0, -1)}
            medium
          />
        </Flex>

        <Div
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          margin="16px 0"
          sm={{ justifyContent: "flex-end", margin: "0" }}
        >
          <Dropdown
            handleSelect={(v) => setSort(v as SortMember)}
            selectValue={sort}
            options={SortMemberOptions}
          />
          {isSignedIn && (
            <Tab
              height="40px"
              sm={{ padding: "12px" }}
              onClick={() => {
                setList(undefined);
                setIsSaved(!isSaved);
              }}
            >
              <Bookmark fill={isSaved ? "currentColor" : "none"} />
            </Tab>
          )}
          <Divider margin="0 16px" />
          <TableButtons tableState={tableState} setTableState={setTableState} />
        </Div>
      </Div>

      {tableState === TableStateEnum.GRID && list?.length === 0 && (
        <NoneCard large />
      )}

      {tableState === TableStateEnum.GRID && (
        <CardGrid>
          {list?.map((d) => (
            <MemberCard
              key={d.id}
              {...d}
              icon={d.icon || ""}
              bio={d.bio || ""}
              rewards={d.statsTotal}
              apps={d._count.apps}
              projects={d._count.memberProjectRoles}
              saved={saved?.savedMembers.some(
                (s) => s.memberHandle === d.memberHandle
              )}
            />
          ))}
        </CardGrid>
      )}

      {tableState === TableStateEnum.ROW && (
        <Card marginTop="32px">
          <DataTable
            cols={MembersTableCols}
            headings={MembersTableHeadings}
            data={list}
            rowData={saved?.savedMembers}
            rowRender={MembersRowRender}
            handleRowClick={(d) => {
              navigate(`/${BasePaths.m}/${d.memberHandle}`);
            }}
          />
        </Card>
      )}

      <InfiniteLoader
        show={isFetching || data?.length === PageSize}
        callback={() => {
          if (list && isFetching && data?.length === PageSize) {
            const newSkip = list.length;
            if (skip !== newSkip) {
              setSkip(newSkip);
            }
          }
        }}
      />
    </Page>
  );
};
