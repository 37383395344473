import { Card, ThemeButtons } from "library/components";
import { useTheme } from "redux/hooks";

// ABOUT: Used to test theme changes and TabBar hiding on input selection (for mobile)
export const DevBar = () => {
  const { state, actions } = useTheme();
  const { theme } = state;
  const { setTheme } = actions;

  return (
    <Card
      position="fixed"
      bottom="48px"
      right="24px"
      padding="16px"
      borderRadius="16px"
      zIndex="51"
      display="flex"
    >
      <ThemeButtons theme={theme} setTheme={setTheme} />

      {/* <input style={{ width: "50px", border: "1px solid lightsteelblue" }} /> */}
    </Card>
  );
};
