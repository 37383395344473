import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { OptionButton, Button as BaseButton } from "../Buttons/Button";
import { Div, Flex } from "../Common";
import { T1, T3 } from "../Text";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "../../assets";

const dayName = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

const monthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MonthGrid = styled(Div)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const Button = styled(BaseButton)`
  padding: 4px;
`;

type DatePickerProps = {
  calendarState: Date; // current viewed month
  date?: string; // selected date
  handleSetCalendarState: Dispatch<SetStateAction<Date>>;
  handleSetDate: (val?: string) => void;
};

export const DatePickerCalendar = ({
  calendarState,
  date,
  handleSetCalendarState,
  handleSetDate,
}: DatePickerProps) => {
  const [today] = useState(new Date());
  const [[todayYear, todayMonth, todayDate]] = useState([
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  ]);

  const [year, setYear] = useState<number>();
  const [month, setMonth] = useState<number>();
  const [daysInMonth, setDaysInMonth] = useState<number[]>();
  const [paddedDays, setPaddedDays] = useState<number[]>();

  const selectedDate = date ? new Date(date) : undefined;
  const isSelectedMonthYear =
    selectedDate &&
    selectedDate.getFullYear() === year &&
    selectedDate.getMonth() === month;

  useEffect(() => {
    if (calendarState) {
      const currYear = calendarState.getFullYear();
      const currMonth = calendarState.getMonth();
      setYear(currYear);
      setMonth(currMonth);

      const lastDayOfMonth = new Date(currYear, currMonth + 1, 0).getDate();
      setDaysInMonth([...Array(lastDayOfMonth)]);

      const firstDayOfMonth = new Date(currYear, currMonth, 1).getDay();
      setPaddedDays([...Array(firstDayOfMonth)]);
    }
  }, [calendarState]);

  if (
    !year ||
    typeof month !== "number" ||
    !(daysInMonth && daysInMonth.length > 0) ||
    !paddedDays
  ) {
    return null;
  }

  const handleNewYear = (offset: number) => {
    const newDate = new Date(year + offset, month, calendarState.getDate());
    handleSetCalendarState(newDate);
  };

  const handleNewMonth = (offset: number) => {
    const newDate = new Date(year, month + offset, calendarState.getDate());
    handleSetCalendarState(newDate);
  };

  const handleClickDate = (dateVal: number) => {
    const newDate = new Date(year, month, dateVal);
    const dateStr = newDate.toISOString();
    const newVal = dateStr === date ? undefined : dateStr;
    handleSetDate(newVal);
  };

  return (
    <>
      <Flex marginBottom="8px" alignCenter justifyBetween>
        <T1 medium>{`${monthName[month]} ${year}`}</T1>
        <Flex>
          <Button type="button" onClick={() => handleNewYear(-1)}>
            <ChevronsLeft size={20} />
          </Button>
          <Button type="button" onClick={() => handleNewMonth(-1)}>
            <ChevronLeft size={20} />
          </Button>
          <Button type="button" onClick={() => handleNewMonth(1)}>
            <ChevronRight size={20} />
          </Button>
          <Button type="button" onClick={() => handleNewYear(1)}>
            <ChevronsRight size={20} />
          </Button>
        </Flex>
      </Flex>

      <MonthGrid>
        {dayName.map((d) => (
          <T3 key={d} padding="4px" textAlign="center" medium>
            {d}
          </T3>
        ))}
        {paddedDays.map((_, i) => (
          <Div key={i} />
        ))}
        {daysInMonth.map((_, i) => {
          const currDate = i + 1;
          const isToday =
            todayYear === year &&
            todayMonth === month &&
            currDate === todayDate;
          const isSelectedDate =
            isSelectedMonthYear && currDate === selectedDate.getDate();

          return (
            <OptionButton
              key={`${year}-${month}-${currDate}`}
              type="button"
              justifyContent="center"
              padding="4px"
              borderRadius="4px"
              $active={isSelectedDate}
              textActive={isToday}
              onClick={() => handleClickDate(currDate)}
            >
              {currDate}
            </OptionButton>
          );
        })}
      </MonthGrid>
    </>
  );
};
