import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useBack = () => {
  const navigate = useNavigate();

  // Use timeout and go to home if back button fails
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    if (!isClicked) {
      return;
    }

    const handler = window.setTimeout(() => {
      navigate("/", { replace: true });
    }, 1000);

    return () => window.clearTimeout(handler);
  }, [isClicked, navigate]);

  // Go back to initial backIdx page - 1
  const handleBack = () => {
    const state = window.history.state;
    const idx = state.idx;
    const backIdx = state.backIdx;
    if (backIdx) {
      navigate(backIdx - idx - 1);
    } else {
      navigate("/", { replace: true });
    }
    setIsClicked(true);
  };

  return {
    handleBack,
  };
};
