export const FinancialPageTypes = {
  PROJECTS: "PROJECTS",
  VESTINGS: "VESTINGS",
};

export const FinancialPageOptions = [
  { label: "Project Transactions", value: "PROJECTS" },
  { label: "Vesting Transactions", value: "VESTINGS" },
];

export const MemberFinancialPageOptions = [
  { label: "Project Transactions", value: "PROJECTS" },
  { label: "Vesting Transactions", value: "VESTINGS" },
];

export const ProjectTransactionsTableCols = [
  { textAlign: "center" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "left" },
  { textAlign: "left" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
  { textAlign: "center" },
];

export const ProjectTransactionsTableHeadings = [
  "#",
  "App",
  "Project #",
  "By",
  "To",
  "Type",
  "Amount",
  "Date Time",
  "Tx",
];

// Vesting Transactions

export const VestingTransactionsTableHeadings = [
  "#",
  "App",
  "Vesting #",
  "By",
  "To",
  "Type",
  "Amount",
  "Date Time",
  "Tx",
];
