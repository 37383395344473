import { z } from "zod";
import {
  BooleanSchema,
  CategoryEnumZod,
  DefaultProfileReqSchema,
  HandleSchema,
  NameSchema,
  SocialsSchema,
  StageEnumZod,
  StringSchema,
  SymbolSchema,
  TagSchema,
  UrlSchema,
} from "./common-schema";

const AppProfileSchema = z
  .object({
    appUrl: UrlSchema.or(z.string().length(0)),
    websiteUrl: UrlSchema.or(z.string().length(0)),
  })
  .merge(DefaultProfileReqSchema)
  .merge(SocialsSchema.partial());

export const AppReq = z.object({
  appHandle: HandleSchema,
  name: NameSchema.regex(new RegExp(/^[a-zA-Z0-9 :-]*$/)),
  subtitle: NameSchema,
  icon: UrlSchema,
  image: UrlSchema,

  stage: StageEnumZod,
  category: CategoryEnumZod,
  tags: z.array(TagSchema).length(2, { message: "Must have 2 tags" }),
  isHidden: BooleanSchema.optional(),

  componentAddress: StringSchema,

  resourceAddress: StringSchema.or(z.string().length(0)),
  tokenSymbol: SymbolSchema.or(z.string().length(0)),
  tokenIcon: UrlSchema.or(z.string().length(0)),

  appProfile: AppProfileSchema,
});
