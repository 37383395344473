import { GithubClientID, isProd } from "consts";

// used in NavBar and TabBar
export const parseBaseRoute = (pathname: string) => pathname.split("/")[1];

// imageUrl: `https://i.ytimg.com/vi_webp/${videoId}/maxresdefault.webp`,
export const getYoutubeUrls = (videoId: string) => ({
  imageUrl: `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
  videoUrl: `https://www.youtube-nocookie.com/embed/${videoId}`,
});

export const getYoutubeImg = (videoId: string) =>
  `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

export const getRandomState = () =>
  window.crypto.randomUUID() || `dev-${Date.now()}`;

// https://docs.github.com/en/developers/apps/building-oauth-apps/authorizing-oauth-apps
export const getGithubOauth = (state: string) =>
  `https://github.com/login/oauth/authorize?client_id=${GithubClientID}&state=${state}`;

export const getExplorerUrl = (address: string) =>
  isProd
    ? ``
    : `https://stokenet-dashboard.radixdlt.com/${address
        .split("_")
        .find((v) => v)}/${address}`;
