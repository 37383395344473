import { useEffect, useState } from "react";
import { Button, Checkbox, Div, Flex, PX, UserIcons } from "library/components";
import { MemberTaskType, MemberType } from "../../types";
import { IdRes } from "redux/apiTypes";
import { awaitTo } from "library/utils";
import { LoadingDots } from "library/assets";

type MemberTaskProps = {
  disabled?: boolean;
  loadingTaskId?: string;
  task: MemberTaskType;
  members: MemberType[];
  handleUpdateList: (val: { id: string; isCompleted: boolean }) => void;
};

const MemberTask = ({
  disabled,
  loadingTaskId,
  task,
  members,
  handleUpdateList,
}: MemberTaskProps) => (
  <Flex alignStart>
    {task.id === loadingTaskId ? (
      <Div padding="8px">
        <LoadingDots />
      </Div>
    ) : (
      <Checkbox
        padding="8px"
        checked={task.isCompleted}
        handleCheck={() => {
          handleUpdateList({ id: task.id, isCompleted: !task.isCompleted });
        }}
        disabled={disabled}
      />
    )}

    <Button
      type="button"
      fontSize={PX(15)}
      fontWeight="400"
      padding="8px"
      justifyContent="start"
      textAlign="left"
      flex="1"
      onClick={() => {
        handleUpdateList({ id: task.id, isCompleted: !task.isCompleted });
      }}
      disabled={disabled}
    >
      {task.name}
    </Button>

    <Div padding="6px 4px">
      <UserIcons
        members={task.memberProjectRoles.map(
          (m) =>
            members.find((mem) => mem.id === m.memberId) || {
              id: "unknown",
              icon: null,
              name: "Unknown",
            }
        )}
      />
    </Div>
  </Flex>
);

// --- Dashboard Member Task List

type MemberTasksListProps = {
  disabled?: boolean;
  handleTask: (
    taskId: string,
    isCompleted: boolean
  ) => Promise<IdRes | undefined>;
  tasks: MemberTaskType[];
  members: MemberType[];
};

export const MemberTasksList = ({
  disabled,
  handleTask,
  tasks,
  members,
}: MemberTasksListProps) => {
  const [tasksList, setTasksList] = useState<MemberTaskType[]>([]);
  const [loadingTaskId, setLoadingTaskId] = useState("");

  // update tasks if updated in Dashboard Objective, not tasks list
  useEffect(() => {
    setTasksList(tasks);
  }, [tasks]);

  return (
    <>
      {tasksList.map((task, i) => (
        <MemberTask
          key={task.id || i}
          task={task}
          members={members}
          handleUpdateList={async (task) => {
            if (disabled || loadingTaskId) return;
            // call api update
            setLoadingTaskId(task.id);
            const [err, res] = await awaitTo(
              handleTask(task.id, task.isCompleted)
            );
            setLoadingTaskId("");

            if (err || !res?.id) return;

            // update component state
            const newList = [...tasksList];
            const updateIdx = newList.findIndex((t) => t.id === task.id);
            newList[updateIdx] = { ...newList[updateIdx], ...task };
            setTasksList(newList);
          }}
          disabled={disabled || Boolean(loadingTaskId)}
          loadingTaskId={loadingTaskId}
        />
      ))}
    </>
  );
};
