import { awaitTo } from "library/utils";
import { savedApi } from "redux/apis";
import { useJwt } from "redux/hooks";
import { useAppDispatch } from "redux/store";

export const useSaved = () => {
  const dispatch = useAppDispatch();
  const { state } = useJwt();
  const { isSignedIn } = state;
  const {
    data: saved,
    isLoading,
    error,
  } = savedApi.useSavedHandlesQuery(undefined, {
    skip: !isSignedIn,
  });
  const [handleSaveAppMut, { isLoading: isLoadingA, error: errorA }] =
    savedApi.useSavedAddAppMutation();
  const [handleSaveProjectMut, { isLoading: isLoadingP, error: errorP }] =
    savedApi.useSavedAddProjectMutation();
  const [handleSaveMemberMut, { isLoading: isLoadingM, error: errorM }] =
    savedApi.useSavedAddMemberMutation();
  const [handleSaveFileMut, { isLoading: isLoadingF, error: errorF }] =
    savedApi.useSavedAddFileMutation();

  const handleSaveApp = async (appHandle: string, isSaved: boolean) => {
    const [, data] = await awaitTo(
      handleSaveAppMut({
        handle: appHandle,
        isSaved,
      }).unwrap()
    );
    if (data) {
      dispatch(
        savedApi.util.updateQueryData("savedHandles", undefined, (data) => {
          if (isSaved) {
            data.savedApps.push({ appHandle });
          } else {
            data.savedApps = data.savedApps.filter(
              (s) => s.appHandle !== appHandle
            );
          }
        })
      );
    }
  };

  const handleSaveProject = async (projectHandle: string, isSaved: boolean) => {
    const [, data] = await awaitTo(
      handleSaveProjectMut({
        handle: projectHandle,
        isSaved,
      }).unwrap()
    );
    if (data) {
      dispatch(
        savedApi.util.updateQueryData("savedHandles", undefined, (data) => {
          if (isSaved) {
            data.savedProjects.push({ projectHandle });
          } else {
            data.savedProjects = data.savedProjects.filter(
              (s) => s.projectHandle !== projectHandle
            );
          }
        })
      );
    }
  };

  const handleSaveMember = async (memberHandle: string, isSaved: boolean) => {
    const [, data] = await awaitTo(
      handleSaveMemberMut({
        handle: memberHandle,
        isSaved,
      }).unwrap()
    );
    if (data) {
      dispatch(
        savedApi.util.updateQueryData("savedHandles", undefined, (data) => {
          if (isSaved) {
            data.savedMembers.push({ memberHandle });
          } else {
            data.savedMembers = data.savedMembers.filter(
              (s) => s.memberHandle !== memberHandle
            );
          }
        })
      );
    }
  };

  const handleSaveFile = async (fileHandle: string, isSaved: boolean) => {
    const [, data] = await awaitTo(
      handleSaveFileMut({
        handle: fileHandle,
        isSaved,
      }).unwrap()
    );
    if (data) {
      dispatch(
        savedApi.util.updateQueryData("savedHandles", undefined, (data) => {
          if (isSaved) {
            data.savedFiles.push({ fileHandle });
          } else {
            data.savedFiles = data.savedFiles.filter(
              (s) => s.fileHandle !== fileHandle
            );
          }
        })
      );
    }
  };

  return {
    saved,
    isLoading:
      isLoading || isLoadingA || isLoadingP || isLoadingM || isLoadingF,
    error: error || errorA || errorP || errorM || errorF,
    handleSaveApp,
    handleSaveProject,
    handleSaveMember,
    handleSaveFile,
  };
};
