import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { css } from "styled-components";
import { BasePaths } from "consts";
import {
  BaseButton,
  CreatedUpdatedCard,
  DatePicker,
  Div,
  Dropdown,
  Error,
  Flex,
  Input,
  MdEditor,
  MdRender,
  MemberDropdown,
  Modal,
  ObjectiveStateOptions,
  OptionButton,
  PX,
  SecondaryButton,
  T2,
  T3,
  TasksList,
  TeamTypeOptionsSmall,
} from "library/components";
import { Colors } from "library/consts";
import { MemberType, ObjectiveState, TeamType } from "library/types";
import { awaitTo } from "library/utils";
import {
  IdRes,
  ObjectiveReq as ObjectiveReqType,
  ObjectiveRes,
  ProjectObjectivesRes,
} from "redux/apiTypes";
import { ObjectiveReq } from "schema";

const parseData = (data?: ProjectObjectivesRes[number]) =>
  data
    ? {
        name: data.name,
        description: data.description || "",
        state: data.state,
        startDate: data.startDate,
        endDate: data.endDate,
        tasks: data.tasks.map((t) => ({
          ...t,
          owners: t.memberProjectRoles.map((m) => m.memberId),
        })),
        requesterIds: data.requesters.map((r) => r.memberId),
        ownerIds: data.owners.map((r) => r.memberId),
      }
    : {
        state: ObjectiveState.DRAFT,
        tasks: [],
        requesterIds: [],
        ownerIds: [],
      };

const getLink = (projectHandle?: string, number?: number) => {
  const projectLink = projectHandle
    ? `launchspace.app/${BasePaths.p}/${projectHandle}`
    : null;
  return number ? `${projectLink}/${BasePaths.o}/${number}` : projectLink;
};

type ObjectiveModalProps = {
  hideModal: () => void;
  show: boolean;
  members: MemberType[];
  objectiveNumber?: string | number;
  teamType?: TeamType;
  error?: string;
  isLoading?: boolean;
  handleCreate?: (data: ObjectiveReqType) => Promise<IdRes | undefined>;
  handleUpdate?: (data: ObjectiveReqType) => Promise<ObjectiveRes | undefined>;
  data?: ProjectObjectivesRes[number];
  projectHandle?: string;
  projectName?: string;
  readOnly?: boolean;
};

export const ObjectiveModal = ({
  hideModal,
  show,
  members,
  objectiveNumber,
  teamType,
  error,
  isLoading,
  handleCreate,
  handleUpdate,
  data,
  projectHandle,
  projectName,
  readOnly,
}: ObjectiveModalProps) => {
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(Boolean(data));

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ObjectiveReqType>({
    resolver: zodResolver(ObjectiveReq),
    defaultValues: parseData(data),
  });

  const onSubmit = async (d: ObjectiveReqType) => {
    const createFunc = handleCreate || handleUpdate;
    if (!createFunc) {
      return;
    }
    const [err, res] = await awaitTo(createFunc(d));
    if (!err && res?.id) {
      hideModal();
      if (!data) {
        reset({});
        setIsPreview(false);
      }
    }
  };

  // reset form if input data changes
  useEffect(() => {
    if (data) {
      reset(parseData(data));
      setIsPreview(true);
    }
  }, [data, reset]);

  const objectiveLink = getLink(projectHandle, data?.number);

  return (
    <Modal show={show} closeModal={hideModal} large>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap="40px" flexDirection="column" sm={{ flexDirection: "row" }}>
          <Flex flex="1" flexDirection="column" gap="20px">
            <Input
              error={errors.name?.message}
              rProps={register("name")}
              iProps={{
                placeholder: "Objective Name*",
                fontSize: PX(20),
                fontWeight: "500",
                readOnly,
              }}
            />

            <Controller
              control={control}
              name="description"
              render={({ field: { value, onChange } }) =>
                isPreview ? (
                  <MdRender text={value || "--"} />
                ) : (
                  <MdEditor
                    formError={errors.description?.message}
                    value={value}
                    setValue={onChange}
                    style={{ height: "320px" }}
                  />
                )
              }
            />
            <SecondaryButton
              type="button"
              height="32px"
              width="fit-content"
              padding="8px 12px"
              sm={{ padding: "8px 12px" }}
              borderRadius="8px"
              marginBottom="24px"
              onClick={() => setIsPreview(!isPreview)}
              disabled={readOnly}
            >
              {isPreview ? "Edit" : "Preview"}
            </SecondaryButton>

            <Controller
              control={control}
              name="tasks"
              render={({ field: { value, onChange } }) => (
                <TasksList
                  setTasksList={onChange}
                  tasksList={value}
                  membersList={members}
                  disabled={readOnly}
                />
              )}
            />
          </Flex>

          <Flex
            gap="8px"
            flexDirection="column"
            width="100%"
            sm={{ width: "300px" }}
          >
            <Div marginBottom="12px">
              <Flex
                borderRadius="8px"
                padding="8px 12px"
                css={css`
                  border: 2px solid ${({ theme }) => theme.borderColor};
                `}
                justifyBetween
              >
                <T2 medium>Objective #:</T2>
                <T2 medium>{objectiveNumber}</T2>
              </Flex>

              {objectiveLink && (
                <OptionButton
                  justifyContent="space-between"
                  borderRadius="8px"
                  marginTop="8px"
                  showBorder
                  textActive
                  onClick={() => {
                    navigator.clipboard.writeText(`https://${objectiveLink}`);
                  }}
                >
                  <T3 normal marginRight="12px">
                    Link:
                  </T3>
                  <T2 ellipsis medium>
                    {objectiveLink}
                  </T2>
                </OptionButton>
              )}

              {data && (
                <OptionButton
                  type="button"
                  justifyContent="space-between"
                  borderRadius="8px"
                  textActive
                  onClick={() => {
                    navigator.clipboard.writeText(data.numberId.toString());
                  }}
                >
                  <T3 normal>Objective ID:</T3>
                  <T3 normal>{data.numberId}</T3>
                </OptionButton>
              )}
            </Div>

            <Div
              borderRadius="8px"
              padding="8px 12px"
              css={css`
                border: 2px solid ${({ theme }) => theme.borderColor};
              `}
            >
              <T3 marginBottom="4px" normal>
                Team
              </T3>
              <T2 medium>
                {TeamTypeOptionsSmall.find((o) => o.value === teamType)?.label}
              </T2>
            </Div>

            <Controller
              control={control}
              name="state"
              render={({ field: { value, onChange } }) => (
                <Div>
                  <Dropdown
                    heading="State"
                    handleSelect={onChange}
                    selectValue={value}
                    options={ObjectiveStateOptions}
                    wrapperProps={{ width: "100%" }}
                    buttonProps={{ minHeight: "56px", showBorder: true }}
                    disabled={readOnly}
                    hideChevron
                  />
                  {errors.state?.message && (
                    <T2 color={Colors.red} marginTop="8px" medium>
                      {errors.state.message}
                    </T2>
                  )}
                </Div>
              )}
            />

            <Div>
              <Flex
                position="relative"
                flexWrap="nowrap"
                borderRadius="8px"
                css={css`
                  border: ${({ theme }) => `2px solid ${theme.borderColor}`};
                `}
              >
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      handleSelectDate={(v) => onChange(v || "")}
                      label="Start"
                      date={value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      handleSelectDate={(v) => onChange(v || "")}
                      label="End"
                      date={value}
                    />
                  )}
                />
              </Flex>
              {(errors.startDate?.message || errors.endDate?.message) && (
                <T2 color={Colors.red} marginTop="8px" medium>
                  {errors.startDate?.message || errors.endDate?.message}
                </T2>
              )}
            </Div>

            {projectHandle && projectName && (
              <OptionButton
                display="block"
                borderRadius="8px"
                showBorder
                textActive
                onClick={() => {
                  navigate(`/${BasePaths.p}/${projectHandle}`);
                }}
              >
                <T3 marginBottom="4px" normal>
                  Project
                </T3>
                <T2 ellipsis medium>
                  {projectName || "--"}
                </T2>
              </OptionButton>
            )}

            <Div marginTop="12px">
              <Controller
                control={control}
                name="requesterIds"
                render={({ field: { value, onChange } }) => (
                  <MemberDropdown
                    handleSelectMember={(id) => {
                      const newMembers = value?.includes(id)
                        ? value.filter((m) => m !== id)
                        : value
                        ? [...value, id]
                        : [id];
                      onChange(newMembers);
                    }}
                    heading="Requesters"
                    selected={value}
                    members={members}
                  />
                )}
              />
            </Div>
            <Controller
              control={control}
              name="ownerIds"
              render={({ field: { value, onChange } }) => (
                <MemberDropdown
                  handleSelectMember={(id) => {
                    const newMembers = value?.includes(id)
                      ? value.filter((m) => m !== id)
                      : value
                      ? [...value, id]
                      : [id];
                    onChange(newMembers);
                  }}
                  heading="Owners"
                  selected={value}
                  members={members}
                />
              )}
            />
            {data && (
              <CreatedUpdatedCard
                created={data.createdAt}
                updated={data.updatedAt}
              />
            )}

            {!readOnly && (
              <BaseButton isLoading={isLoading} marginTop="32px">
                {data ? "Update" : "Create"} Objective
              </BaseButton>
            )}
            <Error error={error} />
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
