import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import styled, { css } from "styled-components";
import {
  BaseButton,
  Div,
  Error,
  Flex,
  H2,
  MdEditor,
  MdRender,
  Modal,
  NoneCard,
  PrimaryButton,
  SecondaryButton,
  T2,
  UserIconNameHandle,
} from "library/components";
import { awaitTo, formatDate, parseRtkError } from "library/utils";
import { useEffect, useState } from "react";
import { FeedbackGetRes, FeedbackReq as FeedbackReqType } from "redux/apiTypes";
import { feedbackApi } from "redux/apis";
import { FeedbackReq } from "schema";

const Form = styled.form`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

type FeebackFormProps = {
  error?: string;
  isLoading?: boolean;
  handleCreateUpdate: (data: FeedbackReqType) => Promise<void>;
  data?: FeedbackGetRes[number];
};

export const FeedbackForm = ({
  error,
  isLoading,
  handleCreateUpdate,
  data,
}: FeebackFormProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FeedbackReqType>({
    resolver: zodResolver(FeedbackReq),
    defaultValues: {
      description: data?.description || "",
    },
  });
  const onSubmit = (d: FeedbackReqType) => handleCreateUpdate(d);

  useEffect(() => {
    if (data) {
      reset({ description: data?.description || "" });
    }
  }, [data, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="description"
        render={({ field: { value, onChange } }) => (
          <MdEditor
            formError={errors.description?.message}
            value={value}
            setValue={onChange}
            style={{ flex: "1" }}
            wrapperStyle={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
            }}
          />
        )}
      />
      <Flex gap="16px" marginTop="32px" alignCenter justifyEnd>
        <Error error={error} margin="0" />
        <SecondaryButton>Cancel</SecondaryButton>
        <BaseButton isLoading={isLoading}>
          {data ? "Update" : "Create"}
        </BaseButton>
      </Flex>
    </Form>
  );
};

type FeedbackModalProps = {
  hideModal: () => void;
  show: boolean;
  projectHandle: string;
  userId: string | undefined;
  isAMember: boolean;
};

export const FeedbackModal = ({
  hideModal,
  show,
  projectHandle,
  userId,
  isAMember,
}: FeedbackModalProps) => {
  const [isEdit, setIsEdit] = useState(false);

  const {
    refetch,
    data: feedbacks,
    error: errorG,
    isLoading: isLoadingG,
  } = feedbackApi.useFeedbackGetQuery({
    projectHandle,
  });
  const [handleCreateMut, { error: errorC, isLoading: isLoadingC }] =
    feedbackApi.useFeedbackCreateMutation();
  const [handleUpdateMut, { error: errorU, isLoading: isLoadingU }] =
    feedbackApi.useFeedbackUpdateMutation();

  const feedback = feedbacks?.find(
    (f) => f.memberProjectRole.member.id === userId
  );
  const error = parseRtkError(errorG || errorC || errorU);
  const isLoading = isLoadingG || isLoadingC || isLoadingU;

  const handleCreate = async (d: FeedbackReqType) => {
    const [, res] = await awaitTo(
      handleCreateMut({
        feedbackReq: d,
        projectHandle,
      }).unwrap()
    );
    if (res?.id) {
      await refetch();
      setIsEdit(false);
    }
  };

  const handleUpdate = async (d: FeedbackReqType) => {
    if (!feedback) return;
    const [, res] = await awaitTo(
      handleUpdateMut({ feedbackReq: d, feedbackId: feedback.id }).unwrap()
    );
    if (res?.id) {
      await refetch();
      setIsEdit(false);
    }
  };

  return (
    <Modal closeModal={hideModal} show={show} large>
      {isEdit && (
        <FeedbackForm
          data={feedback}
          error={error}
          isLoading={isLoading}
          handleCreateUpdate={feedback ? handleUpdate : handleCreate}
        />
      )}
      {!isEdit && (
        <>
          <Flex marginBottom="32px" justifyBetween>
            <H2>Feedback</H2>
            {isAMember && !feedback && (
              <PrimaryButton onClick={() => setIsEdit(true)}>Add</PrimaryButton>
            )}
          </Flex>

          {feedbacks?.map((f, i) => (
            <Div key={f.id}>
              {Boolean(i) && (
                <Div
                  margin="32px 0"
                  css={css`
                    border-top: 1px solid ${({ theme }) => theme.borderColor};
                  `}
                />
              )}
              <Flex marginBottom="16px" flexWrap="wrap" alignCenter>
                <UserIconNameHandle {...f.memberProjectRole.member} />
                <T2>{formatDate(f.createdAt)}</T2>
              </Flex>
              <MdRender text={f.description} />
              {f.memberProjectRole.member.id === userId && (
                <PrimaryButton marginTop="16px" onClick={() => setIsEdit(true)}>
                  Edit
                </PrimaryButton>
              )}
            </Div>
          ))}
          {feedbacks && feedbacks.length === 0 && <NoneCard />}
        </>
      )}
    </Modal>
  );
};
