import { useMemo } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { fileActions } from "redux/actions";
import { useAppDispatch, useAppSelector } from "redux/store";

export const useFileState = () => {
  const state = useAppSelector((state) => state.fileReducer);
  const dispatch = useAppDispatch();
  const actions = useMemo(
    () => bindActionCreators(fileActions, dispatch),
    [dispatch]
  );

  return { state, actions };
};
