import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ThemeOptionType } from "library/types";
import { themeActionConsts } from "redux/actions";

const { SET_THEME } = themeActionConsts;

type ThemeReducerType = {
  theme: ThemeOptionType;
};

const ThemeReducerState: ThemeReducerType = {
  theme: localStorage.getItem("theme") as ThemeOptionType,
};

export const themeReducer = createReducer(ThemeReducerState, (builder) => {
  builder.addCase(
    SET_THEME,
    (state, { payload }: PayloadAction<ThemeOptionType>) => {
      state.theme = payload;
    }
  );
});
